<template>
  <div class="lang_wrapper">
    <!-- <div class="lang_item" @click="handleCommand('zh')">中文</div>
    <div class="lang_item" @click="handleCommand('en')">English</div> -->
    <el-dropdown @command="handleCommand">
      <span class="el-dropdown-link">
        <span :style="styleObj">{{ $t("msg.lang") }}</span>
      </span>
      <template #dropdown>
        <el-dropdown-menu>
          <el-dropdown-item command="zh">中文</el-dropdown-item>
          <el-dropdown-item command="en">English</el-dropdown-item>
        </el-dropdown-menu>
      </template>
    </el-dropdown>
  </div>
</template>

<script>
export default {
  name: "LangOption",
  props: {
    styleObj: {
      type: Object,
      default() {
        return {
          border: '1px solid #7F96CD',
          padding: '0px 10px',
          borderRadius: '3px'
        }
      }
    },
    position: {
      type: String
    }
  },
  created() {
    const lang = localStorage.getItem('lang')
    if (lang) this.$i18n.locale = lang
  },
  methods: {
    handleCommand(val) {
      this.$i18n.locale = val
      localStorage.setItem('lang', val)
      if (['login', 'home', 'finder'].includes(this.position)) {
        this.$listener.$emit('lang', val)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.lang_wrapper {
  display: flex;
  .lang_item {
    cursor: pointer;
    width: 60px;
    font-size: 14px;
    padding: 4px;
    color: rgb(18, 89, 184);
    border: 1px solid rgb(127, 150, 205);
    border-radius: 8px;
    margin-right: 8px;
  }
}
.el-dropdown-link {
  color: #fff;
  font-size: 13px;
}
.el-dropdown-link span  {
  word-break: keep-all;
}
</style>