<template>
    <!-- 电子卷宗  -->

    <div>
        <!-- 顶部搜索框开始 -->
        <div class="header" style="margin: 10px 0 10px 10px;">
            <el-row>
                <el-col :span="6">
                    <el-input v-model="searchContent" :placeholder="$t('msg.Pleaseenterhecasenumber')" @keyup.enter="serachClick"
                        :clearable="true"></el-input>
                </el-col>
                <el-col :span="2">
                    <el-button type="primary" @click="serachClick" class="butColor">
                        <el-icon class="el-icon--right">
                            <Search />
                        </el-icon>{{$t('msg.finder_search')}}
                    </el-button>
                </el-col>
                <el-col :span="16">
                    <!-- <el-switch v-model="switchType" :active-text="$t('msg.Sortbytime')" :inactive-text="$t('msg.sortbyType')" style="display: flex;">
                    </el-switch> -->
                </el-col>
            </el-row>
        </div>
        <!-- 顶部搜索框结束 -->

        <!-- 内容表格开始 -->
        <main>
            <el-table style="width: 100%" :data="tableData" v-loading="tableLoading">
                <el-table-column :label="$t('msg.case')" prop="date" width="300" align="center" sortable>
                    <template #default="scope">
                        <div>
                            <el-button type="text" @click="goTimeLine(scope.row)">
                                {{ scope.row.caseNo === null ? scope.row.receiveLetterNo !== null ? '未知 ' + ' (' +
                                    scope.row.receiveLetterNo + ')'
                                    : '未知' : scope.row.caseNo }}
                            </el-button>
                            <!-- <span v-else>未知</span> -->
                        </div>
                    </template>
                </el-table-column>
                <el-table-column :label="$t('msg.case_name')" prop="caseName" width="400" align="center" sortable>
                    <template #default="scope">
                        <span v-if="scope.row.caseName">{{ scope.row.caseName }}</span>
                        <span v-else>无</span>
                    </template>
                </el-table-column>
                <el-table-column :label="$t('msg.case_status')" prop="caseStateStr" width="300" align="center" sortable>
                    <template #default="scope">
                        <span v-if="scope.row.caseStateStr">{{ scope.row.caseStateStr }}</span>
                        <span v-else>无</span>
                    </template>
                </el-table-column>
                <el-table-column :label="$t('msg.Operate')" align="center">
                    <template #default="scope">

                        <el-button type="primary" @click="goTimeLine(scope.row)" size="small"
                            class="butColor">{{$t('msg.Dossier')}}</el-button>
                        <span>&nbsp;&nbsp;|&nbsp;&nbsp;</span>
                        <el-button type="primary" @click="goCaseDetail(scope.row)" size="small"
                            class="butColor">{{$t('msg.ElectronicProgram')}}</el-button>
                        <span>&nbsp;&nbsp;|&nbsp;&nbsp;</span>
                        <el-button type="primary" size="small" class="butColor" @click="goL3(scope.row)">{{$t('msg.Factsofhecase')}}</el-button>

                    </template>

                </el-table-column>


            </el-table>

        </main>
        <!-- 内容表格结束 -->
        <!-- 分页器开始 -->
        <div class="paginationStyle">
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="current"
                :page-sizes="[100, 200, 300, 400]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper"
                :total="total">
            </el-pagination>
        </div>
        <!-- 分页器结束 -->

    </div>
</template>
 
<script>
export default {
    name: 'CaseFiles',
    components: {},
    data() {
        return {
            tableData: [],
            // 切换按钮状态
            switchType: false,
            // 搜索内容 
            searchContent: '',
            // 当前页数
            current: 1,
            // 分页器默认显示当前条数
            pageSize: 100,
            // 总条数
            total: 0,
            // 表格加载等待效果
            tableLoading: false
        };
    },
    methods: {
        load() {
            this.current = 1
            this.searchContent = ''
            this.GetCaseList()
        },
        // 去时间线页面事件
        goTimeLine(row) {

            if (this.switchType) {
                this.$emit('goPage', row, 'timeLine')
            } else {
                this.$emit('goPage', row, 'eventGraph')
            }
        },
        // 页面打开调用函数
        async GetCaseList() {
            this.tableLoading = true
            // this.total = 0
            let param = {
                "req": {
                    "current": this.current,
                    "size": this.pageSize,
                    "q": {
                        "caseNo": this.searchContent
                    }
                }
            }
            let data = await this.$API.sendRequest('CaseListInternal', param)
            if (data.success) {
                this.tableData = data.data.records
                this.total = data.data.total
                this.tableLoading = false
            } else {
                this.$message.error('请求数据失败')
            }

        },
        // 分页器功能 --- 每页条数发生变化时触发
        handleSizeChange(val) {
            // console.log(`每页 ${val} 条`);

            this.pageSize = val
            this.GetCaseList()
            // this.listReceive(1, this.pageSize)
        },
        // 分页器功能 --- 当前页数发生变化时触发
        handleCurrentChange(val) {
            // console.log(`当前页 ${val} 条`);
            this.current = val
            // 获取最大页数
            let pageMax = Math.ceil(this.total / val)
            // 判断跳转页数是否大于最大页数，如果大于，跳转页数就等于最大页数
            if (this.pageSize > pageMax) {
                this.pageSize = pageMax
            }
            this.GetCaseList()

            // this.listReceive(this.current)
        },
        // 点击搜索
        serachClick() {
            this.GetCaseList()
        },
        // 去往案件的描述详情页面
        goCaseDetail(row) {
            this.$emit('goPage', row, 'CaseDetail')
        },
        // 去往案情页
        goL3(row) {
            this.$emit('goPage', row, 'CaseFiles_L3')
        }

    },


    watch: {
        // 当输入框的内容长度为0则重新请求
        searchContent(newVal) {
            if (newVal.length !== 0) return
            this.GetCaseList()
        }
    }
};
</script>
 
<style scoped lang="scss">
.search {
    padding-left: 10px;
}

.paginationStyle {
    margin-top: 10px;
    display: flex;
    justify-content: center;
}

// :deep([type="primary"]) {
//     background-color: #79bbff;
// }
:deep(.butColor) {
    background-color: #79bbff;
}
</style>