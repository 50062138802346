<template>
    <div>
        <!-- 事件流程表 -->
        <div class="tree" v-loading="loading">
            <el-row style="margin: 10px">
                <el-col
                    :span="8"
                    :class="[caseNo ? 'topTitle' : 'opacityShow']"
                >
                    {{ caseNo }}号案{{$t('msg.ElectronicDossier')}}
                </el-col>
                <el-col :span="4">
                    <div @click="goCaseFileL2">
                        <el-switch
                            v-model="switchType"
                            :active-text="$t('msg.Sortbytime')"
                            :inactive-text="$t('msg.sortbyType')"
                            style="display: flex; justify-content: center"
                        >
                        </el-switch>
                    </div>
                </el-col>
                <el-col :span="12">
                   <p style="color: red;text-align: right;font-size: 14px;margin-top: 0;">
                    注:扫描版PDF内的文字可Copy
                   </p>
                </el-col>
            </el-row>
            <h3>{{$t('msg.PartyMaterial')}}</h3>
            <el-collapse v-model="activeNames">
                <el-collapse-item
                    v-for="(item, index) in activity.caseTypeVos"
                    :key="index"
                    :title="
                        item.evidenceTypeName +
                        '(' +
                        item.caseTimeLenDetailVos.length +
                        ')'
                    "
                    :name="index"
                >
                    <div class="box">
                        <el-table
                            :data="item.caseTimeLenDetailVos"
                            stripe
                            style="width: 100%; display: flex; font-size: 15px"
                            :row-style="{ height: '50px' }"
                        >
                            <el-table-column
                                prop="receiveLetterNo"
                                :label="$t('msg.ReceiptletterNumber')"
                                min-width="10%"
                            >
                            </el-table-column>

                            <el-table-column
                                prop="submitDate"
                                :label="$t('msg.DateofReceipt')"
                                min-width="10%"
                            >
                            </el-table-column>

                            <el-table-column
                                prop="evidenceDesc"
                                :label="$t('msg.FileDescription')"
                                min-width="35%"
                            >
                                <template #default="scope">
                                    <!-- {{ scope.row.filePath }} -->
                                    <div v-if="scope.row.evidenceDesc">
                                        {{ scope.row.evidenceDesc }}
                                    </div>
                                    <div v-else>{{$t('msg.Nofiles')}}</div>
                                </template>
                            </el-table-column>

                            <el-table-column :label="$t('msg.ScannedVersion')" min-width="20%">
                                <template #default="scope">
                                    <!-- {{ scope.row.filePath }} -->
                                    <el-dropdown
                                        trigger="hover"
                                        v-if="scope.row.filePath"
                                    >
                                        <span class="el-dropdown-link">
                                            <div style="margin: 0 10px">
                                                <svg
                                                    class="icon"
                                                    aria-hidden="true"
                                                >
                                                    <use
                                                        xlink:href="#icon-pdf"
                                                    ></use>
                                                </svg>
                                            </div>
                                            <p>
                                                {{ scope.row.fileNameDsp }}.pdf
                                            </p>
                                        </span>
                                        <template #dropdown>
                                            <el-dropdown-menu>
                                                <el-dropdown-item
                                                    @click="
                                                        PreviewPdfClick(
                                                            scope.row
                                                        )
                                                    "
                                                    >{{$t('msg.Preview')}}</el-dropdown-item
                                                >
                                                <el-dropdown-item
                                                    @click="
                                                        PdfUpload(scope.row)
                                                    "
                                                    >{{$t('msg.Download')}}</el-dropdown-item
                                                >
                                            </el-dropdown-menu>
                                        </template>
                                    </el-dropdown>
                                    <div v-else class="elseFlexSpan">
                                        <div v-if="scope.row.oldFileName">
                                        <!-- <span>{{$t('msg.Nofiles')}}</span> -->
                                        <span>
                                            提示：点完识别后，请耐心等待，不用重复点。识别后文档可检索可拷贝。
                                        </span>
                                        <span 
                                            ><el-button
                                                @click="
                                                    identifyClick(scope.row)
                                                "
                                            >
                                                {{$t('msg.Clicktoidentify')}}</el-button
                                            ></span
                                        >
                                    </div>
                                    <div v-else>
                                        无文件
                                    </div>
                                    </div>
                                </template>
                            </el-table-column>

                            <!-- 影印版  -->
                            <el-table-column :label="$t('msg.Photocopy')" min-width="20%">
                                <template #default="scope">
                                    <div class="dropdownItem">
                                        <el-dropdown
                                            trigger="hover"
                                            v-if="scope.row.oldFileName"
                                        >
                                            <span class="el-dropdown-link">
                                                <div
                                                    v-if="
                                                        scope.row.oldFileName.endsWith(
                                                            '.pdf'
                                                        )
                                                    "
                                                    style="margin: 0 10px"
                                                >
                                                    <svg
                                                        class="icon"
                                                        aria-hidden="true"
                                                    >
                                                        <use
                                                            xlink:href="#icon-pdf"
                                                        ></use>
                                                    </svg>
                                                </div>
                                                <div
                                                    v-else
                                                    style="margin: 0 10px"
                                                >
                                                    <svg
                                                        t="1694139430288"
                                                        class="icon"
                                                        viewBox="0 0 1024 1024"
                                                        version="1.1"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        p-id="5036"
                                                        width="15"
                                                        height="15"
                                                    >
                                                        <path
                                                            d="M137.902164 0.00045a48.572979 48.572979 0 0 0-35.588984 15.293993 53.964976 53.964976 0 0 0-15.272993 35.639985v917.411596a48.706979 48.706979 0 0 0 15.272993 35.639985 49.970978 49.970978 0 0 0 35.589984 15.292993h746.335672a48.639979 48.639979 0 0 0 35.589985-15.292993 50.369978 50.369978 0 0 0 15.272993-35.639985V288.717323L646.727941 0.00045H137.902164z"
                                                            fill="#FF5562"
                                                            p-id="5037"
                                                        ></path>
                                                        <path
                                                            d="M935.101814 288.717323H697.655918c-27.821988-0.666-50.226978-23.07599-50.927977-50.933977V0.00045l288.373873 288.716873z"
                                                            fill="#FFBBC0"
                                                            p-id="5038"
                                                        ></path>
                                                        <path
                                                            d="M772.289886 715.070136L666.049932 550.303208a16.593993 16.593993 0 0 0-14.411993-7.649996 18.851992 18.851992 0 0 0-14.407994 7.649996l-56.836975 88.299961L453.171026 432.47026a16.526993 16.526993 0 0 0-14.406994-7.643997 18.851992 18.851992 0 0 0-14.412993 7.649997L249.657115 715.263136a15.446993 15.446993 0 0 0 0 16.957992 15.666993 15.666993 0 0 0 14.406994 8.508996h493.547783a18.851992 18.851992 0 0 0 15.272993-8.509996 16.977993 16.977993 0 0 0-0.793999-16.956992l0.2-0.2zM628.069949 403.149273a42.489981 42.489981 0 0 0 26.142989 39.305983 42.387981 42.387981 0 0 0 46.264979-9.169996 42.531981 42.531981 0 0 0 9.225996-46.30998A42.429981 42.429981 0 0 0 670.49993 360.730291c-23.40899 0-42.393981 18.979992-42.434981 42.418982z"
                                                            fill="#FFFFFF"
                                                            p-id="5039"
                                                        ></path>
                                                    </svg>
                                                </div>
                                                <p>
                                                    {{ scope.row.oldFileName }}
                                                </p>
                                            </span>
                                            <template #dropdown>
                                                <el-dropdown-menu>
                                                    <el-dropdown-item
                                                        @click="
                                                            clickDropdown(
                                                                scope.row
                                                                    .caseTimLenSonVos[0]
                                                            )
                                                        "
                                                        >{{$t('msg.Preview')}}</el-dropdown-item
                                                    >
                                                    <el-dropdown-item
                                                        @click="
                                                            clickDownLoad(
                                                                scope.row
                                                                    .caseTimLenSonVos[0]
                                                            )
                                                        "
                                                        >{{$t('msg.Download')}}</el-dropdown-item
                                                    >
                                                </el-dropdown-menu>
                                            </template>
                                        </el-dropdown>
                                        <div v-else style="margin: 0 10px">
                                           {{$t('msg.Nofiles')}}
                                        </div>
                                    </div>
                                    <div
                                        class="butSpan"
                                        v-if="scope.row.caseTimLenSonVos"
                                    >
                                        <el-dropdown
                                            trigger="click"
                                            size="large"
                                        >
                                            <span
                                                class="el-dropdown-link"
                                                @click="
                                                    clickDetail(
                                                        scope.row
                                                            .caseTimLenSonVos
                                                    )
                                                "
                                                v-show="
                                                    scope.row.caseTimLenSonVos
                                                        .length > 1
                                                        ? true
                                                        : false
                                                "
                                            >
                                                {{$t('msg.workbench_more')}}
                                                <el-icon class="el-icon--right">
                                                    <arrow-down />
                                                </el-icon>
                                            </span>
                                            <template #dropdown>
                                                <el-dropdown-menu
                                                    v-if="caseTimeList"
                                                >
                                                    <el-dropdown-item
                                                        v-for="(
                                                            items, index
                                                        ) in caseTimeList"
                                                        :key="index"
                                                    >
                                                        <div class="imgBox">
                                                            <svg
                                                                class="icon"
                                                                aria-hidden="true"
                                                                style="
                                                                    margin-right: 10px;
                                                                "
                                                                v-if="
                                                                    items.fileNameDsp.endsWith(
                                                                        '.pdf'
                                                                    )
                                                                "
                                                            >
                                                                <use
                                                                    xlink:href="#icon-pdf"
                                                                ></use>
                                                            </svg>
                                                            <svg
                                                                v-else
                                                                t="1694139430288"
                                                                class="icon"
                                                                style="
                                                                    margin-right: 10px;
                                                                "
                                                                viewBox="0 0 1024 1024"
                                                                version="1.1"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                p-id="5036"
                                                                width="26"
                                                                height="26"
                                                            >
                                                                <path
                                                                    d="M137.902164 0.00045a48.572979 48.572979 0 0 0-35.588984 15.293993 53.964976 53.964976 0 0 0-15.272993 35.639985v917.411596a48.706979 48.706979 0 0 0 15.272993 35.639985 49.970978 49.970978 0 0 0 35.589984 15.292993h746.335672a48.639979 48.639979 0 0 0 35.589985-15.292993 50.369978 50.369978 0 0 0 15.272993-35.639985V288.717323L646.727941 0.00045H137.902164z"
                                                                    fill="#FF5562"
                                                                    p-id="5037"
                                                                ></path>
                                                                <path
                                                                    d="M935.101814 288.717323H697.655918c-27.821988-0.666-50.226978-23.07599-50.927977-50.933977V0.00045l288.373873 288.716873z"
                                                                    fill="#FFBBC0"
                                                                    p-id="5038"
                                                                ></path>
                                                                <path
                                                                    d="M772.289886 715.070136L666.049932 550.303208a16.593993 16.593993 0 0 0-14.411993-7.649996 18.851992 18.851992 0 0 0-14.407994 7.649996l-56.836975 88.299961L453.171026 432.47026a16.526993 16.526993 0 0 0-14.406994-7.643997 18.851992 18.851992 0 0 0-14.412993 7.649997L249.657115 715.263136a15.446993 15.446993 0 0 0 0 16.957992 15.666993 15.666993 0 0 0 14.406994 8.508996h493.547783a18.851992 18.851992 0 0 0 15.272993-8.509996 16.977993 16.977993 0 0 0-0.793999-16.956992l0.2-0.2zM628.069949 403.149273a42.489981 42.489981 0 0 0 26.142989 39.305983 42.387981 42.387981 0 0 0 46.264979-9.169996 42.531981 42.531981 0 0 0 9.225996-46.30998A42.429981 42.429981 0 0 0 670.49993 360.730291c-23.40899 0-42.393981 18.979992-42.434981 42.418982z"
                                                                    fill="#FFFFFF"
                                                                    p-id="5039"
                                                                ></path>
                                                            </svg>
                                                            <span>{{
                                                                items.fileNameDsp
                                                            }}</span>
                                                        </div>
                                                        <div class="butBox">
                                                            <el-button
                                                                type="primary"
                                                                size="small"
                                                                @click="
                                                                    clickDropdown(
                                                                        items
                                                                    )
                                                                "
                                                                >{{$t('msg.Preview')}}</el-button
                                                            >
                                                            <el-button
                                                                type="primary"
                                                                size="small"
                                                                @click="
                                                                    clickDownLoad(
                                                                        items
                                                                    )
                                                                "
                                                                >{{$t('msg.Download')}}</el-button
                                                            >
                                                        </div>
                                                    </el-dropdown-item>
                                                </el-dropdown-menu>
                                                <div v-else>{{$t('msg.Nofiles')}}</div>
                                            </template>
                                        </el-dropdown>
                                    </div>
                                </template>
                            </el-table-column>

                            <!-- 按钮 -->
                            <!-- <el-table-column
                                prop="receiveLetterNo"
                                label="操作"
                                min-width="5%"
                            >
                                <template #default="scope">
                                    <div>
                                        <el-button
                                            round
                                            @click="clickEdit(scope.row)"
                                            title="变更材料"
                                        >
                                            <svg
                                                class="icon"
                                                aria-hidden="true"
                                            >
                                                <use
                                                    xlink:href="#icon-zhuanyi2-copy"
                                                ></use>
                                            </svg>
                                        </el-button>
                                    </div>
                                </template>
                            </el-table-column> -->
                        </el-table>
                    </div>
                </el-collapse-item>
            </el-collapse>

            <h3>{{$t('msg.IssuanceOfCommunicationsByTheCaseManager')}}</h3>
            <el-collapse v-model="activeNamesTwo">
                <el-collapse-item
                    v-for="(item, index) in activity.caseTypeVosDocument"
                    :key="index"
                    :title="
                        item.evidenceTypeName +
                        '(' +
                        item.documentVos.length +
                        ')'
                    "
                    :name="index"
                >
                    <div class="box">
                        <el-table
                            :data="item.documentVos"
                            stripe
                            style="width: 100%; display: flex; font-size: 15px"
                            :row-style="{ height: '50px' }"
                        >
                            <el-table-column
                                prop="letterCode"
                                :label="$t('msg.IssueletterNumber')"
                                min-width="10%"
                            >
                            </el-table-column>

                            <el-table-column
                                prop="sendDate"
                                :label="$t('msg.PublicationDate')"
                                min-width="10%"
                            >
                            </el-table-column>

                            <el-table-column
                                prop="letterTitle"
                                :label="$t('msg.FileDescription')"
                                min-width="40%"
                            >
                            </el-table-column>

                            <el-table-column
                                prop="templateTypeName"
                                :label="$t('msg.Material')"
                                min-width="40%"
                            >
                            </el-table-column>

                            <el-table-column :label="$t('msg.ScannedVersion')" min-width="20%">
                                <template #default="scope">
                                    <!-- {{ scope.row.filePath }} -->
                                    <el-dropdown
                                        trigger="hover"
                                        v-if="scope.row.filePath"
                                    >
                                        <span class="el-dropdown-link">
                                            <div style="margin: 0 10px">
                                                <svg
                                                    class="icon"
                                                    aria-hidden="true"
                                                >
                                                    <use
                                                        xlink:href="#icon-pdf"
                                                    ></use>
                                                </svg>
                                            </div>
                                            <p>
                                                {{ scope.row.fileNameDsp }}.pdf
                                            </p>
                                        </span>
                                        <template #dropdown>
                                            <el-dropdown-menu>
                                                <el-dropdown-item
                                                    @click="
                                                        PreviewPdfClick(
                                                            scope.row
                                                        )
                                                    "
                                                    >{{$t('msg.Preview')}}</el-dropdown-item
                                                >
                                                <el-dropdown-item
                                                    @click="
                                                        PdfUpload(scope.row)
                                                    "
                                                    >{{$t('msg.Download')}}</el-dropdown-item
                                                >
                                            </el-dropdown-menu>
                                        </template>
                                    </el-dropdown>
                                    <div v-else>{{$t('msg.Nofiles')}}</div>
                                </template>
                            </el-table-column>

                            <!-- 影印版  -->
                            <el-table-column :label="$t('msg.Photocopy')" min-width="20%">
                                <template #default="scope">
                                    <div class="dropdownItem">
                                        <el-dropdown
                                            trigger="hover"
                                            v-if="scope.row.oldFileName"
                                        >
                                            <span class="el-dropdown-link">
                                                <div
                                                    v-if="
                                                        scope.row.oldFileName.endsWith(
                                                            '.pdf'
                                                        )
                                                    "
                                                    style="margin: 0 10px"
                                                >
                                                    <svg
                                                        class="icon"
                                                        aria-hidden="true"
                                                    >
                                                        <use
                                                            xlink:href="#icon-pdf"
                                                        ></use>
                                                    </svg>
                                                </div>
                                                <div
                                                    v-else
                                                    style="margin: 0 10px"
                                                >
                                                    <svg
                                                        t="1694139430288"
                                                        class="icon"
                                                        viewBox="0 0 1024 1024"
                                                        version="1.1"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        p-id="5036"
                                                        width="15"
                                                        height="15"
                                                    >
                                                        <path
                                                            d="M137.902164 0.00045a48.572979 48.572979 0 0 0-35.588984 15.293993 53.964976 53.964976 0 0 0-15.272993 35.639985v917.411596a48.706979 48.706979 0 0 0 15.272993 35.639985 49.970978 49.970978 0 0 0 35.589984 15.292993h746.335672a48.639979 48.639979 0 0 0 35.589985-15.292993 50.369978 50.369978 0 0 0 15.272993-35.639985V288.717323L646.727941 0.00045H137.902164z"
                                                            fill="#FF5562"
                                                            p-id="5037"
                                                        ></path>
                                                        <path
                                                            d="M935.101814 288.717323H697.655918c-27.821988-0.666-50.226978-23.07599-50.927977-50.933977V0.00045l288.373873 288.716873z"
                                                            fill="#FFBBC0"
                                                            p-id="5038"
                                                        ></path>
                                                        <path
                                                            d="M772.289886 715.070136L666.049932 550.303208a16.593993 16.593993 0 0 0-14.411993-7.649996 18.851992 18.851992 0 0 0-14.407994 7.649996l-56.836975 88.299961L453.171026 432.47026a16.526993 16.526993 0 0 0-14.406994-7.643997 18.851992 18.851992 0 0 0-14.412993 7.649997L249.657115 715.263136a15.446993 15.446993 0 0 0 0 16.957992 15.666993 15.666993 0 0 0 14.406994 8.508996h493.547783a18.851992 18.851992 0 0 0 15.272993-8.509996 16.977993 16.977993 0 0 0-0.793999-16.956992l0.2-0.2zM628.069949 403.149273a42.489981 42.489981 0 0 0 26.142989 39.305983 42.387981 42.387981 0 0 0 46.264979-9.169996 42.531981 42.531981 0 0 0 9.225996-46.30998A42.429981 42.429981 0 0 0 670.49993 360.730291c-23.40899 0-42.393981 18.979992-42.434981 42.418982z"
                                                            fill="#FFFFFF"
                                                            p-id="5039"
                                                        ></path>
                                                    </svg>
                                                </div>
                                                <p>
                                                    {{ scope.row.oldFileName }}
                                                </p>
                                            </span>
                                            <template #dropdown>
                                                <el-dropdown-menu>
                                                    <el-dropdown-item
                                                        @click="
                                                            clickDropdown(
                                                                scope.row
                                                                    .caseTimLenSonVos[0]
                                                            )
                                                        "
                                                        >{{$t('msg.Preview')}}</el-dropdown-item
                                                    >
                                                    <el-dropdown-item
                                                        @click="
                                                            clickDownLoad(
                                                                scope.row
                                                                    .caseTimLenSonVos[0]
                                                            )
                                                        "
                                                        >{{$t('msg.Download')}}</el-dropdown-item
                                                    >
                                                </el-dropdown-menu>
                                            </template>
                                        </el-dropdown>
                                        <div v-else style="margin: 0 10px">
                                           {{$t('msg.Nofiles')}}
                                        </div>
                                    </div>
                                    <div
                                        class="butSpan"
                                        v-if="scope.row.caseTimLenSonVos"
                                    >
                                        <el-dropdown
                                            trigger="click"
                                            size="large"
                                        >
                                            <span
                                                class="el-dropdown-link"
                                                @click="
                                                    clickDetail(
                                                        scope.row
                                                            .caseTimLenSonVos
                                                    )
                                                "
                                                v-show="
                                                    scope.row.caseTimLenSonVos
                                                        .length > 1
                                                        ? true
                                                        : false
                                                "
                                            >
                                                 {{$t('msg.workbench_more')}}
                                                <el-icon class="el-icon--right">
                                                    <arrow-down />
                                                </el-icon>
                                            </span>
                                            <template #dropdown>
                                                <el-dropdown-menu
                                                    v-if="caseTimeList"
                                                >
                                                    <el-dropdown-item
                                                        v-for="(
                                                            items, index
                                                        ) in caseTimeList"
                                                        :key="index"
                                                    >
                                                        <div class="imgBox">
                                                            <svg
                                                                class="icon"
                                                                aria-hidden="true"
                                                                style="
                                                                    margin-right: 10px;
                                                                "
                                                                v-if="
                                                                    items.fileNameDsp.endsWith(
                                                                        '.pdf'
                                                                    )
                                                                "
                                                            >
                                                                <use
                                                                    xlink:href="#icon-pdf"
                                                                ></use>
                                                            </svg>
                                                            <svg
                                                                v-else
                                                                t="1694139430288"
                                                                class="icon"
                                                                style="
                                                                    margin-right: 10px;
                                                                "
                                                                viewBox="0 0 1024 1024"
                                                                version="1.1"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                p-id="5036"
                                                                width="26"
                                                                height="26"
                                                            >
                                                                <path
                                                                    d="M137.902164 0.00045a48.572979 48.572979 0 0 0-35.588984 15.293993 53.964976 53.964976 0 0 0-15.272993 35.639985v917.411596a48.706979 48.706979 0 0 0 15.272993 35.639985 49.970978 49.970978 0 0 0 35.589984 15.292993h746.335672a48.639979 48.639979 0 0 0 35.589985-15.292993 50.369978 50.369978 0 0 0 15.272993-35.639985V288.717323L646.727941 0.00045H137.902164z"
                                                                    fill="#FF5562"
                                                                    p-id="5037"
                                                                ></path>
                                                                <path
                                                                    d="M935.101814 288.717323H697.655918c-27.821988-0.666-50.226978-23.07599-50.927977-50.933977V0.00045l288.373873 288.716873z"
                                                                    fill="#FFBBC0"
                                                                    p-id="5038"
                                                                ></path>
                                                                <path
                                                                    d="M772.289886 715.070136L666.049932 550.303208a16.593993 16.593993 0 0 0-14.411993-7.649996 18.851992 18.851992 0 0 0-14.407994 7.649996l-56.836975 88.299961L453.171026 432.47026a16.526993 16.526993 0 0 0-14.406994-7.643997 18.851992 18.851992 0 0 0-14.412993 7.649997L249.657115 715.263136a15.446993 15.446993 0 0 0 0 16.957992 15.666993 15.666993 0 0 0 14.406994 8.508996h493.547783a18.851992 18.851992 0 0 0 15.272993-8.509996 16.977993 16.977993 0 0 0-0.793999-16.956992l0.2-0.2zM628.069949 403.149273a42.489981 42.489981 0 0 0 26.142989 39.305983 42.387981 42.387981 0 0 0 46.264979-9.169996 42.531981 42.531981 0 0 0 9.225996-46.30998A42.429981 42.429981 0 0 0 670.49993 360.730291c-23.40899 0-42.393981 18.979992-42.434981 42.418982z"
                                                                    fill="#FFFFFF"
                                                                    p-id="5039"
                                                                ></path>
                                                            </svg>
                                                            <span>{{
                                                                items.fileNameDsp
                                                            }}</span>
                                                        </div>
                                                        <div class="butBox">
                                                            <el-button
                                                                type="primary"
                                                                size="small"
                                                                @click="
                                                                    clickDropdown(
                                                                        items
                                                                    )
                                                                "
                                                                >{{$t('msg.Preview')}}</el-button
                                                            >
                                                            <el-button
                                                                type="primary"
                                                                size="small"
                                                                @click="
                                                                    clickDownLoad(
                                                                        items
                                                                    )
                                                                "
                                                                >{{$t('msg.Download')}}</el-button
                                                            >
                                                        </div>
                                                    </el-dropdown-item>
                                                </el-dropdown-menu>
                                                <div v-else>{{$t('msg.Nofiles')}}</div>
                                            </template>
                                        </el-dropdown>
                                    </div>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </el-collapse-item>
            </el-collapse>
        </div>

        <el-dialog
            :title="$t('msg.Nofiles') + dialogTitle"
            width="50%"
            :before-close="handleClose"
            v-model="dialog"
        >
            <el-divider></el-divider>
            <div class="tableInput">
                <el-row>
                    <el-col :span="12" style="color: red; font-size: 20px"
                        >{{$t('msg.TheCurrentDirectory')}}</el-col
                    >
                    <el-col
                        :span="12"
                        style="color: rgb(0, 72, 255); font-size: 20px"
                        >{{$t('msg.TheDirectoryAftertheChange')}}</el-col
                    >
                </el-row>
                <el-row style="margin-top: 30px">
                    <el-col :span="10">
                        <el-row>
                            <el-col :span="8">{{$t('msg.case')}}</el-col>
                            <el-col :span="16">
                                <el-input
                                    v-model="caseNo"
                                    :disabled="true"
                                ></el-input>
                            </el-col>
                        </el-row>
                        <el-row style="margin-top: 30px">
                            <el-col :span="8">{{$t('msg.MaterialType')}}</el-col>
                            <el-col :span="16">
                                <el-input
                                    v-model="dialogEvidenceTypeName"
                                    :disabled="true"
                                ></el-input>
                            </el-col>
                        </el-row>
                    </el-col>
                    <el-col :span="4">
                        <div class="iconFont">
                            <svg class="icon" aria-hidden="true">
                                <use xlink:href="#icon-transfer-copy"></use>
                            </svg>
                        </div>
                    </el-col>
                    <el-col :span="10" style="margin-left: -30px">
                        <el-row>
                            <el-col :span="8">{{$t('msg.case')}}</el-col>
                            <el-col :span="16">
                                <el-input
                                    v-model="dialogCaseNo"
                                    placeholder="请输入完整的案件编号"
                                ></el-input>
                            </el-col>
                        </el-row>
                        <el-row style="margin-top: 30px">
                            <el-col :span="8">{{$t('msg.MaterialType')}}</el-col>
                            <el-col :span="16">
                                <el-select
                                    v-model="selectActive"
                                    filterable
                                    :placeholder="$t('msg.PleaseSelect')"
                                >
                                    <el-option
                                        v-for="item in selectList"
                                        :key="item.dictionaryItemValue"
                                        :label="item.dictionaryItemName"
                                        :value="item.dictionaryItemValue"
                                    >
                                    </el-option>
                                </el-select>
                            </el-col>
                        </el-row>
                    </el-col>
                </el-row>
            </div>
            <template #footer>
                <span class="dialog-footer">
                    <el-button type="primary" @click="submitDialog"
                        >{{$t('msg.Alteration')}}</el-button
                    >
                    <el-button
                        @click="handleClose"
                        style="background-color: #fff"
                    >
                       {{$t('msg.case_bind_cancel')}}
                    </el-button>
                </span>
            </template>
        </el-dialog>
    </div>
</template>
 
<script>
export default {
    name: '',
    data() {
        return {
            // 加载效果
            loading: false,
            // 被选择的数据
            activity: {},
            // 获取的数据---》要是局部点击切换需要数据
            caseId: '',
            switchType: false,
            // 源文件详情数组
            caseTimeList: [],
            activeNames: [],
            activeNamesTwo: [],
            caseNo: '',
            // 弹出框
            dialog: false,
            // 弹出框中的title
            dialogTitle: '',
            // 弹出框中的材料类型
            dialogEvidenceTypeName: '',
            // 弹出框中的下拉框
            selectList: [],
            selectActive: 1,
            // 输入新的案件编号
            dialogCaseNo: '',
            // 原来数据对象
            oldObj: {},
            // 传递过来的对象
            oldRow: {}
        }
    },
    async created() {
        if (this.$route.query.token) {
            localStorage.setItem('oneToken', this.$route.query.token)
            this.GetOneTimeToken(this.$route.query.token)
        }
    },

    methods: {
        load(row) {
            this.caseNo = row.caseNo
            this.oldRow = row
            this.CaseTimeLenDetail(row)
        },

        // 根据id获取详细信息
        async CaseTimeLenDetail(row) {
            this.activity = {}
            this.activeNames = []
            this.activeNamesTwo = []
            this.loading = true
            this.caseId = row
            let param
            if (typeof row === 'number') {
                param = {
                    req: row
                }
            } else {
                param = {
                    req: row.caseId
                }
            }
            let res = await this.$API.sendRequest('CaseDetail', param)
            if (!res.success) {
                this.loading = false
                return this.$message.error(res.msg)
            }
            this.caseNo = res.data.caseNo
            this.loading = false
            this.activity = res.data
        },

        // 点击预览pdf
        async PreviewPdfClick(row) {
            this.loading = true
            let param = {
                req: {
                    receiveLetterNo: row.receiveLetterNo  || row.letterCode,
                    caseId:typeof row === 'number' ? this.caseId : this.caseId.caseId
                }
            }
            const paramRes = await this.$API.sendRequest('OcrDownFile', param)
            if (paramRes.status === 200) {
                const blob = new Blob([paramRes.data], {
                    type: 'application/pdf'
                })
                let url = window.URL.createObjectURL(blob)
                window.open(url)
                this.loading = false
            } else {
                this.loading = false
                this.$message.error('预览失败')
            }
        },

        // 点击下载pdf
        async PdfUpload(row) {
            this.loading = true

             let param = {
                req: {
                    receiveLetterNo: row.receiveLetterNo  || row.letterCode,
                    caseId:typeof row === 'number' ? this.caseId : this.caseId.caseId
                }
            }
            const paramRes = await this.$API.sendRequest('OcrDownFile', param)
            if (paramRes.status === 200) {
                const blob = new Blob([paramRes.data], {
                    type: 'application/pdf'
                })
                let url = window.URL.createObjectURL(blob)
                let fileName = paramRes.headers['content-disposition']
                let index = fileName.indexOf('=')
                let result = fileName.substring(index + 1, fileName.length)
                let link = document.createElement('a')
                link.href = url
                link.download = decodeURIComponent(result) + '.pdf'
                link.click()
                this.loading = false
                window.URL.revokeObjectURL(link.href)
            } else {
                this.loading = false
                this.$message.error('下载失败')
            }
        },
        goCaseFileL2() {
            let caseId = this.$route.query.caseId
            if (caseId) {
                this.$router.push(
                    'CaseFilePages_L2?caseId=' +
                        caseId +
                        '&token=' +
                        localStorage.getItem('oneToken')
                )
            } else {
                this.$emit('goCaseFilePages_L2')
            }
        },
        // 影印版获取当前行
        clickDetail(row) {
            this.caseTimeList = row
        },

        // 预览影印版事件
        async clickDropdown(row) {
            let param = {
                req: {
                    fileNameDsp: row.fileNameDsp,
                    filePath: row.filePath,
                    flagPdf: row.flagPdf
                }
            }
            const res = await this.$API.sendRequest(
                'ocrDownFilePathAndName',
                param
            )
            let blob
            if (row.flagPdf === 1) {
                blob = new Blob([res], { type: 'application/pdf' })
                window.open(window.URL.createObjectURL(blob), '_blank')
            } else {
                // this.dialogPreview = true
                blob = new Blob([res], { type: 'image/jpeg' })
                let newWindow = window.open(
                    window.URL.createObjectURL(blob),
                    '_blank'
                )
                newWindow.onload = () => {
                    let button = document.createElement('button')
                    this.$GF.cretateRotateBtn(newWindow, button)
                }
            }
        },

        // 点击下载影印版事件
        async clickDownLoad(row) {
            let param = {
                req: {
                    fileNameDsp: row.fileNameDsp,
                    filePath: row.filePath,
                    flagPdf: row.flagPdf
                }
            }
            const res = await this.$API.sendRequest(
                'ocrDownFilePathAndName',
                param
            )
            let blob
            if (row.flagPdf === 1) {
                blob = new Blob([res], { type: 'application/pdf' })
            } else {
                // this.dialogPreview = true
                blob = new Blob([res], { type: 'image/jpeg' })
            }
            let url = window.URL.createObjectURL(blob)
            let link = document.createElement('a')
            link.href = url
            link.download = row.fileNameDsp
            link.click()
            window.URL.revokeObjectURL(link.href)
        },

        // 点击编辑
        async clickEdit(row) {
            let res = await this.$API.sendRequest('styleTypeList', { req: 30 })

            this.selectList = res.data.materialStyleList
            this.dialog = true
            this.oldObj = row
            this.dialogTitle = row.receiveLetterNo
            this.dialogEvidenceTypeName = row.evidenceTypeName
        },
        // 关闭弹窗事件
        handleClose() {
            this.dialog = false
        },
        // 点击弹出框中的提交
        async submitDialog() {
            if (!this.dialogCaseNo.trim())
                return this.$message.error('案件编号不能为空')
            let param = {
                req: {
                    // 收文函号
                    receiveLetterNo: this.oldObj.receiveLetterNo,
                    // 转移前案件编号
                    caseNoOld: this.caseNo || '',
                    // 转移前材料类型ID
                    typeIdOld: this.oldObj.evidenceType,
                    // 转移前材料类型名称
                    typeNameOld: this.oldObj.evidenceTypeName,

                    // 转移后案件编号
                    caseNoNew: this.dialogCaseNo,
                    // 转移后材料类型ID
                    typeIdNew: this.selectActive,
                    // 转移后材料类型名称
                    typeNameNew: this.selectList.filter(
                        (item) => item.dictionaryItemValue === this.selectActive
                    )[0].dictionaryItemName
                }
            }
            let res = await this.$API.sendRequest('CaseTransfer', param)
            if (!res.success) {
                this.$message.error(res.msg)
            } else {
                if (!res.success) return this.$message.success('转移失败')
                this.$message.success(res.msg)
                this.handleClose()
                this.CaseTimeLenDetail({
                    caseId: this.$route.query.caseId
                })
            }

            // 点击完后清空
            this.selectActive = 1
            this.dialogCaseNo = ''
        },

        // 点击识别   ->>获取后端返回数据后立即转发
        async identifyClick(row) {
            const param = {
                req: row.receiveLetterNo
            }
            this.$message.success('后台识别中...')
            // 先从windows去取当前数据的参数
            const paramRes = await this.$API.sendRequest('OcrFlagUpload', param)
            let paramList = {
                req: paramRes.data
            }

            if (paramRes.errorCode === '10001') {
                const res1 = await this.$API.sendRequest(
                    'OcRUpdDigital',
                    paramList
                )
                if (res1) {
                    if (res1.success) {
                        this.$message.success('识别成功')
                    } else {
                        this.$message.error('识别失败')
                    }
                }
            } else {
                return this.$message.error('识别失败')
            }
        },

        // 一次性token验证后发请求
        async GetOneTimeToken(token) {
            const param = {
                req: token
            }
            this.loading = true
            const res = await this.$API.sendRequest('GetOneTimeToken', param)
            if (res && res.success) {
                this.case_id = res.data.caseId
                localStorage.setItem('c_auth_token', res.data.authToken)
                this.$message.success('授权登录成功')
            } else {
                this.$message.error(res.msg)
            }
            this.loading = false
            await this.CaseTimeLenDetail({
                caseId: this.$route.query.caseId
            })
        }
    },
    watch: {
        switchType() {
            this.switchType = false
        }
    }
}
</script>
 
<style scoped lang="scss">
:deep(.el-collapse-item__header.is-active) {
    font-size: 20px;
    position: relative;
    top: 0;

    &::after {
        content: '';
        display: block;
        width: 25px;
        height: 1px;
        position: absolute;
        top: 100%;
        left: 15px;
        background-color: #50da4d;
    }

    &::before {
        content: '';
        width: 5px;
        height: 20px;
        background-color: red;
        position: absolute;
        top: 50%;
        left: 10px;
        transform: translateY(-10px);
    }
}

:deep(.el-collapse-item__header) {
    padding-left: 20px;
    font-size: 18px;
    position: relative;
    top: 0;

    &::after {
        content: '';
        width: 5px;
        height: 20px;
        background-color: red;
        position: absolute;
        top: 50%;
        left: 10px;
        transform: translateY(-10px);
    }
}

.box {
    width: 100%;
}

.el-dropdown-link {
    display: flex;
    align-items: center;
}

.iconfont {
    padding: 0 10px;
    color: red;
}

:deep([data-v-3bb84d4d] .el-collapse-item__header) {
    font-size: 16px;
}

.el-switch.el-disabled .el-switch-handle {
    background-color: #fff;
    cursor: not-allowed;
}

:deep(.butSpan .el-dropdown-link) {
    cursor: pointer;
    color: var(--el-color-primary);
    display: flex;
    align-items: center;
}

:deep(.butSpan) {
    margin-right: 15px;
    display: flex;
    justify-content: space-between;
}

:deep(.el-table td.el-table__cell div) {
    display: flex;
    justify-content: space-between;
}

:deep([class='el-dropdown-menu__item']) {
    display: flex;
    justify-content: space-between;
}

:deep(.butSpan .el-dropdown-link) {
    cursor: pointer;
    color: var(--el-color-primary);
    display: flex;
    align-items: center;
}

:deep([class='el-dropdown-menu__item']) {
    display: flex;
    justify-content: space-between;
}

.dropdownItem {
    flex: 1;
}

h3 {
    margin-left: 10px;
    text-align: left;
}

.topTitle {
    font-size: 22px;
    font-weight: 400;
    display: flex;
    align-items: center;
}

.opacityShow {
    opacity: 0;
}

.el-dialog__footer .dialog-footer {
    display: flex;
    justify-content: center;
}

.tableBox header {
    text-align: center;
    font-size: 20px;
    font-weight: 500;
}

:deep(.el-dialog__body) {
    padding-top: 0;
}

.iconFont {
    font-size: 40px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.tableInput .el-col-8 {
    display: flex;
    justify-content: center;
    align-items: center;
}

.el-select {
    width: 100%;
}

.elseFlexSpan {
    display: flex;
    justify-content: space-between;
    width: 100%;
}
</style>