<template>
    <!-- 时间流程图  -->
    <div v-loading="loading">
        <el-row style="margin: 10px">
            <el-col :span="8" :class="[caseNo ? 'topTitle' : 'opacityShow']"
                ><span> {{ caseNo }}号案{{$t('msg.ElectronicDossier')}} </span></el-col
            >
            <el-col :span="4">
                <div @click="goCaseFileL1">
                    <el-switch
                        v-model="switchType"
                        :active-text="$t('msg.Sortbytime')"
                        :inactive-text="$t('msg.sortbyType')"
                        style="display: flex; justify-content: center"
                    >
                    </el-switch>
                </div>
            </el-col>
            <el-col :span="7">
                <div class="radio">
                    {{$t('msg.Sort')}}：
                    <el-radio-group v-model="reverse">
                        <el-radio :label="true">{{$t('msg.InReverseOrder')}}</el-radio>
                        <el-radio :label="false">{{$t('msg.PositiveSequence')}}</el-radio>
                    </el-radio-group>
                </div>
            </el-col>
        </el-row>

        <el-collapse accordion>
            <el-collapse-item
                :title="$t('msg.PartyMaterial') + '(' + sotedItem.length + ')'"
                name="1"
                class="partyStyle"
            >
                <div class="main">
                    <el-timeline v-show="!loading">
                        <el-timeline-item
                            placement="top"
                            v-for="(item, i) in sotedItem"
                            :key="i"
                            :timestamp="item.submitDateStr"
                            color="skyblue"
                        >
                            <el-card class="cardLayout">
                                <div class="left">
                                    <p
                                        style="
                                            font-size: 16px;
                                            font-weight: 700;
                                            display: flex;
                                            align-content: center;
                                        "
                                    >
                                        <subscript></subscript>
                                        <span style="margin-left: -20px">
                                            {{ item.evidenceTypeName }}</span
                                        >
                                    </p>
                                    <p>{{ item.evidenceDesc }}</p>
                                </div>
                                <div class="right" v-loading="fileLoading">
                                    <el-dropdown
                                        trigger="hover"
                                        v-if="item.fileNameDsp"
                                    >
                                        <div class="dropdownBox">
                                            <strong style="white-space: nowrap">
                                                扫描版：</strong
                                            >
                                            <span class="el-dropdown-link">
                                                <div>
                                                    <svg
                                                        class="icon"
                                                        aria-hidden="true"
                                                    >
                                                        <use
                                                            xlink:href="#icon-pdf"
                                                        ></use>
                                                    </svg>
                                                </div>
                                                <p>
                                                    {{ item.fileNameDsp }}.pdf
                                                </p>
                                            </span>
                                        </div>
                                        <template #dropdown>
                                            <el-dropdown-menu>
                                                <el-dropdown-item
                                                    @click="
                                                        PreviewPdfClick(item)
                                                    "
                                                    >{{$t('msg.Preview')}}</el-dropdown-item
                                                >
                                                <el-dropdown-item
                                                    @click="PdfUpload(item)"
                                                    >{{$t('msg.Download')}}</el-dropdown-item
                                                >
                                            </el-dropdown-menu>
                                        </template>
                                    </el-dropdown>
                                    <div
                                        v-else
                                        class="rightItem"
                                        style="
                                            display: flex;
                                            flex-direction: row;
                                        "
                                    >
                                        <p>
                                            <strong style="white-space: nowrap">
                                                {{$t('msg.ScannedVersion')}}&nbsp;：&nbsp;</strong
                                            >{{$t('msg.ThereisnoScannedVersion')}}&nbsp;&nbsp;<el-button
                                                v-if="item.oldFilePath"
                                                @click="identifyClick(item)"
                                                >{{$t('msg.Clicktoidentify')}}</el-button
                                            >
                                        </p>
                                    </div>

                                    <div
                                        v-if="item.oldFileName"
                                        class="dropdownBoxRight"
                                    >
                                        <el-dropdown trigger="hover">
                                            <div class="dropdownBoxRightItem">
                                                <strong
                                                    style="white-space: nowrap"
                                                >
                                                    {{$t('msg.ElectronicVersion')}}：</strong
                                                >
                                                <span class="el-dropdown-link">
                                                    <div
                                                        v-if="
                                                            item.oldFileName.endsWith(
                                                                '.pdf'
                                                            )
                                                        "
                                                    >
                                                        <svg
                                                            class="icon"
                                                            aria-hidden="true"
                                                        >
                                                            <use
                                                                xlink:href="#icon-pdf"
                                                            ></use>
                                                        </svg>
                                                    </div>
                                                    <svg
                                                        v-else
                                                        t="1694139430288"
                                                        class="icon"
                                                        viewBox="0 0 1024 1024"
                                                        version="1.1"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        p-id="5036"
                                                        width="26"
                                                        height="26"
                                                    >
                                                        <path
                                                            d="M137.902164 0.00045a48.572979 48.572979 0 0 0-35.588984 15.293993 53.964976 53.964976 0 0 0-15.272993 35.639985v917.411596a48.706979 48.706979 0 0 0 15.272993 35.639985 49.970978 49.970978 0 0 0 35.589984 15.292993h746.335672a48.639979 48.639979 0 0 0 35.589985-15.292993 50.369978 50.369978 0 0 0 15.272993-35.639985V288.717323L646.727941 0.00045H137.902164z"
                                                            fill="#FF5562"
                                                            p-id="5037"
                                                        ></path>
                                                        <path
                                                            d="M935.101814 288.717323H697.655918c-27.821988-0.666-50.226978-23.07599-50.927977-50.933977V0.00045l288.373873 288.716873z"
                                                            fill="#FFBBC0"
                                                            p-id="5038"
                                                        ></path>
                                                        <path
                                                            d="M772.289886 715.070136L666.049932 550.303208a16.593993 16.593993 0 0 0-14.411993-7.649996 18.851992 18.851992 0 0 0-14.407994 7.649996l-56.836975 88.299961L453.171026 432.47026a16.526993 16.526993 0 0 0-14.406994-7.643997 18.851992 18.851992 0 0 0-14.412993 7.649997L249.657115 715.263136a15.446993 15.446993 0 0 0 0 16.957992 15.666993 15.666993 0 0 0 14.406994 8.508996h493.547783a18.851992 18.851992 0 0 0 15.272993-8.509996 16.977993 16.977993 0 0 0-0.793999-16.956992l0.2-0.2zM628.069949 403.149273a42.489981 42.489981 0 0 0 26.142989 39.305983 42.387981 42.387981 0 0 0 46.264979-9.169996 42.531981 42.531981 0 0 0 9.225996-46.30998A42.429981 42.429981 0 0 0 670.49993 360.730291c-23.40899 0-42.393981 18.979992-42.434981 42.418982z"
                                                            fill="#FFFFFF"
                                                            p-id="5039"
                                                        ></path>
                                                    </svg>
                                                    <p>
                                                        {{ item.oldFileName }}
                                                    </p>
                                                </span>
                                            </div>
                                            <template #dropdown>
                                                <el-dropdown-menu>
                                                    <el-dropdown-item
                                                        @click="
                                                            clickDropdown(
                                                                item
                                                                    .caseTimLenSonVos[0]
                                                            )
                                                        "
                                                        >{{$t('msg.Preview')}}</el-dropdown-item
                                                    >
                                                    <el-dropdown-item
                                                        @click="
                                                            clickDownLoad(
                                                                item
                                                                    .caseTimLenSonVos[0]
                                                            )
                                                        "
                                                        >{{$t('msg.Download')}}</el-dropdown-item
                                                    >
                                                </el-dropdown-menu>
                                            </template>
                                        </el-dropdown>
                                        <span
                                            class="butSpan"
                                            style="display: flex"
                                            v-show="
                                                item.caseTimLenSonVos.length > 1
                                                    ? true
                                                    : false
                                            "
                                        >
                                            <el-dropdown
                                                trigger="click"
                                                size="large"
                                            >
                                                <span
                                                    class="el-dropdown-link"
                                                    @click="
                                                        clickDetail(
                                                            item.caseTimLenSonVos
                                                        )
                                                    "
                                                >
                                                    {{$t('msg.workbench_more')}}
                                                    <el-icon
                                                        class="el-icon--right"
                                                    >
                                                        <arrow-down />
                                                    </el-icon>
                                                </span>
                                                <template #dropdown>
                                                    <el-dropdown-menu>
                                                        <el-dropdown-item
                                                            v-for="(
                                                                items, index
                                                            ) in caseTimeList"
                                                            :key="index"
                                                        >
                                                            <div class="imgBox">
                                                                <svg
                                                                    class="icon"
                                                                    aria-hidden="true"
                                                                    v-if="
                                                                        items.fileNameDsp.endsWith(
                                                                            '.pdf'
                                                                        )
                                                                    "
                                                                >
                                                                    <use
                                                                        xlink:href="#icon-pdf"
                                                                    ></use>
                                                                </svg>
                                                                <svg
                                                                    v-else
                                                                    t="1694139430288"
                                                                    class="icon"
                                                                    viewBox="0 0 1024 1024"
                                                                    version="1.1"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    p-id="5036"
                                                                    width="26"
                                                                    height="26"
                                                                >
                                                                    <path
                                                                        d="M137.902164 0.00045a48.572979 48.572979 0 0 0-35.588984 15.293993 53.964976 53.964976 0 0 0-15.272993 35.639985v917.411596a48.706979 48.706979 0 0 0 15.272993 35.639985 49.970978 49.970978 0 0 0 35.589984 15.292993h746.335672a48.639979 48.639979 0 0 0 35.589985-15.292993 50.369978 50.369978 0 0 0 15.272993-35.639985V288.717323L646.727941 0.00045H137.902164z"
                                                                        fill="#FF5562"
                                                                        p-id="5037"
                                                                    ></path>
                                                                    <path
                                                                        d="M935.101814 288.717323H697.655918c-27.821988-0.666-50.226978-23.07599-50.927977-50.933977V0.00045l288.373873 288.716873z"
                                                                        fill="#FFBBC0"
                                                                        p-id="5038"
                                                                    ></path>
                                                                    <path
                                                                        d="M772.289886 715.070136L666.049932 550.303208a16.593993 16.593993 0 0 0-14.411993-7.649996 18.851992 18.851992 0 0 0-14.407994 7.649996l-56.836975 88.299961L453.171026 432.47026a16.526993 16.526993 0 0 0-14.406994-7.643997 18.851992 18.851992 0 0 0-14.412993 7.649997L249.657115 715.263136a15.446993 15.446993 0 0 0 0 16.957992 15.666993 15.666993 0 0 0 14.406994 8.508996h493.547783a18.851992 18.851992 0 0 0 15.272993-8.509996 16.977993 16.977993 0 0 0-0.793999-16.956992l0.2-0.2zM628.069949 403.149273a42.489981 42.489981 0 0 0 26.142989 39.305983 42.387981 42.387981 0 0 0 46.264979-9.169996 42.531981 42.531981 0 0 0 9.225996-46.30998A42.429981 42.429981 0 0 0 670.49993 360.730291c-23.40899 0-42.393981 18.979992-42.434981 42.418982z"
                                                                        fill="#FFFFFF"
                                                                        p-id="5039"
                                                                    ></path>
                                                                </svg>

                                                                <span>{{
                                                                    items.fileNameDsp
                                                                }}</span>
                                                            </div>
                                                            <div class="butBox">
                                                                <el-button
                                                                    type="primary"
                                                                    size="small"
                                                                    @click="
                                                                        clickDropdown(
                                                                            items
                                                                        )
                                                                    "
                                                                    >{{$t('msg.Preview')}}</el-button
                                                                >
                                                                <el-button
                                                                    type="primary"
                                                                    size="small"
                                                                    @click="
                                                                        clickDownLoad(
                                                                            items
                                                                        )
                                                                    "
                                                                    >{{$t('msg.Download')}}</el-button
                                                                >
                                                            </div>
                                                        </el-dropdown-item>
                                                    </el-dropdown-menu>
                                                </template>
                                            </el-dropdown>
                                        </span>
                                    </div>
                                    <div
                                        v-else
                                        class="rightItem"
                                        style="
                                            pointer-events: none;
                                            display: flex;
                                            flex-direction: row;
                                        "
                                    >
                                        <p>
                                            <strong style="white-space: nowrap">
                                                {{$t('msg.ElectronicVersion')}}&nbsp;：&nbsp;</strong
                                            >{{$t('msg.ThereisnoElectronicVersionYet')}}
                                        </p>
                                    </div>
                                </div>
                            </el-card>
                        </el-timeline-item>
                    </el-timeline>
                </div>
            </el-collapse-item>

            <el-collapse-item
                :title="$t('msg.IssuanceOfCommunicationsByTheCaseManager') + '(' + sotedSend.length + ')'"
                name="2"
            >
                <div class="main">
                    <el-timeline v-show="!loading">
                        <el-timeline-item
                            placement="top"
                            v-for="(item, i) in sotedSend"
                            :key="i"
                            :timestamp="item.submitDateStr"
                            color="skyblue"
                        >
                            <el-card class="cardLayout">
                                <div class="left">
                                    <p
                                        style="
                                            font-size: 16px;
                                            font-weight: 700;
                                            display: flex;
                                            aligin-content: center;
                                        "
                                    >
                                        <subscript></subscript>
                                        <span style="margin-left: -20px">
                                            {{ item.templateTypeName }}</span
                                        >
                                    </p>
                                    <p>{{ item.evidenceDesc }}</p>
                                </div>
                                <div class="right" v-loading="fileLoading">
                                    <el-dropdown
                                        trigger="hover"
                                        v-if="item.fileNameDsp"
                                    >
                                        <div class="dropdownBox">
                                            <strong style="white-space: nowrap">
                                                {{$t('msg.ScannedVersion')}}：</strong
                                            >
                                            <span class="el-dropdown-link">
                                                <div>
                                                    <svg
                                                        class="icon"
                                                        aria-hidden="true"
                                                    >
                                                        <use
                                                            xlink:href="#icon-pdf"
                                                        ></use>
                                                    </svg>
                                                </div>
                                                <p>
                                                    {{ item.fileNameDsp }}.pdf
                                                </p>
                                            </span>
                                        </div>
                                        <template #dropdown>
                                            <el-dropdown-menu>
                                                <el-dropdown-item
                                                    @click="
                                                        PreviewPdfClick(item)
                                                    "
                                                    >{{$t('msg.Preview')}}</el-dropdown-item
                                                >
                                                <el-dropdown-item
                                                    @click="PdfUpload(item)"
                                                    >{{$t('msg.Download')}}</el-dropdown-item
                                                >
                                            </el-dropdown-menu>
                                        </template>
                                    </el-dropdown>

                                    <div
                                        v-else
                                        style="
                                            pointer-events: none;
                                            display: flex;
                                            flex-direction: row;
                                        "
                                    >
                                        <p>
                                            <strong style="white-space: nowrap">
                                                {{$t('msg.ScannedVersion')}}&nbsp;：&nbsp;</strong
                                            >{{$t('msg.ThereisnoScannedVersion')}}&nbsp;&nbsp;<el-button
                                                v-if="item.oldFilePath"
                                                @click="identifyClick(item)"
                                                >{{$t('msg.Clicktoidentify')}}</el-button
                                            >
                                        </p>
                                    </div>

                                    <div
                                        v-if="item.oldFileName"
                                        class="dropdownBoxRight"
                                    >
                                        <el-dropdown trigger="hover">
                                            <div class="dropdownBoxRightItem">
                                                <strong
                                                    style="white-space: nowrap"
                                                >
                                                    {{$t('msg.ElectronicVersion')}}：</strong
                                                >
                                                <span class="el-dropdown-link">
                                                    <div
                                                        v-if="
                                                            item.oldFileName.endsWith(
                                                                '.pdf'
                                                            )
                                                        "
                                                    >
                                                        <svg
                                                            class="icon"
                                                            aria-hidden="true"
                                                        >
                                                            <use
                                                                xlink:href="#icon-pdf"
                                                            ></use>
                                                        </svg>
                                                    </div>
                                                    <svg
                                                        v-else
                                                        t="1694139430288"
                                                        class="icon"
                                                        viewBox="0 0 1024 1024"
                                                        version="1.1"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        p-id="5036"
                                                        width="26"
                                                        height="26"
                                                    >
                                                        <path
                                                            d="M137.902164 0.00045a48.572979 48.572979 0 0 0-35.588984 15.293993 53.964976 53.964976 0 0 0-15.272993 35.639985v917.411596a48.706979 48.706979 0 0 0 15.272993 35.639985 49.970978 49.970978 0 0 0 35.589984 15.292993h746.335672a48.639979 48.639979 0 0 0 35.589985-15.292993 50.369978 50.369978 0 0 0 15.272993-35.639985V288.717323L646.727941 0.00045H137.902164z"
                                                            fill="#FF5562"
                                                            p-id="5037"
                                                        ></path>
                                                        <path
                                                            d="M935.101814 288.717323H697.655918c-27.821988-0.666-50.226978-23.07599-50.927977-50.933977V0.00045l288.373873 288.716873z"
                                                            fill="#FFBBC0"
                                                            p-id="5038"
                                                        ></path>
                                                        <path
                                                            d="M772.289886 715.070136L666.049932 550.303208a16.593993 16.593993 0 0 0-14.411993-7.649996 18.851992 18.851992 0 0 0-14.407994 7.649996l-56.836975 88.299961L453.171026 432.47026a16.526993 16.526993 0 0 0-14.406994-7.643997 18.851992 18.851992 0 0 0-14.412993 7.649997L249.657115 715.263136a15.446993 15.446993 0 0 0 0 16.957992 15.666993 15.666993 0 0 0 14.406994 8.508996h493.547783a18.851992 18.851992 0 0 0 15.272993-8.509996 16.977993 16.977993 0 0 0-0.793999-16.956992l0.2-0.2zM628.069949 403.149273a42.489981 42.489981 0 0 0 26.142989 39.305983 42.387981 42.387981 0 0 0 46.264979-9.169996 42.531981 42.531981 0 0 0 9.225996-46.30998A42.429981 42.429981 0 0 0 670.49993 360.730291c-23.40899 0-42.393981 18.979992-42.434981 42.418982z"
                                                            fill="#FFFFFF"
                                                            p-id="5039"
                                                        ></path>
                                                    </svg>
                                                    <p>
                                                        {{ item.oldFileName }}
                                                    </p>
                                                </span>
                                            </div>
                                            <template #dropdown>
                                                <el-dropdown-menu>
                                                    <el-dropdown-item
                                                        @click="
                                                            clickDropdown(
                                                                item
                                                                    .caseTimLenSonVos[0]
                                                            )
                                                        "
                                                        >{{$t('msg.Preview')}}</el-dropdown-item
                                                    >
                                                    <el-dropdown-item
                                                        @click="
                                                            clickDownLoad(
                                                                item
                                                                    .caseTimLenSonVos[0]
                                                            )
                                                        "
                                                        >{{$t('msg.Download')}}</el-dropdown-item
                                                    >
                                                </el-dropdown-menu>
                                            </template>
                                        </el-dropdown>
                                        <span
                                            class="butSpan"
                                            style="
                                                display: flex;
                                                align-items: center;
                                            "
                                            v-show="
                                                item.caseTimLenSonVos.length > 1
                                                    ? true
                                                    : false
                                            "
                                        >
                                            <el-dropdown
                                                trigger="click"
                                                size="large"
                                            >
                                                <span
                                                    class="el-dropdown-link"
                                                    @click="
                                                        clickDetail(
                                                            item.caseTimLenSonVos
                                                        )
                                                    "
                                                >
                                                    {{$t('msg.workbench_more')}}
                                                    <el-icon
                                                        class="el-icon--right"
                                                    >
                                                        <arrow-down />
                                                    </el-icon>
                                                </span>
                                                <template #dropdown>
                                                    <el-dropdown-menu>
                                                        <el-dropdown-item
                                                            v-for="(
                                                                items, index
                                                            ) in caseTimeList"
                                                            :key="index"
                                                        >
                                                            <div class="imgBox">
                                                                <svg
                                                                    class="icon"
                                                                    aria-hidden="true"
                                                                    v-if="
                                                                        items.fileNameDsp.endsWith(
                                                                            '.pdf'
                                                                        )
                                                                    "
                                                                >
                                                                    <use
                                                                        xlink:href="#icon-pdf"
                                                                    ></use>
                                                                </svg>
                                                                <svg
                                                                    v-else
                                                                    t="1694139430288"
                                                                    class="icon"
                                                                    viewBox="0 0 1024 1024"
                                                                    version="1.1"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    p-id="5036"
                                                                    width="26"
                                                                    height="26"
                                                                >
                                                                    <path
                                                                        d="M137.902164 0.00045a48.572979 48.572979 0 0 0-35.588984 15.293993 53.964976 53.964976 0 0 0-15.272993 35.639985v917.411596a48.706979 48.706979 0 0 0 15.272993 35.639985 49.970978 49.970978 0 0 0 35.589984 15.292993h746.335672a48.639979 48.639979 0 0 0 35.589985-15.292993 50.369978 50.369978 0 0 0 15.272993-35.639985V288.717323L646.727941 0.00045H137.902164z"
                                                                        fill="#FF5562"
                                                                        p-id="5037"
                                                                    ></path>
                                                                    <path
                                                                        d="M935.101814 288.717323H697.655918c-27.821988-0.666-50.226978-23.07599-50.927977-50.933977V0.00045l288.373873 288.716873z"
                                                                        fill="#FFBBC0"
                                                                        p-id="5038"
                                                                    ></path>
                                                                    <path
                                                                        d="M772.289886 715.070136L666.049932 550.303208a16.593993 16.593993 0 0 0-14.411993-7.649996 18.851992 18.851992 0 0 0-14.407994 7.649996l-56.836975 88.299961L453.171026 432.47026a16.526993 16.526993 0 0 0-14.406994-7.643997 18.851992 18.851992 0 0 0-14.412993 7.649997L249.657115 715.263136a15.446993 15.446993 0 0 0 0 16.957992 15.666993 15.666993 0 0 0 14.406994 8.508996h493.547783a18.851992 18.851992 0 0 0 15.272993-8.509996 16.977993 16.977993 0 0 0-0.793999-16.956992l0.2-0.2zM628.069949 403.149273a42.489981 42.489981 0 0 0 26.142989 39.305983 42.387981 42.387981 0 0 0 46.264979-9.169996 42.531981 42.531981 0 0 0 9.225996-46.30998A42.429981 42.429981 0 0 0 670.49993 360.730291c-23.40899 0-42.393981 18.979992-42.434981 42.418982z"
                                                                        fill="#FFFFFF"
                                                                        p-id="5039"
                                                                    ></path>
                                                                </svg>

                                                                <span>{{
                                                                    items.fileNameDsp
                                                                }}</span>
                                                            </div>
                                                            <div class="butBox">
                                                                <el-button
                                                                    type="primary"
                                                                    size="small"
                                                                    @click="
                                                                        clickDropdown(
                                                                            items
                                                                        )
                                                                    "
                                                                    >{{$t('msg.Preview')}}</el-button
                                                                >
                                                                <el-button
                                                                    type="primary"
                                                                    size="small"
                                                                    @click="
                                                                        clickDownLoad(
                                                                            items
                                                                        )
                                                                    "
                                                                    >{{$t('msg.Download')}}</el-button
                                                                >
                                                            </div>
                                                        </el-dropdown-item>
                                                    </el-dropdown-menu>
                                                </template>
                                            </el-dropdown>
                                        </span>
                                    </div>
                                    <div
                                        v-else
                                        class="rightItem"
                                        style="
                                            pointer-events: none;
                                            display: flex;
                                            flex-direction: row;
                                        "
                                    >
                                        <p>
                                            <strong style="white-space: nowrap">
                                                {{$t('msg.ElectronicVersion')}}&nbsp;：&nbsp;</strong
                                            >{{$t('msg.ThereisnoElectronicVersionYet')}}
                                        </p>
                                    </div>
                                </div>
                            </el-card>
                        </el-timeline-item>
                    </el-timeline>
                </div>
            </el-collapse-item>
        </el-collapse>
    </div>
</template>
 
<script>
export default {
    name: '',
    data() {
        return {
            // 正序倒序
            reverse: true,
            // 数据列表
            activityPut: [],
            activitySed: [],
            // 加载效果
            loading: false,
            // 文件列表
            fileList: [],
            // 在线预览pdf
            pdfUrl: '',
            // pdf弹出框
            dialogShow: false,
            // 加载效果
            fileLoading: false,
            switchType: true,
            caseId: '',
            // 弹窗状态
            previewDialog: false,
            // 源文件详情数组
            caseTimeList: [],
            caseNo: ''
        }
    },
    async created() {
        if (this.$route.query.token) {
            localStorage.setItem('oneToken', this.$route.query.token)
            this.GetOneTimeToken(this.$route.query.token)
        }
        // await this.CaseTimeLenDetail({
        //     caseId: this.$route.query.caseId
        // })
    },
    methods: {
        async GetOneTimeToken(token) {
            const param = {
                req: token
            }
            this.loading = true
            const res = await this.$API.sendRequest('GetOneTimeToken', param)
            if (res && res.success) {
                this.case_id = res.data.caseId
                localStorage.setItem('c_auth_token', res.data.authToken)
                this.$message.success('授权登录成功')
            } else {
                this.$message.error(res.msg)
            }
            this.loading = false
            await this.CaseTimeLenDetail({
                caseId: this.$route.query.caseId
            })
        },

        load(row) {
            this.caseNo = row.caseNo
            this.CaseTimeLenDetail(row)
        },
        // 根据id获取详细信息
        async CaseTimeLenDetail(row) {
            this.activityPut = []
            this.activitySed = []
            this.loading = true
            this.caseId = row
            let param
            if (typeof row === 'number') {
                param = {
                    req: row
                }
            } else {
                param = {
                    req: row.caseId
                }
            }
            let res = await this.$API.sendRequest('CaseTimeLenDetail', param)
            if (!res.success) {
                this.loading = false
                return this.$message.error(res.msg)
            }
            this.caseNo = res.data.caseNo
            this.loading = false
            this.activityPut = res.data.caseTimeLenDetailVo
            this.activitySed = res.data.documentVo
        },
        // 点击预览pdf
        async PreviewPdfClick(row) {
            this.loading = true
            let param = {
                req: {
                    receiveLetterNo: row.receiveLetterNo  || row.letterCode,
                    caseId:typeof row === 'number' ? this.caseId : this.caseId.caseId
                }
            }
            // 先从windows去取当前数据的参数
            const paramRes = await this.$API.sendRequest('OcrDownFile', param)
            if (paramRes.status === 200) {
                const blob = new Blob([paramRes.data], {
                    type: 'application/pdf'
                })
                let url = window.URL.createObjectURL(blob)
                window.open(url, '_blank')
                this.loading = false
            } else {
                this.loading = false
                this.$message.error('预览失败')
            }
        },
        // 点击下载pdf
        async PdfUpload(row) {
            this.loading = true
            let param = {
                req: {
                    receiveLetterNo: row.receiveLetterNo  || row.letterCode,
                    caseId:typeof row === 'number' ? this.caseId : this.caseId.caseId
                }
            }
            const paramRes = await this.$API.sendRequest('OcrDownFile', param)
            if (paramRes.status === 200) {
                const blob = new Blob([paramRes.data], {
                    type: 'application/pdf'
                })
                let url = window.URL.createObjectURL(blob)
                let fileName = paramRes.headers['content-disposition']
                let index = fileName.indexOf('=')
                let result = fileName.substring(index + 1, fileName.length)
                let link = document.createElement('a')
                link.href = url
                link.download = decodeURIComponent(result) + '.pdf'
                link.click()
                this.loading = false
                window.URL.revokeObjectURL(link.href)
            } else {
                this.loading = false
                this.$message.error('下载失败')
            }
        },

        // 点击了开关去另一个页面
        goCaseFileL1() {
            let caseId = this.$route.query.caseId
            if (caseId) {
                this.$router.push(
                    'CaseFilePages_L1?caseId=' +
                        caseId +
                        '&token=' +
                        localStorage.getItem('oneToken')
                )
            } else {
                this.$emit('goCaseFilePages_L1')
            }
        },
        // 点击了更多  打开弹窗
        clickDetail(rowList) {
            this.caseTimeList = rowList
        },

        // 预览电子版事件
        async clickDropdown(row) {
            let param = {
                req: {
                    fileNameDsp: row.fileNameDsp,
                    filePath: row.filePath,
                    flagPdf: row.flagPdf
                }
            }
            const res = await this.$API.sendRequest(
                'ocrDownFilePathAndName',
                param
            )
            let blob
            if (row.flagPdf === 1) {
                blob = new Blob([res], { type: 'application/pdf' })
                window.open(window.URL.createObjectURL(blob), '_blank')
            } else {
                // this.dialogPreview = true
                blob = new Blob([res], { type: 'image/jpeg' })
                let newWindow = window.open(
                    window.URL.createObjectURL(blob),
                    '_blank'
                )
                newWindow.onload = () => {
                    let button = document.createElement('button')
                    this.$GF.cretateRotateBtn(newWindow, button)
                }
            }
        },

        // 点击下载电子版事件
        async clickDownLoad(row) {
            let param = {
                req: {
                    fileNameDsp: row.fileNameDsp,
                    filePath: row.filePath,
                    flagPdf: row.flagPdf
                }
            }
            const res = await this.$API.sendRequest(
                'ocrDownFilePathAndName',
                param
            )
            let blob
            if (row.flagPdf === 1) {
                blob = new Blob([res], { type: 'application/pdf' })
            } else {
                // this.dialogPreview = true
                blob = new Blob([res], { type: 'image/jpeg' })
            }
            let url = window.URL.createObjectURL(blob)
            let link = document.createElement('a')
            link.href = url
            link.download = row.fileNameDsp
            link.click()
            window.URL.revokeObjectURL(link.href)
        },

        // 点击识别   ->>获取后端返回数据后立即转发
        async identifyClick(row) {
            const param = {
                req: row.receiveLetterNo
            }
            this.$message.success('后台识别中...')
            // 先从windows去取当前数据的参数
            const paramRes = await this.$API.sendRequest('OcrFlagUpload', param)
            let paramList = {
                req: paramRes.data
            }

            if (paramRes.errorCode === '10001') {
                const res1 = await this.$API.sendRequest(
                    'OcRUpdDigital',
                    paramList
                )
                if (res1) {
                    if (res1.success) {
                        this.$message.success('识别成功')
                    } else {
                        this.$message.error('识别失败')
                    }
                }
            } else {
                return this.$message.error('识别失败')
            }
        }
    },

    computed: {
        // Element排序
        sotedItem() {
            let items = [...this.activityPut]
            if (!this.reverse) {
                items.sort(
                    (a, b) => new Date(a.submitDate) - new Date(b.submitDate)
                )
            } else {
                items.sort(
                    (a, b) => new Date(b.submitDate) - new Date(a.submitDate)
                )
            }
            return items
        },
        sotedSend() {
            let items = [...this.activitySed]
            if (!this.reverse) {
                items.sort(
                    (a, b) => new Date(a.sendDate) - new Date(b.sendDate)
                )
            } else {
                items.sort(
                    (a, b) => new Date(b.sendDate) - new Date(a.sendDate)
                )
            }
            return items
        }
    },
    watch: {
        switchType() {
            this.switchType = true
        }
    }
}
</script>
 
<style scoped lang="scss">
:deep(.el-timeline-item__timestamp) {
    display: flex;
}

.cardLayout {
    display: flex;
    width: 95%;

    :deep(.el-card__body) {
        width: 100%;
        display: flex;
        min-height: 50px;

        // height: 30px;
        .left {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            flex: 1;
            padding-right: 10px;

            p {
                width: 100%;
                text-align: left;
                letter-spacing: 0.1em;
                line-height: 18px;
                text-indent: 2em;
                margin-top: -5px;
            }
        }

        .right {
            flex: 1;
            border-left: 1px dashed #ccc;
            padding-left: 10px;
            width: 100%;
            display: flex;
            flex-direction: column;

            &:hover {
                cursor: pointer;
            }

            .el-dropdown-link {
                display: flex;
                align-items: center;

                div {
                    display: flex;
                    align-items: center;

                    i {
                        font-size: 20px;
                        color: red;
                    }
                }

                p {
                    // margin-left: 15px;
                    font-size: 14px;
                    max-width: 190px;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
            }
        }
    }
}

.rightItem {
    &:hover {
        pointer-events: auto;
    }
}

.dropdownBox {
    display: flex;
    align-items: center;
}

:deep([class='circular']) {
    margin-top: 10%;
}

:deep(.el-tooltip__trigger) {
    display: flex;
    align-items: center;
}

:deep(.el-card__body .circular) {
    margin-top: 0;
}

.dropdownBoxRight {
    display: flex;

    .dropdownBoxRightItem {
        display: flex;
        strong {
            display: flex;
            align-items: center;
        }
        span {
            margin: 0 15px 0 4px;
            display: flex;
            align-content: center;
        }
    }
}

:deep(.butSpan .el-dropdown-link) {
    // cursor: pointer;
    color: var(--el-color-primary);
    display: flex;
    align-items: center;
}

:deep([class='el-dropdown-menu__item']) {
    display: flex;
    justify-content: space-between;
}

:deep(.el-collapse-item__header) {
    font-size: 17px;
    font-weight: 500;
    position: relative;
    top: 0;
    padding: 0 20px;
    transition: all 0.6s;

    &::before {
        content: '';
        width: 5px;
        height: 20px;
        background-color: red;
        position: absolute;
        top: 50%;
        left: 10px;
        transform: translateY(-10px);
    }

    &::after {
        content: '';
        display: block;
        width: 0px;
        height: 2px;
        position: absolute;
        top: 100%;
        left: 15px;
        background-color: #50da4d;
        transition: all 0.6s;
    }
}

:deep(.el-collapse-item__header.is-active) {
    font-weight: 500;
    font-size: 17px;

    &::after {
        content: '';
        display: block;
        width: 93px;
    }

    &::before {
        content: '';
        width: 5px;
        height: 20px;
        background-color: red;
        position: absolute;
        top: 50%;
        left: 10px;
        transform: translateY(-10px);
    }
}

.topTitle {
    font-size: 22px;
    font-weight: 400;
    display: flex;
    align-items: center;
}

:deep(.el-collapse-item__content) {
    padding: 20px;
}

.opacityShow {
    opacity: 0;
}
</style>