<template>
      <div class="containerBox">
        <!-- <h1>上传扫描件(经办人)</h1> -->
            <!-- 动态切换显示else -->
            <div class="OCRIdentify" >
            <!-- 顶部两按钮开始 -->
            <el-row class="elRowMargin">
                <el-col
                    :span="6"
                    style="display: flex; justify-content: flex-start"
                >
                    <el-button
                        @click="isShowDialog"
                        style="color: #fff"
                        class="backColorButBlue"
                        v-if="selectButColor"
                    >
                        <el-icon class="el-icon--center">
                            <UploadFilled />
                        </el-icon>
                        {{$t('msg.SelectFile')}}
                    </el-button>
                    <el-button
                        @click="isShowDialog"
                        style="color: #606266; background: #fff"
                        v-else
                    >
                        <el-icon class="el-icon--center">
                            <UploadFilled />
                        </el-icon>
                        {{$t('msg.SelectFile')}}
                    </el-button>
                </el-col>

                <el-col
                    :span="6"
                    style="display: flex; justify-content: flex-end"
                >
                    <el-button
                        type="primary"
                        @click="fileClick"
                        v-if="!selectButColor"
                    >
                        <el-icon class="el-icon--right">
                            <Upload />
                        </el-icon>
                        {{$t('msg.finder_upload')}}
                    </el-button>
                    <el-button
                        type="primary"
                        @click="fileClick"
                        v-else
                        style="background-color: #fff; color: #606266"
                    >
                        <el-icon class="el-icon--right">
                            <Upload />
                        </el-icon>
                       {{$t('msg.finder_upload')}}
                    </el-button>
              
                </el-col>
          
                <el-col  :span="6"   style="display: flex; justify-content: flex-end">
                    <el-button
                        type="primary"
                        @click="clickBack"
                    >
                        
                      返回
                    </el-button>
                </el-col>
            </el-row>
            <!-- 顶部两按钮结束 -->

            <!-- 展示表格开始 -->
            <el-table
                ref="singleTable"
                :data="tableData"
                highlight-current-row
                style="width: 100%"
                v-loading="tableLoading"
            >
                <el-table-column type="index" width="50"> </el-table-column>
                <el-table-column property="fileName" :label="$t('msg.FileName')" width="160">
                    <template #default="scope">
                        <div v-if="scope.row.filePath">
                            <el-dropdown>
                                <span class="el-dropdown-link">
                                    {{ scope.row.fileName }}
                                </span>
                                <template #dropdown>
                                    <el-dropdown-menu>
                                        <el-dropdown-item
                                            v-if="
                                                scope.row.pdfFlag === 0 ||
                                                scope.row.pdfFlag === 1
                                            "
                                            @click="clickDropdown(scope.row)"
                                            >{{$t('msg.Preview')}}</el-dropdown-item
                                        >
                                        <el-dropdown-item
                                            @click="clickDownLoad(scope.row)"
                                            >{{$t('msg.Download')}}</el-dropdown-item
                                        >
                                    </el-dropdown-menu>
                                </template>
                            </el-dropdown>
                        </div>
                        <div v-else>{{ scope.row.fileName }}</div>
                    </template>
                </el-table-column>
              
                <el-table-column property="uploadDate" :label="$t('msg.UploadTime')">
                </el-table-column>
                <el-table-column>
                    <template #default="scope">
                        <el-button
                            type="primary"
                            @click="deleteFileItem(scope.$index, scope)"
                            v-if="scope.row.type !== false && !scope.row.uploadDate"
                        >
                            <el-icon class="el-icon--center">
                                <Delete />
                            </el-icon>
                        </el-button>
                        <el-icon
                            class="el-icon--center"
                            v-else
                            style="margin-left: 15px"
                        >
                            <Check />
                        </el-icon>
                    </template>
                </el-table-column>
                <!-- 27上面需要删除按钮  域名上面不要 -->
                <el-table-column :label="$t('msg.DeletetheuploadedFile')">
                    <template #default="scope">
                        <el-button
                            v-if="scope.row.uploadDate"
                            type="danger"
                            style="background-color: #ee4848"
                            @click="deleteUploadFile(scope.row)"
                            >{{$t('msg.DeletetheuploadedFile')}}</el-button
                        >
                    </template>
                </el-table-column>
            </el-table>

            <!-- 上传文件进度  percentageNumType-->
            <el-row
                v-if="percentageNumType"
                style="display: flex; align-items: center"
            >
                <p style="text-align: right; padding-right: 10px">
                    {{$t('msg.Uploadfileprogress')}}
                </p>

                <div class="progressBox">
                    <el-progress
                        :percentage="percentageNum"
                        style="width: 80%"
                    />
                    <span
                        :class="{
                            progressTextColor: trueNum !== newFileList.length
                        }"
                    >
                        {{ trueNum }}</span
                    >/{{ newFileList.length }}
                </div>
            </el-row>

            <!-- 展示表格结束 -->

            <!-- 第二个弹出框开始-----》上传弹出框 -->
            <el-dialog
                :title="dictionaryForm.dictionaryItemName"
                v-model="dialogVisibleTwo"
                width="40%"
            >
                <el-card >
                    <div class="box-card">
                    <el-row>
                        <el-col :span="20"
                            >{{$t('msg.UploadDirectory')}}：
                            <el-button @click="selectFile" style="color: #fff">
                                {{$t('msg.SelectFile')}}
                                <el-icon class="el-icon--FolderOpened">
                                    <FolderOpened />
                                </el-icon>
                            </el-button>
                        </el-col>
                        <el-col :span="4" style="padding-top: 10px">
                            <span style="font-size: 12px; color: red; zoom: 0.9"
                                >{{$t('msg.PleaseSelect')}}PDF / JPG</span
                            >
                        </el-col>
                    </el-row>
                    <el-card>
                        <el-row>
                            <el-col :span="24">
                                <div
                                    @dragenter="handleDragEnter"
                                    @dragover="handleDrageOver"
                                    @drop="handleDrop"
                                    :class="[
                                        'directoryMain',
                                        scrollType ? 'scroll' : ''
                                    ]"
                                >
                                    <el-upload
                                        class="upload-demo"
                                        action="#"
                                        multiple
                                        :limit="100"
                                        :file-list="fileList"
                                        :auto-upload="false"
                                        :accept="acceptedFormats"
                                        @change="fileListChange"
                                    >
                                        <el-button
                                            size="small"
                                            type="primary"
                                            v-show="false"
                                            ref="uploadButRef"
                                            >{{$t('msg.ClicktoUpload')}}</el-button
                                        >
                                    </el-upload>
                                </div>
                            </el-col>
                        </el-row>
                    </el-card>
                 
                    <el-row>
                        <el-col :span="12">
                            <el-button type="primary" @click="getFileListArr"
                                >{{$t('msg.Confirm')}}</el-button
                            >
                        </el-col>
                        <el-col :span="12">
                            <el-button
                                @click="dialogVisibleTwoClose"
                                style="background: #fff"
                                >{{$t('msg.finder_cancel')}}</el-button
                            >
                        </el-col>
                    </el-row>
                </div>
                </el-card>
            </el-dialog>
            <!-- 第二个弹出框结束 -->
        </div>
        <!-- 修改描述弹窗 / -->
        <el-dialog
            :title="$t('msg.ModifyDescription')"
            v-model="modifyEvidenceDescDialog"
            width="50%"
            :before-close="modifyEvidenceDescDialogClose"
        >
            <div>
                <el-row>
                    <el-col :span="4">
                        <span>{{$t('msg.FileDescription')}}</span>
                    </el-col>
                    <el-col :span="20">
                        <el-input
                            type="textarea"
                            v-model="modifyEvidenceDialogInputValue"
                        ></el-input>
                    </el-col>
                </el-row>
            </div>
            <template #footer>
                <div class="dialogFooterBtn">
                    <el-button
                        style="color: #fff"
                        @click="modifyEvidenceDescDialogClose"
                        >{{$t('msg.finder_cancel')}}</el-button
                    >
                    <el-button
                        type="primary"
                        @click="modifyEvidenceDialogSubmit"
                        >{{$t('msg.Confirm')}}{{$t('msg.Revamp')}}</el-button
                    >
                </div>
            </template>
        </el-dialog>
   
    </div>
  </template>
 
<script>
import axios from 'axios';

export default {
  name: '',
  components: {},

  data() {
        return {
            dictionaryForm: {
                // 被选中的材料类型
                dictionaryItemName: '',
                dictionaryText: '',
                dictionaryItemId: ''
            },
            inputText: '',

            // 第二个弹出框状态
            dialogVisibleTwo: false,
            // 文件备注
            dictionaryText: '',
            // 数组数据
            tableData: [],
            tableDataLoading: false,
            // 校验数据规则
            dictionaryRules: {
                dictionaryText: [
                    {
                        required: true,
                        message: '文件描述为必填',
                        trigger: 'blur'
                    }
                ]
            },
            // 上传列表
            fileList: [],
            // 累计上传列表
            newFileList: [],
            // 被隐藏的上传按钮
            uploadButRef: null,

            // 进入哪一个页面
            OCRType: true,

            // 需要存储本地数组
            fileListLocal: [],
            //  识别得loading效果
            identifyLoading: [],

            // 返回下载得base64
            base64Data: '',
            // 第一页数组显示列表
            getDetailCase: [],

        

            // 什么区  比如北京分区。。
            abbr: '',
            // 需要的id
            caseId: '',

            // 收文编号
            receiveLetterNo: '',
            // 上传的文件格式
            acceptedFormats: '.pdf,.jpg,',
            // partyType字段
            partyType: '',

            // 需要搜索的收文函号
            inputContent: '',
            // 需要搜索的案件编号
            inputCaseNum: '',
            // 进度条
            percentageNum: 0,
            // 进度条是否显示
            percentageNumType: false,
            // 上传的个数
            trueNum: 0,
            // 修改描述弹窗
            modifyEvidenceDescDialog: false,
            // 修改描述弹窗的输入框的值
            modifyEvidenceDialogInputValue: '',
            // 修改描述弹窗的receiveLetterNo
            modifyEvidenceDialogReceiveLetterNo: '',
          
            // 下载枚举
            downEnum: this.$store.state.downEnum,
            // list加载效果
            identifyDialogLoading: false,
            // 识别的数组列表
            identifyDialogList: [],
            // 清除ocr的定时器
            clearIdentifyNo: '',

            // 传递的值
            templateType: '',

            // 案件编号
            caseNo:'',
            letterCode: '',
            // 材料类型名
            templateTypeName: '',
            // 表格加载
            tableLoading:false
        }
    },

  created() {},
  methods: {
      load(row) {
          this.caseId = row.caseId
            this.partyType = row.partyType
          this.templateType = row.templateType
          this.letterCode = row.letterCode
          this.caseNo = row.caseNo
          this.templateTypeName = row.templateTypeName
            this.tableData = []
            this.ocrFlagUploadRegAddOCRFn(row)
        },


      // 已进入就掉方法获取数据
      async ocrFlagUploadRegAddOCRFn(row) {
        this.tableLoading = true
          let param = {
              req: {
                  letterCode: row.letterCode,
                  caseId: row.caseId,
                evidenceType:row.caseevidenceType
              }
          }
          let res = await this.$API.sendRequest('ocrFlagUploadRegAddOCR', param)
          if (!res.success) {
        this.tableLoading = false

            return this.$message.error(res.msg)
          }
          if (+res.errorCode === 10001) {
              // 已上传过
              this.tableData = res.data
          } 
          this.tableLoading = false
      },
        
        // 第一个弹出框的先显示
        isShowDialog() {
            this.dialogVisibleTwo = true
            this.dictionaryForm.dictionaryText = this.inputText
        },
        // 点击关闭弹窗，清空表单
        closeDialog() {
            this.fileList = []
        },

        // 显示的点击选择文件按钮
        selectFile() {
            this.uploadButRef = this.$refs.uploadButRef.ref
            this.uploadButRef.click()
        },

        // 点击上传
        async fileClick() {
            if (!this.newFileList.length) {
                return this.$message.error('请先选择文件')
            }
			console.log(this.newFileList,'上传文件88888888');

            this.percentageNumType = true
            // 保存其他信息
            this.$message.info('正在上传文件请勿退出')

            let trueNum = 0
            this.trueNum = 0
            this.percentageNum = 0
            let myThis = this
            for (let i = 0; i < this.newFileList.length; i++) {
                const formData = new FormData()
                formData.append('req.abbr', 'SECRETARY')
                formData.append('req.caseId', this.caseId)
                formData.append('file', this.newFileList[i].file)
                formData.append('req.flagPdf', this.newFileList[i].flagPdf)
                formData.append('req.oldFileName', this.newFileList[i].name)
                formData.append('req.typeName',  this.templateTypeName)
                formData.append('req.profile',  this.templateTypeName)
                formData.append('req.remarks',  null)
                formData.append(
                    'req.typeId',
                    this.templateType
                )
                formData.append(
                    'req.materialFlag','10' )
                formData.append('req.receiveLetterNo', this.letterCode)
                formData.append(
                    'req.partyType',
                    this.partyType
                )
                // let res = await this.$API.sendRequest('OcrSaveUpload', formData)

                let res = await axios({
                    url: '/api/ocrUse/ocrSaveUploadReg',
                    method: 'POST',
                    onUploadProgress: function (progressEvent) {
                        const percentCompleted = Math.round(
                            (progressEvent.loaded * 100) / progressEvent.total
                        )

                        if (percentCompleted < 100) {
                            // 可以在这里更新用户界面以显示上传进度
                            myThis.percentageNum = percentCompleted
                        } else {
                            // 可以在这里更新用户界面以显示上传进度
                            myThis.percentageNum = 99
                        }
                    },
                    headers: {
                        c_Auth_token: localStorage.getItem('c_Auth_toke')
                    },
                    data: formData
                })
                if (res.data.success) {
                    trueNum++
                    this.trueNum = trueNum
                    if (trueNum === this.newFileList.length) {
                        this.$message.success(res.data.msg)
                        // this.clickSynchronous()
                        setTimeout(() => {
                       
                            this.percentageNum = 0
                            this.percentageNumType = false
                            this.ocrFlagUploadRegAddOCRFn({
                                letterCode: this.letterCode,
                                caseId: this.caseId,
                                evidenceType:this.evidenceType
                            })
                        }, 500)
                    } else {
                        myThis.percentageNum = 0
                    }
                } else {
                    this.percentageNumType = false
                    this.$message.error('上传文件失败，请刷新后重试！')
                }
            }
            this.newFileList = []
        },

        // 上传同步数据
        clickSynchronous() {
            let param = {
                req: {
                    caseId: this.caseId,
                    userId: this.$store.state.loginInfo.userId,
                    regUserId: null
                }
            }
            this.$API.sendRequest('UnifiedFileTransfer', param)
        },

        //  点击第二次确定收集数据
        getFileListArr() {
            if (this.fileList.length === 0) {
                return this.$message.error('文件不能为空')
            }

            for (let i = 0; i < this.fileList.length; i++) {
                if (!this.fileList[i].raw) {
                    const obj = {}
                    obj['typeName'] = this.dictionaryForm.dictionaryItemName
                    obj['caseId'] = this.caseId
                    obj['name'] = this.fileList[i].name
                    obj['flagPdf'] =
                        this.fileList[i].type === 'application/pdf' ? 1 : 0
                    obj['file'] = this.fileList[i]
                    this.newFileList.push(obj)
                } else {
                    const obj = {}
                    obj['typeName'] = this.dictionaryForm.dictionaryItemName
                    obj['caseId'] = this.caseId
                    obj['name'] = this.fileList[i].raw.name
                    obj['flagPdf'] =
                        this.fileList[i].raw.type === 'application/pdf' ? 1 : 0
                    obj['file'] = this.fileList[i].raw
                    this.newFileList.push(obj)
                }
            }

            // 给页面返回数据进行渲染查看
            for (let i = 0; i < this.fileList.length; i++) {
                const obj = {}
                obj.fileName = this.fileList[i].name
                obj.fileType = this.dictionaryForm.dictionaryItemName
                obj.fileText = this.dictionaryForm.dictionaryText
                this.tableData.push(obj)
            }
            this.dialogVisibleTwo = false
            // 清空输入的内容
            this.dictionaryForm.dictionaryText =
                this.dictionaryForm.dictionaryItemNam
            this.closeDialog()
        },

        // 删除数据某一项
        deleteFileItem(scope) {
            this.tableData.splice(scope, 1)
            this.newFileList.splice(scope, 1)
        },

    
        isLoading(index) {
            return this.identifyLoading.includes(index)
        },

     

    

        // 表格颜色事件
        tableRowClassName({ row }) {
            if (row.ocrStatus === 0) {
                return 'ocrStatus0'
            } else if (row.ocrStatus === 1) {
                return 'ocrStatus1'
            } else if (row.ocrStatus === 2) {
                return 'ocrStatus2'
            }
            return ''
        },


        // 以下三个为拖拽上传
        // 将文件拖到地方上传的方法
        handleDragEnter(e) {
            // 阻止浏览器默认拖拽行为
            e.preventDefault()
        },
        // 阻止浏览器默认拖拽行为
        handleDrageOver(e) {
            e.preventDefault()
        },
        handleDrop(e) {
            e.preventDefault()
            const filesList = e.dataTransfer.files
            this.fileList.push(...filesList)
        },

   
        // 修改文件描述弹窗开启
        modifyEvidenceDesc(row) {
            this.modifyEvidenceDescDialog = true
            this.modifyEvidenceDialogInputValue = row.evidenceDesc
            this.modifyEvidenceDialogReceiveLetterNo = row.receiveLetterNo
        },

        // 关闭文件描述弹窗
        modifyEvidenceDescDialogClose() {
            this.modifyEvidenceDescDialog = false
        },

        // 点击文件描述弹窗的确定按钮
        async modifyEvidenceDialogSubmit() {
            let param = {
                req: {
                    receiveNo: this.modifyEvidenceDialogReceiveLetterNo,
                    remark: this.modifyEvidenceDialogInputValue
                }
            }
            let res = await this.$API.sendRequest('UpdFileRemark', param)
            if (!res.success) {
                return this.$message.error(res.msg)
            }
            this.$message.success(res.msg)
            this.modifyEvidenceDescDialog = false
        },


        // 点击删除已上传文件按钮.
        deleteUploadFile(row) {
            this.$confirm('确认删除当前文件吗,是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            })
                .then(async () => {
                    this.tableDataLoading = true
                    let param = {
                        req: {
                            id: row.id,
                            receiveNo: row.receiveLetterNo
                        }
                    }
                    let res = await this.$API.sendRequest(
                        'DelUploadFile',
                        param
                    )
                    if (!res.success) {
                        this.tableDataLoading = false
                        return this.$message.error(res.msg)
                    }
                    this.$message.success(res.msg)
                    this.tableData = this.tableData.filter((item) => {
                        return item.id !== row.id
                    })

                    this.tableDataLoading = false
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message:this.$t('msg.Undelete')
                    })
                })
        },

   


        // 上传文件的时候需要判断大小
        fileListChange(fileList) {
            let type = fileList.size / 1024 / 1024 < 500
            if (!type) {
                this.fileList.splice(this.fileList.length - 1, 1)
                this.$message.error('上传文件大小不能超过500' + 'MB!')
            }
        },

        // 关闭第二个弹窗
        dialogVisibleTwoClose() {
            this.fileList = []
            this.dialogVisibleTwo = false
      },

        // 预览影印版事件
        async clickDropdown(row) {
            let param = {
                req: {
                    fileNameDsp: row.fileName,
                    filePath: row.filePath,
                    flagPdf: row.pdfFlag
                }
            }
            const res = await this.$API.sendRequest(
                'ocrDownFilePathAndName',
                param
            )
            let blob
            if (row.pdfFlag === 1) {
                blob = new Blob([res], { type: 'application/pdf' })
                window.open(window.URL.createObjectURL(blob))
            } else {
                // this.dialogPreview = true
                blob = new Blob([res], { type: 'image/jpeg' })
                let newWindow = window.open(window.URL.createObjectURL(blob))
                newWindow.onload = () => {
                    let button = document.createElement('button')
                    this.$GF.cretateRotateBtn(newWindow, button)
                }
            }
        },

        // 点击下载影印版事件
        async clickDownLoad(row) {
            let param = {
                req: {
                    fileNameDsp: row.fileName,
                    filePath: row.filePath,
                    flagPdf: row.pdfFlag
                }
            }
            const res = await this.$API.sendRequest(
                'ocrDownFilePathAndName',
                param
            )
            let blob
            if (row.pdfFlag === 1) {
                blob = new Blob([res], { type: 'application/pdf' })
            } else {
                // this.dialogPreview = true
                blob = new Blob([res], { type: 'image/jpeg' })
            }
            let url = window.URL.createObjectURL(blob)
            let link = document.createElement('a')
            link.href = url
            link.download = row.fileNameDsp
            link.click()
            window.URL.revokeObjectURL(link.href)
        },

      clickBack() {
        this.$emit('goPage', {caseNo:this.caseNo}, 'uploadScanSecDoc_1')
        }
    
    },
    computed: {
        // 滚动条是否显示出来
        scrollType() {
            return this.fileList.length > 8 ? true : false
        },

        // 选择按钮颜色监听
        selectButColor() {
            return this.newFileList.length === 0
        }
    },
};
</script>
 
 
<style scoped lang="scss">
.elRowMargin {
    margin: 10px 0;
}
.progressBox {
    display: flex;
    align-items: center;
    height: 100%;
    width: 80%;
}
.dialogFooterBtn {
    display: flex;
    justify-content: center;
    color: #fff;
}
:deep(.el-button) {
    background-color: #79bbff;
}
.directoryMain {
    height: 200px;
}
.box-card {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

</style>