<template>
    <div v-loading="loadingBox">
        <el-form
            :model="protectForm"
            class="partyForm"
            ref="preserveFormRef"
            :rules="protectFormRules"
        >
            <!-- 保全类型 -->
            <div class="partyBox">
                <div class="partyInfoBox">
                    <el-divider></el-divider>
                    <!-- 保全类型  -->
                    <el-row style="margin-top: 20px">
                        <!-- <el-col :span="4"><p>{{$t('msg.PreservationType')}}</p></el-col> -->
                        <el-col :span="24">
                            <el-form-item prop="preserveType"  :label="$t('msg.PreservationType')">
                                <el-select
                                    filterable
                                    :placeholder="$t('msg.PleaseSelect')"
                                    style="width: 100%"
                                    v-model="protectForm.preserveType"
                                    :disabled="!allType"
                                >
                                    <el-option
                                        v-for="item in preserveTypeList"
                                        :key="item.dictionaryItemValue"
                                        :label="item.remark"
                                        :value="item.dictionaryItemValue"
                                    >
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <!-- 法院名称 -->
                    <el-row>
                        <!-- <el-col :span="4"><p>{{$t('msg.CourtName')}}</p></el-col> -->
                        <el-col :span="24">
                            <el-form-item prop="courtName" :label="$t('msg.CourtName')">
                                <el-input
                                    v-model.trim="protectForm.courtName"
                                    :placeholder="$t('msg.PleaseentertheNameofCourt')"
                                    :disabled="!allType"
                                ></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>

                    <!-- 法院地址  -->
                    <el-row>
                        <!-- <el-col :span="4">
                            <p>{{$t('msg.CourtAddress')}}</p>
                        </el-col> -->
                        <el-col :span="24">
                            <el-form-item prop="courtAddress" :label="$t('msg.CourtAddress')">
                                <el-input
                                    v-model.trim="protectForm.courtAddress"
                                    :placeholder="$t('msg.PleaseentertheCourtAddress')"
                                    :disabled="!allType"
                                ></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>

                    <!-- 保全内容  -->
                    <el-row>
                        <!-- <el-col :span="4">
                            <p>{{$t('msg.Application')}}</p>
                        </el-col> -->
                        <el-col :span="24">
                            <el-form-item prop="preserveContent"  :label="$t('msg.Application')">
                                <el-input
                                    v-model.trim="protectForm.preserveContent"
                                    :placeholder="$t('msg.PleaseenterApplication')"
                                    type="textarea"
                                    :disabled="!allType"
                                ></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-divider></el-divider>
                    <!-- 已上传文件  -->
                    <el-row v-if="protectForm.filePath">
                        <el-col :span="4">
                            <p>{{$t('msg.Fileuploaded')}}</p>
                        </el-col>
                        <el-col
                            :span="20"
                            style="display: flex; align-items: center"
                        >
                            <el-dropdown>
                                <span class="el-dropdown-link">
                                    {{ fileShow.fileName }}
                                    <el-icon class="el-icon--right">
                                        <arrow-down />
                                    </el-icon>
                                </span>
                                <template #dropdown>
                                    <el-dropdown-menu>
                                        <el-dropdown-item
                                            v-if="!(fileShow.flagPdf === 2)"
                                            @click="viewFileMaterial"
                                            >{{$t('msg.Preview')}}</el-dropdown-item
                                        >
                                        <el-dropdown-item
                                            @click="downLoadFileMaterial"
                                            >{{$t('msg.Download')}}</el-dropdown-item
                                        >
                                    </el-dropdown-menu>
                                </template>
                            </el-dropdown>
                        </el-col>
                    </el-row>
                    <el-divider></el-divider>
                    <!-- 上传附件  -->
                    <el-row v-if="allType">
                        <el-col :span="4"><p>{{$t('msg.UploadAttachment')}}</p></el-col>
                        <el-col :span="20">
                            <div style="display: flex; align-items: center">
                                <el-upload
                                    :file-list="fileList"
                                    class="upload-demo"
                                    action="#"
                                    :multiple="false"
                                    :auto-upload="false"
                                    :on-change="partyUploadProgress"
                                    :accept="acceptedFormats"
                                >
                                    <el-button>
                                        <svg class="icon" aria-hidden="true">
                                            <use
                                                xlink:href="#icon-shenqing"
                                            ></use></svg
                                        >{{$t('msg.AddAttachment')}}</el-button
                                    >
                                </el-upload>
                                <span style="font-size: 14px; font-weight: 500">
                                    ({{$t('msg.Thesizeoftheuploadedfiledoenotexceed')}}{{
                                        this.$store.state.uploadSize
                                    }}MB)</span
                                >
                            </div>
                        </el-col>
                    </el-row>
                </div>
            </div>

            <!-- 上传文件进度  -->
            <el-row v-if="percentageNumType">
                <el-col :span="4">
                    <p>{{$t('msg.Uploadfileprogress')}}</p>
                </el-col>
                <el-col :span="20">
                    <div class="progressBox">
                        <el-progress
                            :percentage="percentageNum"
                            style="width: 50%"
                        />
                    </div>
                </el-col>
            </el-row>
            <!-- 保存按钮 -->
            <el-row>
                <div class="btn">
                    <el-button
                        v-if="allType"
                        type="primary"
                        @click="preserveSubmit"
                        :loading="preserveLoading"
                        >{{$t('msg.Save')}}</el-button
                    >
                </div>
            </el-row>
        </el-form>
    </div>
</template>
 
<script>
import axios from 'axios'

export default {
    name: 'protectDialog',

    data() {
        return {
            loadingBox: false,
            // 全局变量
            allType: false,
            // 保全类型参数
            protectForm: {
                preserveType: 1,
                courtName: '',
                courtAddress: '',
                preserveContent: ''
            },
            // 保全类型表单校验
            // 校验规则
            protectFormRules: {
                preserveType: [
                    {
                        required: true,
                        message: '请输入保全类型！',
                        trigger: 'blur'
                    }
                ],
                courtName: [
                    {
                        required: true,
                        message: '请输入法院名称！',
                        trigger: 'blur'
                    }
                ],
                courtAddress: [
                    {
                        required: true,
                        message: '请输入法院地址！',
                        trigger: 'blur'
                    }
                ],
                preserveContent: [
                    {
                        required: true,
                        message: '请输入保全内容！',
                        trigger: 'blur'
                    }
                ]
            },
            // 保全下拉框选项
            preserveTypeList: [],
            // 上传数组
            fileList: [],
            // 文件上传格式
            acceptedFormats:
                '.git,.jpg,.jpeg,.bmp,.doc,.xls,.docx,.xlsx,.pdf,.zip,.rar',
            // 在保全页的保存按钮加载效果
            preserveLoading: false,
            // 传递的对象
            myObj: this.$store.state.caseObj,
            // 上传进度条
            percentageNum: 0,
            percentageNumType: false,

            // 判断是什么类型的文件
            pdfType: 0
        }
    },
    methods: {
        async load(allType, row) {
            this.allType = allType
            this.myObj = this.$store.state.caseObj

            this.preserveTypeList = await this.getPreserveTypeList()
            if (row.id) {
                this.protectForm = { ...row }
                this.fileShow = {
                    fileName: row.fileName,
                    filePath: row.filePath,
                    flagPdf: row.flagPdf
                }
            }
        },
        // 请求保全类型
        async getPreserveTypeList() {
            if (this.$store.state['277']) {
                return this.$store.state['277']
            }
            let param = {
                req: 277
            }
            let { data } = await this.$API.sendRequest('styleTypeList', param)
            this.$store.commit('setPartyList', {
                key: '277',
                list: data.materialStyleList
            })
            return data.materialStyleList
        },

        // 上传的change事件，fileList更改触发
        partyUploadProgress() {
            let uploadSize = this.$store.state.uploadSize
            let type = this.fileList[0].size / 1024 / 1024 < uploadSize
            if (this.fileList.length === 1) {
                if (!type) {
                    this.fileList.splice(0, 1)
                    return this.$message.error(
                        '上传文件大小不能超过' + uploadSize + 'MB!'
                    )
                }
            }
            if (this.fileList.length > 1) {
                if (!type) {
                    this.fileList.splice(1, 1)
                    return this.$message.error(
                        '上传文件大小不能超过' + uploadSize + 'MB!'
                    )
                }
            }

            let newFileList = this.fileList
            this.fileList = []
            this.fileList = newFileList

            if (this.fileList.length > 1) {
                this.fileList.splice(0, 1)
            }
        },
        // 点击保存
        preserveSubmit() {
            this.$refs.preserveFormRef.validate(async (valid) => {
                if (valid) {
                    if (
                        this.protectForm.filePath === undefined &&
                        this.fileList.length < 1
                    ) {
                        return this.$message.info('材料为必传')
                    }

                    let fileType
                    if (this.protectForm.filePath && this.fileList.length < 1) {
                        fileType = { success: true }
                    } else {
                        fileType = await this.uploadFile()
                    }

                    if (fileType.success) {
                        let param = {
                            req: {
                                caseId: this.myObj.caseId,
                                ...this.protectForm,
                                filePath: fileType.data?.filePath
                                    ? fileType.data?.filePath
                                    : '',
                                fileName: fileType.data?.fileName
                                    ? fileType.data?.fileName
                                    : '',
                                receiveNo: this.myObj.receiveNo,
                                flagPdf: this.pdfType
                            }
                        }
                        let res = await this.$API.sendRequest(
                            'SavePreserve',
                            param
                        )
                        this.list = res.data
                        if (res.success) {
                            this.$message.success(res.msg)
                            this.preserveLoading = false
                            setTimeout(() => {
                                this.$emit('emitClose')
                            }, 500)
                        } else {
                            this.preserveLoading = false
                            this.$message.error(res.msg)
                        }
                    } else {
                        this.preserveLoading = false
                        this.$message.error('文件上传失败')
                    }
                }
            })
        },
        // 申请材料列表预览
        async viewFileMaterial() {
            this.loadingBox = true
            let param = {
                req: {
                    fileNameDsp: this.fileShow.fileName,
                    filePath: this.fileShow.filePath,
                    flagPdf: this.fileShow.flagPdf
                }
            }
            const res = await this.$API.sendRequest(
                'ocrDownFilePathAndName',
                param
            )
            this.loadingBox = false
            let blob
            if (this.fileShow.flagPdf === 1) {
                blob = new Blob([res], { type: 'application/pdf' })
                window.open(window.URL.createObjectURL(blob))
            } else {
                // this.dialogPreview = true
                blob = new Blob([res], { type: 'image/jpeg' })
                let newWindow = window.open(window.URL.createObjectURL(blob))
                newWindow.onload = () => {
                    let button = document.createElement('button')
                    this.$GF.cretateRotateBtn(newWindow, button)
                }
            }
        },

        // 申请材料列表下载
        async downLoadFileMaterial() {
            this.loadingBox = true
            let param = {
                req: {
                    fileNameDsp: this.fileShow.fileName,
                    filePath: this.fileShow.filePath,
                    flagPdf: this.fileShow.flagPdf
                }
            }
            let res = await this.$API.sendRequest(
                'ocrDownFilePathAndName',
                param
            )
            this.loadingBox = false
            const filename = this.fileShow.fileName
            let downEnum = this.$store.state.downEnum
            let fileType = filename.substring(filename.lastIndexOf('.'))
            for (let key in downEnum) {
                if (key === fileType) {
                    let blob = new Blob([res], { type: downEnum[key] })
                    let url = window.URL.createObjectURL(blob)
                    let link = document.createElement('a')
                    link.href = url
                    link.download = this.fileShow.fileName
                    link.click()
                    window.URL.revokeObjectURL(link.href)
                }
            }
        },

        // 上传材料
        async uploadFile() {
            this.preserveLoading = true
            this.percentageNumType = true
            let str = this.fileList[0].name
            this.pdfType = this.$GF.pdfFlagFn(str)
            const formData = new FormData()
            formData.append('req.abbr', 'WS')
            formData.append('req.typeName', '')
            formData.append('req.profile', '')
            formData.append('req.remarks', '')
            formData.append('req.caseId', this.myObj.caseId)
            formData.append('file', this.fileList[0].raw)
            formData.append('req.flagPdf', this.pdfType)
            formData.append('req.oldFileName', this.fileList[0].name)
            formData.append('req.typeId', 2)
            formData.append('req.receiveLetterNo', this.myObj.receiveNo)
            formData.append('req.partyType', 1)
            formData.append('req.materialFlag', 5)

            let myThis = this
            let res = await axios({
                url: '/api/ocrUse/ocrSaveUpload',
                method: 'POST',
                onUploadProgress: function (progressEvent) {
                    const percentCompleted = Math.round(
                        (progressEvent.loaded * 100) / progressEvent.total
                    )
                    if (percentCompleted < 100) {
                        // 可以在这里更新用户界面以显示上传进度
                        myThis.percentageNum = percentCompleted
                    } else {
                        // 可以在这里更新用户界面以显示上传进度
                        myThis.percentageNum = 99
                    }
                },
                headers: {
                    c_Auth_token: localStorage.getItem('c_Auth_toke')
                },
                data: formData
            })

            if (res.data.success) {
                return res.data
            } else {
                return res.data
            }
        },

        // 关闭弹窗  清空数据
        closeDilalogClear() {
            this.protectForm = {
                preserveType: 1,
                courtName: '',
                courtAddress: '',
                preserveContent: ''
            }
            this.percentageNum = 0
            this.percentageNumType = false
            this.myObj = {}
            this.fileList = []
            this.pdfType = 0
            this.preserveLoading = false
            this.$refs.preserveFormRef.resetFields()
        }
    }
}
</script>
 
<style scoped lang="scss">
.btn {
    width: 100%;
    display: flex;
    justify-content: center;
}
p {
    font-size: 16px;
    text-align: right;
    padding-right: 40px;
}
.progressBox {
    height: 100%;

    display: flex;
    align-items: center;
}
</style>