<template>
    <!-- 月份选择器，支持左右箭头修改月份 -->
    <div class="month-con">
        <h1>ss</h1>
        1111111111
        <el-button @click="clickUploadBtn">上传</el-button>
        <img :src="src" alt="" />
        <el-button @click="getOcrCode">getOcrCode</el-button>
    </div>
</template>
  
  <script>
import printJS from 'print-js'
export default {
    data() {
        return {
            fileList: [],
            src: ''
        }
    },
    created() {},
    methods: {
        async clickUploadBtn() {
            let res = await this.$API.sendRequest('GetCode')
            // console.log(res)

            let urlText = 'data:image/jpg;base64,' + res
            this.src = urlText
        },

        // 点击了补打里面打印
        async getOcrCode() {
            printJS({
                // 要打印的内容 放在了data里
                printable: this.src,
                // 打印类型 目前为图片样式 可以根据上面的网址进行修改
                type: 'image',
                // 二维码样式 可以自己进行修改
                imageStyle:
                    'width:80px; height:80px; display: block; margin: 0px 0px 0px -13px;'
                // 也可以设置以下参数 继承所有css样式 没试过image的 html的效果不错
            })
        }
    }
}
</script>
  <style lang="scss" scoped>
img {
    width: 300px;
    height: 300px;
}
</style>
 