<template>
    <div v-loading="partyDialog">
        <el-form :model="partyInfo" :rules="partyInfoRules" class="partyForm" ref="partyFormRef"
            :validate-on-rule-change="false">
            <!-- 添加申请人信息 -->
            <div class="partyBox">
                <div class="partyInfoBox">
                    <h4>
                        {{
                            partyType === 1
                                ? $t('msg.AddApplicantInformation')
                                : $t('msg.AddTheDefendantInformation')
                        }}
                    </h4>
                    <el-divider></el-divider>
                    <!-- 当事人类型  -->
                    <el-row>
                        <el-col :span="4">
                            <p>
                                {{
                                    partyType === 1
                                        ? $t('msg.PartyType')
                                        : $t('msg.TypeofRespondent')
                                }}
                            </p>
                        </el-col>
                        <el-col :span="20">
                            <el-radio-group :disabled="!allType" class="ml-4" v-model="partyInfo.partyClass"
                                @input="radioChange">
                                <el-radio :label="item.dictionaryItemValue" size="large" v-for="item in partyTypeList"
                                    :key="item.dictionaryItemValue">{{ item.dictionaryItemName }}</el-radio>
                            </el-radio-group>
                        </el-col>
                    </el-row>

                    <!-- -------------------------自然人---1------------------------------------ -->
                    <div v-if="partyInfo.partyClass === 1">
                        <!-- 姓名 -->
                        <el-row>
                            <el-col :span="4">
                                <p>
                                    <span class="requireIconBox">{{ $t('msg.Name') }}
                                        <span>
                                            <svg class="icon">
                                                <use xlink:href="#icon-bitian"></use>
                                            </svg></span>
                                    </span>
                                </p>
                            </el-col>
                            <el-col :span="20">
                                <el-form-item prop="partyCompany">
                                    <!-- <el-input :disabled="!allType" :placeholder="$t('msg.PleaseEnteraName')
                                        " v-model.trim="partyInfo.partyCompany"></el-input> -->

                                    <el-autocomplete style="width: 100%" :disabled="!allType"
                                        v-model.trim="partyInfo.partyCompany" :trigger-on-focus="false"
                                        :fetch-suggestions="partyInfoPartyCompanyList" :debounce="500"
                                        :placeholder="$t('msg.PleaseEnteraName')"></el-autocomplete>
                                </el-form-item>
                            </el-col>
                        </el-row>

                            <!-- 邮箱 -->
                            <el-row>
                            <el-col :span="4">
                                <p>
                                    <span class="requireIconBox">{{ $t('msg.register_email') }}
                                    </span>
                                </p>
                            </el-col>
                            <el-col :span="20">
                                <el-form-item >
                                    <el-input :disabled="!allType" :placeholder="$t('msg.register_email_tip')
                                        " v-model.trim="partyInfo.email"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <!-- 国家/地区名称 -->
                        <el-row>
                            <el-col :span="4">
                                <p>
                                    <span class="requireIconBox">{{ $t('msg.NameofCountryRegion') }}
                                        <span>
                                            <svg class="icon">
                                                <use xlink:href="#icon-bitian"></use>
                                            </svg></span>
                                    </span>
                                </p>
                            </el-col>
                            <el-col :span="20">
                                <el-form-item prop="nationality">
                                    <el-select :disabled="!allType" v-model="partyInfo.nationality" filterable
                                        :placeholder="$t('msg.PleaseSelect')" style="width: 100%"
                                        @change="countrySelectChange">
                                        <el-option v-for="(
                                                item, index
                                            ) in getCountriesList" :key="index" :label="item.dictionaryItemName"
                                            :value="item.dictionaryItemValue" />
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <!-- 选择证件类型  -->
                        <el-row>
                            <el-col :span="4">
                                <p>
                                    <span class="requireIconBox">{{ $t('msg.IDType') }}
                                        <span>
                                            <svg class="icon">
                                                <use xlink:href="#icon-bitian"></use>
                                            </svg></span>
                                    </span>
                                </p>
                            </el-col>
                            <el-col :span="20">
                                <el-form-item prop="idType">
                                    <el-select :disabled="!allType" v-model="partyInfo.idType" filterable
                                        :placeholder="$t('msg.PleaseSelect')" style="width: 100%">
                                        <el-option v-for="(
                                                item, index
                                            ) in newGetDictionaryLtemsList" :key="index"
                                            :label="item.dictionaryItemName" :value="item.dictionaryItemValue" />
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>

                        <!-- 证件号码  -->
                        <el-row v-if="partyInfo.idType === 1">
                            <el-col :span="4">
                                <p>
                                    <span class="requireIconBox">{{ $t('msg.IDNo') }}
                                        <span>
                                            <svg class="icon">
                                                <use xlink:href="#icon-bitian"></use>
                                            </svg></span>
                                    </span>
                                </p>
                            </el-col>
                            <el-col :span="20">
                                <el-form-item  prop="idNum">
                                    <el-autocomplete style="width: 100%" :disabled="!allType"
                                        v-model.trim="partyInfo.idNum" :trigger-on-focus="false"
                                        :fetch-suggestions="partyInfoIdNumList" :debounce="500"
                                        :placeholder="$t('msg.PleaseEnterTheIDNumber')"></el-autocomplete>
                                </el-form-item>
                        
                            </el-col>
                        </el-row>
                        <el-row v-else>
                            <el-col :span="4">
                                <p>
                                    <span class="requireIconBox">{{ $t('msg.IDNo') }}
                                        <span>
                                            <svg class="icon">
                                                <use xlink:href="#icon-bitian"></use>
                                            </svg></span>
                                    </span>
                                </p>
                            </el-col>
                            <el-col :span="20">
                                <el-form-item  prop="idNum2">
                                    <el-autocomplete style="width: 100%" :disabled="!allType"
                                        v-model.trim="partyInfo.idNum2" :trigger-on-focus="false"
                                        :fetch-suggestions="partyInfoIdNumList" :debounce="500"
                                        :placeholder="$t('msg.PleaseEnterTheIDNumber')"></el-autocomplete>
                                </el-form-item>
                        
                            </el-col>
                        </el-row>

                        <!-- 证件地址 -->
                        <el-row>
                            <el-col :span="4">
                                <p>
                                    <span class="requireIconBox">{{ $t('msg.DomicileDocumentAddress') }}
                                        <span>
                                            <svg class="icon">
                                                <use xlink:href="#icon-bitian"></use>
                                            </svg></span>
                                    </span>
                                </p>
                            </el-col>
                            <el-col :span="20">
                                <el-form-item prop="idAddress">
                                    <!-- <el-input :placeholder="$t(
                                        'msg.PleaseEnterResidenceDocumentAddress'
                                    )
                                        " v-model.trim="partyInfo.idAddress" :disabled="!allType"></el-input> -->

                                    <el-autocomplete style="width: 100%" :disabled="!allType"
                                        v-model.trim="partyInfo.idAddress" :trigger-on-focus="false"
                                        :fetch-suggestions="partyInfoIdAddressList" :debounce="500"
                                        :placeholder="$t('msg.PleaseEnterResidenceDocumentAddress')"></el-autocomplete>
                                </el-form-item>
                            </el-col>
                        </el-row>

                       
                    </div>

                    <!-- --------------------------法人---2------------------------------------ -->
                    <div v-if="partyInfo.partyClass === 2">
                        <!-- 姓名 -->
                        <el-row>
                            <el-col :span="4">
                                <p>
                                    <span class="requireIconBox">{{ $t('msg.Company') }}
                                        <span>
                                            <svg class="icon">
                                                <use xlink:href="#icon-bitian"></use>
                                            </svg></span>
                                    </span>
                                </p>
                            </el-col>
                            <el-col :span="20">
                                <el-form-item prop="partyCompany">
                                    <!-- <el-input :disabled="!allType" :placeholder="$t(
                                        'msg.Pleaseenterthenameoftheorganization'
                                    )
                                        " v-model.trim="partyInfo.partyCompany"></el-input> -->

                                    <el-autocomplete style="width: 100%" :disabled="!allType"
                                        v-model.trim="partyInfo.partyCompany" :trigger-on-focus="false"
                                        :fetch-suggestions="partyInfoPartyCompanyList" :debounce="500"
                                        :placeholder="$t('msg.Pleaseenterthenameoftheorganization')"></el-autocomplete>
                                </el-form-item>
                            </el-col>
                        </el-row>
                            <!-- 邮箱 -->
                            <el-row>
                            <el-col :span="4">
                                <p>
                                    <span class="requireIconBox">{{ $t('msg.register_email') }}
                                    </span>
                                </p>
                            </el-col>
                            <el-col :span="20">
                                <el-form-item >
                                    <el-input :disabled="!allType" :placeholder="$t('msg.register_email_tip')
                                        " v-model.trim="partyInfo.email"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>

                        <!-- 国家/地区名称 -->
                        <el-row>
                            <el-col :span="4">
                                <p>
                                    <span class="requireIconBox">{{ $t('msg.NameofCountryRegion') }}
                                        <span>
                                            <svg class="icon">
                                                <use xlink:href="#icon-bitian"></use>
                                            </svg></span>
                                    </span>
                                </p>
                            </el-col>
                            <el-col :span="20">
                                <el-form-item prop="nationality">
                                    <el-select :disabled="!allType" v-model="partyInfo.nationality" filterable
                                        :placeholder="$t('msg.PleaseSelect')" style="width: 100%"
                                        @change="countrySelectChange">
                                        <el-option v-for="(
                                                item, index
                                            ) in getCountriesList" :key="index" :label="item.dictionaryItemName"
                                            :value="item.dictionaryItemValue" />
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>

                        <!-- 法人分类 v-if="partyClassType"-->
                        <el-row>
                            <el-col :span="4">
                                <p>
                                    <span class="requireIconBox">{{
                                        $t('msg.LegalPersonCountryRegion')
                                    }}
                                        <span>
                                            <svg class="icon">
                                                <use xlink:href="#icon-bitian"></use>
                                            </svg></span>
                                    </span>
                                </p>
                            </el-col>
                            <el-col :span="20">
                                <el-form-item prop="corporationType">
                                    <el-select :disabled="!allType" v-model="partyInfo.corporationType" filterable
                                        :placeholder="$t('msg.PleaseSelect')" style="width: 100%"
                                        @change="corporationTypeChange">
                                        <el-option v-for="(
                                                item, index
                                            ) in corporationTypeList" :key="index" :label="item.dictionaryItemName"
                                            :value="item.dictionaryItemValue" />
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>

                        <!-- -------------------------------------------------  -->
                        <!-- 法人类型 -->
                        <el-row v-if="legalPersonTypeA">
                            <el-col :span="4">
                                <p>
                                    <span class="requireIconBox">{{ $t('msg.LegalEntityType') }}
                                        <span>
                                            <svg class="icon">
                                                <use xlink:href="#icon-bitian"></use>
                                            </svg></span>
                                    </span>
                                    <br />
                                    <span style="font-size: 12px">({{
                                        $t(
                                            'msg.Pleaseselectrdingtothebusinesslicense'
                                        )
                                    }})</span>
                                </p>
                            </el-col>
                            <el-col :span="20">
                                <el-form-item prop="corporationType1">
                                    <el-select :disabled="!allType" v-model="partyInfo.corporationType1" filterable
                                        :placeholder="$t('msg.PleaseSelect')" style="width: 100%"
                                        @change="corporationType1Change">
                                        <el-option v-for="(
                                                item, index
                                            ) in corporationType1List" :key="index" :label="item.dictionaryItemName"
                                            :value="item.dictionaryItemValue" />
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <!-- 外商因素 -->
                        <el-row v-if="
                            legalPersonTypeA &&
                            partyInfo.corporationType1 !== 4 &&
                            partyInfo.corporationType1 !== 5
                        ">
                            <el-col :span="4">
                                <p>
                                    <span class="requireIconBox">
                                        {{ $t('msg.ForeignFactors') }}
                                        <span>
                                            <svg class="icon">
                                                <use xlink:href="#icon-bitian"></use>
                                            </svg></span>
                                    </span>
                                </p>
                            </el-col>
                            <el-col :span="20">
                                <el-form-item prop="investType">
                                    <el-select :disabled="!allType" v-model="partyInfo.investType" filterable
                                        :placeholder="$t('msg.PleaseSelect')" style="width: 100%"
                                        @change="investTypeChange">
                                        <el-option v-for="(
                                                item, index
                                            ) in legalPersonTypeB" :key="index" :label="item.dictionaryItemName"
                                            :value="item.dictionaryItemValue" />
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <!-- 企业类型 -->
                        <el-row v-if="partyInfo.corporationType1">
                            <el-col :span="4">
                                <p>
                                    <span class="requireIconBox">
                                        {{ $t('msg.Classification') }}
                                        <span>
                                            <svg class="icon">
                                                <use xlink:href="#icon-bitian"></use>
                                            </svg></span>
                                    </span>
                                </p>
                            </el-col>
                            <el-col :span="20">
                                <el-form-item prop="entType">
                                    <el-select :disabled="!allType" v-model="partyInfo.entType" filterable
                                        :placeholder="$t('msg.PleaseSelect')" style="width: 100%">
                                        <el-option v-for="(
                                                item, index
                                            ) in legalPersonTypeC" :key="index" :label="item.dictionaryItemName"
                                            :value="item.dictionaryItemValue" />
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <!-- ------------------------------------------------- -->

                        <!-- 营业执照号 -->
                        <el-row>
                            <el-col :span="4">
                                <p>
                                    <span class="requireIconBox">{{
                                        $t(
                                            'msg.UnifiedSocialCreditCodeBusinessLicenseNo'
                                        )
                                    }}
                                        <br />（{{
                                            $t('msg.Businesslicensenumber')
                                        }}）
                                    </span>
                                </p>
                            </el-col>
                            <el-col :span="20">
                                <el-form-item>
                                    <el-input :placeholder="$t(
                                        'msg.Pleaseenterthebusinesslicensenumber'
                                    )
                                        " v-model.trim="partyInfo.licenseNo" :disabled="!allType"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <!-- 营业执照地址 -->
                        <el-row>
                            <el-col :span="4">
                                <p>
                                    <span class="requireIconBox">{{ $t('msg.Residence') }}({{
                                        $t('msg.Businesslicenseaddress')
                                    }})
                                        <span>
                                            <svg class="icon">
                                                <use xlink:href="#icon-bitian"></use>
                                            </svg></span>
                                    </span>
                                </p>
                            </el-col>
                            <el-col :span="20">
                                <el-form-item prop="licenseAddress">
                                    <!-- <el-input :placeholder="$t(
                                        'msg.Pleaseenterresidencebusinesslicenseaddress'
                                    )
                                        " v-model.trim="partyInfo.licenseAddress" :disabled="!allType"></el-input> -->
                                    <el-autocomplete style="width: 100%" :disabled="!allType"
                                        v-model.trim="partyInfo.licenseAddress" :trigger-on-focus="false"
                                        :fetch-suggestions="partyInfoLicenseAddressList" :debounce="500"
                                        :placeholder="$t('msg.Pleaseenterresidencebusinesslicenseaddress')"></el-autocomplete>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </div>

                    <!-- ------------------------其他组织--3----------------------------------------------- -->
                    <div v-if="partyInfo.partyClass === 3">
                        <!-- 姓名 -->
                        <el-row>
                            <el-col :span="4">
                                <p>
                                    <span class="requireIconBox">{{ $t('msg.OrganizationName') }}
                                        <span>
                                            <svg class="icon">
                                                <use xlink:href="#icon-bitian"></use>
                                            </svg></span>
                                    </span>
                                </p>
                            </el-col>
                            <el-col :span="20">
                                <el-form-item prop="partyCompany">
                                    <!-- <el-input :disabled="!allType" :placeholder="$t(
                                        'msg.PleaseenteranorganizationName'
                                    )
                                        " v-model.trim="partyInfo.partyCompany"></el-input> -->

                                    <el-autocomplete style="width: 100%" :disabled="!allType"
                                        v-model.trim="partyInfo.partyCompany" :trigger-on-focus="false"
                                        :fetch-suggestions="partyInfoPartyCompanyList" :debounce="500"
                                        :placeholder="$t('msg.PleaseenteranorganizationName')"></el-autocomplete>
                                </el-form-item>
                            </el-col>
                        </el-row>

                            <!-- 邮箱 -->
                            <el-row>
                            <el-col :span="4">
                                <p>
                                    <span class="requireIconBox">{{ $t('msg.register_email') }}
                                    </span>
                                </p>
                            </el-col>
                            <el-col :span="20">
                                <el-form-item >
                                    <el-input :disabled="!allType" :placeholder="$t('msg.register_email_tip')
                                        " v-model.trim="partyInfo.email"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>

                        <!-- 国家/地区名称 -->
                        <el-row>
                            <el-col :span="4">
                                <p>
                                    <span class="requireIconBox">{{ $t('msg.NameofCountryRegion') }}
                                        <span>
                                            <svg class="icon">
                                                <use xlink:href="#icon-bitian"></use>
                                            </svg></span>
                                    </span>
                                </p>
                            </el-col>
                            <el-col :span="20">
                                <el-form-item prop="nationality">
                                    <el-select :disabled="!allType" v-model="partyInfo.nationality" filterable
                                        :placeholder="$t('msg.PleaseSelect')" style="width: 100%"
                                        @change="countrySelectChange">
                                        <el-option v-for="(
                                                item, index
                                            ) in getCountriesList" :key="index" :label="item.dictionaryItemName"
                                            :value="item.dictionaryItemValue" />
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>

                        <!-- 组织机构代码 -->
                        <el-row>
                            <el-col :span="4">
                                <p>
                                    <span class="requireIconBox">{{
                                        $t(
                                            'msg.TaxpayerIdentificationNumber'
                                        )
                                    }}
                                    </span>
                                </p>
                            </el-col>
                            <el-col :span="20">
                                <el-form-item>
                                    <!-- <el-input :placeholder="$t(
                                        'msg.PleaseEntertheOrganizationCode'
                                    )
                                        " v-model.trim="partyInfo.licenseNo" :disabled="!allType"></el-input> -->
                                    <el-autocomplete style="width: 100%" :disabled="!allType"
                                        v-model.trim="partyInfo.licenseNo" :trigger-on-focus="false"
                                        :fetch-suggestions="partyInfoLicenseNoList" :debounce="500"
                                        :placeholder="$t('msg.PleaseEntertheOrganizationCode')"></el-autocomplete>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </div>
                    <!-- ------------------------个体工商户--4----------------------------------------------- -->
                    <div v-if="partyInfo.partyClass === 4">
                        <!-- 商号 -->
                        <el-row>
                            <el-col :span="4">
                                <p>
                                    <span class="requireIconBox">{{ $t('msg.Firm') }}
                                        <span>
                                            <svg class="icon">
                                                <use xlink:href="#icon-bitian"></use>
                                            </svg></span>
                                    </span>
                                </p>
                            </el-col>
                            <el-col :span="20">
                                <el-form-item prop="partyCompany">
                                    <!-- <el-input :disabled="!allType" :placeholder="$t('msg.Pleaseenterthebusinessname')
                                        " v-model.trim="partyInfo.partyCompany"></el-input> -->

                                    <el-autocomplete style="width: 100%" :disabled="!allType"
                                        v-model.trim="partyInfo.partyCompany" :trigger-on-focus="false"
                                        :fetch-suggestions="partyInfoPartyCompanyList" :debounce="500"
                                        :placeholder="$t('msg.PleaseenteranorganizationName')"></el-autocomplete>
                                </el-form-item>
                            </el-col>
                        </el-row>

                            <!-- 邮箱 -->
                            <el-row>
                            <el-col :span="4">
                                <p>
                                    <span class="requireIconBox">{{ $t('msg.register_email') }}
                                    </span>
                                </p>
                            </el-col>
                            <el-col :span="20">
                                <el-form-item >
                                    <el-input :disabled="!allType" :placeholder="$t('msg.register_email_tip')
                                        " v-model.trim="partyInfo.email"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>

                        <!-- 国家/地区名称 -->
                        <el-row>
                            <el-col :span="4">
                                <p>
                                    <span class="requireIconBox">{{ $t('msg.NameofCountryRegion') }}
                                        <span>
                                            <svg class="icon">
                                                <use xlink:href="#icon-bitian"></use>
                                            </svg></span>
                                    </span>
                                </p>
                            </el-col>
                            <el-col :span="20">
                                <el-form-item prop="nationality">
                                    <el-select :disabled="!allType" v-model="partyInfo.nationality" filterable
                                        :placeholder="$t('msg.PleaseSelect')" style="width: 100%"
                                        @change="countrySelectChange">
                                        <el-option v-for="(
                                                item, index
                                            ) in getCountriesList" :key="index" :label="item.dictionaryItemName"
                                            :value="item.dictionaryItemValue" />
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>

                        <!-- 经营者 -->
                        <el-row>
                            <el-col :span="4">
                                <p>
                                    <span class="requireIconBox">{{ $t('msg.Operator') }}
                                        <span>
                                            <svg class="icon">
                                                <use xlink:href="#icon-bitian"></use>
                                            </svg></span>
                                    </span>
                                </p>
                            </el-col>
                            <el-col :span="20">
                                <el-form-item :prop="partyInfo.nationality === 1 ? 'partyName' : ''">
                                    <!-- <el-input :placeholder="$t('msg.Pleaseenteroperator')
                                        " v-model.trim="partyInfo.partyName" :disabled="!allType"></el-input> -->
                                    <el-autocomplete style="width: 100%" :disabled="!allType"
                                        v-model.trim="partyInfo.partyName" :trigger-on-focus="false"
                                        :fetch-suggestions="partyInfoPartyNameList" :debounce="500"
                                        :placeholder="$t('msg.Pleaseenteroperator')"></el-autocomplete>
                                </el-form-item>
                            </el-col>
                        </el-row>

                        <!-- 营业执照号 -->
                        <el-row>
                            <el-col :span="4">
                                <p>
                                    <span class="requireIconBox">{{ $t('msg.Businesslicensenumber') }}
                                    </span>
                                </p>
                            </el-col>
                            <el-col :span="20">
                                <el-form-item>
                                    <!-- <el-input :placeholder="$t('msg.Pleaseenterthebusinesslicensenumber')" v-model.trim="partyInfo.licenseNo" :disabled="!allType"></el-input> -->
                                    <el-autocomplete style="width: 100%" :disabled="!allType"
                                        v-model.trim="partyInfo.licenseNo" :trigger-on-focus="false"
                                        :fetch-suggestions="partyInfoLicenseNoList" :debounce="500"
                                        :placeholder="$t('msg.Pleaseenterthebusinesslicensenumber')"></el-autocomplete>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <!-- 营业执照地址 -->
                        <el-row>
                            <el-col :span="4">
                                <p>
                                    <span class="requireIconBox">{{
                                        $t(
                                            'msg.Addressbusinesslicenseaddress'
                                        )
                                    }}
                                        <span>
                                            <svg class="icon">
                                                <use xlink:href="#icon-bitian"></use>
                                            </svg></span>
                                    </span>
                                </p>
                            </el-col>
                            <el-col :span="20">
                                <el-form-item prop="licenseAddress">
                                    <!-- <el-input :placeholder="$t(
                                        'msg.Pleaseenterresidencebusinesslicenseaddress'
                                    )
                                        " v-model.trim="partyInfo.licenseAddress" :disabled="!allType"></el-input> -->

                                    <el-autocomplete style="width: 100%" :disabled="!allType"
                                        v-model.trim="partyInfo.licenseAddress" :trigger-on-focus="false"
                                        :fetch-suggestions="partyInfoLicenseAddressList" :debounce="500"
                                        :placeholder="$t('msg.Pleaseenterresidencebusinesslicenseaddress')"></el-autocomplete>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </div>
                </div>
            </div>

            <!-- 提示文本 -->
            <div class="cardTextBox" v-if="partyType === 1">
                <el-card>
                    <p>
                        1、{{
                            $t(
                                'msg.WhenuploadingafilepleasechangethenameofthefiletobeploadedtothenameofthecorrespondingmaterialuchasIDcardbusinesslicenseetc'
                            )
                        }}；
                    </p>
                    <p>
                        2、{{
                            $t(
                                'msg.Iftheapplicantisanaturalersonhesheshouldsubmitscannedcopiesuc'
                            )
                        }}；
                    </p>
                    <p>
                        3.
                        {{
                            $t(
                                'msg.Ifthereisanaturalpersoninvolcontractlenancbusiness'
                            )
                        }}
                    </p>
                    <p>
                        4、{{
                            $t(
                                'msg.IftheapplicantisalegalpersoninChinesemainlandascannedcopyofavalidbusinesslicenseandthesubmitted'
                            )
                        }}；
                    </p>
                    <p>
                        5、{{
                            $t(
                                'msg.IftheapplicantisalegalpersonfromHongKongMacaoTaiwanoraforeigncountry'
                            )
                        }}；
                    </p>
                    <p>
                        6.
                        {{
                            $t(
                                'msg.Alldocumentssubmittedbythepartiesdonotneedtobenotarizedathetribunal'
                            )
                        }}；
                    </p>
                    <p>
                        7.{{
                            $t(
                                'msg.Wherethereischangeinthenameofthepartiesrelevantevidenceofthenamechangeshallbesubmitted'
                            )
                        }}
                    </p>
                    <p v-if="partyInfo.partyClass === 2">
                        8.
                        鉴于案涉合同当事人存在自然人，请书面告知该自然人是否存在《最高人民法院关于适用&lt;中华人民共和国涉外民事关系法律适用法&gt;若干问题的解释（一）》第十三条之情形，以便确认本案适用程序。
                    </p>
                    <p>
                        {{ $t('msg.ReferenceTemplate') }}:
                        <span @click="downloadDoc(2)">{{
                            $t('msg.Certificateofidentityoflegalrepresentative')
                            }}</span>
                    </p>
                </el-card>
            </div>
            <div class="cardTextBox" v-else>
                <el-card>
                    <p>
                        1.
                        {{
                            $t(
                                'msg.Whenuploadingafilepleaseheofthefileslicenseetc'
                            )
                        }}
                    </p>
                    <p>
                        2.
                        {{
                            $t(
                                'msg.Iftheapplicantisnapersoncopyofhisandpassport'
                            )
                        }}
                    </p>
                    <p>
                        3.
                        {{
                            $t(
                                'msg.IftherespondentisCionslatformssuchasQichacha'
                            )
                        }}
                    </p>
                    <p>
                        4.
                        {{ $t('msg.IftheaplegaHoqualificationstillexists') }}
                    </p>
                    <p>
                        5. {{ $t('msg.Ifthnameoftherespoeenrelevantubmitted') }}
                    </p>
                    <!-- <p>
                        附件:
                        <span @click="downloadDoc(2)"
                            >法定代表人证明书模板</span
                        >
                    </p> -->
                </el-card>
            </div>
            <!-- 添加主体资格证明材料  -->
            <div class="partyBox">
                <div class="partyInfoBox">
                    <h4 style="display: flex; align-items: center">
                        {{ $t('msg.Addproofofthesubjectisqualifications') }}
                        <el-upload :file-list="fileList" class="upload-demo" action="#" multiple :auto-upload="false"
                            :show-file-list="false" :on-change="partyUploadProgress" :accept="acceptedFormats">
                            <el-button style="margin-left: 20px" :disabled="!allType">
                                <svg class="icon">
                                    <use xlink:href="#icon-shenqing"></use>
                                </svg>{{ $t('msg.AddAttachment') }}</el-button>
                        </el-upload>
                        <span style="font-size: 14px; font-weight: 500">
                            ({{
                                $t('msg.Thesizeoftheuploadedfiledoenotexceed')
                            }}
                            {{ this.$store.state.uploadSize }}MB)</span>
                    </h4>

                    <el-divider></el-divider>

                    <!-- 展示表头的表格 -->
                    <el-table :border="true" style="width: 100%">
                        <el-table-column prop="fileName" :label="$t('msg.NameoftheMaterial')" />
                        <el-table-column prop="address" :label="$t('msg.Operate')" />
                    </el-table>

                    <!-- 材料类型       待上传        表格  -->

                    <el-table v-if="myMaterialList.length !== 0" :data="myMaterialList" :border="true"
                        style="width: 100%" :show-header="false">
                        <el-table-column prop="name" :label="$t('msg.NameoftheMaterial')" />
                        <el-table-column prop="address" :label="$t('msg.Operate')">
                            <template #default="scope">
                                <el-button link type="primary" size="small" @click="clickViewPreview(scope)">{{
                                    $t('msg.View') }}</el-button>
                                <el-button link type="danger" size="small" @click="deleteMyMaterialList(scope, 'M')">{{
                                    $t('msg.Delete') }}</el-button>
                            </template>
                        </el-table-column>
                    </el-table>

                    <!-- 材料类型       已添加      表格  -->
                    <el-table :data="ShowFileList" :border="true" style="width: 100%" :show-header="false"
                        v-if="ShowFileList.length !== 0">
                        <el-table-column prop="fileNameDsp" :label="$t('msg.NameoftheMaterial')" />
                        <el-table-column prop="address" :label="$t('msg.Operate')">
                            <template #default="scope">
                                <el-button link type="primary" size="small" @click="newclickViewPreview(scope.row)"
                                    v-if="!(scope.row.flagPdf === 2)">{{ $t('msg.View') }}</el-button>
                                <el-button link type="danger" size="small" @click="deleteMyMaterialList(scope, 'S')"
                                    v-if="allType">{{ $t('msg.Delete') }}</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                    <el-row style="margin-top: 20px">
                        <el-col :span="20"></el-col>
                        <el-col :span="4"> </el-col>
                    </el-row>
                </div>
            </div>

            <!-- 上传文件进度  percentageNumType-->
            <el-row v-if="percentageNumType">
                <el-col :span="4">
                    <p style="text-align: right; padding-right: 10px">
                        {{ $t('msg.Uploadfileprogress') }}
                    </p>
                </el-col>
                <el-col :span="20" style="font-size: 16px">
                    <div class="progressBox">
                        <el-progress :percentage="percentageNum" style="width: 50%" />
                        <span :class="{
                            progressTextColor:
                                trueNum !== myMaterialList.length
                        }">
                            {{ trueNum }}</span>/{{ myMaterialList.length }}
                    </div>
                </el-col>
            </el-row>

            <!-- 保存按钮 -->
            <el-row>
                <div class="btn">
                    <el-button type="primary" @click="addPartySubmit" :loading="submitLoading" v-if="allType">{{
                        $t('msg.Save')
                        }}</el-button>
                </div>
            </el-row>
        </el-form>
    </div>
</template>

<script>
import axios from 'axios'
export default {
    name: 'partyDialog',
    components: {},
    data() {
        return {
            // 当事人类型
            partyTypeList: [],
            // 自己选择上传的文件列表数组
            myMaterialList: [],
            // 上传的文件列表
            fileList: [],
            // 文件上传格式
            acceptedFormats:
                '.git,.jpg,.jpeg,.bmp,.doc,.xls,.docx,.xlsx,.pdf,.zip,.rar',

            // 添加申请人信息选择国家/地区  数组
            getDictionaryLtemsList: [],
            // 城市下拉框
            areaNameSelectList: [],
            // 申请人下拉框省级选择内容
            areaNameValue: '',
            // 市级下拉框内容
            marketList: [],
            // 县级下拉框内容
            countyList: [],
            // 添加申请人代理人弹窗
            agentDialog: false,
            // 添加申请人代理人国籍
            nationalitySelect: '',
            // 添加申请人选择非中国后选择国别数组
            getCountriesList: [],
            // 添加申请人弹窗加载效果
            partyDialogDialog: true,
            // 添加申请人信息
            partyInfo: {
                // 申请人名称
                partyName: '',

                // 邮箱 
                email:'',
                //当事人类别
                partyClass: 1,
                //国家/地区名称
                nationality: '',
                // 证件类型
                idType: '',
                // 身份证号
                idNum: '',
                // 证件地址
                idAddress: '',
                // 单位
                partyCompany: '',
                // 法人类型
                corporationType1: '',
                // 法人分类
                corporationType: '',
                // 外商因素  企业分类
                investType: '',
                // 营业执照号
                licenseNo: '',
                // 企业分类
                entType: '',
                // 是否上市
                isListedCompany: ''
            },
            partyInfoRules: {
                partyName: [
                    {
                        required: true,
                        message: '请按照营业执照列明经营者！',
                        trigger: 'blur'
                    },
                    {  max: 200, message: '长度不能超过200个字符', trigger: 'blur' }
                ],
                phone: [
                    {
                        required: true,
                        message: '请输入联系电话！',
                        trigger: 'blur'
                    },
                    {
                        pattern: '^1[3-9][0-9]{9}$',
                        message: '请输入正确的手机号',
                        trigger: 'blur'
                    },
                    {  max: 80, message: '长度不能超过80个字符', trigger: 'blur' }
                ],
                nationality: [
                    {
                        required: true,
                        message: '请选择国家/地区名称！',
                        trigger: 'change'
                    }
                ],
                idType: [
                    {
                        required: true,
                        message: '请选择证件类型！',
                        trigger: 'change'
                    }
                ],

                idNum: [
                    {
                        required: true,
                        message: '请输入证件号码！',
                        trigger: 'blur'
                    },
                    {
                        pattern: '^(\\d{15}$|^\\d{18}$|^\\d{17}(\\d|X|x))$',
                        message: '请输入正确的证件号码',
                        trigger: 'blur'
                    },
                    {  max: 25, message: '长度不能超过25个字符', trigger: ['change','blur'] }
                ],
                idNum2: [
                    {
                        required: true,
                        message: '请输入证件号码！',
                        trigger: 'blur'
                    },
                    {  max: 25, message: '长度不能超过25个字符', trigger: ['change','blur'] }
                ],

                corporationType1: [
                    {
                        required: true,
                        message: '请选择法人分类！',
                        trigger: 'change'
                    }
                ],
                corporationType: [
                    {
                        required: true,
                        message: '请选择法人身份！',
                        trigger: 'change'
                    }
                ],

                investType: [
                    {
                        required: true,
                        message: '此项为必填!',
                        trigger: 'change'
                    }
                ],
                entType: [
                    {
                        required: true,
                        message: '此项为必填!',
                        trigger: 'change'
                    }
                ],

                idAddress: [
                    {
                        required: true,
                        message: '证件地址为必填',
                        trigger: 'blur'
                    },
                    {  max: 150, message: '长度不能超过150个字符', trigger: 'blur' }
                ],
                email: [
                    {
                        required: true,
                        message: '邮箱为必填',
                        trigger: 'blur'
                    },
                    {
                        pattern:
                            '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,}$',
                        message: '请输入正确的邮箱格式',
                        trigger: 'blur'
                    },
                    {  max: 80, message: '长度不能超过80个字符', trigger: 'blur' }
                ],

                licenseAddress: [
                    {
                        required: true,
                        message: '住所(营业执照地址)为必填',
                        trigger: 'blur'
                    },
                    {  max: 300, message: '长度不能超过300个字符', trigger: 'blur' }
                ],
                licenseNo: [
                    {
                        required: true,
                        message: '此项为必填',
                        trigger: 'blur'
                    },
                    {  max: 30, message: '长度不能超过30个字符', trigger: 'blur' }
                ],
                partyCompany: [
                    {
                        required: true,
                        message: '此项为必填',
                        trigger: 'blur'
                    },
                    {  max: 300, message: '长度不能超过300个字符', trigger: 'blur' }
                ],

                isListedCompany: [
                    {
                        required: true,
                        message: '此项为必填',
                        trigger: 'blur'
                    }
                ]

                //
            },
            partyDialog: false,
            // 保存按钮loading
            submitLoading: false,
            // 区分申和被
            partyType: 0,
            // 新数组
            newGetDictionaryLtemsList: [],
            // caseId等等
            caseObj: {},
            // 展示的文件列表
            ShowFileList: [],
            // 点击删除的展示文件列表
            deleteShowFileList: [],

            // 法人分类
            corporationTypeList: [],
            // 法人国家后出现的下拉框
            investTypeList: [],
            // 全部的状态
            allType: false,
            // 上传文件后返回的文件识别id
            digitalId: [],
            // 进度条
            percentageNum: 0,
            // 进度条是否显示
            percentageNumType: false,
            // 上传的个数
            trueNum: 0,
            // 企业分类数组列表--下拉框
            entTypeList: [],
            // 是否上市--下拉框
            isListedCompanyList: [],
            // 如果是反显需要留下这个partyId
            partyId: '',
            // 所有的下拉框数组
            selectListAll: [],
            // 法人类型
            corporationType1List: []
            // 企业分类数组
            // corporationTypeListB: []
        }
    },
    methods: {
        async load(type, obj, detailObj, allType) {
            this.$refs.partyFormRef.clearValidate()
            this.caseObj = obj
            this.partyType = type
            this.partyDialog = true
            this.allType = allType
            this.getDictionaryLtemsList = await this.GetDictionaryLtems(275)
            // 添加申请人选择非中国后选择国别
            this.getCountriesList = await this.GetDictionaryLtems(4)
            this.partyTypeList = await this.GetDictionaryLtems(163)
            this.selectData()
            // 以下代码是反显  开始
            if (detailObj) {
                this.partyInfo = {
                    ...detailObj
                }
                if (this.partyInfo.idType !== 1) {
                    this.partyInfo.idNum2 = this.partyInfo.idNum
                }
                this.partyId = detailObj.partyId
                this.ShowFileList = detailObj.fileListVos
            }
            // 以下代码是反显  结束
            this.submitLoading = false
            this.partyDialog = false
        },
        // 退出清空表单事件
        clickResetFields() {
            this.$refs.partyFormRef.clearValidate()
            this.getCountriesList = []
            this.partyTypeList = []
            this.myMaterialList = []
            this.fileList = []
            this.deleteShowFileList = []
            this.ShowFileList = []
            this.digitalId = []
            this.partyInfo = {
                partyClass: 1,
                // 数组
                partyAddressInfoIns: [
                    {
                        partyArea: '',
                        partyCity: '',
                        district: '',
                        companyAddress: ''
                    }
                ]
            }
            // 进度条
            this.percentageNum = 0
            // 进度条是否显示
            this.percentageNumType = false
            // 上传的个数
            this.trueNum = 0
            this.partyId = ''
        },

        // 页面一进渲染下拉框
        async selectData() {
            let localType = JSON.parse(
                localStorage.getItem('styleTypePartyList')
            )
            if (!localType && localType?.length !== 11) {
                let promisesList = [
                    this.$API.sendRequest('styleTypeList', { req: 162 }), //0
                    this.$API.sendRequest('styleTypeList', { req: 294 }), //1
                    this.$API.sendRequest('styleTypeList', { req: 295 }), //2
                    this.$API.sendRequest('styleTypeList', { req: 296 }), //3
                    this.$API.sendRequest('styleTypeList', { req: 297 }), //4
                    this.$API.sendRequest('styleTypeList', { req: 298 }), //5
                    this.$API.sendRequest('styleTypeList', { req: 299 }), //6
                    this.$API.sendRequest('styleTypeList', { req: 300 }), //7
                    this.$API.sendRequest('styleTypeList', { req: 166 }), //8
                    this.$API.sendRequest('styleTypeList', { req: 290 }), //9
                    this.$API.sendRequest('styleTypeList', { req: 301 }) //10
                ]
                // 合伙企业  301

                let type = await Promise.all(promisesList)
                this.selectListAll = type
                localStorage.setItem('styleTypePartyList', JSON.stringify(type))
                // 法人分类
                this.corporationTypeList = type[8].data.materialStyleList
                this.corporationType1List = type[1].data.materialStyleList
            } else {
                this.selectListAll = localType
                // 法人分类
                this.corporationTypeList = localType[8].data.materialStyleList
                this.corporationType1List = localType[1].data.materialStyleList
            }
        },

        // 申请人-----》选择国家/地区
        async GetDictionaryLtems(req) {
            if (this.$store.state[req]) {
                return this.$store.state[req]
            }

            let param = {
                req
            }
            let res = await this.$API.sendRequest('styleTypeList', param)
            this.$store.commit('setPartyList', {
                key: req,
                list: res.data.materialStyleList
            })
            return res.data.materialStyleList
        },

        // 上传的change事件，fileList更改触发
        partyUploadProgress() {
            let newFileList = []
            let uploadSize = this.$store.state.uploadSize
            for (let index = 0; index < this.fileList.length; index++) {
                const mySize =
                    this.fileList[index].size / 1024 / 1024 < uploadSize
                if (mySize) {
                    newFileList.push(this.fileList[index])
                    continue
                } else {
                    this.$message.error(
                        '上传文件大小不能超过' + uploadSize + 'MB!'
                    )
                    this.fileList.splice(index, 1) // 从 fileList 中移除不满足条件的文件
                    index-- // 因为移除了一个元素，所以需要将 index 减 1
                    continue
                }
            }
            this.myMaterialList = newFileList
        },

        // 删除自己选择上传列表的数据
        deleteMyMaterialList(row, type) {
            if (type === 'M') {
                this.myMaterialList = this.myMaterialList.filter(
                    (item, index) => {
                        return index !== row.$index
                    }
                )
                this.fileList = this.myMaterialList
            } else {
                let showFileList = []
                showFileList = this.ShowFileList.filter((item, index) => {
                    if (index !== row.$index) {
                        return item
                    }
                })
                let deleteArr = this.ShowFileList.filter((item, index) => {
                    if (index === row.$index) {
                        return item
                    }
                })
                this.ShowFileList = showFileList
                this.deleteShowFileList.push(deleteArr[0])
            }

            // this.myMaterialList = this.fileList
        },

        // 提交申请人事件
        addPartySubmit() {
            this.$refs.partyFormRef.validate(async (valid) => {
                console.log(this.partyInfo);
                if (valid) {

                    // 判断本地有没有数据,用做下拉记忆存储--姓名
                    if (this.partyInfo.partyCompany) {
                        this.$GF.localStorageSelectList(
                            'partyInfo.partyCompany',
                            this.partyInfo.partyCompany
                        )
                    }

                    // 判断本地有没有数据,用做下拉记忆存储--身份证号
                    if (this.partyInfo.idNum) {
                        this.$GF.localStorageSelectList(
                            'partyInfo.idNum',
                            this.partyInfo.idNum
                        )
                    }

                    // 判断本地有没有数据,用做下拉记忆存储--证件地址
                    if (this.partyInfo.idAddress) {
                        this.$GF.localStorageSelectList(
                            'partyInfo.idAddress',
                            this.partyInfo.idAddress
                        )
                    }
                    // 判断本地有没有数据,用做下拉记忆存储--组织机构代码
                    if (this.partyInfo.licenseNo) {
                        this.$GF.localStorageSelectList(
                            'partyInfo.licenseNo',
                            this.partyInfo.licenseNo
                        )
                    }
                    // 判断本地有没有数据,用做下拉记忆存储--组织机构代码
                    if (this.partyInfo.partyName) {
                        this.$GF.localStorageSelectList(
                            'partyInfo.partyName',
                            this.partyInfo.partyName
                        )
                    }
                    // 判断本地有没有数据,用做下拉记忆存储--住所(营业执照地址)
                    if (this.partyInfo.licenseAddress) {
                        this.$GF.localStorageSelectList(
                            'partyInfo.licenseAddress',
                            this.partyInfo.licenseAddress
                        )
                    }
                    if (
                        this.partyType === 1 &&
                        this.myMaterialList.length === 0
                    ) {
                        if (this.ShowFileList.length === 0) {
                            return this.$message.info('请上传主体资料证明')
                        }
                    }

                    // 校验表格
                    this.submitLoading = true
                    // 上传文件请求 开始
                    let type = await this.uploadFile()
                    if (type) {
                        // 保存其他信息
                        await this.savaPartyInfoFn()
                        this.submitLoading = false
                    } else {
                        this.submitLoading = false
                        return this.$message.error('文件上传失败')
                    }
                } else {
                    this.submitLoading = false
                    return false
                }



            })
        },

        // 上传文件-----》返回true或者false代表上传成功或者失败

        // 申请人列表上传文件中点击查看
        clickViewPreview(scope) {
            let url = window.URL.createObjectURL(scope.row.raw)
            window.open(url)
            URL.revokeObjectURL(url)
        },

        // 点击已添加列表里的预览
        async newclickViewPreview(row) {
            this.materialBoxLoading = true
            let param = {
                req: {
                    ...row
                }
            }
            const res = await this.$API.sendRequest(
                'ocrDownFilePathAndName',
                param
            )
            this.materialBoxLoading = false
            let blob
            if (row.flagPdf === 1) {
                blob = new Blob([res], { type: 'application/pdf' })
                window.open(window.URL.createObjectURL(blob))
            } else {
                // this.dialogPreview = true
                blob = new Blob([res], { type: 'image/jpeg' })

                // 图片旋转
                let newWindow = window.open(window.URL.createObjectURL(blob))
                newWindow.onload = () => {
                    let button = document.createElement('button')
                    this.$GF.cretateRotateBtn(newWindow, button)
                }
            }
        },

        // 点击请求中-----》保存函数
        async savaPartyInfoFn() {
            this.partyInfo.fileListVos = this.deleteShowFileList
            this.partyInfo.materialFlag = this.partyType === 1 ? 1 : 3
            // this.partyInfo.corporationType = this.partyInfo.partyClass === 2 ? this.partyInfo.corporationType : ''
            // this.partyInfo.corporationType1 = this.partyInfo.corporationType === 1  ? this.partyInfo.corporationType1 : ''
            // this.partyInfo.investType = this.partyInfo.corporationType1 === 1  ? this.partyInfo.investType : ''
            this.partyInfo.idNum =
                this.partyInfo.idType === 1
                    ? this.partyInfo.idNum
                    : this.partyInfo.idNum2
            let param = {
                req: {
                    ...this.partyInfo,
                    partyType: this.partyType,
                    receiveNo: this.caseObj.receiveNo,
                    caseId: this.caseObj.caseId,
                    digitalId: this.digitalId
                }
            }



            let res = await this.$API.sendRequest('SavePartyInfo', param)
            if (!res.success) {
                return this.$message.error(res.msg)
            }
            this.percentageNumType = false
            this.$message.success(res.msg)




            setTimeout(() => {
                this.emitClose()
            }, 500)
        },

        // 上传文件
        async uploadFile() {
            if (this.myMaterialList.length === 0) return true
            this.percentageNumType = true
            // 保存其他信息
            this.$message.info('正在上传文件请勿退出')
            let trueNum = 0
            let myThis = this
            for (let i = 0; i < this.myMaterialList.length; i++) {
                let str = this.myMaterialList[i].name
                let pdfType = this.$GF.pdfFlagFn(str)
                const formData = new FormData()
                formData.append('req.abbr', 'WS')
                formData.append('req.typeName', '')
                formData.append('req.profile', '')
                formData.append('req.caseId', this.caseObj.caseId)
                formData.append('file', this.myMaterialList[i].raw)
                formData.append('req.flagPdf', pdfType)
                formData.append('req.oldFileName', this.myMaterialList[i].name)
                formData.append('req.typeId', 2)
                formData.append('req.receiveLetterNo', this.caseObj.receiveNo)
                formData.append('req.partyType', 1)
                formData.append('req.remarks', null)
                formData.append(
                    'req.materialFlag',
                    this.partyType === 1 ? 1 : 3
                )
                // let res = await this.$API.sendRequest('OcrSaveUpload', formData)

                let res = await axios({
                    url: '/api/ocrUse/ocrSaveUpload',
                    method: 'POST',
                    onUploadProgress: function (progressEvent) {
                        const percentCompleted = Math.round(
                            (progressEvent.loaded * 100) / progressEvent.total
                        )

                        if (percentCompleted < 100) {
                            // 可以在这里更新用户界面以显示上传进度
                            myThis.percentageNum = percentCompleted
                        } else {
                            // 可以在这里更新用户界面以显示上传进度
                            myThis.percentageNum = 99
                        }
                    },
                    headers: {
                        c_Auth_token: localStorage.getItem('c_Auth_toke')
                    },
                    data: formData
                })
                this.digitalId.push(res.data.data)
                if (res.data.success) {
                    trueNum++
                    this.trueNum = trueNum
                    if (trueNum === this.myMaterialList.length) {
                        return true
                    } else {
                        myThis.percentageNum = 0
                    }
                } else {
                    return false
                }
            }
        },

        // 选择国家更换证件类型
        countrySelectChange() {
            this.partyInfo.idType = null
            this.$nextTick(() => { })
            this.$refs.partyFormRef.clearValidate(['idType'])
        },

        // 调用外面关闭弹窗
        emitClose() {
            this.$emit('patyDialogClose')
        },

        // 单选框更新，需要去把法人清空
        async radioChange() {
            this.partyInfo = {}
            this.partyInfo.partyId = this.partyId
        },

        // 下载模版
        async downloadDoc(type) {
            let param = {
                req: type
            }
            let res = await this.$API.sendRequest('TemplateDownloadDoc', param)
            let blob = new Blob([res], {
                type: 'application/msword'
            })
            let url = window.URL.createObjectURL(blob)
            let link = document.createElement('a')
            link.href = url
            link.download = '法定代表人证明书模板'
            link.click()
            window.URL.revokeObjectURL(link.href)
        },

        // 法人分类change清空是否上市
        corporationTypeChange() {
            this.partyInfo.corporationType1 = null
            this.partyInfo.investType = null
            this.partyInfo.entType = null
        },
        // 法人类型change清空是否上市
        corporationType1Change() {
            this.partyInfo.investType = null
            this.partyInfo.entType = null
        },
        // 外商因素change清空是否上市
        investTypeChange() {
            this.partyInfo.entType = null
        },



        // 来函人单位---单位
        partyInfoPartyCompanyList(queryString, callback) {
            let arr = []
            if (localStorage.getItem('partyInfo.partyCompany')) {
                arr = JSON.parse(localStorage.getItem('partyInfo.partyCompany'))
            }
            // 示例：模拟异步请求获取补全选项
            callback(arr.filter((item) => item.value.includes(queryString)))
        },

        // 来函人单位---身份证号
        partyInfoIdNumList(queryString, callback) {
            let arr = []
            if (localStorage.getItem('partyInfo.idNum')) {
                arr = JSON.parse(localStorage.getItem('partyInfo.idNum'))
            }
            // 示例：模拟异步请求获取补全选项
            callback(arr.filter((item) => item.value.includes(queryString)))
        },

        // 来函人单位---证件地址
        partyInfoIdAddressList(queryString, callback) {
            let arr = []
            if (localStorage.getItem('partyInfo.idAddress')) {
                arr = JSON.parse(localStorage.getItem('partyInfo.idAddress'))
            }
            // 示例：模拟异步请求获取补全选项
            callback(arr.filter((item) => item.value.includes(queryString)))
        },
        // 来函人单位---住所(营业执照地址)
        partyInfoLicenseAddressList(queryString, callback) {
            let arr = []
            if (localStorage.getItem('partyInfo.licenseAddress')) {
                arr = JSON.parse(localStorage.getItem('partyInfo.licenseAddress'))
            }
            // 示例：模拟异步请求获取补全选项
            callback(arr.filter((item) => item.value.includes(queryString)))
        },
        // 来函人单位---组织机构代码
        partyInfoLicenseNoList(queryString, callback) {
            let arr = []
            if (localStorage.getItem('partyInfo.licenseNo')) {
                arr = JSON.parse(localStorage.getItem('partyInfo.licenseNo'))
            }
            // 示例：模拟异步请求获取补全选项
            callback(arr.filter((item) => item.value.includes(queryString)))
        },
        // 来函人单位---当事人名称
        partyInfoPartyNameList(queryString, callback) {
            let arr = []
            if (localStorage.getItem('partyInfo.partyName')) {
                arr = JSON.parse(localStorage.getItem('partyInfo.partyName'))
            }
            // 示例：模拟异步请求获取补全选项
            callback(arr.filter((item) => item.value.includes(queryString)))
        },


    },
    watch: {
        'partyInfo.nationality': {
            handler(newValue) {
                if (newValue === undefined) return
                if (newValue === 1) {
                    //中国
                    let arr = [
                        this.getDictionaryLtemsList[0]
                        // this.getDictionaryLtemsList[1]
                    ]
                    return (this.newGetDictionaryLtemsList = arr)
                } else if (newValue === 10) {
                    //台湾
                    let arr = [this.getDictionaryLtemsList[5]]
                    return (this.newGetDictionaryLtemsList = arr)
                } else if (newValue === 8) {
                    //香港
                    let arr = [
                        this.getDictionaryLtemsList[2],
                        this.getDictionaryLtemsList[3],
                        this.getDictionaryLtemsList[4]
                    ]
                    return (this.newGetDictionaryLtemsList = arr)
                } else if (newValue === 9) {
                    //澳门
                    let arr = [this.getDictionaryLtemsList[4]]
                    return (this.newGetDictionaryLtemsList = arr)
                } else {
                    return (this.newGetDictionaryLtemsList = [
                        { dictionaryItemName: '护照', dictionaryItemValue: 7 }
                    ])
                }
            }
        }
    },
    computed: {
        // 法人分类是否选择中国内地--->只有法人类型亮
        legalPersonTypeA() {
            if (this.partyInfo.corporationType === 1) {
                return true
            } else {
                return false
            }
        },

        // 法人类型选择返回不同的数组
        legalPersonTypeB() {
            console.log(this.partyInfo.corporationType1)
            if (this.partyInfo.corporationType1 === 4) {
                return this.selectListAll[7].data.materialStyleList
            } else {
                return this.selectListAll[0].data.materialStyleList
            }
        },

        // 根据法人类型,外商因素返回特定的数组
        legalPersonTypeC() {
            if (
                this.partyInfo.corporationType1 === 1 &&
                this.partyInfo.investType === 1
            ) {
                return this.selectListAll[9].data.materialStyleList
            } else if (
                this.partyInfo.corporationType1 === 1 &&
                this.partyInfo.investType === 2
            ) {
                return this.selectListAll[2].data.materialStyleList
            } else if (
                this.partyInfo.corporationType1 === 2 &&
                this.partyInfo.investType === 1
            ) {
                return this.selectListAll[3].data.materialStyleList
            } else if (
                this.partyInfo.corporationType1 === 2 &&
                this.partyInfo.investType === 2
            ) {
                return this.selectListAll[4].data.materialStyleList
            } else if (
                this.partyInfo.corporationType1 === 3 &&
                this.partyInfo.investType === 1
            ) {
                return this.selectListAll[5].data.materialStyleList
            } else if (
                this.partyInfo.corporationType1 === 3 &&
                this.partyInfo.investType === 2
            ) {
                return this.selectListAll[6].data.materialStyleList
            } else if (this.partyInfo.corporationType1 === 4) {
                return this.selectListAll[7]?.data.materialStyleList
            } else if (this.partyInfo.corporationType1 === 5) {
                return this.selectListAll[10]?.data.materialStyleList
            } else {
                return []
            }
        }
    }
}
</script>


<style lang="scss" scoped>
.partyBox {
    padding: 0 20px;

    .partyInfoBox {
        width: 100%;
        font-size: 16px;

        &>div:nth-child(3) {
            :deep(.el-scrollbar) {
                display: none !important;
            }
        }
    }

    .el-col-4 {
        display: flex;
        align-items: center;

        p {
            width: 100%;
            text-align: right;
            padding-right: 55px;

            &:not(:first-child) {
                margin-top: 0;
            }
        }
    }

    .el-col-20 {
        display: flex;
        align-content: center;
    }
}

:deep(.el-dialog__body) {
    padding-top: 0;
}

.el-divider--horizontal {
    margin: 0;
}

.btn {
    margin-top: 20px;
    width: 100%;
    display: flex;
    justify-content: center;
}

.cardTextBox {
    margin-top: 20px;

    span {
        color: #699eff;
    }
}

.selectAddress {
    .el-col {
        margin: 0 10px;

        &:nth-child(1) {
            margin: 0;
        }
    }
}

.partyForm {
    :deep(.el-form-item) {
        width: 100%;
    }
}

.el-checkbox-group {
    display: flex;
    align-items: center;
}

.requireIconBox {
    position: relative;

    span {
        position: absolute;
        top: -10px;
        right: -10px;
    }
}

:deep(.el-radio.el-radio--large .el-radio__inner) {
    width: 22px !important;
    height: 22px !important;
}

:deep(.el-radio__inner::after) {
    width: 10px !important;
    height: 10px !important;
}

.progressBox {
    display: flex;
    align-items: center;
    height: 100%;
}

.progressTextColor {
    color: red;
}
</style>