<template>
      <div class="containerBox">
        <!-- <h1>上传扫描件(经办人)</h1> -->
        <div class="nullTextBox">
            <div class="glass-container" id="glass">
                <div class="contentBox">
                    <h4>  {{ $t('msg.UploadScannedCopyHandler') }}</h4>
                    <div class="contentInput">
                        <el-input
                            :placeholder="$t('msg.case')"
                            style="width: 100%"
                            v-model="caseNumber"
                            ref="inputRef"
                            @keydown.enter="search"
                        >
                            <template #append>
                                <div
                                    v-if="caseNumber.trim()"
                                    @click="search"
                                    class="inputRightText"
                                    aria-disabled=""
                                >
                                    {{ $t('msg.finder_search') }}
                                </div>
                                <div
                                    v-else
                                    @click="search"
                                    class="inputRightText"
                                    aria-disabled=""
                                >
                                    {{ $t('msg.finder_search') }}
                                </div>
                            </template>
                        </el-input>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </template>
 
<script>
export default {
  name: '',
  components: {},
  data() {
    return {
        caseNumber: '' // 用于绑定输入框的值
    };
  },
    methods: {
        load(caseId) {
            // this.caseNumber = 'DSC20230637'
            console.log(caseId);
            
            this.caseNumber = ''
    },  
    async search() {
      // 在这里处理搜索逻辑
      if (this.caseNumber) {
          console.log('搜索案件编号:', this.caseNumber);
          let param = {
            req:this.caseNumber
          }
          let res = await this.$API.sendRequest('caseDetailByCaseNoFlag', param)
          if (!res.success) {
      return  this.$message.error(res.msg)
          }
          this.$emit('goPage', {caseNo:this.caseNumber}, 'uploadScanSecDoc_1')
       
        // 你可以在这里调用API或其他逻辑
      } else {
        this.$message.info('请输入案件编号');
      }
    }
  },
  created() {},
  mounted() {}
};
</script>
 
 
<style scoped lang="scss">
.containerBox {
    height: 100%;
    .nullTextBox {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        .glass-container {
            width: 90%;
            height: 80%;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 20px;
            border-radius: 10px;
            backdrop-filter: blur(5px);
            border-top: 1px solid #ccc;
            border-left: 1px solid #ccc;
            border-bottom: 2px rgba(40, 40, 40, 0.35) solid;
            border-right: 2px rgba(40, 40, 40, 0.35) solid;
            transition: all 0.5s;
            .contentBox {
                height: 100%;
                h4 {
                    font-size: 40px;
                    font-weight: 500;
                    font-family: cursive;
                    // margin-bottom: 0;
                }
                .contentInput {
                    width: 500px;
                    :deep(.el-input__wrapper) {
                        height: 50px !important;
                    }
                }
                :deep(.el-input__inner)::placeholder {
                    color: rgb(69, 105, 247) !important;
                    font-style: italic;
                    font-size: 20px;
                    letter-spacing: 0.2em;
                }
                :deep(.el-input__inner) {
                    font-size: 20px !important;
                    letter-spacing: 0.2em;
                }

                :deep(.el-input-group__append) {
                    height: 50px !important;
                    color: #fff;
                    font-size: 20px;
                    background: linear-gradient(
                        90deg,
                        #74ebd5 0%,
                        #9face6 100%
                    );
                    cursor: pointer;
                }
            }
        }

        .glass-container:hover {
            box-shadow: rgba(0, 0, 0, 0.3) 22px 20px 8px;
        }
        .inputRightText {
            width: 100%;
            height: 100%;
            text-align: center;
            display: flex;
            padding: 0 20px;
            align-items: center;
        }
        :deep(.el-input-group__append) {
            padding: 0;
        }
    }
}
</style>