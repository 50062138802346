<template>
    <!-- 时间流程图  -->
    <div v-loading="loading" class="margin-ss">
        <!-- <h1>
            时间流程图
        </h1> -->
        <el-row style="padding: 10px">
            <el-col :span="8" :class="[caseNo ? 'topTitle' : 'opacityShow']"
                ><span> {{ caseNo }}号案电子卷宗 </span></el-col
            >
            <el-col :span="8">
                <div @click="goCaseFileL1">
                    <el-switch
                        v-model="switchType"
                        active-text="按时间排"
                        inactive-text="按类型排"
                        style="display: flex; justify-content: center"
                    >
                    </el-switch>
                </div>
            </el-col>
            <el-col :span="8">
                <div class="radio">
                    排序：
                    <el-radio-group v-model="reverse">
                        <el-radio :label="true">倒序</el-radio>
                        <el-radio :label="false">正序</el-radio>
                    </el-radio-group>
                </div>
            </el-col>
        </el-row>

        <el-collapse accordion>
         
            <el-collapse-item
                :title="'经办人发文' + '(' + sotedSend.length + ')'"
                name="2"
            >
                <div class="main">
                    <el-timeline v-show="!loading">
                        <el-timeline-item
                            placement="top"
                            v-for="(item, i) in sotedSend"
                            :key="i"
                            :timestamp="item.submitDateStr"
                            color="skyblue"
                        >
                            <el-card class="cardLayout">
                                <div class="left">
                                    <p
                                        style="
                                            font-size: 16px;
                                            font-weight: 700;
                                            display: flex;
                                            align-content: center;
                                        "
                                    >
                                        <subscript></subscript>
                                        <span style="margin-left: -20px">
                                            {{ item.templateTypeName }}</span
                                        >
                                    </p>
                                    <p>{{ item.evidenceDesc }}</p>
                                </div>
                                <div class="right" v-loading="fileLoading">
                                    <el-dropdown
                                        trigger="hover"
                                        v-if="item.fileNameDsp"
                                    >
                                        <div class="dropdownBox">
                                            <strong style="white-space: nowrap">
                                                扫描版：</strong
                                            >
                                            <span class="el-dropdown-link">
                                                <div>
                                                    <svg
                                                        class="icon"
                                                        aria-hidden="true"
                                                    >
                                                        <use
                                                            xlink:href="#icon-pdf"
                                                        ></use>
                                                    </svg>
                                                </div>
                                                <p>
                                                    <span class="fileNameClass">
                                                        {{
                                                            item.fileNameDsp
                                                        }}.pdf</span
                                                    >
                                                </p>
                                            </span>
                                        </div>
                                        <template #dropdown>
                                            <el-dropdown-menu>
                                                <el-dropdown-item
                                                    @click="
                                                        PreviewPdfClick(item)
                                                    "
                                                    >预览</el-dropdown-item
                                                >
                                                <el-dropdown-item
                                                    @click="PdfUpload(item)"
                                                    >下载</el-dropdown-item
                                                >
                                            </el-dropdown-menu>
                                        </template>
                                    </el-dropdown>
                                    <span
                                        v-else
                                        style="text-align: left; flex: 1"
                                    >
                                        扫描版： 暂无文件
                                    </span>

                                    <div
                                        v-if="item.oldFileName"
                                        class="dropdownBoxRight"
                                    >
                                        <el-dropdown trigger="hover">
                                            <div class="dropdownBoxRightItem">
                                                <strong
                                                    style="white-space: nowrap"
                                                >
                                                    电子版：</strong
                                                >
                                                <span class="el-dropdown-link">
                                                    <p>
                                                        <span
                                                            class="fileNameClass"
                                                        >
                                                            {{
                                                                item.oldFileName
                                                            }}</span
                                                        >
                                                    </p>
                                                </span>
                                            </div>
                                            <template #dropdown>
                                                <el-dropdown-menu>
                                                    <el-dropdown-item
                                                        @click="
                                                            clickDropdown(
                                                                item
                                                                    .caseTimLenSonVos[0]
                                                            )
                                                        "
                                                        >预览</el-dropdown-item
                                                    >
                                                    <el-dropdown-item
                                                        @click="
                                                            clickDownLoad(
                                                                item
                                                                    .caseTimLenSonVos[0]
                                                            )
                                                        "
                                                        >下载</el-dropdown-item
                                                    >
                                                </el-dropdown-menu>
                                            </template>
                                        </el-dropdown>
                                        <span
                                            class="butSpan"
                                            style="
                                                display: flex;
                                                align-items: center;
                                            "
                                            v-show="
                                                item.caseTimLenSonVos.length > 1
                                                    ? true
                                                    : false
                                            "
                                        >
                                            <el-dropdown
                                                trigger="click"
                                                size="large"
                                            >
                                                <span
                                                    class="el-dropdown-link"
                                                    @click="
                                                        clickDetail(
                                                            item.caseTimLenSonVos
                                                        )
                                                    "
                                                >
                                                    更多
                                                    <el-icon
                                                        class="el-icon--right"
                                                    >
                                                        <arrow-down />
                                                    </el-icon>
                                                </span>
                                                <template #dropdown>
                                                    <el-dropdown-menu>
                                                        <el-dropdown-item
                                                            v-for="(
                                                                items, index
                                                            ) in caseTimeList"
                                                            :key="index"
                                                        >
                                                            <div class="imgBox">
                                                                <svg
                                                                    class="icon"
                                                                    aria-hidden="true"
                                                                    v-if="
                                                                        items.fileNameDsp.endsWith(
                                                                            '.pdf'
                                                                        )
                                                                    "
                                                                >
                                                                    <use
                                                                        xlink:href="#icon-pdf"
                                                                    ></use>
                                                                </svg>

                                                                <svg
                                                                    class="icon"
                                                                    aria-hidden="true"
                                                                    v-else
                                                                >
                                                                    <use
                                                                        xlink:href="#icon-jpg-1"
                                                                    ></use>
                                                                </svg>
                                                                <span
                                                                    class="fileNameClass"
                                                                    >{{
                                                                        items.fileNameDsp
                                                                    }}</span
                                                                >
                                                            </div>
                                                            <div class="butBox">
                                                                <el-button
                                                                    type="primary"
                                                                    size="small"
                                                                    @click="
                                                                        clickDropdown(
                                                                            items
                                                                        )
                                                                    "
                                                                    >预览</el-button
                                                                >
                                                                <el-button
                                                                    type="primary"
                                                                    size="small"
                                                                    @click="
                                                                        clickDownLoad(
                                                                            items
                                                                        )
                                                                    "
                                                                    >下载</el-button
                                                                >
                                                            </div>
                                                        </el-dropdown-item>
                                                    </el-dropdown-menu>
                                                </template>
                                            </el-dropdown>
                                        </span>
                                    </div>
                                    <span
                                        v-else
                                        style="text-align: left; flex: 1"
                                    >
                                        电子版： 暂无文件
                                    </span>
                                </div>
                            </el-card>
                        </el-timeline-item>
                    </el-timeline>
                </div>
            </el-collapse-item>
        </el-collapse>
    </div>
</template>
 
<script>
export default {
    name: '',
    data() {
        return {
            // 正序倒序
            reverse: true,
            // 数据列表
            activityPut: [],
            activitySed: [],
            // 加载效果
            loading: false,
            // 文件列表
            fileList: [],
            // 在线预览pdf
            pdfUrl: '',
            // pdf弹出框
            dialogShow: false,
            // 加载效果
            fileLoading: false,
            switchType: true,
            caseId: '',
            // 弹窗状态
            previewDialog: false,
            // 源文件详情数组
            caseTimeList: [],
            caseNo: ''
        }
    },
    methods: {
        load(row) {
            this.caseNo = row.caseNo
            this.CaseTimeLenDetail(row)
        },
        // 根据id获取详细信息
        async CaseTimeLenDetail(row) {
            this.activityPut = []
            this.activitySed = []
            this.loading = true
            let param = {
                req:row.caseNo
            }
            let res = await this.$API.sendRequest('CaseTimeLenDetailByCaseNo', param)
            this.loading = false
            if (!res.success) {
                return this.$message.error(res.msg)
            }
            this.caseId = res.data.caseId
            this.activityPut = res.data.caseTimeLenDetailVo
            this.activitySed = res.data.documentVo
        },
        // 点击预览pdf
        async PreviewPdfClick(row) {
            this.loading = true
            let param = {
                    req: {
                    receiveLetterNo: row.letterCode,
                    caseId:this.caseId
                }
            }
            const paramRes = await this.$API.sendRequest('OcrDownFile', param)
            if (paramRes.status === 200) {
                const blob = new Blob([paramRes.data], {
                    type: 'application/pdf'
                })
                let url = window.URL.createObjectURL(blob)
                window.open(url)
                this.loading = false
            } else {
                this.loading = false
                this.$message.error('预览失败')
            }
        },
        // 点击下载pdf
        async PdfUpload(row) {
            // console.log(row);
            this.loading = true

            let param = {
                    req: {
                    receiveLetterNo: row.letterCode,
                    caseId:this.caseId
                }
            }
            const paramRes = await this.$API.sendRequest('OcrDownFile', param)
            if (paramRes.status === 200) {
                const blob = new Blob([paramRes.data], {
                    type: 'application/pdf'
                })
                let url = window.URL.createObjectURL(blob)
                let fileName = paramRes.headers['content-disposition']
                let index = fileName.indexOf('=')
                let result = fileName.substring(index + 1, fileName.length)
                let link = document.createElement('a')
                link.href = url
                link.download = decodeURIComponent(result) + '.pdf'
                link.click()
                this.loading = false
                window.URL.revokeObjectURL(link.href)
            } else {
                this.loading = false
                this.$message.error('下载失败')
            }
        },

        // 点击了开关去另一个页面
        goCaseFileL1() {
            // console.log(this.caseId);
            this.$emit('goPage', {caseNo:this.caseNo}, 'uploadScanSecDoc_1')
        },
        // 点击了更多  打开弹窗
        clickDetail(rowList) {
            this.caseTimeList = rowList
        },

        // 预览影印版事件
        async clickDropdown(row) {
            let param = {
                req: {
                    fileNameDsp: row.fileNameDsp,
                    filePath: row.filePath,
                    flagPdf: row.flagPdf
                }
            }
            const res = await this.$API.sendRequest(
                'ocrDownFilePathAndName',
                param
            )
            let blob
            if (row.flagPdf === 1) {
                blob = new Blob([res], { type: 'application/pdf' })
                window.open(window.URL.createObjectURL(blob))
            } else {
                // this.dialogPreview = true
                blob = new Blob([res], { type: 'image/jpeg' })
                let newWindow = window.open(window.URL.createObjectURL(blob))
                newWindow.onload = () => {
                    let button = document.createElement('button')
                    this.$GF.cretateRotateBtn(newWindow, button)
                }
            }
        },

        // 点击下载影印版事件
        async clickDownLoad(row) {
            let param = {
                req: {
                    fileNameDsp: row.fileNameDsp,
                    filePath: row.filePath,
                    flagPdf: row.flagPdf
                }
            }
            const res = await this.$API.sendRequest(
                'ocrDownFilePathAndName',
                param
            )
            let blob
            if (row.flagPdf === 1) {
                blob = new Blob([res], { type: 'application/pdf' })
            } else {
                // this.dialogPreview = true
                blob = new Blob([res], { type: 'image/jpeg' })
            }
            let url = window.URL.createObjectURL(blob)
            let link = document.createElement('a')
            link.href = url
            link.download = row.fileNameDsp
            link.click()
            window.URL.revokeObjectURL(link.href)
        }
    },

    computed: {
        // Element排序
        sotedItem() {
            let items = [...this.activityPut]
            if (!this.reverse) {
                items.sort(
                    (a, b) => new Date(a.submitDate) - new Date(b.submitDate)
                )
            } else {
                items.sort(
                    (a, b) => new Date(b.submitDate) - new Date(a.submitDate)
                )
            }
            return items
        },
        sotedSend() {
            let items = [...this.activitySed]
            if (!this.reverse) {
                items.sort(
                    (a, b) => new Date(a.sendDate) - new Date(b.sendDate)
                )
            } else {
                items.sort(
                    (a, b) => new Date(b.sendDate) - new Date(a.sendDate)
                )
            }
            return items
        }
    },
    watch: {
        switchType() {
            // console.log(newValue);
            this.switchType = true
        }
    }
}
</script>
 
<style scoped lang="scss">
:deep(.el-timeline-item__timestamp) {
    display: flex;
}

.cardLayout {
    display: flex;

    :deep(.el-card__body) {
        display: flex;
        width: 100%;
        min-height: 50px;

        // height: 30px;
        .left {
            display: flex;
            flex-direction: column;
            flex: 1;
            padding-right: 10px;

            p {
                width: 100%;
                text-align: left;
                letter-spacing: 0.1em;
                line-height: 18px;
                text-indent: 2em;
                margin-top: -5px;
            }
        }

        .right {
            flex: 1;
            font-size: 18px;
            display: flex;
            align-items: center;
            overflow: hidden;
            .ocrBox {
                flex: 1;
                overflow: hidden;
                display: flex;
            }
            .scriptBox {
                flex: 1;
                overflow: hidden;
                display: flex;
                align-items: center;

                p {
                    flex: 3;
                    overflow: hidden;
                    display: flex;
                    justify-content: space-between;
                    div {
                        flex: 1;
                        overflow: hidden;
                    }
                }
            }

            span {
                font-weight: 500;
                font-size: 18px;
            }
        }
    }
}
.fileNameClass {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: blue;
    width: 200px;
    text-align: left;
}

.rightItem {
    &:hover {
        pointer-events: auto;
    }
}

.dropdownBox {
    display: flex;
    align-items: center;
}

:deep([class='circular']) {
    margin-top: 10%;
}

:deep(.el-tooltip__trigger) {
    display: flex;
    align-items: center;
}

:deep(.el-card__body .circular) {
    margin-top: 0;
}

.dropdownBoxRight {
    display: flex;

    .dropdownBoxRightItem {
        display: flex;

        strong {
            display: flex;
            align-items: center;
        }

        span {
            margin: 0 15px 0 4px;
            display: flex;
            align-content: center;
        }
    }
}

:deep(.butSpan .el-dropdown-link) {
    // cursor: pointer;
    color: var(--el-color-primary);
    display: flex;
    align-items: center;
}

:deep([class='el-dropdown-menu__item']) {
    display: flex;
    justify-content: space-between;
}

:deep(.el-collapse-item__header) {
    font-size: 17px;
    font-weight: 500;
    position: relative;
    top: 0;
    padding: 0 20px;
    transition: all 0.6s;

    &::before {
        content: '';
        width: 5px;
        height: 20px;
        background-color: red;
        position: absolute;
        top: 50%;
        left: 10px;
        transform: translateY(-10px);
    }

    &::after {
        content: '';
        display: block;
        width: 0px;
        height: 2px;
        position: absolute;
        top: 100%;
        left: 15px;
        background-color: #50da4d;
        transition: all 0.6s;
    }
}

:deep(.el-collapse-item__header.is-active) {
    font-weight: 500;
    font-size: 17px;

    &::after {
        content: '';
        display: block;
        width: 93px;
    }

    &::before {
        content: '';
        width: 5px;
        height: 20px;
        background-color: red;
        position: absolute;
        top: 50%;
        left: 10px;
        transform: translateY(-10px);
    }
}

.topTitle {
    font-size: 22px;
    font-weight: 400;
    display: flex;
    align-items: center;
}

:deep(.el-collapse-item__content) {
    padding: 20px;
}

.opacityShow {
    opacity: 0;
}
</style>