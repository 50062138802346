<!-- @format -->

<!-- 重构登录页 -->
<template>
	<div class="user_login_body_div">
		<!-- 移动端提示 START -->
		<div class="move_header_div">
			<img
				src="../assets/image/logo.png"
				style="width: 73px; height: 61px"
				alt="logo" />
			<div>贸仲智慧平台</div>
		</div>
		<div class="move_phone_tips_div">
			<el-icon><WarningFilled /></el-icon>
			<span
				>苹果手机请登苹果商店，安卓手机请登安卓应用商店，下载贸仲智慧平台；或扫码直接下载贸仲智慧平台以获得最佳体验。</span
			>
		</div>
		<!-- 移动端提示 END -->
		<!-- 顶部 START -->
		<div class="top_user_login_div">
			<div class="left_content">
				<img src="../assets/image/logo.png" alt="logo" />
				<div class="company_name">
					<div style="font-size: 17px" class="zh_cietac_div">
						中国国际经济贸易仲裁委员会<span style="font-size: 22px"
							>贸仲智慧平台</span
						>
					</div>
					<div style="font-size: 12px">
						{{
							"China International Economic and Trade Arbitration Commission".toUpperCase()
						}}
						<span style="font-size: 22px">{{
							"odr Platform".toUpperCase()
						}}</span>
					</div>
				</div>
			</div>
			<div class="right_content">
				<div
					class="login_user_info_div"
					:style="{ color: getLoginUserName.color }">
					<el-icon><UserFilled /></el-icon>
					<!-- 1：当事人 2：仲裁员 3：经办秘书。 为空不显示 -->
					<span style="margin-left: 5px">{{ getLoginUserName.name }}</span>
				</div>
				<LangOption :style-obj="langStyle" position="login" />
			</div>
		</div>
		<!-- 顶部 END -->
		<!-- 中部 START -->
		<div class="center_user_login_div">
			<div class="user_login_content" :class="{ show_tips_actived: showTips }">
				<!-- 登录 -->
				<div
					v-if="form_type === 'login'"
					class="pan_in"
					v-loading="submitLoading">
					<div class="login_type">
						<div
							:class="login_type === 'account' ? 'tab_item active' : 'tab_item'"
							@click="handleLoginType('account')">
							<span>{{ $t("msg.login_account_type") }}</span>
						</div>
						<div
							:class="login_type === 'wechat' ? 'tab_item active' : 'tab_item'"
							@click="handleLoginType('wechat')">
							<!--
              <img v-if="login_type==='wechat'" src="../assets/image/login/wechat_login_active.png" alt="">
              <img v-else src="../assets/image/login/wechat_login.png" alt="">
              -->
							<span>{{ $t("msg.login_wechat_type") }}</span>
						</div>
						<!-- 提示按钮 START -->
						<!-- <div class="tab_item" @click="showTips = true">
							<span>{{ $t("msg.smBtn") }}</span>
						</div> -->
						<!-- 提示按钮 END -->
					</div>
					<!-- 账号登录 -->
					<el-form
						v-show="login_type === 'account'"
						:model="login_form"
						:rules="loginRules"
						ref="login_form"
						class="login_form">
						<el-form-item prop="account">
							<el-input
								v-model="login_form.account"
								size="large"
								:placeholder="$t('msg.login_username')">
								<template #prefix>
									<img
										class="prefix"
										src="../assets/image/login/user.png"
										alt="" />
								</template>
							</el-input>
						</el-form-item>
						<el-form-item prop="password">
							<el-input
								v-model="login_form.password"
								type="password"
								show-password
								size="large"
								:placeholder="$t('msg.login_password')">
								<template #prefix>
									<img
										class="prefix"
										src="../assets/image/login/lock.png"
										alt="" />
								</template>
							</el-input>
						</el-form-item>
						<el-form-item prop="captcha">
							<div class="code_wrapper">
								<el-input
									v-model="login_form.captcha"
									class="left"
									size="large"
									:placeholder="$t('msg.login_verify_code')">
									<template #prefix>
										<img
											class="prefix"
											src="../assets/image/login/code.png"
											alt="" />
									</template>
								</el-input>
								<div
									v-loading="captchaLoading"
									class="verify_code"
									@click="Captcha">
									<el-image fit="contain" :src="captchaUrl" />
								</div>
							</div>
						</el-form-item>
						<el-button
							color="#4578FF"
							size="large"
							style="width: 100%"
							@click="handleLogin"
							>{{ $t("msg.login_btn") }}</el-button
						>
						<div class="login_footer">
							<span class="register" @click="handleForm('forget')">{{
								$t("msg.login_forget")
							}}</span>
							<span
								class="register"
								@click="handleForm('register')"
							
								>{{ $t("msg.register_btn") }}</span
							>
						</div>
					</el-form>
					<div id="wx_login_form" v-show="login_type === 'wechat'"></div>
					<!-- 跳转页面 START -->
					<div class="copy_right_login">
						<!-- 贸仲官网 -->
						<a href="http://www.cietac.org/" target="_blank">{{
							$t("msg.other_link1")
						}}</a>
						<el-divider direction="vertical"></el-divider>
						<!-- 网上立案  -->
						<!-- <a href="http://online.cietac.org/" target="_blank">{{
                $t('msg.other_link2')
            }}</a>
            <el-divider direction="vertical"></el-divider> -->

						<!-- 在线庭审 -->
						<a href="http://kt.cietac.org/" target="_blank">{{
							$t("msg.other_link3")
						}}</a>
						<el-divider direction="vertical"></el-divider>

						<!-- 域名争议 -->
						<a href="http://www.odr.org.cn/" target="_blank">{{
							$t("msg.other_link4")
						}}</a>
						<el-divider direction="vertical"></el-divider>

						<!-- 网上仲裁  -->
						<!-- <a href="http://odr.odr.org.cn/" target="_blank">{{
                $t('msg.other_link5')
            }}</a>
            <el-divider direction="vertical"></el-divider> -->

						<!-- <a @click="goSearchGuidePages('注册指引')">{{
						$t("msg.OperationInstructions")
					}}</a> -->
					</div>
					<!-- 跳转页面 END -->
				</div>
				<!-- 注册 -->
				<div
					v-if="form_type === 'register'"
					class="pan_in less_padding"
					v-loading="submitLoading">
					<div class="sticky01">
						<el-radio-group
							v-removeAriaHidden
							v-model="register_form.regType"
							@change="regTypeChange">
							<el-radio label="mobile_num">{{
								$t("msg.register_type_mobile")
							}}</el-radio>
							<el-radio label="e_mail">{{
								$t("msg.register_type_email")
							}}</el-radio>
						</el-radio-group>
					</div>
					<el-form
						:model="register_form"
						:rules="registerRules"
						ref="register_form"
						label-width="70px"
						class="register_form">
						<!-- 手机号或者邮箱注册单选框 -->
						<!-- <el-form-item label-width="0" class="sticky01">
							<el-radio-group
								v-removeAriaHidden
								v-model="register_form.regType"
								@change="regTypeChange">
								<el-radio label="mobile_num">{{
									$t("msg.register_type_mobile")
								}}</el-radio>
								<el-radio label="e_mail">{{
									$t("msg.register_type_email")
								}}</el-radio>
							</el-radio-group>
						</el-form-item> -->

						<!-- 如果是手机号注册 -->
						<span v-if="register_form.regType === 'mobile_num'">
							<!-- 手机号输入框 mobileNo1-->
							<el-form-item
								prop="mobileNo"
								:label="$t('msg.register_mobile_no')">
								<el-input
									v-model="register_form.mobileNo"
									size="small"
									autocomplete="new-password"
									:placeholder="$t('msg.register_mobile_no_tip')">
									<template #prefix>
										<img
											class="prefix"
											src="../assets/image/login/mobile.png"
											alt="" />
									</template>
								</el-input>
							</el-form-item>

							<!-- 邮箱输入框 -->
							<el-form-item prop="email" :label="$t('msg.register_email')">
								<el-input
									v-model="register_form.email"
									size="small"
									:placeholder="$t('msg.register_email_tip')">
									<template #prefix>
										<img
											class="prefix"
											src="../assets/image/login/email.png"
											alt="" />
									</template>
								</el-input>
							</el-form-item>
						</span>
						<!-- 如果是邮箱注册 -->
						<span v-if="register_form.regType === 'e_mail'">
							<!-- 邮箱输入框 -->
							<el-form-item prop="email" :label="$t('msg.register_email')">
								<el-input
									v-model="register_form.email"
									size="small"
									:placeholder="$t('msg.register_email_tip')">
									<template #prefix>
										<img
											class="prefix"
											src="../assets/image/login/email.png"
											alt="" />
									</template>
								</el-input>
							</el-form-item>
							<!-- 手机号输入框         prop="mobileNo"-->
							<el-form-item :label="$t('msg.register_mobile_no')">
								<el-input
									v-model="register_form.mobileNo"
									size="small"
									:placeholder="$t('msg.register_mobile_no_tip')">
									<template #prefix>
										<img
											class="prefix"
											src="../assets/image/login/mobile.png"
											alt="" />
									</template>
								</el-input>
							</el-form-item>
						</span>

						<!-- 剩余共同的 -->
						<!-- 姓名输入框 -->
						<el-form-item prop="realName" :label="$t('msg.register_real_name')">
							<el-input
								v-model="register_form.realName"
								size="small"
								:placeholder="$t('msg.register_real_name_tip')">
								<template #prefix>
									<img
										class="prefix"
										src="../assets/image/login/name.png"
										alt="" />
								</template>
							</el-input>
						</el-form-item>

						<!-- 身份证输入框 -->
						<div class="idNum">
							<el-form-item
								:prop="
									register_form.regType === 'mobile_num' ? 'idCardNum' : ' '
								"
								:label="
									register_form.regType === 'mobile_num'
										? $t('msg.register_tel_no')
										: $t('msg.register_tel_no') + '（护照）'
								">
								<el-input
									v-model="register_form.idCardNum"
									size="small"
									:placeholder="$t('msg.register_tel_no_tip')">
									<template #prefix>
										<img
											class="prefix"
											src="../assets/image/login/name.png"
											alt="" />
									</template>
								</el-input>
							</el-form-item>
						</div>
						<!-- 密码输入框 -->
						<el-form-item prop="password" :label="$t('msg.register_pass')">
							<el-input
								v-model="register_form.password"
								type="password"
								show-password
								size="small"
								autocomplete="new-password"
								:placeholder="$t('msg.register_pass_tip')">
								<template #prefix>
									<img
										class="prefix"
										src="../assets/image/login/lock.png"
										alt="" />
								</template>
							</el-input>
						</el-form-item>

						<!-- 密码确认输入框 -->
						<el-form-item prop="confirm" :label="$t('msg.register_confirm')">
							<el-input
								v-model="register_form.confirm"
								type="password"
								show-password
								size="small"
								:placeholder="$t('msg.register_confirm_tip')">
								<template #prefix>
									<img
										class="prefix"
										src="../assets/image/login/lock.png"
										alt="" />
								</template>
							</el-input>
						</el-form-item>

						<!-- 验证码输入框 -->
						<el-form-item prop="recCode" :label="$t('msg.register_rec_code')">
							<div class="code_wrapper">
								<el-input
									v-model="register_form.recCode"
									class="left"
									size="small"
									:placeholder="$t('msg.register_rec_code_tip')">
									<template #prefix>
										<img
											class="prefix"
											src="../assets/image/login/code.png"
											alt="" />
									</template>
								</el-input>
								<div>
									<el-button
										v-if="sending"
										type="text"
										:disabled="true"
										style="margin-left: 16px"
										>{{ `${left} s ${$t("msg.register_resend")}` }}</el-button
									>
									<el-button
										v-else
										type="primary"
										size="small"
										:disabled="!can_send"
										@click="handleSend"
										style="height: 28px; margin-left: 16px"
										>{{ $t("msg.register_send") }}</el-button
									>
								</div>
							</div>
						</el-form-item>
						<el-form-item prop="checked" style="margin-bottom: 0">
							<el-checkbox v-model="register_form.checked"
								>{{ $t("msg.register_agree") }}
							</el-checkbox>
							<el-link type="primary" @click="showAgreement">{{
								$t("msg.register_service_agreement")
							}}</el-link>
						</el-form-item>
					</el-form>
					<div class="sticky02">
						<el-button
							color="#4578FF"
							size="small"
							style="width: 100%"
							@click="registerSubmit"
							>{{ $t("msg.register_now") }}</el-button
						>
						<div class="register" @click="handleForm('login')">
							{{ $t("msg.register_to_login") }}
						</div>
					</div>
				</div>
				<!-- 忘记密码 -->
				<div
					v-if="form_type === 'forget'"
					class="forget_content_div"
					v-loading="submitLoading">
					<p class="forget" @click="handleForm('login')">
						{{ $t("msg.back") }}
					</p>
					<div style="text-align: left; margin-bottom: 15px">
						<strong>{{ $t("msg.found_forget_pass") }}</strong>
					</div>
					<el-form :model="forget_form" :rules="forgetRules" ref="forget_form">
						<div class="step" v-if="step === '1'">
							<el-form-item label-width="0">
								<el-radio-group
									v-model="forget_form.regType"
									@change="resetTypeChange">
									<el-radio label="mobile_num">{{
										$t("msg.found_by_mobile")
									}}</el-radio>
									<el-radio label="e_mail">{{
										$t("msg.found_by_email")
									}}</el-radio>
								</el-radio-group>
							</el-form-item>
							<div style="text-align: left; margin-bottom: 15px">
								<strong>{{ $t("msg.found_verify") }}</strong>
							</div>
							<!-- 手机号找回密码 -->
							<span v-if="forget_form.regType === 'mobile_num'">
								<el-form-item prop="mobileNo" label="">
									<el-input
										v-model="forget_form.mobileNo"
										size="large"
										:placeholder="$t('msg.register_mobile_no_tip')">
										<template #prefix>
											<img
												class="prefix"
												src="../assets/image/login/mobile.png"
												alt="" />
										</template>
									</el-input>
								</el-form-item>
								<el-form-item prop="recCode" label="">
									<div class="code_wrapper">
										<el-input
											v-model="forget_form.recCode"
											class="left"
											size="large"
											:placeholder="$t('msg.register_rec_code_tip')">
											<template #prefix>
												<img
													class="prefix"
													src="../assets/image/login/code.png"
													alt="" />
											</template>
										</el-input>
										<div>
											<el-button
												v-if="sending"
												type="text"
												:disabled="true"
												style="margin-left: 16px"
												>{{
													`${left} s ${$t("msg.register_resend")}`
												}}</el-button
											>
											<el-button
												v-else
												type="primary"
												size="small"
												:disabled="!can_send"
												@click="handleSend"
												style="height: 28px; margin-left: 16px"
												>{{ $t("msg.register_send") }}</el-button
											>
										</div>
									</div>
								</el-form-item>
							</span>
							<!-- 邮箱找回密码 -->
							<span v-if="forget_form.regType === 'e_mail'">
								<el-form-item prop="email">
									<el-input
										v-model="forget_form.email"
										size="large"
										:placeholder="$t('msg.register_email_tip')">
										<template #prefix>
											<img
												class="prefix"
												src="../assets/image/login/email.png"
												alt="" />
										</template>
									</el-input>
								</el-form-item>
								<el-form-item prop="recCode">
									<div class="code_wrapper">
										<el-input
											v-model="forget_form.recCode"
											class="left"
											size="large"
											:placeholder="$t('msg.register_rec_code_tip')">
											<template #prefix>
												<img
													class="prefix"
													src="../assets/image/login/code.png"
													alt="" />
											</template>
										</el-input>
										<div>
											<el-button
												v-if="sending"
												type="text"
												:disabled="true"
												style="margin-left: 16px"
												>{{
													`${left} s ${$t("msg.register_resend")}`
												}}</el-button
											>
											<el-button
												v-else
												type="primary"
												size="small"
												:disabled="!can_send"
												@click="handleSend"
												style="height: 28px; margin-left: 16px"
												>{{ $t("msg.register_send") }}</el-button
											>
										</div>
									</div>
								</el-form-item>
							</span>
							<el-button
								color="#4578FF"
								size="large"
								style="width: 100%"
								@click="goNext"
								>{{ $t("msg.found_next") }}</el-button
							>
						</div>

						<div class="step" v-if="step === '2'">
							<div style="text-align: left; margin-bottom: 15px">
								<strong>{{ $t("msg.found_set_new") }}</strong>
							</div>
							<el-form-item prop="newPassword" :label="$t('msg.found_new')">
								<el-input
									v-model="forget_form.newPassword"
									type="password"
									show-password
									size="large"
									:placeholder="$t('msg.found_new')">
									<template #prefix>
										<img
											class="prefix"
											src="../assets/image/login/lock.png"
											alt="" />
									</template>
								</el-input>
							</el-form-item>
							<el-form-item prop="confirm" :label="$t('msg.register_confirm')">
								<el-input
									v-model="forget_form.confirm"
									type="confirm"
									show-password
									size="large"
									:placeholder="$t('msg.register_confirm_tip')">
									<template #prefix>
										<img
											class="prefix"
											src="../assets/image/login/lock.png"
											alt="" />
									</template>
								</el-input>
							</el-form-item>
							<el-form-item>
								<el-button
									color="#4578FF"
									size="large"
									style="width: 100px"
									@click="this.step = '1'"
									>{{ $t("msg.found_prev") }}</el-button
								>
								<el-button
									color="#4578FF"
									size="large"
									style="width: 100px"
									@click="ResetConfirm"
									>{{ $t("msg.finder_confirm") }}</el-button
								>
							</el-form-item>
						</div>
					</el-form>
				</div>
			</div>
			<!-- 提示栏 START -->
			<div class="tips_div" :class="{ hide_tips_content_actived: !showTips }">
				<div class="close_btn_div">
					<i class="iconfont icon-guanbi" @click="showTips = false"></i>
				</div>
				<div class="tips_header">{{ $t("msg.showCietac") }}</div>
				<div class="tips_content">
					<p class="tips_item" v-if="!loginShowType || loginShowType == 1">
						<!-- <img
							class="prefix"
							style="margin-right: 8px"
							src="../assets/image/login/user.png"
							alt="" /> -->
						<span>{{ $t("msg.showTips") }} </span>
					</p>
					<p class="tips_item" v-if="!loginShowType || loginShowType == 2">
						<!-- <img
							class="prefix"
							style="margin-right: 8px"
							src="../assets/image/login/user.png"
							alt="" /> -->
						<span>{{ $t("msg.showTips_one") }} </span>
					</p>
					<p class="tips_item" v-if="loginShowType == 3">
						<!-- <img
							class="prefix"
							style="margin-right: 8px"
							src="../assets/image/login/user.png"
							alt="" /> -->
						<!-- <span>{{ $t("msg.showTips_one") }} </span> -->
						<span>{{$t("msg.showTips_two")}}</span>
					</p>
				</div>
				<div class="router_span_div">
					<template v-if="!loginShowType || loginShowType == 1">
						<div @click="goSearchGuidePages('注册指引')">
							<span>注册指引</span> <el-icon><WarningFilled /></el-icon>
						</div>
						<div @click="goSearchGuidePages('立案指引')">
							<span>立案指引</span> <el-icon><WarningFilled /></el-icon>
						</div>
					</template>
					<div @click="goSearchGuidePages('办案指引')">
						<span>办案指引</span> <el-icon><WarningFilled /></el-icon>
					</div>
					<div @click="goSearchGuidePages('庭审指引')">
						<span>庭审指引</span> <el-icon><WarningFilled /></el-icon>
					</div>
				</div>
			</div>
			<!-- 提示栏 END -->
			<!-- 指引图片 START -->
			<div class="zhiyin_img_div" @click="goSearchGuidePages('注册指引')">
				<svg
					t="1741773247028"
					class="icon"
					viewBox="0 0 1024 1024"
					version="1.1"
					xmlns="http://www.w3.org/2000/svg"
					p-id="12455"
					width="200"
					height="200">
					<path
						d="M511.944 0C229.21 0 0 229.079 0 511.68a510.695 510.695 0 0 0 43.511 205.681c0.68 1.161 2.344 2.498 2.344 3.68v0.855c12.466 25.37 24.954 49.711 37.42 72.694a43.401 43.401 0 0 1 5.259 20.725v96.618a43.599 43.599 0 0 0 43.598 43.6h112.524a43.642 43.642 0 0 1 18.885 4.38c27.78 13.321 56.963 28.482 87.636 38.67a510.476 510.476 0 0 0 160.767 25.414c212.143 0 394.162-129.262 471.785-313.033a511.79 511.79 0 0 0 40.159-198.976C1023.888 229.364 794.678 0.13 511.944 0.13z m-6.025 812.686a45.483 45.483 0 1 1 45.68-45.482 45.548 45.548 0 0 1-45.68 45.482z m59.899-264.374c-1.665 0.504-8.37 4.185-9.706 7.011a72.3 72.3 0 0 0-4.688 18.228c-0.833 5.193 0.328 24.582 0.328 28.591a45.548 45.548 0 0 1-90.505 7.515 155.334 155.334 0 0 1-0.504-16.716c0.175-15.205 0.328-45.483 0.679-60.03a120.499 120.499 0 0 1 2.19-16.564c8.523-29.774 29.622-43.97 47.675-50.653 5.85-2.19 22.259-6.572 27.933-8.763a77.908 77.908 0 1 0-104.33-82.202c-0.328 3.001-4.381 28.59-5.52 32.271a43.555 43.555 0 0 1-77.974 12.204 74.906 74.906 0 0 1-12.554-39.787 156.232 156.232 0 0 1 2.673-26.75 173.365 173.365 0 1 1 224.346 195.645z m0 0"
						fill="#e6e6e6"
						p-id="12456"></path>
				</svg>
				<!-- <img src="../assets/svg/zhiyin.svg" alt="指引图片" /> -->
			</div>
			<!-- 指引图片 END -->
		</div>
		<!-- 中部 END -->
		<!-- 底部 START -->
		<div class="bottom_user_login_div">
			<div class="record_show_div">
				<p class="copy_right">
					{{ $t("msg.CIETAC") }}<span>©</span
					>{{ new Date().getFullYear() }}版权所有 京ICP备14011988号
				</p>
				<p class="copy_right">
					{{ $t("msg.TechnicalSupportContactPhoneNumber") }}:
					<span>010-82217631</span>、
					<span>010-82217622</span>
				</p>
			</div>
			<!-- 二维码显示区域 START -->
			<div class="er_code_show_div">
				<el-image
					style="width: 140px; height: 140px"
					src="https://mp.cietac.org/impic/download-apple.png" />
				<el-image
					style="width: 140px; height: 140px"
					src="https://mp.cietac.org/impic/download-android.png" />
			</div>
			<!-- 二维码显示区域 END -->
		</div>
		<!-- 底部 END -->
		<!-- 图形验证码对话框 -->
		<el-dialog
			v-model="dialogVisible"
			:title="$t('msg.register_safe')"
			width="400px">
			<el-form
				:model="sendForm"
				ref="sendForm"
				:rules="sendRules"
				v-loading="loading">
				<el-form-item prop="captcha">
					<div class="code_wrapper">
						<el-input
							v-model="sendForm.captcha"
							class="left"
							size="large"
							:placeholder="$t('msg.login_verify_code')"
							@keydown.enter="ConfirmSend">
							<template #prefix>
								<img
									class="prefix"
									src="../assets/image/login/code.png"
									alt="" />
							</template>
						</el-input>
						<div class="verify_code" @click="Captcha">
							<el-image fit="contain" :src="captchaUrl" />
						</div>
					</div>
				</el-form-item>
			</el-form>
			<template #footer>
				<span class="dialog-footer">
					<el-button @click="CloseDialog">{{
						$t("msg.finder_cancel")
					}}</el-button>
					<el-button type="primary" @click="ConfirmSend">{{
						$t("msg.register_send")
					}}</el-button>
				</span>
			</template>
		</el-dialog>

		<!-- 服务协议对话框 -->
		<el-dialog v-model="agreeVisible" title="网上立案平台注册须知" width="70%">
			<div class="loginTextBox">
				<p>
					在您访问并开始使用本网站www.cietacodr.org之前，请您仔细阅读以下服务条款，其阐述内容和条件适用于本网站向您提供的所有服务。
				</p>
				<h4>1、服务对象</h4>
				<p>
					本网站由中国中国国际经济贸易仲裁委员会（以下简称贸仲）运营管理，网站内容面向和适用于中华人民共和国境内外的律师及律师事务所及当事人。
				</p>
				<h4>2、服务条款的确认</h4>
				<p>
					您点击选择服务条款页面下的“同意”选择框，即视为您已阅读、了解并完全同意服务条款中的各项内容，包括本网站对服务条款所作的任何修改。除另行明确声明外，本网站任何服务范围或功能的变化均受服务条款约束。
				</p>
				<h4>3、服务条款的修改</h4>
				<p>
					本网站在必要时可随时修改服务条款，并在网站进行公告，一经公告，立即生效。如您继续使用服务，则视为您已接受修订的服务条款。
				</p>
				<h4>4、网站内容释明</h4>
				<p>
					在经本网站提供的所有案件信息仅供查询和参考，不一致的以记载在贸仲案件卷宗中的信息为准。
				</p>
				<h4>5、用户注册</h4>
				<p>
					考虑到本网站用户服务的针对性及重要性，您同意在注册时提供真实、完整及准确的资料，并及时更新。
					如您提供的资料不准确，或本网站有合理的理由认为该资料不真实、不完整、不准确，本网站有权暂停或终止您的注册身份及资料，会影响您使用本网站的服务。
				</p>
				<h4>6、送达条款</h4>
				<p>
					贸仲将通过您在本网站预留的电子邮箱、手机号码等送达仲裁文书及相关案件材料，以电子邮件、手机短信等到达您预留的特定系统的日期为送达日期，该送达具有法律效力。
				</p>
				<h4>7、用户资料及其他信息保密</h4>
				<p>
					注册时，请您选择填写用户名和密码，并按页面提示提交相关信息。您负有对用户名和密码以及他人案件信息保密的义务，并对该用户名和密码下发生的所有活动承担责任。本网站不会向您所使用服务所涉及相关方之外的其他方公开或透露您的个人资料及相关案件信息，但法律法规规定的除外。
				</p>
				<h4>8、责任的免除和限制</h4>
				<p>(1)遇以下情况，本网站不承担任何责任，包括但不仅限于：</p>
				&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
				①因不可抗力、系统故障、通讯故障、网络拥堵、供电系统故障、恶意攻击等造成本网站未能及时、准确、完整地提供服务。
				<br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
				②无论在任何原因下，您通过使用本网站上的信息或由本网站链接的其他网站上的信息，或其他与本网站链接的网站上的信息所导致的任何损失或损害。
				<br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
				③在“5.用户注册”第二款情形下，注册用户被暂停使用以及因此导致后续业务操作无法进行的后果。
				<p>
					(2)本网站负责对本网站上的信息进行审核与更新，但并不就信息的时效性、准确性以及服务功能的完整性和可靠性承担任何义务和赔偿责任。
				</p>
				<p>
					(3)如用户利用系统差错、故障或其他原因导致的漏洞，损害本网站及任何第三方的权益，本网站将终止该用户资格，并保留法律追究的权利。
				</p>

				<p>
					(4)如果本网站发现有影响用户信息安全的行为，本网站有权对用户信息实施保护。
				</p>

				<h4>9、网站链接</h4>
				<p>
					为了便利您的使用，本网站中设有相关链接项目，您使用本网站的相关链接时，将会自动离开本网站而访问其他完全独立的网站。由于所连接的网站完全独立，不在本网站的控制范围内，因此，对于您使用该链接后所带来的结果和风险全部由您自行承担。
				</p>
				<h4>10、保障</h4>
				<p>
					您同意保障和维护本网站的利益，并承担您或其他人使用您的用户资料造成本网站或任何第三方的损失或损害的赔偿责任。
				</p>
				<h4>11、知识产权</h4>
				<p>
					本网站所有内容和资源的版权归本网站所有(除非本网站已经标明版权所有人)，页面所有信息受《中华人民共和国著作权法》及相关法律法规和中国加入的所有知识产权方面的国际条约的保护。本网站所登载的内容仅供您个人使用，除非经贸仲的事前许可，任何人不得为了商业目的使用本网站内容或擅自对本网站内容进行任何修改、复制、出版、分发或再传送等。您在法律许可的合理使用范围内使用本网站内容时，应注明出处。
					对于本网站内容有可能涉及他人知识产权的情况，您在使用时应注意尊重该他人的权利。您应对自己因超出所述限定用途的使用而侵犯他人知识产权的情况自行承担责任，贸仲对此不承担任何责任。
				</p>
				<h4>12、法律适用</h4>
				<p>
					本服务条款之效力、解释、变更、执行与争议解决均适用中华人民共和国法律。
				</p>
			</div>
			<template #footer>
				<!-- <span class="dialog-footer">
                    <el-button @click="CloseDialog">{{
                        $t('msg.case_bind_cancel')
                    }}</el-button>
                    <el-button type="primary" @click="ConfirmSend">{{
                        $t('msg.register_confirm')
                    }}</el-button>
                </span> -->
			</template>
		</el-dialog>
	</div>
</template>
<script>
	// TODO: 2025年3月12日09:32:27 需要在地址栏加参数判断是内部登录还是官网登录
	// type: 1当事人 2.仲裁员 3.经办人
	import LangOption from "@/components/LangOption";
	import "../libs/wx";

	export default {
		name: "Login",
		components: {
			LangOption,
		},

		async created() {
			console.log("login中的create调用了");
			await this.GetGuestToken();
			await this.Captcha();
			this.$listener.$on("lang", () => {
				if (this.form_type === "login") {
					this.$refs.login_form?.resetFields();
				} else if (this.form_type === "register") {
					this.$refs.register_form?.resetFields();
				} else if (this.form_type === "forget") {
					this.$refs.forget_form?.resetFields();
				}
				// this.initLang()
			});
			document.addEventListener("keydown", (e) => {
				if (
					e.keyCode === 13 &&
					this.form_type === "login" &&
					this.$route.path === "/login"
				) {
					this.handleLogin();
				}
			});
			// 处理微信扫码登录失败提示
			if (this.$route.query.success !== undefined) {
				this.$message.error(this.$route.query.msg);
			}
		},
		data() {
			const validPass = (rule, value, cb) => {
				this.lang = localStorage.getItem("lang");
				if (value !== this.register_form.password) {
					return cb(this.lang === "en" ? "not same" : "两次输入的密码不同");
				}
				return cb();
			};
			const resetValidPass = (rule, value, cb) => {
				if (value !== this.forget_form.newPassword) {
					return cb(this.lang === "en" ? "not same" : "两次输入的密码不同");
				}
				return cb();
			};

			return {
				// 显示详情
				loginShowType: 0,
				showTips: true,
				lang: "zh",
				login_type: "account", // account wechat
				form_type: "login", // login/register/forget
				login_form: {
					account: "",
					password: "",
					captcha: "",
				},
				loginRules: {
					account: [
						{
							required: true,
							trigger: "blur",
							validator: this.$GF.formValidator,
						},
					],
					password: [
						{
							required: true,
							trigger: "blur",
							validator: this.$GF.formValidator,
						},
					],
					captcha: [
						{
							required: true,
							trigger: "blur",
							validator: this.$GF.formValidator,
						},
					],
				},
				register_form: {
					regType: "mobile_num", // 邮箱 e_mail；手机号 mobile_num
					mobileNo: "",
					email: "",
					realName: "",
					idCardNum: "",
					password: "",
					confirm: "",
					recCode: "", // 手机验证码or邮箱验证码
					checked: false,
				},
				registerRules: {
					mobileNo: [
						{
							required: true,
							validator: this.mobileNoRulesFn,
							trigger: "blur",
						},
					],
					email: [
						{
							required: true,
							validator: this.emailRulesFn,
							trigger: "blur",
						},
					],
					recCode: [{ required: true, validator: this.$GF.formValidator }],
					realName: [{ required: true, validator: this.$GF.formValidator }],
					password: [
						{ required: true, validator: this.$GF.formValidator },
						{ validator: this.$GF.passValidator },
					],
					confirm: [
						{ required: true, validator: this.$GF.formValidator },
						{ validator: validPass },
					],
					checked: [{ required: true, validator: this.$GF.formValidator }],
					// 身份证校验
					idCardNum: [{ required: true, validator: this.idCardNumRulesFn }],
				},
				forget_form: {
					regType: "mobile_num", // mobile_num or e_mail
					mobileNo: "",
					email: "",
					account: "",
					newPassword: "",
					recCode: "",
					confirm: "",
				},
				forgetRules: {
					mobileNo: [
						{
							required: true,
							validator: this.mobileNoRulesFnforgetRules,
							trigger: "blur",
						},
					],
					email: [
						{
							required: true,
							validator: this.emailRulesFnforgetRules,
							trigger: "blur",
						},
					],
					recCode: [
						{
							required: true,
							validator: this.$GF.formValidator,
							trigger: "blur",
						},
					],
					newPassword: [
						{
							required: true,
							validator: this.$GF.formValidator,
							trigger: "blur",
						},
					],
					confirm: [
						{
							required: true,
							validator: this.$GF.formValidator,
							trigger: "blur",
						},
						{ validator: resetValidPass },
					],
				},
				sending: false,
				can_send: false,
				timer: null,
				left: 60,
				langStyle: {
					border: "1px solid #7F96CD",
					color: "#1259B8",
					padding: "0px 10px",
					borderRadius: "3px",
				},
				url: "https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg",
				guestToken: "",
				captchaUrl: "",
				c_auth_token: "",
				dialogVisible: false,
				sendForm: {
					mobileNo: "",
					captcha: "",
				},
				sendRules: {
					captcha: [{ required: true, trigger: "blur" }],
				},
				loading: false,
				submitLoading: false,
				captchaLoading: false,
				step: "1",
				agreeVisible: false,
				t1: null,
			};
		},
		mounted() {
			this.initLoginType();
		},
		computed: {
			getLoginUserName() {
				let nameObj = {
					color: "",
					name: "",
				};
				if (this.loginShowType) {
					if (this.loginShowType == 1) {
						nameObj.name = "当事人";
						nameObj.color = "#4578ff";
					} else if (this.loginShowType == 2) {
						nameObj.name = "仲裁员";
						nameObj.color = "#FFCD43";
					} else if (this.loginShowType == 3) {
						nameObj.name = "经办秘书";
						nameObj.color = "#61dd21";
					}
				}
				return nameObj;
			},
		},
		methods: {
			initLoginType() {
				this.loginShowType = this.$route.query.type;
			},
			openWeb() {
				window.open("http://www.cietac.org", "_blank");
			},
			async handleLoginType(val) {
				this.login_type = val;
				if (this.login_type === "wechat") {
					const info = await this.getWechatInfo();
					new window.WxLogin({
						id: "wx_login_form",
						appid: info.appid,
						scope: info.scope,
						redirect_uri: info.redirect_uri,
						state: info.state,
						style: "",
						href: "http://www.cietacodr.org/wx.css",
					});
				}
			},
			handleForm(val) {
				this.form_type = val;

				this.register_form.regType = "mobile_num";
				if (val === "login") {
					this.Captcha();
					this.register_form = {
						regType: "mobile_num", // 邮箱 e_mail；手机号 mobile_num
						mobileNo: "",
						email: "",
						realName: "",
						idCardNum: "",
						password: "",
						confirm: "",
						recCode: "", // 手机验证码or邮箱验证码
						checked: false,
					};
				}
			},
			handleSend() {
				this.dialogVisible = true;
				this.Captcha();
			},
			registerSubmit() {
				this.$refs.register_form.validate(async (valid) => {
					if (valid) {
						const param = {
							req: {
								...this.register_form,
							},
						};
						this.submitLoading = true;
						const res = await this.$API.sendRequest("Register", param);
						if (res && res.success) {
							if (res.success) {
								this.$message.success("注册成功");
								this.form_type = "login";
								await this.Captcha();
							}
						}

						this.submitLoading = false;
					}
				});
			},
			async GetGuestToken() {
				const res = await this.$API.sendRequest("GetGuestToken");
				if (res && res.data) {
					this.guestToken = res.data;
					this.t1 = setInterval(() => {
						this.GuestHeartBeat();
					}, 60000 * 5);
				}
			},
			async GuestHeartBeat() {
				if (localStorage.getItem("c_auth_token")) {
					clearInterval(this.t1);
					return;
				}
				const headers = { "c-guest-token": this.guestToken };
				await this.$API.sendRequest("GuestHeartBeat", {}, headers);
			},
			async Captcha() {
				const headers = { "c-guest-token": this.guestToken };
				if (this.guestToken) {
					this.captchaLoading = true;
					const res = await this.$API.sendRequest("Captcha", {}, headers);
					this.captchaLoading = false;
					if (res && res.success) {
						this.captchaUrl = res && res.data;
					} else {
						this.captchaUrl = "";
						this.$message.error(res.msg);
					}
				}
			},
			handleLogin() {
				this.$refs.login_form.validate(async (valid) => {
					if (valid) {
						const param = {
							req: { ...this.login_form },
						};
						const headers = { "c-guest-token": this.guestToken };
						this.submitLoading = true;
						const res = await this.$API.sendRequest("Login", param, headers);

						if (res.success) {
							this.c_auth_token = res.data;
							localStorage.setItem("c_auth_token", this.c_auth_token);
							this.$message.success("登录成功");
							this.submitLoading = false;
							await this.$router.replace({ path: "/" });
						} else {
							this.$message.error(res.msg);
							this.submitLoading = false;
						}
					}
				});
			},
			CloseDialog() {
				this.dialogVisible = false;
				this.$refs.sendForm.resetFields();
			},
			ConfirmSend() {
				this.$refs.sendForm.validate(async (valid) => {
					if (valid) {
						const param = {
							req: {
								captcha: this.sendForm.captcha,
							},
						};
						let api = "";
						if (
							this.register_form.regType === "mobile_num" &&
							this.form_type === "register"
						) {
							param.req.mobileNo = this.register_form.mobileNo;
							api = "SendCode";
						}
						if (
							this.register_form.regType === "e_mail" &&
							this.form_type === "register"
						) {
							param.req.email = this.register_form.email;
							api = "SendEmailCode";
						}
						if (
							this.forget_form.regType === "mobile_num" &&
							this.form_type === "forget"
						) {
							param.req.mobileNo = this.forget_form.mobileNo;
							api = "SendPCode";
						}
						if (
							this.forget_form.regType === "e_mail" &&
							this.form_type === "forget"
						) {
							param.req.email = this.forget_form.email;
							api = "SendPEmailCode";
						}
						this.loading = true;
						const res = await this.$API.sendRequest(api, param);
						if (res && res.success) {
							this.$message.success("已发送");
							this.CloseDialog();
							this.sending = true;
							// 处理重发倒计时
							this.timer = setInterval(this.updateTime, 1000);
						}
						this.loading = false;
					}
				});
			},
			updateTime() {
				this.left -= 1;
				if (this.left === 0) {
					clearInterval(this.timer);
					this.sending = false;
					this.left = 60;
				}
			},
			regTypeChange() {
				this.$refs.register_form.resetFields();
				this.can_send = false;
			},
			resetTypeChange() {
				this.$refs.forget_form.resetFields();
			},
			goNext() {
				this.$refs.forget_form.validate((valid) => {
					if (valid) {
						this.step = "2";
						this.forget_form.newPassword = "";
						this.forget_form.confirm = "";
					}
				});
			},
			ResetConfirm() {
				this.$refs.forget_form.validate(async (valid) => {
					if (valid) {
						const param = {
							req: {
								account: "",
								regType: "",
								recCode: "",
								newPassword: "",
							},
						};
						param.req.recCode = this.forget_form.recCode;
						param.req.newPassword = this.forget_form.newPassword;
						param.req.regType = this.forget_form.regType;
						if (this.forget_form.regType === "mobile_num") {
							param.req.account = this.forget_form.mobileNo;
						}
						if (this.forget_form.regType === "e_mail") {
							param.req.account = this.forget_form.email;
						}
						this.submitLoading = true;
						const res = await this.$API.sendRequest("ResetPass", param);
						if (res) {
							this.$message.success(res.msg);

							this.submitLoading = false;
							this.form_type = "login";
						}
					}
				});
			},
			showAgreement() {
				this.agreeVisible = true;
			},
			async getWechatInfo() {
				const headers = { "c-guest-token": this.guestToken };
				const param = {
					req: {
						loginChannel: "common", //common - 通用登录页；scansignature - 触摸屏签章登录
					},
				};
				const res = await this.$API.sendRequest(
					"GetWxLoginConfigGuest",
					param,
					headers
				);
				if (res) {
					return res.data;
				} else {
					this.$message.error("获取appid失败");
				}
			},
			// 去往查询复函指引
			goSearchGuidePages(text) {
				// this.right_type = 'searchGuidePagesVue'
				const { href } = this.$router.resolve({
					path: "/SearchGuidePages",
					query: {
						type: text,
					},
				});
				window.open(href, "_blank");
			},

			// 打开老项目的立案
			// clickAOpenOroject() {
			//     window.open('http://online.cietac.org/')
			// },

			// 身份证校验自定义规则
			idCardNumRulesFn(rule, value, callback) {
				if (!value) {
					callback(new Error("身份证号为必填"));
				}
				let type = this.$GF.validateIDCard(value);
				if (!type) {
					callback(new Error("请输入正确的身份证"));
				}
				callback();
			},
			// 手机号校验自定义规则
			async mobileNoRulesFn(rule, value, callback) {
				if (!value) {
					this.can_send = false;
					callback(new Error("手机号不能为空"));
				}
				let type = this.$GF.validatePhoneNumber(value);
				if (!type) {
					this.can_send = false;
					callback(new Error("请输入正确的手机号"));
				} else {
					let param = {
						req: {
							phone: value,
						},
					};
					let res = await this.$API.sendRequest("FlagRegister", param);
					if (!res.success) {
						callback(new Error(res.msg));
					} else {
						if (this.register_form.regType === "mobile_num") {
							this.can_send = true;
						}

						callback();
					}
				}
			},

			// 邮箱校验自定义规则
			async emailRulesFn(rule, value, callback) {
				if (!value) {
					callback(new Error("邮箱不能为空"));
				}
				let type = this.$GF.validateEmail(value);
				if (!type) {
					callback(new Error("请输入正确的邮箱"));
				} else {
					let param = {
						req: {
							email: value,
						},
					};
					let res = await this.$API.sendRequest("FlagRegister", param);
					if (!res.success) {
						callback(new Error(res.msg));
					} else {
						if (this.register_form.regType === "e_mail") {
							this.can_send = true;
						}
						callback();
					}
				}
			},

			//找回的时候------- 手机号校验自定义规则
			async mobileNoRulesFnforgetRules(rule, value, callback) {
				if (!value) {
					this.can_send = false;
					callback(new Error("手机号不能为空"));
				}
				let type = this.$GF.validatePhoneNumber(value);
				if (!type) {
					this.can_send = false;
					callback(new Error("请输入正确的手机号"));
				} else {
					this.can_send = true;
					callback();
				}
			},

			//找回的时候------------ 邮箱校验自定义规则
			async emailRulesFnforgetRules(rule, value, callback) {
				if (!value) {
					callback(new Error("邮箱不能为空"));
				}
				let type = this.$GF.validateEmail(value);
				if (!type) {
					callback(new Error("请输入正确的邮箱"));
				} else {
					this.can_send = true;
					callback();
				}
			},
		},
	};
</script>
<style lang="scss">
	.user_login_body_div {
		height: 100vh;
		display: flex;
		flex-direction: column;
		.top_user_login_div {
			min-height: 95px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			.left_content {
				margin-left: 20px;
				display: flex;
				align-items: center;
				img {
					width: 73px;
					height: 61px;
					margin-right: 15px;
				}
				.company_name {
					text-align: left;
					color: #434a54;
					font-weight: 800;
				}
			}
			.right_content {
				display: flex;
				align-items: center;
				margin-right: 30px;
				.login_user_info_div {
					display: flex;
					align-items: center;
					margin-right: 10px;
					font-size: 20px;
				}
			}
		}
		.center_user_login_div {
			position: relative;
			flex: 1;
			background: url("../assets/image/login/login_bg.gif") no-repeat;
			background-size: 100% 100%;
			display: flex;
			justify-content: flex-end;
			align-items: center;
			flex-wrap: wrap;
			padding: 0 10%;
			.user_login_content {
				width: 400px;
				height: 450px;
				border-radius: 4px;
				background-color: hsla(0, 0%, 100%, 0.8);
				box-sizing: border-box;
				overflow: auto;
				.login_type {
					display: flex;
					align-items: center;
					padding: 0 23px;
					border-bottom: 1px solid #a7b9e3;
					height: 40px;
					.tab_item {
						cursor: pointer;
						margin: 0 8px;
						font-size: 15px;
						font-weight: 500;
					}
					.active {
						position: relative;
						span {
							color: #4578ff;
						}
					}
					.active::before {
						content: " ";
						width: 100%;
						height: 2px;
						background-color: #4578ff;
						position: absolute;
						bottom: -11px;
						left: 0;
					}
				}
				.login_form {
					padding: 20px 23px;
					.el-form-item {
						margin-right: 10px;
						margin-bottom: 14px;
						.el-input__wrapper {
							height: 43px !important;
						}
					}
				}
				.register_form {
					padding: 10px 23px;
					.el-form-item {
						margin-bottom: 14px;
						.el-input__wrapper {
							height: 30px !important;
						}
					}
				}
				.sticky01 {
					border-bottom: 1px solid #a7b9e3;
					background-color: #cddcf3;
					height: 40px;
					line-height: 40px;
					padding-left: 10px;
				}
				.sticky02 {
					background-color: #edf0f3;
					margin-top: 10px;
					padding: 5px 15px 2px 15px;
					.register {
						margin: 16px 0;
						text-align: center;
						color: #434a54;
						font-size: 13px;
						cursor: pointer;
					}
				}
				.login_footer {
					text-align: right;
					margin-top: 16px;
					.register {
						color: #434a54;
						font-size: 13px;
						cursor: pointer;
					}
					span:first-child {
						margin-right: 20px;
					}
				}
				.verify_code {
					height: 41px;
					margin-left: 5px;
					// width: 126px;
					background-color: #fff;
					border-radius: 5px;
					display: flex;
					align-items: center;
					justify-content: center;
					cursor: pointer;
				}
				// 忘记密码
				.forget_content_div {
					padding: 20px 23px;
					.forget {
						color: #434a54;
						text-align: right;
						font-size: 12px;
						cursor: pointer;
					}
				}
			}
			.tips_div {
				display: flex;
				flex-direction: column;
				margin-left: 30px;
				border-radius: 4px;
				width: 350px;
				height: 435px;
				background-color: rgba(255, 255, 255, 0.8);
				padding: 8px 23px;
				// box-sizing: border-box;
				overflow: auto;
				opacity: 1;
				transition: all 0.3s ease;

				.close_btn_div {
					text-align: right;
					i {
						cursor: pointer;
					}
				}

				.tips_header {
					font-size: 20px;
					margin-bottom: 8px;
					font-weight: bolder;
				}

				.tips_content {
					flex: 1;
					.tips_item {
						text-indent: 2em;
						text-align: left;
						display: flex;
						align-items: baseline;

						img {
							padding-top: 5px;
						}
					}
				}
				.router_span_div {
					margin-bottom: 60px;
					div {
						position: relative;
						display: flex;
						align-items: center;
						justify-content: flex-start;
						margin-bottom: 10px;
						color: #4578ff;
						cursor: pointer;
						span {
							font-size: 15px;
							margin: 0 5px;
						}
						.el-icon {
							transform: rotate(180deg);
						}
					}
					div:before {
						content: " ";
						background-color: #4578ff;
						position: absolute;
						width: 8px;
						height: 8px;
						border-radius: 50%;
						left: -7px;
						top: 6px;
					}
				}
			}
			.hide_tips_content_actived::before {
				content: " ";
				position: absolute;
				left: 0;
				top: 0;
				right: 0;
				bottom: 0;
				background-color: #ffffff;
			}
			.hide_tips_content_actived {
				position: relative;
				visibility: hidden; /* 隐藏但占位 */
				opacity: 0;
				width: 0;
				height: 0;
				padding: 0; /* 清除 padding */
				pointer-events: none;
			}
			.zhiyin_img_div {
				cursor: pointer;
				position: absolute;
				right: 50px;
				bottom: 20px;
				z-index: 9;
				font-size: 40px;
				animation: fadeIn 1s ease-in-out infinite;
			}
		}
		.copy_right_login {
			margin-top: 10px;
			color: #434a54;
			font-size: 13px;
			text-align: center;
		}
		.bottom_user_login_div {
			min-height: 155px;
			display: flex;
			// flex-direction: column;
			justify-content: space-around;
			align-items: center;
			.record_show_div {
				flex: 1;
				text-align: right;
				.copy_right {
					color: #434a54;
					font-size: 13px;
					text-align: center;
					a {
						color: #434a54;
						font-size: 13px;
					}
				}
			}
			.er_code_show_div {
				margin-right: 16px;
			}
		}
		img.prefix {
			width: 12px;
		}
		.code_wrapper {
			display: flex;
			justify-content: space-between;
			align-items: center;
		}
		.loginTextBox {
			text-align: left;
			font-family: cursive;
			font-size: 18px;

			p {
				text-indent: 2em;
			}
		}
	}
	@keyframes fadeIn {
		from {
			transform: scale(0.5);
		}

		to {
			transform: scale(1);
		}
	}
	::-webkit-scrollbar {
		display: none;
	}
	.move_phone_tips_div,
	.move_header_div {
		display: none;
	}
	@media screen and (max-width: 600px) {
		.top_user_login_div {
			display: none !important;
		}
		.user_login_content {
			display: none;
		}
		.center_user_login_div {
			display: none !important;
		}
		.tips_div {
			display: none !important;
		}
		.zh_cietac_div {
			display: none;
		}
		.record_show_div {
			display: none;
		}
		.zhiyin_img_div {
			display: none;
		}
		.move_header_div {
			display: flex;
			align-items: center;
			padding: 16px;
			div {
				font-size: 20px;
				font-weight: 800;
				margin-left: 18px;
			}
		}
		.move_phone_tips_div {
			flex: 1;
			display: flex;
			align-items: center;
			background-color: #ffffff;
			padding: 16px;
			font-size: 18px;
			font-weight: 800;
			text-align: left;
			.el-icon {
				font-size: 20px;
				margin-right: 10px;
				color: red;
			}
		}
	}
</style>
