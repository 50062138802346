<template>
    <div>
        <div class="container" v-loading="isContentBox">
            <header>
                <h1>
                    {{ info.partyType === 1 ? $t('msg.Applicant') : $t('msg.TheDefendant') }} {{$t('msg.AddressForService')}}
                </h1>
            </header>

            <el-divider></el-divider>
            <div class="contentBox" v-show="!isContentBox">
                <!-- // :rules="rules" -->

                <div>
                    <div class="applicant">
                        <el-card>
                            <el-card>
                                <!-- 邮寄送达地址 -->
                                <div class="titleBox">
                                    <h3>
                                        {{
                                            info.partyType === 1
                                                ?  $t('msg.ApplicantsAddressofService')
                                                : $t('msg.ServiceAddressoftheRespondent')
                                        }}
                                    </h3>
                                </div>
                                <div class="addressBox">
                                    <el-row>
                                        <el-col :span="4">
                                            <span></span>
                                            <span style="line-height: 100%">
                                                {{
                                                    info.partyType === 1
                                                        ? $t('msg.Applicant')
                                                        : $t('msg.TheDefendant')
                                                }}</span
                                            >
                                            <span></span>
                                        </el-col>
                                        <el-col :span="20">
                                            <div class="addressBoxItem">
                                                <el-radio-group
                                                    :disabled="radioDisabled"
                                                    v-model="radioParty"
                                                >
                                                    <el-radio
                                                        size="large"
                                                        :label="item.partyId"
                                                        v-for="(
                                                            item, index
                                                        ) in radioList"
                                                        :key="index"
                                                        >{{ item.partyCompany }}
                                                    </el-radio>
                                                </el-radio-group>
                                            </div>
                                        </el-col>
                                        <!-- <el-col :span="10"></el-col> -->
                                    </el-row>

                                    <el-card>
                                        <!-- <p
                                            v-if="info.partyType === 2"
                                            style="font-size: 14px; color: red"
                                        >
                                            申请人如列明被申请人多个地址，将按照申请人列明的顺序，向被申请人地址逐一送达。如需同时送达的，申请人应提交书面申请，在寄送纸质材料时，增加提交相应份数的材料。
                                        </p> -->
                                        <div>
                                            <div
                                                v-for="(item, index) in addList"
                                                :key="index"
                                            >
                                                <el-form
                                                    ref="form"
                                                    :model="addList[index]"
                                                    :rules="rules"
                                                >
                                                    <div
                                                        class="titleBox"
                                                        v-if="
                                                            info.partyType === 2
                                                        "
                                                    >
                                                        <h3>
                                                            第{{
                                                                chznArr[index]
                                                            }}送达地址
                                                        </h3>
                                                    </div>

                                                    <!-- 收件人  -->
                                                    <el-row>
                                                        <el-col :span="4">
                                                            <span></span>
                                                            <span
                                                                class="requireIconBox"
                                                                >{{$t('msg.Recipients')}}
                                                                <span>
                                                                    <svg
                                                                        class="icon"
                                                                        aria-hidden="true"
                                                                    >
                                                                        <use
                                                                            xlink:href="#icon-bitian"
                                                                        ></use></svg></span
                                                            ></span>
                                                            <span></span>
                                                        </el-col>
                                                        <el-col :span="20">
                                                            <el-form-item
                                                                prop="partyName"
                                                            >
                                                                <el-input
                                                                    :placeholder="$t('msg.PleaseentertheRecipient')"
                                                                    v-model.trim="
                                                                        item.partyName
                                                                    "
                                                                ></el-input>
                                                            </el-form-item>
                                                        </el-col>
                                                    </el-row>
                                                    <!-- 联系电话  -->
                                                    <el-row>
                                                        <el-col :span="4">
                                                            <span></span>
                                                            <span
                                                                class="requireIconBox"
                                                                >{{$t('msg.SendToMobile')}} / {{ $t('msg.Landline') }}
                                                                <span>
                                                                    <svg
                                                                        class="icon"
                                                                        aria-hidden="true"
                                                                    >
                                                                        <use
                                                                            xlink:href="#icon-bitian"
                                                                        ></use></svg></span
                                                            ></span>
                                                            <span></span>
                                                        </el-col>
                                                        <el-col :span="20">
                                                            <el-form-item
                                                                prop="phone"
                                                            >
                                                                <el-input
                                                                    :placeholder="$t('msg.PleaseenteryourMobilePhoneNumber')"
                                                                    v-model.trim="
                                                                        item.phone
                                                                    "
                                                                ></el-input>
                                                            </el-form-item>
                                                        </el-col>
                                                    </el-row>
                                                    <!-- 邮箱  -->
                                                    <el-row>
                                                        <el-col :span="4">
                                                            <span></span>
                                                            <span
                                                                class="requireIconBox"
                                                                >{{$t('msg.register_email')}}
                                                                <span>
                                                                    <svg
                                                                        v-if="
                                                                            info.partyType ===
                                                                            1
                                                                        "
                                                                        class="icon"
                                                                        aria-hidden="true"
                                                                    >
                                                                        <use
                                                                            xlink:href="#icon-bitian"
                                                                        ></use></svg></span
                                                            ></span>
                                                            <span></span>
                                                        </el-col>
                                                        <el-col :span="20">
                                                            <el-form-item
                                                                :prop="
                                                                    info.partyType ===
                                                                    1
                                                                        ? 'email'
                                                                        : ' '
                                                                "
                                                            >
                                                                <el-input
                                                                    :placeholder="$t('msg.register_email_tip')"
                                                                    v-model.trim="
                                                                        item.email
                                                                    "
                                                                ></el-input>
                                                            </el-form-item>
                                                        </el-col>
                                                    </el-row>

                                                    <!-- 是否境外  -->
                                                    <el-row>
                                                        <el-col :span="4">
                                                            <span></span>
                                                            <span
                                                                class="requireIconBox"
                                                                >{{$t('msg.WhetherOverseas')}}
                                                            </span>
                                                            <span></span>
                                                        </el-col>
                                                        <el-col :span="20">
                                                            <el-form-item
                                                                :prop="
                                                                    info.partyType ===
                                                                    1
                                                                        ? 'email'
                                                                        : ' '
                                                                "
                                                            >
                                                                <el-checkbox
                                                                    @change="
                                                                        changeIsAbroad(
                                                                            index
                                                                        )
                                                                    "
                                                                    v-model="
                                                                        item.isAbroad
                                                                    "
                                                                    >{{$t('msg.Overseas')}}</el-checkbox
                                                                >
                                                            </el-form-item>
                                                        </el-col>
                                                    </el-row>

                                                    <!-- 选择地址 -->
                                                    <el-row>
                                                        <el-col :span="4">
                                                            <span></span>
                                                            <span
                                                                class="requireIconBox"
                                                                >{{$t('msg.SelectAddress')}}
                                                                <span>
                                                                    <svg
                                                                        class="icon"
                                                                        aria-hidden="true"
                                                                    >
                                                                        <use
                                                                            xlink:href="#icon-bitian"
                                                                        ></use></svg></span
                                                            ></span>
                                                            <span></span>
                                                        </el-col>
                                                        <el-col :span="20">
                                                            <div
                                                                class="selectBox"
                                                            >
                                                                <el-form-item
                                                                    :prop="
                                                                        item.isAbroad
                                                                            ? ' '
                                                                            : 'partyArea'
                                                                    "
                                                                >
                                                                    <!-- 省  -->
                                                                    <el-select
                                                                        filterable
                                                                        :placeholder="$t('msg.PleaseSelect')"
                                                                        v-model="
                                                                            item.partyArea
                                                                        "
                                                                        :disabled="
                                                                            item.isAbroad
                                                                        "
                                                                        @change="
                                                                            areaNameChange(
                                                                                index
                                                                            )
                                                                        "
                                                                    >
                                                                        <el-option
                                                                            v-for="(
                                                                                item,
                                                                                index
                                                                            ) in areaNameSelectList"
                                                                            :key="
                                                                                index
                                                                            "
                                                                            :label="
                                                                                item.areaName
                                                                            "
                                                                            :value="
                                                                                item.areaId
                                                                            "
                                                                        >
                                                                        </el-option>
                                                                    </el-select>
                                                                </el-form-item>

                                                                <el-form-item
                                                                    :prop="
                                                                        item.isAbroad
                                                                            ? ' '
                                                                            : 'partyCity'
                                                                    "
                                                                >
                                                                    <!-- 市  -->
                                                                    <el-select
                                                                        :disabled="
                                                                            item.isAbroad
                                                                        "
                                                                        filterable
                                                                        :placeholder="$t('msg.PleaseSelect')"
                                                                        style="
                                                                            margin-left: 20px;
                                                                            margin-right: 20px;
                                                                        "
                                                                        v-model="
                                                                            item.partyCity
                                                                        "
                                                                        @change="
                                                                            marketChange(
                                                                                index
                                                                            )
                                                                        "
                                                                    >
                                                                        <el-option
                                                                            v-for="(
                                                                                item,
                                                                                index
                                                                            ) in marketList[
                                                                                index
                                                                            ]"
                                                                            :key="
                                                                                index
                                                                            "
                                                                            :label="
                                                                                item.areaName
                                                                            "
                                                                            :value="
                                                                                item.areaId
                                                                            "
                                                                        >
                                                                        </el-option>
                                                                    </el-select>
                                                                </el-form-item>
                                                                <el-form-item
                                                                    :prop="
                                                                        item.isAbroad
                                                                            ? ' '
                                                                            : 'district'
                                                                    "
                                                                >
                                                                    <!-- 县  -->
                                                                    <el-select
                                                                        :disabled="
                                                                            item.isAbroad
                                                                        "
                                                                        filterable
                                                                        :placeholder="$t('msg.PleaseSelect')"
                                                                        v-model="
                                                                            item.district
                                                                        "
                                                                    >
                                                                        <el-option
                                                                            v-for="(
                                                                                item,
                                                                                index
                                                                            ) in countyList[
                                                                                index
                                                                            ]"
                                                                            :key="
                                                                                index
                                                                            "
                                                                            :label="
                                                                                item.areaName
                                                                            "
                                                                            :value="
                                                                                item.areaId
                                                                            "
                                                                        >
                                                                        </el-option>
                                                                    </el-select>
                                                                </el-form-item>
                                                            </div>
                                                        </el-col>
                                                    </el-row>

                                                    <!-- 送达地址  -->
                                                    <el-row>
                                                        <el-col :span="4">
                                                            <span></span>
                                                            <span
                                                                class="requireIconBox"
                                                                >{{$t('msg.DetailedAddress')}}
                                                                <span>
                                                                    <svg
                                                                        class="icon"
                                                                        aria-hidden="true"
                                                                    >
                                                                        <use
                                                                            xlink:href="#icon-bitian"
                                                                        ></use></svg></span
                                                            ></span>
                                                            <span></span>
                                                        </el-col>
                                                        <el-col :span="20">
                                                            <el-form-item
                                                                prop="companyAddress"
                                                            >
                                                                <el-input
                                                                    :placeholder="$t('msg.PleaseentertheDeliveryAddress')"
                                                                    type="textarea"
                                                                    style="
                                                                        width: 59%;
                                                                    "
                                                                    v-model.trim="
                                                                        item.companyAddress
                                                                    "
                                                                ></el-input>
                                                                <el-button
                                                                    type="primary"
                                                                    style="
                                                                        margin-left: 20px;
                                                                    "
                                                                    v-if="
                                                                        index ===
                                                                            0 &&
                                                                        info.partyType ===
                                                                            2
                                                                    "
                                                                    @click="
                                                                        clickAddAddress
                                                                    "
                                                                    >{{$t('msg.Add')}}</el-button
                                                                >
                                                                <el-button
                                                                    type="primary"
                                                                    style="
                                                                        margin-left: 20px;
                                                                    "
                                                                    v-if="
                                                                        index !==
                                                                        0
                                                                    "
                                                                    @click="
                                                                        clickDelAddress(
                                                                            index
                                                                        )
                                                                    "
                                                                    >{{$t('msg.Delete')}}</el-button
                                                                >
                                                                <el-button
                                                                    type="primary"
                                                                    style="
                                                                        margin-left: 20px;
                                                                    "
                                                                    v-if="
                                                                        index !==
                                                                        0
                                                                    "
                                                                    @click="
                                                                        clickMoveUp(
                                                                            index
                                                                        )
                                                                    "
                                                                    >{{$t('msg.MoveUp')}}</el-button
                                                                >
                                                            </el-form-item>
                                                        </el-col>
                                                    </el-row>
                                                </el-form>
                                            </div>
                                        </div>
                                    </el-card>
                                </div>

                                <el-divider></el-divider>
                            <p style="text-align: left;">
                                附件:        
                                <el-dropdown>
								<span class="el-dropdown-link">
									<span>送达地址确认书.pdf </span>
									<el-icon class="el-icon--right">
										<arrow-down />
									</el-icon>
								</span>
								<template #dropdown>
									<el-dropdown-menu>
										<el-dropdown-item @click="viewPdf(scope.row)">{{$t('msg.Preview')}}</el-dropdown-item>
										<el-dropdown-item @click="downPdf(scope.row)">{{$t('msg.Download')}}</el-dropdown-item>
									</el-dropdown-menu>
								</template>
							</el-dropdown>
                            </p>
                            </el-card>
                        </el-card>
                    </div>
                </div>
            </div>
            <el-divider></el-divider>
            <el-button @click="clickSubmit" type="primary" v-if="allType"
                >{{$t('msg.ConfirmtheAddress')}}</el-button
            >
            <el-button @click="clickBlack" type="info">{{$t('msg.back')}}</el-button>
        </div>
    </div>
</template>
 
<script>
export default {
    name: '',
    components: {},
    data() {
        return {
            // 加载效果
            isContentBox: false,

            // 表单
            form: {
                phone: '',
                email: '',
                partyName: ''
            },
            rules: {
                phone: [
                    {
                        required: true,
                        message: '请输入联系方式',
                        trigger: 'blur'
                    }
                ],
                email: [
                    {
                        required: true,
                        message: '请输入邮箱',
                        trigger: 'blur'
                    },
                    {
                        pattern:
                            '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,}$',
                        message: '请输入正确的邮箱格式',
                        trigger: 'blur'
                    }
                ],
                partyName: [
                    {
                        required: true,
                        message: '请输入收件人',
                        trigger: 'blur'
                    }
                ],
                partyArea: [
                    {
                        required: true,
                        message: '请选择省',
                        trigger: 'change'
                    }
                ],
                partyCity: [
                    {
                        required: true,
                        message: '请选择市',
                        trigger: 'change'
                    }
                ],
                district: [
                    {
                        required: true,
                        message: '请选择区/县',
                        trigger: 'change'
                    }
                ],
                companyAddress: [
                    {
                        required: true,
                        message: '请输入送达地址',
                        trigger: 'blur'
                    },
                    {  max: 300, message: '长度不能超过300个字符', trigger: 'blur' }
                ]
            },

            // 选择的身份
            partyId: 1,
            objType: 1,
            // 单选框的值
            radioParty: '',
            // 单选框数组
            radioList: [],
            addList: [
                {
                    // 收件人
                    partyName: '',
                    // 送达手机
                    phone: '',
                    // 邮箱
                    email: '',
                    // 省
                    partyArea: '',
                    // 市
                    partyCity: '',
                    // 县
                    district: '',
                    // 详细地址
                    companyAddress: '',
                    // 排序字段
                    partySort: 0,
                    // 每一个都需要
                    partyId: '',
                    flagUpd: '',
                    receiveNo: '',
                    caseId: '',
                    // 是否是境外
                    isAbroad: false
                }
            ],
            // 省数组
            areaNameSelectList: [],
            // 市数组
            marketList: [],
            // 县数组
            countyList: [],
            // 传递的info
            info: {},
            // 中文数组  换算
            chznArr: [
                '一',
                '二',
                '三',
                '四',
                '五',
                '六',
                '七',
                '八',
                '九',
                '十'
            ],
            // 是否处于编辑状态   1添加  2编辑
            flagUpd: 1,
            allType: null
        }
    },
    methods: {
        async load(info) {
            // this.$refs.form.clearValidate()
            this.clearForm()
            this.flagUpd = 1
            this.info = info
            this.allType = info.allType
            this.isContentBox = true
            this.radioList = await this.getElectronicDeliveryParty(
                info.caseId,
                info.partyType
            )
            if (info.partyId) {
                this.flagUpd = 2
                await this.clickAddressView(
                    info.partyId,
                    info.caseId,
                    info.receiveNo
                )
            }

            this.addList[0].flagUpd = this.flagUpd
            this.addList[0].receiveNo = this.info.receiveNo
            this.addList[0].caseId = this.info.caseId
            this.addList[0].partyType = info.partyType
            this.areaNameSelectList = await this.clickStyleTypeList()
            this.isContentBox = false
        },

        // 清空表单
        clearForm() {
            // 受送达地址
            this.radioList = []
            this.radioParty = ''
            this.addList = [
                {
                    // 收件人
                    partyName: '',
                    // 送达手机
                    phone: '',
                    // 邮箱
                    email: '',
                    // 省
                    partyArea: '',
                    // 市
                    partyCity: '',
                    // 县
                    district: '',
                    // 详细地址
                    companyAddress: '',
                    // 排序字段
                    partySort: 0,
                    // 每一个都需要
                    partyId: '',
                    flagUpd: '',
                    receiveNo: '',
                    caseId: '',
                    isAbroad: false
                }
            ]

            this.$nextTick(() => {
                for (let i = 0; i < this.addList.length; i++) {
                    this.$refs.form[i].clearValidate()
                }
            })
        },

        // 获取送达人复选框信息
        async getElectronicDeliveryParty(caseId, partyType) {
            let param = {
                req: {
                    caseId,
                    partyType
                }
            }
            let res = await this.$API.sendRequest(
                'ElectronicDeliveryParty',
                param
            )
            this.radioParty = res.data[0].partyId
            this.addList[0].partyId = res.data[0].partyId
            return res.data
        },

        // 不做提交操作，直接返回
        clickBlack() {
            this.clearForm()
            this.$listener.$emit('goRegisterCaseListener', {
                receiveNo: this.info.receiveNo,
                caseId: this.info.caseId
            })
        },

        // 地址 省
        async clickStyleTypeList(req = 0) {
            let param = {
                req
            }
            let res = await this.$API.sendRequest('GetProvince', param)
            return res.data
        },

        // 省改变
        async areaNameChange(index) {
            this.marketList[index] = await this.clickStyleTypeList(
                this.addList[index].partyArea
            )
            this.addList[index].partyCity = ''
            this.addList[index].district = ''
        },

        // 省改变
        async marketChange(index) {
            this.countyList[index] = await this.clickStyleTypeList(
                this.addList[index].partyCity
            )
            this.addList[index].district = ''
        },

        // 保存送达信息
        async clickSubmit() {
            let isValidType = 0
            for (let i = 0; i < this.$refs.form.length; i++) {
                this.$refs.form[i].validate(async (valid) => {
                    if (valid) {
                        // 如果校验不通过，则将 isValid 标记为 false
                        isValidType++
                        if (isValidType === this.$refs.form.length) {
                            let param = {
                                req: this.addList
                            }
                            let res = await this.$API.sendRequest(
                                'ElectronicDelivery',
                                param
                            )
                            if (!res.success) {
                                return this.$message.error(res.msg)
                            }
                            this.$message.success(res.msg)

                            setTimeout(() => {
                                this.clearForm()
                                this.clickBlack()
                            }, 500)
                        }
                    }
                })
            }
        },

        // 点击添加新增对象
        clickAddAddress() {
            if (this.addList.length < 10) {
                this.addList.push({
                    // 省
                    partyArea: '',
                    // 市
                    partyCity: '',
                    // 县
                    district: '',
                    // 详细地址
                    companyAddress: '',
                    // 排序
                    partySort: this.addList.length,
                    // 收件人
                    partyName: '',
                    // 送达手机
                    phone: '',
                    // 邮箱
                    email: '',
                    // 每一个都需要
                    partyId: this.radioParty,
                    flagUpd: this.addList[0].flagUpd,
                    partyType: this.addList[0].partyType,
                    receiveNo: this.addList[0].receiveNo,
                    caseId: this.addList[0].caseId,
                    // 是否是境外
                    isAbroad: false
                })
            } else {
                this.$message.info('最多添加十个送达地址')
            }
        },

        // 点击了地址的删除
        clickDelAddress(index) {
            this.addList = this.addList.filter((item, i) => i !== index)
        },

        // 如果传递了partyId，就说明点击了查看
        async clickAddressView(partyId, caseId, receiveNo) {
            let param = {
                req: {
                    partyId,
                    caseId,
                    receiveNo
                }
            }
            let { data } = await this.$API.sendRequest(
                'ShowElectronicDeliveryParty',
                param
            )

            // this.form = { ...data }
            this.radioParty = data[0].partyId

            for (let i = 0; i < data.length; i++) {
                this.marketList[i] = await this.clickStyleTypeList(
                    data[i].partyArea
                )
                this.countyList[i] = await this.clickStyleTypeList(
                    data[i].partyCity
                )
                if (!data[i].partyArea) {
                    data[i].isAbroad = true
                }
            }
            this.addList = data
        },

        // 点击了上移
        clickMoveUp(index) {
            let newMarketList = this.marketList.splice(index, 1)[0]
            this.marketList.splice(index - 1, 0, newMarketList)

            let newCountyList = this.countyList.splice(index, 1)[0]
            this.countyList.splice(index - 1, 0, newCountyList)

            let arr = this.addList.splice(index, 1)[0]
            this.addList.splice(index - 1, 0, arr)
        },

        // 点击了是不是境外的多选框
        changeIsAbroad(index) {
            this.addList[index].partyArea = ''
            this.addList[index].partyCity = ''
            this.addList[index].district = ''
        },


			// 预览pdf
			async viewPdf(row) {
				let param = {
					req: row.caseId
				}
				let res = await this.$API.sendRequest('GeneratePdf', param)
				let blob
				blob = new Blob([res], {
					type: 'application/pdf'
				})
				let url = window.URL.createObjectURL(blob)
				window.open(url)
			},

			// 下载pdf
			async downPdf(row) {
				let param = {
					req: row.caseId
				}
				let res = await this.$API.sendRequest('GeneratePdf', param)
				let blob = new Blob([res], {
					type: 'application/pdf'
				})
				let url = window.URL.createObjectURL(blob)
				let link = document.createElement('a')
				link.href = url
				link.download = '送达地址确认书'
				link.click()
				window.URL.revokeObjectURL(link.href)
			},
    },
    watch: {
        radioParty(newValue) {
            this.addList[0].partyId = newValue
        }
    },
    computed: {
        radioDisabled() {
            if (this.addList[0].flagUpd === 1) {
                return false
            } else {
                return true
            }
        }
    }
}
</script>
 
<style scoped lang=scss>
header {
    h1 {
        font-weight: 500;
    }
}
.inform {
    text-align: left;
    padding: 10px 40px;
    background-color: #85bef3;
    color: #fff;
    line-height: 34px;
    h4 {
        font-size: 18px;
        margin-bottom: 10px;
        margin-top: 10px;
    }
    p {
        text-indent: 2em;
        margin: 0;
    }
}

.contentBox {
    .titleBox {
        display: flex;
        justify-content: space-between;
        h3 {
            font-weight: 500;
            position: relative;
            top: 0;
            font-size: 16px;
            padding-left: 10px;
            &::before {
                content: '';
                display: block;
                height: 25px;
                width: 4px;
                background: #ff0000;
                position: absolute;
                top: -3px;
                left: 2px;
            }
        }
        .butBox {
            margin-right: 20px;
            display: flex;
            align-self: center;
        }
    }
    .addressBoxItem {
        display: flex;
    }
    .el-row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 10px 0;
    }
    .selectBox {
        display: flex;
        :deep(.el-input) {
            width: 100%;
        }
    }
    :deep(.el-input) {
        width: 58%;
        display: flex;
    }
}

.requireIconBox {
    position: relative;
    span {
        position: absolute;
        top: -10px;
        right: -10px;
    }
}

:deep(.el-radio.el-radio--large .el-radio__inner) {
    width: 22px !important;
    height: 22px !important;
}
:deep(.el-radio__inner::after) {
    width: 10px !important;
    height: 10px !important;
}

		.el-dropdown-link {
			color: #409eff;
		}
</style>