<template>
    <div class="uploadLog">
        <!-- 搜索 -->
         
        <div class="search">
            <span>{{$t('msg.FileDescription')}}：</span>
            <el-input
                v-model="remark"
                size="small"
                clearable
                @keydown.enter="handleSearch"
            ></el-input>
            <span>{{$t('msg.FileName')}}：</span>
            <el-input
                v-model="attName"
                size="small"
                clearable
                @keydown.enter="handleSearch"
            ></el-input>
            <div class="btn_group">
                <el-button @click="handleSearch">
                    <template #icon
                        ><img
                            src="../assets/image/case/search.png"
                            alt="" /></template
                    >{{ $t('msg.case_search') }}
                </el-button>
            </div>
        </div>
        <div class="table_wrapper">
            <el-table
                v-loading="loading"
                :data="list"
                border
                :stripe="true"
                style="width: 100%"
            >
                <el-table-column
                    :label="$t('msg.Party')"
                    prop="submitUserName"
                    align="center"
                >
                </el-table-column>
                <el-table-column :label="$t('msg.FileDescription')" prop="remark" align="center">
                </el-table-column>
                <el-table-column :label="$t('msg.Dateofsubmission')" prop="subDate" align="center">
                </el-table-column>
                <el-table-column :label="$t('msg.FileName')" prop="attName" align="center">
                </el-table-column>
                <el-table-column
                    :label="$t('msg.OperationLog')"
                    prop="operateDesc"
                    align="center"
                >
                </el-table-column>
            </el-table>
            <!-- 分页 -->
            <el-pagination
                style="margin-top: 10px; display: flex; justify-content: center"
                small="small"
                @size-change="paginationSizeChange"
                @current-change="paginationCurrentChange"
                :current-page="pagination.page"
                :page-sizes="pagination.sizes"
                :page-size="pagination.limit"
                layout="total, sizes, prev, pager, next, jumper"
                :total="pagination.total"
            >
            </el-pagination>
        </div>
    </div>
</template>

<script>
export default {
    name: 'UploadOperationLog',
    data() {
        return {
            uploadVisible: false,
            attName: '',
            remark: '',
            list: [],
            loading: false,
            pagination: {
                page: 1,
                pages: 0,
                limit: 10,
                sizes: [10, 20, 50, 100],
                total: 0
            },
            file_list: [],
            title: '',
            case_id: ''
        }
    },
    methods: {
        load(case_id) {
            this.case_id = case_id
            this.attName = ''
            this.remark = ''
            this.GetFileList()
        },
        paginationSizeChange(val) {
            this.pagination.limit = val
            this.GetFileList()
        },
        paginationCurrentChange(val) {
            this.pagination.page = val
            this.GetFileList()
        },
        async GetFileList() {
            const param = {
                req: {
                    current: this.pagination.page,
                    size: this.pagination.limit,
                    q: {
                        caseId: this.case_id,
                        remark: this.remark,
                        attName: this.attName
                    }
                }
            }
            this.loading = true
            const res = await this.$API.sendRequest('SubmitList', param)
            this.loading = false
            if (res.success) {
                this.list = res.data.records || []
                this.pagination.total = res.data.total
            } else {
                this.$message.error(res.msg)
            }
        },
        handleSearch() {
            this.GetFileList()
        }
    }
}
</script>

<style scoped lang="sass">
.search
    height: 80px
    box-shadow: 0 0 2px 1px #E1EAFA
    display: flex
    font-size: 13px
    align-items: center
    padding: 0 20px 0 10px
    img
        width: 24px
        height: 24px
    span
        width: 180px
    .btn_group
        width: 360px
    img
        width: 13px
        height: 13px
    ::v-deep(.el-input__wrapper)
        height: 35px!important
</style>
