<template>
    <!-- 案件详情输入框 -->

    <div class="bigBox">
        <div class="titleText">{{$t('msg.ReceiptInformation')}}</div>
        <el-form
            ref="form"
            :model="form"
            label-width="200px"
            v-loading="formLoading"
        >
            <!-- 第一行    标题--份数  -->
            <div class="inputBox">
                <div class="inputLayout">
                    <div class="inputItem">
                        <span>{{$t('msg.Subjectofreceipt')}}</span>
                        <div>
                            <el-input
                                v-model="receiveLetterInfo.receiveLetterTitle"
                                id="receiveLetterTitle"
                                @keyup.enter="onSubmit"
                            ></el-input>
                        </div>
                    </div>
                    <div class="inputItem">
                        <span>{{$t('msg.Numberofdocumentsreceived')}}</span>
                        <div>
                            <el-input
                                type="number"
                                v-model="receiveLetterInfo.receiveLetterAmount"
                                id="receiveLetterAmount"
                                   @keyup.enter="onSubmit"
                            ></el-input>
                        </div>
                    </div>
                </div>
            </div>

            <!-- 第二行   日期-->
            <div class="inputBox">
                <div class="inputLayout">
                    <div class="inputItem">
                        <span>{{$t('msg.DateofReceipt')}}</span>
                        <div>
                            <el-date-picker
                                v-model="receiveLetterInfo.receiveLetterDate"
                                align="right"
                                type="date"
                                :placeholder="$t('msg.OptionDate')"
                                :shortcuts="shortcuts"
                                style="width: 100%"
                            >
                            </el-date-picker>
                        </div>
                    </div>
                    <div class="inputItem"></div>
                </div>
            </div>

            <!-- 第三行    材料类型-->
            <div class="inputBox">
                <div class="inputLayout">
                    <div class="inputItem">
                        <span>{{$t('msg.MaterialType')}}</span>
                        <div>
                            <el-select
                                :placeholder="$t('msg.MaterialType')"
                                v-model="form.materialStyle"
                                @change="clearText"
                                style="width: 100%"
                                filterable
                            >
                                <el-option
                                    v-for="(
                                        item, index
                                    ) in receiveDetail.materialStyleList"
                                    @click="getItemName"
                                    :key="index"
                                    :label="item.dictionaryItemName"
                                    :value="item.dictionaryItemValue"
                                >
                                </el-option>
                            </el-select>
                        </div>
                    </div>
                    <div class="inputItem" v-if="checkBoxType">
                        <span>{{$t('msg.workbench_more')}}</span>
                        <div>
                            <el-select
                                style="width: 100%"
                                v-model="checkListText"
                                multiple
                                :placeholder="$t('msg.PleaseSelect')"
                                @change="getItemName"
                            >
                                <el-option
                                    v-for="(item, index) in checkList"
                                    :key="index"
                                    :label="item"
                                    :value="item"
                                >
                                </el-option>
                            </el-select>
                        </div>
                    </div>
                    <div v-else class="inputItem"></div>
                </div>
            </div>

            <!-- 第四行    来文方式  来函人姓名-->
            <div class="inputBox">
                <div class="inputLayout">
                    <div class="inputItem">
                        <span>{{$t('msg.ModeofCommunication')}}</span>
                        <div>
                            <el-select
                                v-model="receiveType"
                                filterable
                                style="width: 100%"
                                :placeholder="$t('msg.ModeofCommunication')"
                            >
                                <el-option
                                    v-for="(
                                        item, index
                                    ) in receiveDetail.receiveTypeList"
                                    :key="index"
                                    :label="item.dictionaryItemName"
                                    :value="item.dictionaryItemValue"
                                >
                                </el-option>
                            </el-select>
                        </div>
                    </div>
                    <div class="inputItem">
                        <span>{{$t('msg.Nameofthewriter')}}</span>
                        <div>
                            <el-input
                                v-model="receiveLetterInfo.senderName"
                                   @keyup.enter="onSubmit"
                            ></el-input>
                        </div>
                    </div>
                </div>
            </div>

            <!-- 第五行   来函人单位-->
            <div class="selectLayout">
                <div class="inputItem">
                    <span>{{$t('msg.CorrespondenceAffiliation')}}</span>
                    <div v-if="senderCompanyType" style="display: flex">
                        <!-- selectAndInputType -->
                        <el-select
                            v-model="partyCompany"
                            filterable
                            style="width: 100%"
                            :placeholder="$t('msg.CorrespondenceAffiliation')"
                            v-if="selectAndInputType"
                        >
                            <el-option
                                v-for="(item, index) in companyList"
                                :key="index"
                                @click="senderCompanyChange(item)"
                                :label="item.partyCompany"
                                :value="item.partyId"
                            >
                            </el-option>
                        </el-select>
                        <!-- <el-input
                            placeholder="手动添加来函人单位"
                            v-model="partyCompany"
                          
                        ></el-input> -->
                        <el-autocomplete
                            v-else
                            style="width: 100%"
                            v-model="partyCompany"
                            :trigger-on-focus="false"
                            :fetch-suggestions="querySearchPartyCompany"
                            :debounce="500"
                            :placeholder="$t('msg.Addthesenanually')"
                                  @keyup.enter="onSubmit"
                 :maxlength="250"
                        >
                        <template #suffix>
      {{ partyCompany.length}} / 250
    </template>
                    </el-autocomplete>
                        <el-button type="info" @click="selectAndInputTypeClick"
                            >{{$t('msg.Handoff')}}</el-button
                        >
                    </div>
                    <div v-else class="prBox">
                        <!-- <el-input v-model="partyCompany"></el-input>    :trigger-on-focus="false"-->
                        <el-autocomplete
                            style="width: 100%"
                            v-model="partyCompany"
                            :trigger-on-focus="false"
                            @blur="partyCompanyBlur"
                            :fetch-suggestions="querySearchPartyCompany"
                            :debounce="500"
                             :maxlength="250"
                            :placeholder="$t('msg.Pleaseenterhemployer')"
                                  @keyup.enter="onSubmit"
                        >
                    
                        <template #suffix>
      {{ partyCompany.length}} / 250
    </template></el-autocomplete>
                        <p v-if="!partyCompanyObj.success">
                            {{ partyCompanyObj.msg }}
                        </p>
                    </div>
                </div>
            </div>

            <!-- 第六行   来函人地址 -->
            <div class="selectLayout">
                <div class="inputItem">
                    <span>{{$t('msg.Addresofthsender')}}</span>
                    <div>
                        <!-- <el-input v-model="companyAddress"></el-input> -->
                        <el-autocomplete
                            style="width: 100%"
                            v-model="companyAddress"
                            :trigger-on-focus="false"
                            :debounce="500"
                            :fetch-suggestions="querySearchCompanyAddress"
                            :placeholder="$t('msg.Pleaseentethedresseeaddress')"
                                  @keyup.enter="onSubmit"
 :maxlength="250"
                        >
                        <template #suffix>
      {{ companyAddress.length}} / 250
    </template>
                    </el-autocomplete>
                    </div>
                </div>
            </div>

            <!-- 第七行   邮编   电话 -->
            <div class="inputBox">
                <div class="inputLayout">
                    <div class="inputItem">
                        <span>{{$t('msg.PostcodeoftheAddressee')}}</span>
                        <div>
                            <el-input v-model="senderZipcodeDemo"    @keyup.enter="onSubmit"></el-input>
                        </div>
                    </div>
                    <div class="inputItem">
                        <span>{{$t('msg.Callerphonenumber')}}</span>
                        <div>
                            <!-- <el-input v-model="senderPhone"></el-input> -->
                            <el-autocomplete
                                style="width: 100%"
                                v-model="senderPhone"
                                :trigger-on-focus="false"
                                :debounce="500"
                                :fetch-suggestions="querySearchSenderPhoneList"
                                :placeholder="$t('msg.Pleaseentetheelepsender')"
                                      @keyup.enter="onSubmit"
                            ></el-autocomplete>
                        </div>
                    </div>
                </div>
            </div>

            <!-- 第八行   传真   国籍-->
            <div class="inputBox">
                <div class="inputLayout">
                    <div class="inputItem">
                        <span>{{$t('msg.ThesenderFaxes')}}</span>
                        <div>
                            <el-input v-model="fax"    @keyup.enter="onSubmit"></el-input>
                        </div>
                    </div>
                    <div class="inputItem">
                        <span>{{$t('msg.Nationalityofthewriter')}}</span>
                        <div>
                            <el-select
                                v-model="senderNationality"
                                filterable
                                style="width: 100%"
                                :placeholder="$t('msg.Nationality')"
                                @change="senderNationalityChange"
                            >
                                <el-option
                                    v-for="(
                                        item, index
                                    ) in receiveDetail.senderNationList"
                                    :key="index"
                                    :label="item.dictionaryItemName"
                                    :value="item.dictionaryItemValue"
                                >
                                </el-option>
                            </el-select>
                        </div>
                    </div>
                </div>
            </div>

            <!-- 第九行    省份  登录人-->
            <div class="inputBox">
                <div class="inputLayout">
                    <div class="inputItem">
                        <span>{{$t('msg.Provincfthewrite')}} </span>
                        <div>
                            <el-select
                                :disabled="selectType"
                                v-model="senderProvince"
                                filterable
                                default-first-option
                                :reserve-keyword="true"
                                style="width: 100%"
                                :placeholder="$t('msg.Province')"
                            >
                                <el-option
                                    v-for="(
                                        item, index
                                    ) in receiveDetail.senderProvinceList"
                                    :key="index"
                                    :label="item.dictionaryItemName"
                                    :value="item.dictionaryItemValue"
                                >
                                </el-option>
                            </el-select>
                        </div>
                    </div>
                    <div class="inputItem">
                        <span>{{$t('msg.Persontoin')}}</span>
                        <div>
                            <span>{{ loadingName || '暂无' }}</span>
                        </div>
                    </div>
                </div>
            </div>

            <!-- 第十行  签收人   秘书 -->
            <div class="inputBox">
                <div class="inputLayout">
                    <div class="inputItem">
                        <span>{{$t('msg.Signer')}}</span>
                        <div>
                            <el-input
                                v-model="receiveLetterInfo.subscriber"
                                   @keyup.enter="onSubmit"
                            ></el-input>
                        </div>
                    </div>
                    <div class="inputItem">
                        <span>{{$t('msg.Secretary')}}</span>
                        <div>
                            <span>{{ receiveLetterInfo.userName }}</span>
                        </div>
                    </div>
                </div>
            </div>

            <!-- 第十行  企业分类   是否上市公司 checkBoxTypeTwo-->
            <div class="inputBox" v-if="false">
                <div class="inputLayout">
                    <div class="inputItem">
                        <span>{{$t('msg.Classification')}}</span>
                        <div>
                            <el-select
                                :placeholder="$t('msg.Classification')"
                                v-model="entType"
                                @change="clearText"
                                filterable
                                style="width: 100%"
                            >
                                <el-option
                                    v-for="(item, index) in entTypeList"
                                    @click="getItemName"
                                    :key="index"
                                    :label="item.dictionaryItemName"
                                    :value="item.dictionaryItemValue"
                                >
                                </el-option>
                            </el-select>
                        </div>
                    </div>
                    <div class="inputItem">
                        <span>{{$t('msg.Whetherhecompanyislisted')}} </span>
                        <div>
                            <el-select
                                :placeholder="$t('msg.MaterialType')"
                                v-model="isListedCompany"
                                @change="clearText"
                                style="width: 100%"
                                filterable
                            >
                                <el-option
                                    v-for="(item, index) in isListedCompanyList"
                                    @click="getItemName"
                                    :key="index"
                                    :label="item.dictionaryItemName"
                                    :value="item.dictionaryItemValue"
                                >
                                </el-option>
                            </el-select>
                        </div>
                    </div>
                </div>
            </div>

            <!-- 第十一行   案件编号  待立案编号  -->
            <div class="inputBox">
                <div class="inputLayout">
                    <div class="inputItem">
                        <span>{{$t('msg.case')}}</span>
                        <div>
                            <span>{{ receiveLetterInfo.caseNo }}</span>
                        </div>
                    </div>
                    <div class="inputItem">
                        <span>{{$t('msg.Tobefiledno')}} </span>
                        <div style="position: relative">
                            <el-autocomplete
                                style="width: 100%"
                                v-model="receiveLetterInfo.caseNoCode"
                                :debounce="500"
                                :trigger-on-focus="false"
                                :fetch-suggestions="querySearchCaseNoCode"
                                 @keyup.enter="onSubmit"
                            ></el-autocomplete>
                        </div>
                    </div>
                </div>
            </div>

            <!-- 第十二行  来函内容  -->
            <div class="selectLayout">
                <div class="inputItem">
                    <span>{{$t('msg.Thecontentoftheletter')}}</span>
                    <div>
                        <el-input
                            type="textarea"
                            :placeholder="$t('msg.Pleaseenterthecontent')"
                            v-model="letterContent"
                            maxlength="500"
                            show-word-limit
                               @keyup.enter="onSubmit"
                        >
                        </el-input>
                    </div>
                </div>
            </div>
            <div class="butBox">
                <el-button type="primary" @click="onSubmit">{{$t('msg.Save')}}</el-button>
            </div>
        </el-form>
    </div>
</template> 

<script>
// import dataCom from '../../components/dataCom.vue'
import { dateFormat } from '../../utils/dateFormat'
export default {
    // 引入日期组件
    name: 'receiveDetail',
    props: {
        datas: {
            type: Object
        }
    },
    created() {
        this.loadingName = this.$store.state.loginInfo.realName
    },
  
    data() {
        return {
            form: {
                name: '',
                materialStyle: ''
            },
            receiveLetterInfo: {
                receiveLetterAmount: 5
            },
            receiveDetail: [],
            letterContent: '申请书及证据材料',

            checkListText: [],
            ReceivedDate: '',
            companyList: [],
            // 来函人单位
            senderCompany: '',
            partyCompanyObj: {},
            // 时间相关
            shortcuts: [
                {
                    text: '今天',
                    value: new Date()
                },
                {
                    text: '昨天',
                    value: () => {
                        const date = new Date()
                        date.setTime(date.getTime() - 3600 * 1000 * 24)
                        return date
                    }
                },
                {
                    text: '一周前',
                    value: () => {
                        const date = new Date()
                        date.setTime(date.getTime() - 3600 * 1000 * 24 * 7)
                        return date
                    }
                }
            ],
            senderCompanyType: false,
            newCompanyList: null,
            senderZipcodeDemo: '',
            // 地址
            senderAddress: '',
            // 电话
            senderPhone: '',
            // 当事人类别
            partyType: '',
            // 当事人姓名
            partyName: '',
            // 表id
            partyId: '',
            // 当事人单位
            partyCompany: '',
            // 省
            senderProvince: '',
            // 国别
            senderNationality: '',
            // 传真
            fax: '',
            // 申请人被申请人
            companyTitle: '',
            // 单位地址
            companyAddress: '',
            // 来文方式
            receiveType: '',
            // 登录人
            loadingName: '',
            // 加载效果
            formLoading: false,
            // 不跳转到二维码页面
            goQuery: false,
            // 材料描述
            materialStyleText: '',
            // 是不是要切换到输入框
            selectAndInputType: true,
            // 企业分类
            // entType: '',
            // 企业分类数组
            entTypeList: [],
            // 是否上市公司
            // isListedCompany: '',
            // 是否上市公司数组
            isListedCompanyList: []
        }
    },

    methods: {
        load() {
            // this.updataData()
            this.selectData()
            this.senderCompanyType = false
        },

        // 点击提交
        onSubmit() {
            if (!this.letterContent)
                return this.$message.info('来函人内容为必填')
            if (!this.receiveLetterInfo.receiveLetterDate)
                return this.$message.info('收文日期为必填')
            if (this.receiveLetterInfo.receiveLetterAmount <= 0)
                return this.$message.error('来函份数不能为小于或等于0')
            this.newCompanyList = {
                companyAddress: this.companyAddress,
                companyTitle: this.companyTitle,
                fax: this.fax,
                nationality: this.nationality,
                partyArea: this.partyArea,
                partyCompany: this.partyCompany,
                partyId: this.partyId,
                partyName: this.partyName,
                partyType: this.partyType,
                partyZipCode: this.senderZipcodeDemo,
                phone: this.senderPhone,
                // 企业分类
                entType: this.entType,
                // 是否上市公司
                isListedCompany: this.isListedCompany
            }
            const newForm = {
                // ...this.receiveLetterInfo
                caseId: this.receiveLetterInfo.caseId,
                // 收文id
                receiveLetterId: this.receiveLetterInfo.receiveLetterId,
                // 收文标题
                receiveLetterTitle: this.receiveLetterInfo.receiveLetterTitle,
                // 收文份数
                receiveLetterAmount: this.receiveLetterInfo.receiveLetterAmount,
                // 收文日期
                receiveLetterDate: dateFormat(
                    +new Date(this.receiveLetterInfo.receiveLetterDate)
                ),
                // 材料类型
                materialStyle: this.form.materialStyle,
                // 来文方式
                receiveType: this.receiveType,
                // 来函人姓名
                senderName: this.receiveLetterInfo.senderName,
                // 来函人单位
                senderCompany: this.partyCompany,
                // 来函人地址
                senderAddress: this.companyAddress,
                // 来函人邮编
                senderZipcode: this.senderZipcodeDemo,
                // 来函人电话
                senderPhone: this.senderPhone,
                // 来函人传真
                senderFax: this.fax || null,
                // 来函人国籍
                senderNationality: this.senderNationality,
                // 来函人省份
                senderProvince:
                    this.senderProvince === '省份' ? 0 : this.senderProvince,
                // 签收人
                subscriber: this.receiveLetterInfo.subscriber,
                // 秘书
                seretary: this.receiveLetterInfo.userName,
                // 案件编号
                caseNo: this.receiveLetterInfo.caseNo,
                // 待立案编号
                otherNo: this.receiveLetterInfo.caseNoCode || null,
                // 来函内容
                letterContent: this.letterContent,
                // 材料类型描述
                evidenceDesc: this.letterContent,

                // 单位信息
                // companyList: this.newCompanyList === null ? null : [this.newCompanyList]
                companyList: [this.newCompanyList]
            }
            const res = this.$API.sendRequest('SaveOrUpdate', {
                req: { ...newForm }
            })
            res.then((result) => {
                if (result.success) {
                    if (!this.goQuery) {
                        this.$emit('data', result)
                    } else {
                        this.$emit('goQuery', false)
                    }
                    this.goQuery = false
                    this.$message.success(result.msg)

                    // 判断本地有没有数据,用做下拉记忆存储--来函人单位
                    if (this.partyCompany && !this.senderCompanyType) {
                        this.$GF.localStorageSelectList(
                            'partyCompanyList',
                            this.partyCompany
                        )
                    }
                    // 判断本地有没有数据,用做下拉记忆存储--来函人地址
                    if (this.companyAddress && !this.senderCompanyType) {
                        this.$GF.localStorageSelectList(
                            'companyAddressList',
                            this.companyAddress
                        )
                    }
                    // 判断本地有没有数据,用做下拉记忆存储--来函人地址
                    if (this.receiveLetterInfo.caseNoCode) {
                        this.$GF.localStorageSelectList(
                            'caseNoCodeList',
                            this.receiveLetterInfo.caseNoCode
                        )
                    }
                    // 判断本地有没有数据,用做下拉记忆存储--来函人电话
                    if (this.senderPhone) {
                        this.$GF.localStorageSelectList(
                            'senderPhoneList',
                            this.senderPhone
                        )
                    }
                    this.partyCompanyObj = {
                        success: true,
                        msg: ''
                    }
                    this.selectAndInputType = true
                } else {
                    this.$message.error(result.msg)
                }
            })
        },

        // 来函人单位---记忆下拉框
        querySearchPartyCompany(queryString, callback) {
            // 在这里编写获取补全选项的逻辑
            // queryString 参数为用户输入的内容
            // callback 参数为获取到的补全选项数组，将其作为参数传递给 callback 函数来展示补全选项
            let arr = []
            if (localStorage.getItem('partyCompanyList')) {
                arr = JSON.parse(localStorage.getItem('partyCompanyList'))
            }
            // 示例：模拟异步请求获取补全选项
            callback(arr.filter((item) => item.value.includes(queryString)))
        },

        // 来函人地址---记忆下拉框
        querySearchCompanyAddress(queryString, callback) {
            // 在这里编写获取补全选项的逻辑
            // queryString 参数为用户输入的内容
            // callback 参数为获取到的补全选项数组，将其作为参数传递给 callback 函数来展示补全选项
            let arr = []
            if (localStorage.getItem('companyAddressList')) {
                arr = JSON.parse(localStorage.getItem('companyAddressList'))
            }
            // 示例：模拟异步请求获取补全选项
            callback(arr.filter((item) => item.value.includes(queryString)))
        },

        // 来函人待立案编号---记忆下拉框
        querySearchCaseNoCode(queryString, callback) {
            // 在这里编写获取补全选项的逻辑
            // queryString 参数为用户输入的内容
            // callback 参数为获取到的补全选项数组，将其作为参数传递给 callback 函数来展示补全选项

            let arr = []
            if (localStorage.getItem('caseNoCodeList')) {
                arr = JSON.parse(localStorage.getItem('caseNoCodeList'))
            }
            // 示例：模拟异步请求获取补全选项

            callback(arr.filter((item) => item.value.includes(queryString)))
        },

        // 来函人电话---记忆下拉框
        querySearchSenderPhoneList(queryString, callback) {
            // 在这里编写获取补全选项的逻辑
            // queryString 参数为用户输入的内容
            // callback 参数为获取到的补全选项数组，将其作为参数传递给 callback 函数来展示补全选项

            let arr = []
            if (localStorage.getItem('senderPhoneList')) {
                arr = JSON.parse(localStorage.getItem('senderPhoneList'))
            }
            // 示例：模拟异步请求获取补全选项

            callback(arr.filter((item) => item.value.includes(queryString)))
        },

        // 页面一进渲染下拉框
        async selectData() {
            
            // let param = {
            //     req: {
            //         caseNo: ''
            //     }
            // }
            // const res = await this.$API.sendRequest('DetailInput', param)
            let promisesList = [
                // this.$API.sendRequest('DetailInput', param)
                this.$API.sendRequest('GetDictionaryItems', { req: 4 }),
                this.$API.sendRequest('GetDictionaryItems', { req: 34 }),
                this.$API.sendRequest('GetDictionaryItems', { req: 33 }),
                this.$API.sendRequest('GetDictionaryItems', { req: 30 }),
                // 企业分类下拉框数据
                // this.$API.sendRequest('styleTypeList', { req: 209 }),
                // this.$API.sendRequest('styleTypeList', { req: 219 })
            ]
            let type = await Promise.all(promisesList)
                        // 材料类型 
                        this.receiveDetail.materialStyleList =type[3].data.materialStyleList
            // 国家 
            this.receiveDetail.senderNationList = type[0].data.materialStyleList
            // 省份
            this.receiveDetail.senderProvinceList =type[1].data.materialStyleList
                // 来文方式 
            this.receiveDetail.receiveTypeList =type[2].data.materialStyleList
        
            // // 材料类型 
            // this.receiveDetail.materialStyleList =await this.$API.sendRequest('GetDictionaryItems', { req: 30 }).data.materialStyleList
            // // 国家 
            // this.receiveDetail.senderNationList = await this.$API.sendRequest('GetDictionaryItems', { req: 4 }).data.materialStyleList
            // // 省份
            // this.receiveDetail.senderProvinceList =await this.$API.sendRequest('GetDictionaryItems', { req: 34 }).data.materialStyleList
            //     // 来文方式 
            // this.receiveDetail.receiveTypeList =await this.$API.sendRequest('GetDictionaryItems', { req: 33 }).data.materialStyleList
            // this.entTypeList = type[1].data.materialStyleList
            // this.isListedCompanyList = type[2].data.materialStyleList
        },

        // 点击材料类型事件e
        getItemName() {
            if (!this.receiveDetail.materialStyleList) {
                return
            }
            const materText = this.receiveDetail.materialStyleList.filter(
                (item) => {
                    if (item.dictionaryItemValue === this.form.materialStyle) {
                        return item
                    }
                }
            )[0].dictionaryItemName
            if (this.checkListText.length) {
                this.letterContent =
                    materText + ',' + this.checkListText.join(',')
                this.materialStyleText =
                    materText + ',' + this.checkListText.join(',')
            } else {
                this.letterContent = materText + this.checkListText.join(',')
                this.materialStyleText =
                    materText + this.checkListText.join(',')
            }
        },

        // 下拉框改变清空内容
        clearText() {
            this.checkListText = []
        },

        // 来文方式
        receiveTypeChange() {
            if(!this.receiveDetail.receiveTypeList) return
            this.receiveType = this.receiveDetail.receiveTypeList.filter(
                (item) => {
                    if (item.dictionaryItemValue === this.receiveType) {
                        return item
                    }
                }
            )[0]?.dictionaryItemValue
        },
        // 改变国家默认清空省选择框的内容
        senderNationalityChange() {
            this.senderProvince = ''
        },

        // 切换单位
        senderCompanyChange(item) {
            // 当事人单位
            this.companyAddress = item.companyAddress || ''
            // 电话

            // 邮编
            this.senderZipcodeDemo = item.partyZipCode || ''
            // 省
            // this.form.senderProvince = item.partyArea || '',
            // 当事人类别
            this.partyType = item.partyType || ''
            // 当事人姓名
            this.partyName = item.partyName
            // 表id
            this.partyId = item.partyId
            // 当事人单位
            this.partyCompany = item.partyCompany
            // 地区
            this.senderProvince = item.partyArea === 0 ? '省份' : item.partyArea
            // 国别
            this.senderNationality = item.nationality
            // 传真
            this.fax = item.fax || ''
            // 申请人被申请人
            this.companyTitle = item.companyTitle
            // 来函人地址
            this.companyAddress = item.companyAddress
            // 手机
            this.senderPhone = item.phone
            // 下拉框内容变化后，需要代码更新
            this.newCompanyList = item
        },

        // 清空回显
        updataData() {
            // 清空来函人单位选择器
            this.companyList = ''
            this.senderCompany = ''
            this.senderZipcodeDemo = ''
            // 地址
            this.senderAddress = ''
            // 电话
            this.senderPhone = ''
            // 当事人类别
            this.partyType = ''
            // 当事人姓名
            this.partyName = ''
            // 表id
            this.partyId = ''
            // 当事人单位
            this.partyCompany = ''
            // 地区
            this.senderProvince = ''
            // 国别
            this.senderNationality = 1
            // 传真
            this.fax = ''
            // 申请人被申请人
            this.companyTitle = ''
            // 单位地址
            this.companyAddress = ''
            // 来文方式
            this.receiveType = 2
            // 如果没有id，开始默认是申请书及证据材料
            this.letterContent = '申请书及证据材料'
            this.materialStyleText = '申请书及证据材料'
            // 清空三个小按钮
            this.checkListText = []
            // 数组有变化，先赋值给本地数组
            // this.receiveDetail = this.datas.receiveDetailVo || {}
            this.receiveLetterInfo = this.datas.receiveLetterInfoVo || ''

            // 材料类型
            this.form.materialStyle = 2
            // 来文方式
            this.receiveType = 1
            // 国别
            this.nationality =  1

            // 企业分类
            this.entType = ''
            // 是否上市公司
            this.isListedCompany = ''

            // 如果有id，代表目前处于修改，进行反显操作
            if (this.datas.receiveLetterInfoVo.materialStyle) {
                this.checkListText = []
                // 材料类型有id选第一个
                this.form.materialStyle = 1
                this.letterContent = '咨询函'
                this.materialStyleText = '咨询函'
                this.senderCompanyType = true
                // 地址
                this.senderAddress = this.receiveLetterInfo.senderAddress
                // 邮编
                this.senderZipcodeDemo = this.receiveLetterInfo.senderZipcode
                // 电话
                this.senderPhone = this.receiveLetterInfo.companyList[0]?.phone
                // 当事人类别
                this.partyType =
                    this.receiveLetterInfo.companyList[0]?.partyType
                //  当事人姓名
                this.partyName =
                    this.receiveLetterInfo.companyList[0]?.partyName
                //  表id
                this.partyId = this.receiveLetterInfo.companyList[0]?.partyId
                // 当事人单位
                this.partyCompany =
                    this.receiveLetterInfo.companyList[0]?.partyCompany
                // 国别
                this.senderNationality =
                    this.receiveLetterInfo.senderNationality
                // 省份
                this.senderProvince =
                    +this.receiveLetterInfo.senderProvince === 0
                        ? 1
                        : this.receiveLetterInfo.senderProvince

                //  传真
                this.fax = this.receiveLetterInfo.senderFax
                // 申请人被申请人
                this.companyTitle =
                    this.receiveLetterInfo.companyList[0]?.companyTitle
                //  单位地址
                this.companyAddress =
                    this.receiveLetterInfo.companyList[0]?.companyAddress
                // 电话
                this.senderPhone = this.receiveLetterInfo.companyList[0]?.phone

                // // 企业分类
                this.entType = this.receiveLetterInfo.companyList[0]?.entType
                //   // 是否上市公司
                this.isListedCompany =
                    this.receiveLetterInfo.companyList[0]?.isListedCompany

                // 来文方式
                this.receiveType = this.receiveLetterInfo.receiveType

                // 企业分类
                this.entType = this.receiveLetterInfo.entType
                // 是否上市公司
                this.isListedCompany = this.receiveLetterInfo.isListedCompany
                // 执行调用调用下拉框函数
                this.getItemName()
                // 来文方式改变执行函数
                this.receiveTypeChange()
                this.companyList = this.receiveLetterInfo.companyList
                this.senderProvince = this.receiveLetterInfo.senderProvince
                this.receiveLetterInfo.senderAddress =
                    this.receiveLetterInfo.companyList[0]?.companyAddress
                this.senderCompany =
                    this.receiveLetterInfo.companyList[0]?.partyId
                this.newCompanyList = {
                    ...this.receiveLetterInfo.companyList[0]
                }
            }
        },
        clickUpdataData() {
            this.receiveLetterInfo.receiveLetterId = null
            this.receiveLetterInfo.receiveLetterTitle = ''
            this.receiveLetterInfo.receiveLetterAmount = ''
            this.receiveLetterInfo.senderName = ''
            this.receiveLetterInfo.subscriber = ''
            this.receiveLetterInfo.caseNo = ''
            this.receiveLetterInfo.caseNoCode = ''
            this.receiveLetterInfo.userName = ''
            // 清空来函人单位选择器
            this.companyList = ''
            this.senderCompany = ''
            this.senderZipcodeDemo = ''
            // 地址
            this.senderAddress = ''
            // 电话
            this.senderPhone = ''
            // 当事人类别
            this.partyType = ''
            // 当事人姓名
            this.partyName = ''
            // 表id
            this.partyId = ''
            // 当事人单位
            this.partyCompany = ''
            // 地区
            this.senderProvince = ''
            // 国别
            this.senderNationality = 1
            // 传真
            this.fax = ''
            // 申请人被申请人
            this.companyTitle = ''
            // 单位地址
            this.companyAddress = ''
            // 来文方式
            // this.receiveType = 2,
            this.letterContent = '申请书及证据材料'

            // 企业分类
            this.entType = ''
            // 是否上市公司
            this.isListedCompany = ''

            // 数组有变化，先赋值给本地数组
            // this.receiveDetail = this.datas.receiveDetailVo || ''
            this.receiveLetterInfo = this.datas.receiveLetterInfoVo || ''

            // 材料类型
            this.form.materialStyle = 2
            // 来文方式
            this.receiveType =this.receiveDetail.receiveTypeList[0].dictionaryItemValue
            // 国别
            this.nationality = this.receiveDetail.senderNationList[0].dictionaryItemValue
        },

        // 点击拷贝事件
        copyDetail(res) {
            this.receiveLetterInfo.receiveLetterId = res.receiveLetterId || null
        },
        // 在查询页点击修改后的方法/
        async modifyDetail(id) {
            this.formLoading = true
            let param = {
                req: id
            }
            const { data } = await this.$API.sendRequest(
                'ReceoveCopyAndUpdInfo',
                param
            )

            // this.receiveLetterInfo.receiveLetterId = null
            this.receiveLetterInfo.receiveLetterId =
                data.receiveLetterId || null //收文id
            this.receiveLetterInfo.receiveLetterTitle =
                data.receiveLetterTitle || '' //收文标题
            this.receiveLetterInfo.receiveLetterAmount =
                data.receiveLetterAmount || '' //收文份数
            this.receiveLetterInfo.receiveLetterDate =
                data.receiveLetterDate || '' //收文日期
            this.receiveLetterInfo.caseNoCode = data.otherNo || '' //待立案编号
            this.receiveType = data.receiveType || '' //来文方式
            this.receiveLetterInfo.senderName = data.senderName || '' //来函人姓名
            this.form.materialStyle = data.materialStyle || '' //材料类型id
            this.receiveLetterInfo.userName = data.userName || '' //立案秘书
            this.companyList = data.companyList || '' //来函人单位
            this.companyAddress = data.senderAddress || '' //来函人地址
            this.senderZipcodeDemo = data.senderZipcode || '' //来函人邮编
            this.senderPhone = data.senderPhone || '' //来函人手机
            this.fax = data.senderFax || '' //来函人传真
            this.senderNationality = data.senderNationality || '' //来函人国籍
            this.senderProvince = data.senderProvince || '' //来函人省份
            this.receiveLetterInfo.subscriber = data.subscriber || '' //签收人
            this.receiveLetterInfo.caseNo = data.caseNo || '' //案件编号
            this.caseId = data.caseId || '' //案件id
            this.letterContent = data.letterContent //来函人内容
            this.formLoading = false

            this.partyCompany = data.senderCompany
            this.materialStyleText =
                data.evidenceDesc === null
                    ? this.letterContent
                    : data.evidenceDesc
            this.entType = data.entType || '' //企业分类
            this.isListedCompany = data.isListedCompany || '' //是否上市公司
        },

        // 来喊人单位失焦查重
        async partyCompanyBlur() {
            let type = !this.partyCompany.trim()
            if (type) {
                this.partyCompanyObj.success = true
                return
            }
            let param = {
                req: this.partyCompany.trim()
            }
            let res = await this.$API.sendRequest('BlurSenderCompany', param)
            this.partyCompanyObj = res
        },

        // 点击切换按钮
        selectAndInputTypeClick() {
            this.partyCompany = ''
            this.selectAndInputType = !this.selectAndInputType
        },

       
    },
   

    // 计算属性
    computed: {
        selectType() {
            return this.senderNationality !== 1
        },

        checkBoxType() {
            let type =
                this.form.materialStyle === 2 ||
                this.form.materialStyle === 9 ||
                this.form.materialStyle === 17
            return type
        },

        // 企业分类   是否上市公司是否显示
        checkBoxTypeTwo() {
            let type = this.form.materialStyle === 2
            return type
        },

        // 需要的更多的数组
        checkList() {
            if (this.form.materialStyle === 2) {
                // 申请人及证据材料
                return [
                    '证据目录',
                    '申请人身份证',
                    '营业执照',
                    '法定代表人身份证明书',
                    '法定代表人身份证/护照',
                    '执行事务合伙人营业执照',
                    '执行事务合伙人证明书',
                    '执行事务合伙人身份证/护照',
                    '授权委托书',
                    '律所函',
                    '律师证复印件',
                    '代理人身份证复印件',
                    '代理人劳动合同复印件',
                    '在职证明',
                    '准予变更登记通知书',
                    '被申请人营业执照',
                    '被申请人工商信息',
                    '被申请人身份证复印件',
                    '申请人文件送达地址',
                    '被申请人文件送达地址',
                    '财产保全',
                    '财产线索',
                    '保单保函',
                    '保险公司主体材料',
                    '网络查控申请书',
                    '法院地址',
                    '延期送达申请书',
                    '民事裁定书',
                    '线上开庭申请书',
                    '指定仲裁员名单',
                    '协商期的说明'
                ]
            } else if (this.form.materialStyle === 9) {
                // 财产保全
                return [
                    '财产线索',
                    '保单保函',
                    '保险公司主体材料',
                    '民事裁定书'
                ]
            } else {
                // 补充材料
                return [
                    '申请书及证据材料',
                    '证据目录',
                    '申请人身份证',
                    '营业执照',
                    '法定代表人身份证明书',
                    '法定代表人身份证/护照',
                    '执行事务合伙人营业执照',
                    '执行事务合伙人证明书',
                    '执行事务合伙人身份证/护照',
                    '授权委托书',
                    '律所函',
                    '律师证复印件',
                    '代理人身份证复印件',
                    '代理人劳动合同复印件',
                    '在职证明',
                    '被申请人营业执照',
                    '被申请人工商信息',
                    '被申请人身份证复印件',
                    '申请人文件送达地址',
                    '被申请人文件送达地址',
                    '财产保全',
                    '财产线索',
                    '保单保函',
                    '保险公司主体材料',
                    '网络查控申请书',
                    '法院地址',
                    '延期送达申请书',
                    '民事裁定书',
                    '线上开庭申请书',
                    '指定仲裁员名单',
                    '协商期的说明',
                    '网上补充材料'
                ]
            }
        }
    },

    
    // 监听属性
    watch: {
        datas:async function () {
            this.caseId = this.datas.caseId
            // await this.updataData()
            console.log('watch1111111111111111');
            // await this.selectData()
            await this.updataData()
        }
    }
}
</script>



<style lang="scss" scoped>
@media (max-width: 1300px) {
    /* 停止响应式布局的样式规则 */
    body {
        /* 设置固定宽度和居中对齐 */
        width: 100%;
        margin: 0 auto;
    }

    .titleText {
        width: 878px;
    }

    /* 可以针对特定元素做样式调整 */
    .bigBox {
        /* 自定义样式 */
        // background-color: aqua;
        width: 646px;
    }

    .inputLayout {
        display: flex;

        .inputItem {
            display: flex;
        }
    }

    /* 还可以针对其他元素做样式调整 */
    /* ... */
}

.bigBox {
    width: 100%;
    padding: 10px 10px 0 10px;
}

.titleText {
    margin-bottom: 5px;
}

.inputLayout {
    display: flex;
    margin-bottom: 6px;

    .inputItem {
        margin-right: 20px;
        flex: 1;
        display: flex;
        justify-content: space-between;
        align-items: center;

        div {
            flex: 4;
        }

        span {
            display: flex;
            flex: 1;
        }
    }
}

.selectLayout {
    margin-bottom: 1vw;

    .inputItem {
        display: flex;

        span {
            flex: 1;
            display: flex;
        }

        div {
            flex: 9;
            margin-right: 20px;
        }
    }
}

:deep(.el-textarea__inner) {
    min-height: 100px !important;
}

// :deep(.el-button) {
//     background-color: #79bbff;
// }

.prBox {
    position: relative;

    p {
        position: absolute;
        top: 62%;
        font-size: 13px;
        color: red;
    }
}
</style>