/**
 * Created by Leo
 * Date: 2022-05-19 10:43
 * Email: asdfpeng@qq.com
 */
// 1. Ready translated locale messages
// The structure of the locale message is the hierarchical object structure with each locale as the top property
const messages = {
  en: {
    msg: {
      hello: "coming soon",
      lang: "中文",
      UnitName: "CIETAC",
      login_account_type: "Account",
      login_wechat_type: "Wechat",
      login_username: "Phone/Email",
      login_password: "Password",
      login_forget: "forget",
      login_verify_code: "Verify Code",
      login_btn: "Login",
      register_btn: "Register",
      register_type_mobile: "Mobile",
      register_type_email: "Email",
      register_mobile_no: "mobileNo",
      register_mobile_no_tip: "mobileNo required",
      register_rec_code: "recCode",
      register_rec_code_tip: "recCode required",
      register_email: "email",
      register_email_tip: "email required",
      register_real_name: "realName",
      register_real_name_tip: "realName required",
      register_address: "address",
      register_address_tip: "address required",
      register_tel_no: "idCard",
      register_tel_no_tip: "idCard required",
      register_pass: "password",
      register_pass_tip: "password required",
      register_confirm: "confirm",
      register_confirm_tip: "again",
      register_now: "register now",
      register_to_login: "Go login",
      register_agree: "I agree",
      register_service_agreement: "《service agreement》",
      found_forget_pass: "forget pass",
      found_verify: "verify",
      found_set_new: "set new",
      found_new: "new pass",
      found_by_mobile: "found_by_mobile",
      found_by_email: "found_by_email",
      found_next: "next",
      found_prev: "prev",
      register_safe: "check",
      register_send: "send",
      register_resend: "resend",
      header_setting: "setting",
      home_workbench: "workbench",
      home_case: "case",
      home_dispatch: "receive and dispatch",
      home_analyze: "analyze",
      bread_current: "current",
      case: "case",
      case_list: "case_list",
      case_num: "case number",
      bind_num: "bind number",
      case_search: "search",
      case_reset: "reset",
      case_bind: "bind",
      case_bind_dialog_title: "Bind Case",
      case_bind_cancel: "Cancel",
      case_bind_confirm: "Confirm",
      case_my: "my case",
      case_name: "case name",
      case_status: "case status",
      case_content: "case content",
      case_create_time: "create time",
      case_in_progress: "in progress",
      finder_upload: "upload",
      finder_create_folder: "create folder",
      finder_folder_name: "name",
      finder_search: "Type something",
      finder_table_name: "name",
      finder_table_type: "type",
      finder_table_size: "size",
      finder_table_update: "update time",
      finder_table_power: "power",
      finder_upload_tip: "drag file here, or click",
      finder_no_data: "no data",
      finder_count: "count",
      finder_size: "size",
      finder_selected_count: "selected",
      setting_personal: "base info",
      setting_pass: "update pass",
      setting_pass_now: "old pass",
      workbench_print: "Print",
      workbench_calendar: "Calendar",
      workbench_timer: "Timer",
      workbench_tip: "Tip",
      workbench_more: "more",
      workbench_todo: "TODO",
      workbench_case_tip: "Case Tip",
      workbench_tool: "Tool",
      workbench_search_info: "info",
      workbench_search_no: "caseNo",
      other_link1: "CIETAC",
      other_link2: "Online",
      other_link3: "Court",
      other_link4: "DNDRC",
      other_link5: "online arbitration",
      not_sign: "not_sign",
      have_sign: "have_sign",
      S_N: "S/N",
      Application_Number: "Application number",
      WithdrawACase: "Withdraw a case",
      Receipt: "Receipt",
      // 首页
      NewCase: "New case",
      AddaCase: "Add a case",
      Inform: "Inform",
      Directive: "Directive",
      Notice: "Notice",
      OperationInstructions: "Operation Instructions",

      HistoricalCaseSearch: "Historical Case Search",
      RemunerationInquiry: "Remuneration Inquiry",
      CalendarOfHearings: "Calendar Of Hearings",
      InstrumentTemplateDownload: "Instrument Template Download",
      // 主页面列表头部
      Claimant: "Claimant",
      Respondent: "Respondent",
      Secretary: "Secretary",
      TelephoneNumberofSecretary: "Telephone number of Secretary",
      GenerateaPDFArbitrationApplication:
        "Generate a PDF arbitration Application",
      GeneratePDF: "Generate PDF",
      ReasonForWithdraw: "Reason for Withdraw",
      PleaseEnterTheReasonForReasonForWithdraw:
        "Please enter the reason for Reason for Withdraw",
      Reason: "Reason",
      ConfirmtheDismissaloftheCase: "Confirm the dismissal of the case",
      ProcessingTime: "Processing Time",
      Arbitrator: "Arbitrator",
      //案件状态
      SubmitApplication: "Submit Application",
      FormationofCourt: "Formation of Court",
      CaseSecretary: "Case Secretary",
      // 新建案件
      Case: "Case",
      NoCaseNumberisAvailable: "No Case Number is Available",
      ViewingErrors: "Viewing errors",
      MessageBoard: "Message Board",
      DeleteTheCurrentCase: "Delete the Current Case",
      SubmittoCIETAC: "Submit to CIETAC",
      ApplicationNumber: "Application Number",
      Party: "Party",
      Applicant: "Applicant",
      TheDefendant: "The Defendant",
      AddTheDefendant: "Add The Defendant",
      AgentofTheApplicant: "Agent of The Applicant",
      AgentoftheRespondent: "Agent of the Respondent",
      AddanApplicant: "Add an Applicant",
      AddAgentofTheApplicant: "Add Agent of The Applicant",
      AddAgentoftheRespondent: "Add Agent of the Respondent",
      AddApplicantInformation: "Add Applicant Information",
      AddTheDefendantInformation: "Add The Defendant Information",
      PartyType: "Type",
      TypeofRespondent: "Type of Respondent",
      Name: "Name",
      NameofCountryRegion: "Name of country/region",
      IDType: "ID Type",
      IDNo: "ID No",
      DomicileDocumentAddress: "Domicile/Document Address",
      Company: "Company",
      LegalPersonCountryRegion: "Legal Person Country/Region",
      LegalEntityType: "Legal Entity Type",
      Pleaseselectrdingtothebusinesslicense:
        "Please select the legal person classification according to the business license",
      ForeignFactors: "Foreign Factors",
      Classification: "Classification",
      UnifiedSocialCreditCodeBusinessLicenseNo: "Unified Social Credit Code",
      Businesslicensenumber: "Business license number",
      Residence: "Residence",
      BusinessLicenseAddress: "Business License Address",
      OrganizationName: "Organization Name",
      TaxpayerIdentificationNumber: "Taxpayer Identification Number",
      Firm: "Firm",
      Operator: "Operator",
      Addressbusinesslicenseaddress: "Address (business license address)",
      Certificateofidentityoflegalrepresentative:
        "Certificate of identity of legal Representative",

      // 代理人
      Addapplicantagentinformation: "Add applicant agent information",
      Client: "Client",
      AgentsName: "Agent's Name",
      Nationality: "Nationality",
      ContactNumber: "Contact Number",
      SelectAddress: "Select Address",
      Overseas: "Overseas",
      WhetherOverseas: "Whether Overseas",
      ConfirmtheAddress: "Confirm the Address",

      //送达地址
      AddressForService: "Address For Service",
      PersonServingaDocument: "Person Serving a Document",
      Recipients: "Recipients",
      TelephoneNumberforDelivery: "Telephone Number for Delivery",
      Email: "Email",
      DetailedAddress: "Detailed Address",
      ApplicantsAddressofService: "Applicant is Address of Service",
      ServiceAddressoftheRespondent: "Service Address of the Respondent",
      // 仲裁依据
      ArbitrationBasis: "Arbitration Basis",
      AddArbitrationBasis: "Add Arbitration Basis",
      ContractName: "Contract Name",
      SigningDate: "Signing Date",
      ContractNo: "Contract No.",
      ArbitrationClause: "Arbitration Clause",
      TypeofSigningDate: "Type of Signing Date",
      SigningPlace: "Signing Place",
      YearMonthDay: "Year Month Day",
      YearMonth: "Year Month",

      //仲裁请求
      ArbitrationClaims: "Arbitration Claim",
      FactsandReasons: "Facts and Reasons",
      ViewFactsandReasons: "View Facts and Reasons",
      ViewArbitrationClaim: "View Arbitration Claim",
      AddarbitrateRequests: "Add/Arbitrate Requests",
      AddmodifyanArbitrationRequest: "Add/modify an Arbitration Request",
      TypeofAmount: "Type of Amount",

      //保全
      ConservatoryMeasures: "Conservatory Measures",
      AddConservatoryMeasures: "Add Conservatory Measures",
      PreservationType: "Preservation Type",
      CourtName: "Court Name",
      CourtAddress: "Court Address",
      Application: "Application",
      PleaseenterApplication: "Please enter Application",

      //申请人证据材料
      ApplicantsEvidentiaryMaterials: "Applicant is Evidentiary Materials",
      Annex: "Annex",
      AddApplicantMaterials: "Add Applicant Materials",
      EvidenceName: "Evidence Name",
      EvidencePurpose: "Evidence Purpose",

      // 留言板--日志--提交贸仲
      LeaveAMessage: "Leave a message",
      SelectSubmittoHeadquartersChapters:
        "Select Submit to Headquarters/Chapters",
      YourCaseNumberIs: "Your Case Number Is",
      KeepinMind: "Keep in Mind",
      AddaMessage: "Add a Message",
      Time: "Time",
      Publisher: "Publisher",
      Content: "Content",
      PleaseentertheMessageContent: "Please enter the Message Content",

      // 没数据提示
      ApplicantInformationHasnotBeenAdded:
        "Applicant Information Has not Been Added",
      ApplicantAgentInformationHasNotBeenAdded:
        "Applicant Agent Information Has Not Been Added",
      TheInformationofTheRespondentIsAgentHasNotBeenAdded:
        "The Information of The Respondent Is Agent Has Not Been Added",
      TheCurrentDataisEmpty: "The Current Data is Empty",
      ThereIsNoRequestForArbitration: "There Is No Request For Arbitration",
      ThereArenoFactsorReasons: "There Are no Facts or Reasons",
      NoMaterialatPresent: "No Material at Present",

      // 侧边栏
      CommonProblem: "Common Problem",
      FeeCalculator: "Fee Calculator",
      GuidetoRegistration: "Guide to Registration",
      GuidelinesforFiling: "Guidelines for Filing",
      GuidelinesonHandlingCases: "Guidelines on Handling Cases",
      CourtTrialGuidelines: "Court Trial Guidelines",
      ApplyforBilling: "Apply for Billing",
      ApplyforRefund: "Apply for Refund",
      NotesonApplicationforArbitration: "Notes on Application for Arbitration",

      // -----------当事人管理
      //庭审
      OnlineCourtHearing: "Online hearing",
      AccessToTheCourtroom: "Access To The Courtroom",
      PleaseEnteraCourtroomCode: "Please Enter Courtroom Code",
      //发票申请
      AmountOfMoney: "Amount Of Money",
      DetailsofInvoiceApplication: "Details of Invoice Application",
      TypeOfTicket: "Type Of Ticket",
      TypeOfExpense: "Type Of Expense",
      AmountToBeInvoiced: "Amount To Be Invoiced",
      AmountApplied: "Amount Applied",
      ApplicationAmountExchangeRatetoRMB:
        "Application Amount (Exchange Rate to RMB)",
      Applicantts: "Applicant",
      TaxCode: "Tax Code",
      CompanyRegisteredAddress: "Company Registered Address",
      InvoiceHeaderPhone: "Invoice Header Phone",
      AccountOpeningBank: "Account Opening Bank",
      BankAccount: "Bank Account",
      RefundamountRMB: "Refund amount (RMB)",
      Telephonenumberofrecipient: "Telephone number of recipient",
      InvoicingInstructions: "Invoicing Instructions",
      Addadescriptionofmakeoutaninvoice:
        "Add a description of make out an invoice",
      Theuploadformatis: "The upload format is",
      BillingInstructionsPrecautions: "Billing Instructions Precautions",
      NoteThereisnoinvoicingquota: "Note: There is no Invoicing Quota",
      Billinginstretheattachmentshauploathefol:
        "Billing instructions (Note: the attachment shall be uploaded for the following two cases)",
      Ifthedrawhangeitshalldregistration:
        "If the drawer changes its name, it shall upload the notice of approval of change (record) registration;",
      Anawardpagetheamounofthrbitrfeaded:
        "An award page with the amount of the arbitration fee should be uploaded",

      // 电子收据下载
      ElectronicReceiptdownload: "Electronic Receipt download",
      NameofPayer: "Name of Payer",
      Manager: "Manager",
      PaymentItems: "Payment Items",
      amountInWords: "Amount in words",
      AmountInFigures: "Amount in figures",
      Note: "Note",
      BillNumber: "Bill Number",
      NoteBillNumber: "Note Bill Number",

      // 退费信息补录
      Refundinformationissupplemented: "Refund information is supplemented",
      RMB: "RMB",
      foreignCurrency: "Foreign Currency",
      PayeeAddress: "Payee Address",
      PleaseenterEnglish: "Please enter English",
      AddressofReceivingBank: "Address of Receiving Bank",
      SubmissionofaSupplement: "Submission of a Supplement",
      NameOfRecipientsAccount: "Name Of Recipient's Account",
      RecipientsAccount: "Recipient's Account",
      Addressofthepayeeisopeningbank: "Address of the Payee is Opening Bank",

      // 裁决书签字
      SignatureStatus: "Signature Status",
      Tobesigned: "To be signed",
      HaveBeenSigned: "Have Been Signed",
      CaseManager: "Case Manager",
      Award: "Award",
      Declaration: "Declaration",
      ArbitrationLanguage: "Arbitration language",
      ProcedureRules: "Procedure Rules",
      TypeOfDispute: "Type Of Dispute",
      AmountinDispute: "Amount In Dispute",
      ViewTheAward: "View The Award",
      ViewDeclaration: "View Declaration",
      MySignature: "My Signature",
      IhaveCarefullyReadthisAwardandagreetoSignit:
        "I have carefully read this award and agree to sign it",

      // 扫描文件管理
      ElectronicDossier: "Electronic Dossier",
      Sortbytime: "Sort by Time",
      sortbyType: "sort by Type",
      Sort: "Sort",
      InReverseOrder: "In Reverse Order",
      PositiveSequence: "Positive Sequence",
      PartyMaterial: "Parties’ Materials",
      ThereisnoScannedVersion: "There is no Scanned Version",
      Clicktoidentify: "Click to identify",
      ElectronicVersion: "Electronic Version",
      ThereisnoElectronicVersionYet: "There is no Electronic Version Yet",
      IssuanceOfCommunicationsByTheCaseManager:
        "Issuance Of Communications By The Case Manager",

      ReceiptletterNumber: "Receipt letter Number",
      DateofReceipt: "Date Of Receipt",
      Nofiles: "No Files",
      Photocopy: "Photocopy",
      IssueletterNumber: "Issue letter Number",
      PublicationDate: "Publication Date",
      Material: "Material",
      Transfer: "Transfer",
      TheCurrentDirectory: "The Current Directory",
      TheDirectoryAftertheChange: "The Directory After the Change",

      MaterialType: "Material Type",
      Alteration: "Alteration",

      // 声明书披露
      Identity: "Identity",
      AppointedDate: "Appointed Date",
      Disclosure: "Disclosure",

      IdeclarethatalthoughIconsiderthattherearenoArbitrationRulesoftheChinaInternationalEconomic:
        "I declare that although I consider that there are no Arbitration Rules of the China International Economic and Trade Arbitration Commission before accepting the appointment of proxyAnd the circumstances under which withdrawal is required as stipulated in the Code of Arbitrators of the China International Economic and Trade Arbitration Commission,And guarantee to perform the duties of arbitrator independently, impartially, efficiently and diligently, but in view of my obligationsThe agent of the subject/party has the following circumstances that may cause the party to doubt his impartiality or independence, so he shall disclose them",

      // 上传裁决书
      Nameofculture: "Name of culture",
      DateofUpload: "Date of Upload",

      //延载信息
      DelayCuttingInformation: "Delay Cutting Information",
      NatureoftheCase: "Nature of the case",
      ApplicationDate: "Application Date",
      NumberofDeferred: "Number of Deferred",
      OriginalHearingDeadline: "Original Hearing Deadline",
      NewTrialDeadlines: "New Trial Deadlines",

      // 开庭信息
      OpenaCourtSessionInformation: "Open a Court Session Information",
      LawfulDay: "Lawful day",
      PlaceOfHearing: "Place Of Hearing",
      SittingRoom: "Sitting Room",
      HearingStatus: "Hearing Status",

      // 仲裁员个人信息
      Modificationofarbitratorspersonalinformation:
        "Modification of arbitrator is personal information",
      ModifyPhotos: "Modify Photos",
      EnglishName: "English Name",
      DateOfBirth: "Date of Birth",
      EnglishNationality: "English Nationality",
      City: "City",
      EnglishCity: "EnglishCity",
      EnglishCompany: "English Company",
      EducationAttainment: "Education Attainment",
      EnglishEducationAttainment: "English Education Attainment",
      OccupationalClassification: "Occupational Classification",
      EnglishOccupationalClassification: "English Occupational Classification",
      EffectiveCommunicationAddress: "Effective Communication Address",
      EnglishEffectiveCommunicationAddress:
        "English Effective Communication Address",

      Title: "Title",
      EnglishTitle: "English Title",
      WorkUnitAddress: "Work Unit Address",
      EnglishWorkUnitAddress: "English Work Unit Address",
      Position: "Position",
      EnglishPosition: "English Position",
      EducationalBackground: "Educational Background",
      EnglishEducationalBackground: "English Educational Background",

      WorkExperience: "Work Experience",
      EnglishWorkExperience: "English Work Experience",
      ArbitrationRelatedWorkExperience: "Arbitration related work experience",
      EnglishArbitrationRelatedWorkExperience:
        "English Arbitration related work experience",
      SocialTeamPartTimeJobSituation: "Social team part-time job situation",
      EnglishSocialTeamPartTimeJobSituation:
        "English Social team part-time job situation",
      BusinessExpertise: "Business Expertise",
      EnglishBusinessExpertise: "English Business Expertise",

      WorkingLanguage: "Working Language",
      EnglishWorkingLanguage: "English Working Language",
      MainBusinessAchievements: "Main Business Achievements",
      EnglishMainBusinessAchievements: "English Main Business Achievements",
      SelfDescriptionOrEvaluation: "Self Description Or Evaluation",
      EnglishSelfDescriptionOrEvaluation:
        "English Self Description Or Evaluation",

      MobilePhoneNumber: "Mobile Phone Number",
      PhoneNumber: "Phone Number",
      Fax: "Fax",
      HomePhoneNumber: "Home Phone Number",
      SavetheMessage: "Save the Message",

      // 微信
      BindWeChat: "Bind WeChat",
      ClickBind: "Click Bind",
      Clickunbind: "Click unbind",
      Wechatmessage: "Wechat Message",
      RealNameAuthentication: "Real-name Authentication",
      NotYetAuthenticated: "Not Yet Authenticated",

      // 主页面
      GuidelinesforFilingArbitrationCases:
        "Guidelines for Filing Arbitration Cases",
      Apartymaysubmitanapplicationforarbitrationinthreeways:
        "A party may submit an application for arbitration in three ways: offline filing, mailing filing and online filing. All three methods do not require an appointment",
      Thepartieschooseofflinefilingandmailingfilingpleasereferto:
        "The parties choose offline filing and mailing filing, please refer to",
      Instructionsforarbitration: "Instructions for arbitration",
      Onlinefilingguide: "Online filing guide",
      PrepareyourfilingForonlinefilingguidelinespleasereferto:
        "Prepare your filing. For online filing guidelines, please refer to",
      Submitanapplicationforarbitration:
        "Submit an application for arbitration",
      Summaryoffrequentlyaskedquestions:
        "Summary of frequently asked questions",
      Documentsrequiredtoapplyforarbitration:
        "Documents required to apply for arbitration",
      RefundofArbitrationFeesforWithdrawalofCase:
        "Refund of Arbitration Fees for Withdrawal of Case",
      GuidelinesforVideoTrial: "Guidelines for Video Trial",
      Issueaninvoiceforthearbitrationfee:
        "Issue an invoice for the arbitration fee",

      // 输入框名称
      PleaseEnteraName: "Please Enter a Name",
      PleaseSelect: "Please Select",
      PleaseEnterTheIDNumber: "Please Enter The ID Number",
      PleaseEnterResidenceDocumentAddress:
        "Please Enter Residence/Document Address",
      Pleaseenterthenameoftheorganization:
        "Please enter the name of the organization",
      Pleaseenterthebusinesslicensenumber:
        "Please enter the business license Number",
      Pleaseenterresidencebusinesslicenseaddress:
        "Please enter Residence (Business license Address)",
      PleaseenteranorganizationName: "Please enter an organization Name",
      PleaseEntertheOrganizationCode: "Please Enter the Organization Code",
      Pleaseenterthebusinessname: "Please enter the business Name",
      Pleaseenteroperator: "Please enter Operator",
      PleaseEntertheAgentName: "Please Enter the Agent Name",
      PleaseenteryourcontactNumber: "Please enter your contact Number",
      PleaseenteryourEmailAddress: "Please enter your Email Address",
      PleaseentertheAddressDetails: "Please enter the Address Details",
      PleaseEntertheContractName: "Please enter the Contract Name",
      PleaseentertheArbitrationClause: "Please enter the Arbitration Clause",
      SelecttheYearandMonth: "Select the Year and Month",
      SelecttheyearmonthandDay: "Select the Year, Month, and Day",
      Optionalyear: "Optional year",
      PleaseentertheContractNumber: "Please enter the Contract Number",
      PleaseenterthePlaceWhereyouSignedtheContract:
        "Please enter the Place Where you Signed the Contract",
      PleaseentertheNameofCourt: "Please enter the Name of Court",
      PleaseentertheCourtAddress: "Please enter the Court Address",
      PleaseentertheRecipient: "Please enter the Recipient",
      PleaseenteryourMobilePhoneNumber: "Please enter your Mobile Phone Number",
      PleaseentertheDeliveryAddress: "Please enter the Delivery Address",
      Pleaseenterhecasenumber: "Please enter the case number",
      Pleaseenterthecasename: "Please enter the case name",
      Pleaseenterthehandler: "Please enter the handler",
      Pleaseenterarbitrator: "Please enter arbitrator",

      // 提示文本
      Article21ofthecETACArbitranformation:
        'Article 21 of the current CIETAC Arbitration Rules (2024) stipulates that "in the event of any inconsistency between the electronic version and the paper version, the electronic version shall prevail, unless otherwise agreed by the parties." Therefore, please carefully and accurately enter the relevant information.',

      WhenuploadingafilepleasechangethenameofthefiletobeploadedtothenameofthecorrespondingmaterialuchasIDcardbusinesslicenseetc:
        "When uploading a file, please change the name of the file to be uploaded to the name of the corresponding material, such as ID card, business license, etc",
      Iftheapplicantisanaturalersonhesheshouldsubmitscannedcopiesuc:
        "If the applicant is a natural person, he/she should submit scanned copies of the front and back of his/her identity card or scanned copies of other valid identity documents, such as Hong Kong and Macao Return certificate, Hong Kong Resident Certificate, Taiwan Compatriot certificate and passport",
      IftheapplicantisalegalpersoninChinesemainlandascannedcopyofavalidbusinesslicenseandthesubmitted:
        "If the applicant is a legal person in Chinese mainland, a scanned copy of a valid business license and the original certificate of legal representative should be submitted",
      IftheapplicantisalegalpersonfromHongKongMacaoTaiwanoraforeigncountry:
        "If the applicant is a legal person from Hong Kong, Macao, Taiwan or a foreign country, it shall submit a valid business registration certificate and other documents issued by the corresponding authority in the place where the party is registered; If you submit relevant documents in a language other than English, please also provide translations that are consistent with the language of the arbitration in this case",
      Alldocumentssubmittedbythepartiesdonotneedtobenotarizedathetribunal:
        "All documents submitted by the parties do not need to be notarized at the stage of case filing, and if the other party raises objections or the arbitral tribunal deems it necessary, it may be necessary to notarize the evidence formed abroad during the arbitration proceedings",
      Wherethereischangeinthenameofthepartiesrelevantevidenceofthenamechangeshallbesubmitted:
        "Where there is a change in the name of the parties, relevant evidence of the name change shall be submitted",
      ReferenceTemplate: "Reference Template",
      TemplateofPowerofAttorney: "Template of Power of Attorney",
      Ifthereisanaturalpersoninvolcontractlenancbusiness:
        "If there is a natural person involved in the contract, please inform the natural person in writing whether there is any circumstance under Article 13 of the Interpretation (I) of the Supreme People is Court on Several Issues Concerning the Application of the <Law of the People is Republic of China on the Application of laws in Civil Relations involving foreign Affairs>Law (see quoted below), so as to confirm the applicable procedures in this case. [Article 13: Where natural persons have resided continuously for more than one year at the time of the creation, modification, or termination of foreign-related civil relationships, and are the center of their lives, the people is courts may designate them to be the habitual residence of natural persons as provided for in the Law on the Application of Laws to Foreign-Related Civil Relations, except in circumstances such as medical treatment, labor dispatch, or official business. 】",

      // 被申请人
      Whenuploadingafilepleaseheofthefileslicenseetc:
        "When uploading a file, please change the name of the file to be uploaded to the corresponding material name, such as ID card, business license, etc;",
      Iftheapplicantisnapersoncopyofhisandpassport:
        "If the applicant is a natural person, the applicant may submit a copy of his/her identity card or a scanned copy of other valid identity documents, such as Hong Kong and Macao Return certificate, Hong Kong resident identity card, Taiwan Compatriot certificate and passport;",
      IftherespondentisCionslatformssuchasQichacha:
        "If the respondent is a legal person in Chinese mainland, the applicant shall submit relevant documents for the existence of its subject qualifications, such as relevant industrial and commercial registration information printed on platforms such as Qixin.com and Qichacha;",
      IftheaplegaHoqualificationstillexists:
        "If the applicant is a legal person from Hong Kong, Macao and Taiwan or a foreign country, the applicant may submit relevant documents indicating that its principal qualification still exists;",
      Ifthnameoftherespoeenrelevantubmitted:
        "If the name of the respondent has been changed, relevant evidence of the name change shall be submitted.",

      //代理申请人
      Whenuploadingilhangenheattorneyletteretc:
        "When uploading files, please change the name of the file to be uploaded to the corresponding material name, such as power of attorney, letter, etc;",
      Ifthepartibitratiothorizationpecialauthorization:
        'If the parties appoint an arbitration agent, they shall submit the original power of attorney, and the relevant authority shall be listed as "arbitration" rather than "litigation", and do not only list "general authorization" or "special authorization"',
      Alldocumentssubmittedbythepaneeformebiroceedings:
        "All documents submitted by the parties do not need to be notarized at the filing stage. If the other party raises objections or the arbitral tribunal deems it necessary, it may be necessary to notarize the evidence formed abroad in the arbitration proceedings.",
      IftheclieongMacaoaiwanptorsdbesubmtted:
        "If the client is a Hong Kong, Macao, Taiwan or foreign legal person, it is necessary to upload the original power of attorney recognized by the party is registration place, signed by an authorized signatory or sealed by the company. If the power of attorney is signed by an individual, the name of the signatory should be listed in a printed font in the power of attorney, and evidence that the signatory has the right to sign relevant legal documents on behalf of the company (such as resolutions of the board of directors, etc.) should be submitted.",
      Thesizeoftheuploadedfiledoenotexceed:
        "The size of the uploaded file does not exceed",

      //仲裁依据
      Thapplicantisreqenamepllvedonebyone:
        "The applicant is requested to enter the name, number, date and place of the contract involved one by one",
      Iftheapplicantinitiatesarbitrantracsnotnecesheconbmission:
        "If the applicant initiates arbitration based on similar orders (contract name and arbitration clause are the same), it is not necessary to enter the contract name and arbitration clause contents repeatedly, but to add all the contract numbers involved in the contract number; You can also make a written letter by yourself, listing all the contract numbers, date of signing and other information, seal or sign before submission.",
      IftheapplicantinitiatesroviationRules:
        "If the applicant initiates arbitration on the basis of multiple contracts, please note the provisions of Article 14 of the Arbitration Rules.",
      Article14Multicontratrationandaddiitration:
        "Article 14 Multi-contract arbitration and additional contracts in arbitration",
      Anapplicantmayapparbitrinbitraolloremet:
        "(One)An applicant may apply for arbitration in a single arbitration case for disputes under multiple contracts, provided that the following conditions are met:",
      Multiplecontractsaremastertiomeactsinvolvethesublicated:
        "Multiple contracts are master-slave contractual relations, or multiple contracts involve the same parties and the nature of the legal relationship is the same, or multiple contracts involve the subject matter is implicated;",
      Disputesarisingtransationortransaciplecontracts:
        "Disputes arising from the same transaction or series of transactions in multiple contracts;",
      Thearbitratmentsinultiplecontticalorcompatible:
        "The arbitration agreements in multiple contracts are identical or compatible.",
      Wheretheircumstancepeciftheapplicationaffectdinonalcontract:
        "(Two) Where the circumstances specified in subparagraphs 1, 2 and 3 of paragraph (One) above are also met, the applicant may apply for an additional contract in the arbitration proceedings, provided that if the delay in the application affects the normal conduct of the arbitration proceedings, the applicant may decide not to make an additional contract.",
      Theproceduralmattersapedthearbitrationtribunalshalldecide:
        "(Three) The procedural matters mentioned in paragraph (One) (Two) above shall be decided by the arbitration Court of the Arbitration Commission. If an application for additional contracts is filed after the arbitration tribunal is formed, the arbitration tribunal shall decide.",

      //证据材料文本
      Whensubmittingevidensnotsubmphoto:
        "When submitting evidence, please serial number, page, copy/scan should be clear, complete, do not submit a single photo;",
      Whenuploadingevidencefromevidencetoevidence:
        "When uploading evidence files, please change the name of the file to be uploaded to the name of the evidence, or list evidence from evidence to evidence;",
      Iftheoriginalequiredinthearbitrationproceedings:
        "If the original evidence is in English, there is no need to provide translation at the filing stage; If the contract in question is in another language, a translation shall be provided at the filing stage (the language shall be consistent with the language of the arbitration in the case). If the opposing party raises an objection or the arbitral tribunal deems it necessary, translation of foreign language evidence may be required in the arbitration proceedings.",

      // 注册指引
      Smartplatformregistrationaccountusageguide:
        "Smart platform registration account usage guide",
      WelcomoMAozhongSmartPlatformNewuserspleaseregisterfirst:
        "Welcome to MAozhong Smart Platform! New users please register first",
      Accountregistration: "Account registration",
      Partiescanaccessthesmartplatformintwoways:
        "Parties can access the smart platform in two ways",
      LogontotheofficialwebsiteofChinaInternationCommission:
        'Log on to the official website of China International Economic and Trade Arbitration Commission (" CIETAC ")',
      OnthehomepageclickOnlineFilingtoplatform:
        'On the home page, Click "Online Filing" to enter the smart platform.',
      Directinput: "Direct input",
      Enterthesmartplatform: "Enter the smart platform",
      Newuserneetoregisterbeforeusingthesystem:
        "New users need to register before using the system. Please click Register now in the lower right corner of the home page of the filing system. You can choose to register by mobile phone or email.",
      gentlereminder: "Gentle Reminder",
      ForcasessubmittedbeforeJanuarypleasealsologintothehomepageoftheofficial:
        'For cases submitted before January 1, 2024, please also log in to the homepage of the official website, click "Online filing" to enter the smart platform, and click "Online filing (old version)" in the upper right corner of the figure above to continue the online filing operation。',
      BydefaultmobilephoneregistrationpreferredPleasefillinthemobilephonenumberemail:
        "By default, mobile phone registration is preferred. Please fill in the mobile phone number, email, name, ID number as required, set the password, click to obtain the verification code and enter the SMS verification code received by the mobile phone",
      PleasereadandchecktheServiceAgreementandclick:
        'Please read and check the "Service Agreement" and click "Register now".',
      PleaseswitchtoEmailregistrationatthetopofheregistrationinformationpageasshownbelow:
        'Please switch to "Email registration" at the top of the registration information page, as shown below:',
      PleasefillintheemailnameIDnumberasrequiredsetthepassword:
        "Please fill in the email, name, ID number as required, set the password, click to obtain the verification code and enter the verification code received by the email.",
      PleasereandchecktheServiceAgreementandclickRegisternow:
        'Please read and check the "Service Agreement" and click "Register now".',
      Aftertheregistrationissuccessfulthesystemautomaticallyreturnstothelogin:
        "After the registration is successful, the system automatically returns to the login page.",
      Loginplatform: "Login platform",
      ThepartiescanlogintothesmartPlatformbyfillingnthewechat:
        "The parties can log in to the smart Platform by filling in the registered account and entering the password, or log in through wechat",
      Howtobindwechat: "How to bind wechat",
      Afterloggingintothesmartplatformyoucanfillinyourpersonalinformation:
        'After logging in to the smart platform, you can fill in your personal information at "Settings" in the upper right corner and bind to wechat.',
      Afterbindingtowechatyoucanusewechattime:
        "After binding to wechat, you can use wechat scan code to log in the next time.",
      IfyouforgetthepasswordoftheregiyoucanclickForgotPasswordtooperate:
        'If you forget the password of the registered account, you can click "Forgot Password" to operate. The password can be retrieved through the mobile phone number and email address respectively.',
      ToretrievethroughthemobilephonenumberhmobilephonenumberuseddurickSend:
        'To retrieve through the mobile phone number, you need to enter the mobile phone number used during registration, click "Send" to send the verification code, enter the verification code received by the mobile phone and click next. Then you need to reset the password. After the setting is complete, click OK, return to the login page, enter the account and new password to log in.',
      Toretrievebyemailouneedtoentertheemailaddressuse:
        'To retrieve by email, you need to enter the email address used during registration, click "Send" to send the verification code, enter the verification code received by the email address, and click Next. Then you need to reset the password. After the setting is complete, click OK, return to the login page, enter the account and new password to log in.',
      Top: "Top",

      // 常见问题
      Pleasenotethatthefollowinganswersareforthereferenceofthepartie:
        "Please note that the following answers are for the reference of the parties only, and the final interpretation is reserved by MAO-Zhong.",
      CanwegototheChinaInternationalEconomicandTradeArbitrationCommission:
        "Can we go to the China International Economic and Trade Arbitration Commission to settle the dispute?",
      settleadisputealEconomicndbitratnCoarbitration:
        "1. To settle a dispute by submitting it to the China International Economic and Trade Arbitration Commission for arbitration, the contract between you and the other party shall contain a valid arbitration clause (or a separate arbitration agreement), which clearly stipulates that in case of any dispute between the two parties, the dispute shall be submitted to the China International Economic and Trade Arbitration Commission for arbitration.",
      clausearbitrationagreementoisputetheparties:
        "2. If there is no arbitration clause (arbitration agreement), the arbitration Commission may be requested to mediate the dispute between the two parties.",
      ModelArbitrationisputearisinoutbotarties:
        "Model Arbitration Clause (1): Any dispute arising out of or in connection with this contract shall be submitted to the China International Economic and Trade Arbitration Commission for arbitration in accordance with the arbitration rules in force at the time of application for arbitration. The arbitral award shall be final and binding on both parties.",
      ModelArbititratioThearbitralbindingonbothparties:
        "Model Arbitration Clause (2): Any dispute arising out of or in connection with this contract shall be submitted to the __________ Sub-Commission of the China International Economic and Trade Arbitration Commission (CIETAC) for arbitration in accordance with the arbitration rules currently in force at the time of the application for arbitration. The arbitral award shall be final and binding on both parties.",

      //费用快算
      DomesticArbitrationCase: "Domestic Arbitration Case",
      ForeignRelatedArbitrationCase: "Foreign-Related Arbitration Case",
      Result: "Result",
      Pleaseenterthecalculatedamount: "Please enter the calculated amount",
      Calculate: "Calculate",
      ThepecificdeliveryamountofthefeeshallCommission:
        "The specific delivery amount of the fees shall be subject to the written reply from the Trade Arbitration Commission!",

      RegistrationFee: "Registration Fee",
      HandlingFee: "Handling Fee",
      TotalAmount: "Total Amount",

      //立案指引
      Createanarbitrationcase: "Create an arbitration case",
      AfterregisteringandlogginginentertheCasescreen:
        'After registering and logging in, enter the "My Case" screen.',
      ClickNewCase: 'Click "New Case".',
      Fillincaseinformation: "Fill in case information",
      Article21ofthecurrentCIETACArbitrationRulessionandthepaperversionagreed:
        'Article 21 of the current CIETAC Arbitration Rules (2024 edition) stipulates that "in case of inconsistency between the electronic version and the paper version, the electronic version shall prevail, unless otherwise agreed by the parties", and the applicant is requested to accurately enter the relevant information.',
      Fillintheinformationofbothpartiesandagents:
        "Fill in the information of both parties and agents",
      AfterselectinganewcasetheNewcasepagewillbedisplayedPlease:
        "After selecting a new case, the New case page will be displayed. Please fill in the relevant information.",
      Pleasefirstpleasefillintherelevantpartyinformationnumberisrequired:
        "Please first check the natural person, legal person, other organization or individual industrial and commercial enterprises according to the type of parties, and then please fill in the relevant party information, * number is required.",
      Whenfillingintheinformationofthepartiesaupload:
        "When filling in the information of the parties and uploading materials, please pay attention to the filling prompt (take the agent filling prompt as an example)",
      Theapplicantanuploadthesubjectqualificationdocument:
        "The applicant can upload the subject qualification document, power of attorney and other documents in this section.",
      Fillintheaddressforservice: "Fill in the address for service",
      Afterenteringheinformationofbothparties:
        'After entering the information of both parties, please fill in the address of service. First, click "Add."',
      Pleasenotethatthedeliverybefilledinaccurately:
        "Please note that the delivery information must be filled in accurately",
      Theapplicantwillreceiveamessagenotificationfromthesmart:
        "The applicant will receive a message notification from the smart platform in his/her mobile phone number and email address. Therefore, please make sure to fill in the mobile phone number and email address accurately to avoid missing messages and delaying the progress of the case.",
      AfterselectingApplicantServiceAddresspleasenotethatonly:
        'After selecting "Applicant Service Address", please note that only one applicant can enter one service address.',
      Afterselectingproviderespondentingoodfaithandtherelevant:
        'After selecting "address of service of the respondent", please note that the applicant is obliged to check and provide the valid address of service of the respondent. If the applicant fails to provide the valid service address of the respondent in good faith, and the relevant legal documents of the case are not served in time or cannot be served, the applicant shall bear the legal consequences arising therefrom.',
      Theapplicantmayprovidemultipleserviceaddressesrespondent:
        "The applicant may provide multiple service addresses of the respondent.",
      Ifthereisanyorderofservicepleasesendthearbitration:
        "If there is any order of service, please specify in writing, and CIETAC will send the arbitration documents to the respondent one by one in that order.",
      Ifyouneedtosenditatthesametimepleasesubmitadocuments:
        "If you need to send it at the same time, please submit a clear written application and increase the number of paper documents.",
      Fillinthebasisofarbitration: "Fill in the basis of arbitration",
      PleaseclickAddtoenterthearbitrationbasis:
        'Please click "Add" to enter the arbitration basis.',
      Pleaseaccuratelyinputnamearbitrationclauseifanysigningdate:
        "Please accurately input the contract name, arbitration clause, if any signing date, contract number and signing place, please also truthfully input.",
      Ifarbitrationisfiledbasedonmorethanonecontractclick:
        'If arbitration is filed based on more than one contract, please click "Add" again after entering one arbitration basis to continue entering relevant contracts.',
      Fillinthearbitrationclaimfactsandeasons:
        "Fill in the arbitration claim, facts and reasons",
      PleaseclickAddtoenterthearbitration:
        'Please click "Add" to enter the arbitration claim, facts and reasons.',
      Iftheapplicantneedspropertpreservationnameuploadthescannedopyofheapplica:
        'If the applicant needs property, evidence or behavior preservation, please click "Add", fill in the name and address of the court, briefly input the preservation content, and upload the scanned copy of the application for property preservation.',
      Iftheapplicantdoesnotneedpreservation:
        "If the applicant does not need preservation, do not fill in.",
      Uploadtheapplicantevidence: "Upload the applicant is evidence",
      Thesubjectqualificationevidencmaterialsustuploadnewev:
        "The subject qualification documents, power of attorney and other documents previously uploaded by the applicant have been shown in the evidence materials. Please do not upload them repeatedly. Just upload new evidence.",
      Itisrecommendedthattheapplicantmaterialsabout500Mforaingledocument:
        "It is recommended that the applicant submit evidence materials with clear scanning and moderate size (about 500M for a single document).",
      Submitthecaseandobtainthecasenumber:
        "Submit the case and obtain the case number",
      AfterfillingintheaboveinformationaccuratelyclickSubmitforfilingnot:
        'After filling in the above information accurately, click "Submit for filing". Please note that if you do not click "Submit for filing", the case will only be saved in your system as a draft.',
      AfterclickingSubmittoCIETACpleasenotethataccordingto:
        'After clicking "Submit to CIETAC", please note that according to the provisions of the arbitration clause and the Arbitration Rules, select the submission to the Federation/Branch first, then click "Submit to CIETAC" again.',
      AfterlickingSubmittoCIETACyouwilbepromptedwithyourcasenumber:
        'After clicking "Submit to CIETAC", you will be prompted with your case number. Please keep your case number in mind during the case filing stage.',
      CasefilingInvestigation: "Case filing Investigation",
      initialExamination: "initial Examination",
      AftertheapplicantsubmitsanewcaseCIETACwillonduc:
        "After the applicant submits a new case, CIETAC will conduct a preliminary examination of the case as soon as possible. The mobile phone number and email address entered by the applicant will be reminded of the filing examination, please pay attention to check and modify accordingly.",
      Contactthefilingsecretary: "Contact the filing secretary",
      AfterthecasesecretaryisdeterminedthenameaceofMyCase:
        'After the case secretary is determined, the name and telephone number of the case secretary will be displayed in the interface of "My Case" after the applicant logs in to the platform. If in doubt, the applicant may contact the filing secretary.',
      Thepplicanalsocheckthemessageboardtofillsecretary:
        'The applicant can also check the "message board" to fill in a message and communicate with the filing secretary.',
      Returnofcase: "Return of case",
      Whenthefilingreviewfindtthecaseneedstobemodified:
        "When the filing review finds that the case needs to be modified or supplemented with the submitted materials, the filing secretary will return the case to the applicant is system, so that the applicant can modify the relevant information or supplement the upload of the documents required for filing. The mobile phone number and email address entered by the applicant will be reminded of the return of the case, please pay attention to check and modify accordingly.",
      Aftertheapplicantsupplementstheuploadedcarbitration:
        'After the applicant modifies or supplements the uploaded materials in accordance with relevant requirements, please click "Submit to arbitration" in time to avoid delaying the progress of the case.',
      Thecasewasfiledndexamined: "The case was filed and examined",
      Afterpassingtheexaminationthemobilephonebytheapplicantnglyintime:
        "After passing the examination, the mobile phone number and email address entered by the applicant will receive a reminder of passing the examination. Please pay attention to check and operate accordingly in time:",
      Pleaseotethatafterpassingotice:
        'Please note that after passing "Message · to Do" or clicking on a specific case, check the notice of approval and submit paper materials as required by the notice.',
      Pleaseselectarbitrationprepaymentaccordingtothenotice:
        'Please select "Notice · Instruction" in the "My Case" section to view the PDF payment notice in time and pay the arbitration prepayment according to the notice.',
      ClickthebuttonofthecorrespondiversionficeofCIETACasrequired:
        'Click the "PDF" button of the corresponding case under "Generate PDF Arbitration Application" in the case list, and then you can preview or download the generated PDF arbitration application. Please print the PDF version of the arbitration application and send the corresponding number of copies to the registration office of CIETAC as required.',
      attentionshouldbepaidto: "Attention should be paid to",
      ApplicationforarbiotesandndnoteThiscanbeusedwheofthedocument:
        "《application for arbitration》 Footnotes and endnotes: This can be used when it is necessary to quote certain large paragraphs of text (such as the relevant legal provisions, the text of the contract awarded to the project at home and abroad, or the discussion of the monograph, etc.), but it is not appropriate to include the text as it is. Footnotes and endnotes can be placed in the footer of this page or on the last page of the document.",
      Aboutthenumberingofthepagesvidence:
        "About the numbering of the pages of evidence:",
      Eachpieceofevidencebepaginatedseparatelthebottomrightofachpage:
        "Each piece of evidence can be paginated separately if required, and this number is placed at the bottom right of each page.",
      Allevidenceshallbepaginatedtotalpageeachpage:
        "All evidence shall be paginated. The total page number is at the top right of each page.",
      WhentherearemorethetotalpagenumberconsecutiveWhenevidenceyubmissions:
        "When there are more than one volume of evidence, the total page number of each volume of evidence shall be consecutive; When evidence is presented more than once, subsequent submissions should be paginated consecutively following previous submissions.",
      Thecontentstobeprovedevidenceshallbeaffected:
        "The contents to be proved in each piece of evidence shall be marked and highlighted in the text with colored pens, but no changes shall be made to the evidence or the identification of the original state of the evidence shall be affected.",
      Whenthevidencesubmittedagainisrepeateeindicated:
        "When the evidence submitted again is repeated with the evidence submitted previously, it is sufficient to state the number and total page number of the evidence submitted previously. Generally, it is not necessary to submit the evidence repeatedly. If necessary, it may be submitted repeatedly, but it shall be indicated that it is the same as a previous submitted evidence and the serial number of the original evidence shall be indicated.",
      Asforthebindingofdocumentsifthedocumentsarethickg:
        "As for the binding of documents, if the documents are thick, it is best to bind them in a way that is easy to remove, such as plastic ring binding.",
      Annotation: "Annotation",
      ThepurposeofwritingTheCalculattheaward:
        'The purpose of writing "The Calculation of claim Amount and the Explanation and basis of each calculation value" is to enable the arbitrator to have a comprehensive, in-depth, detailed and specific understanding of the basis and calculation of each claim in the arbitration case, so as to facilitate the arbitrator to find relevant evidence, study the rationality of the claim item and the amount, calculate the amount of the claim, and finally determine and calculate the amount of the award.',
      Thissectionthearbitrationcaseprojectincludingthenamenproblemsetc:
        "This section should be 1500 words or less. This part is a brief introduction to the basic information of the arbitration case project, including the name and content of the project, the signing time of the contract, the progress of the project, the main problems existing in the project, etc.",
      Thespecificclaimlaimpaymentevidencearenotinvolvedhere:
        "The specific claim, claim payment, claim argument, defense explanation and claim and counterclaim evidence are not involved here.",
      Boththeapplicantandthesubmittingthedocumentlater:
        "Both the applicant and the respondent shall prepare this part of the content when submitting the document for the first time, and may supplement this part of the content if necessary when submitting the document later.",
      Thepurposeofbriefntroductionivethearbitratorbitrationcase:
        "The purpose of this brief introduction is to give the arbitrator a comprehensive understanding of the items involved in the arbitration case.",
      Pleasetionllproceedtothelicantandarbitrationfeepaidinadvance:
        "Please prepare the arbitration documents to be submitted and send them to the filing office of CIETAC as soon as possible; At the same time, please pay the arbitration fee as soon as possible (payment and sending materials will not be in order). The case will proceed to the next arbitration procedure after CIETAC receives the written arbitration document submitted by the applicant and the arbitration fee paid in advance.",
      Atthisointtheonlinefilingprocess:
        "At this point, the online filing process is over.",

      //办案指引
      Thepartiesbindthecase: "The parties bind the case",
      Aftersuccessfullyloggingindisplayed:
        "After successfully logging in, the page of My case will be displayed",
      ClickAddCasetobindtheaccount: "Click Add Case to bind the account",
      Enterthecasenumbertobeboundherebinding:
        "Enter the case number to be bound here, and then click OK to complete the binding",
      Afterthebindingthetheboundcaseinformation:
        'After the binding is successful, click the "Do case" button to see the bound case information',
      Uploadelectronicdocuments: "Upload electronic documents",
      Clickotheasename: "Click on the case name",
      Afterlickitherewillbeinstructionndapplicant:
        "After clicking, there will be three folders: applicant, notification instruction and applicant.",
      Youcanclickafolderdirectortouploadneedstouploadilesrectory:
        "You can click a folder directory to upload files, for example, the applicant needs to upload files to the applicant directory.",
      AfterselectingtheidentityfolderclickUpload:
        "After selecting the identity folder, click Upload",
      Clickuploadimagemiddleselectthefiledraganddropupload:
        "Click the upload image in the middle, select the file to be uploaded, select or drag and drop upload",
      Iftheuploadissuccessfulpleaseuploadeduploadissuccessful:
        "If the upload is successful, please check whether there are uploaded files in the file directory after the upload is successful",
      Inthiswindowpartiesarbitratorsndhandlers:
        "In this window, the parties can manage their own electronic files, including downloading and copying, and can view the electronic files uploaded by both parties, arbitrators and handlers.",
      Operatingguideforarbitratorend: "Operating guide for arbitrator end",
      Registerofarbitrators: "Register of arbitrators",
      Notethathearbitratordoeseratedcharactercode:
        "Note that the arbitrator does not need to register, the account and password will be informed by the secretary through email or SMS, and the case does not need to be bound. If bound with wechat, you can also log in by scanning the code through wechat. The default login account of arbitrator is mobile phone number or email address, and the default password is a string of randomly generated character code.",
      Afterenteringtheaccountpasswordanderificationlogging:
        "After entering the account, password and verification code, the following window will pop up after logging in",
      ClickheArbitratorAdministrationagain:
        "Click the Arbitrator Administration menu. Click on the case name again",
      Viewelectronicfiles: "View electronic files",
      AfterlogginginclickonElectronicManagement:
        "After logging in, click on Electronic File Management.",
      Thearbitratorcanviewllelectronicuploadedbythesecretary:
        "The arbitrator can view all electronic documents uploaded by the parties, the arbitrator and the handling secretary.",
      Viewcaseprogress: "View case progress",
      ClicktheViewCaseprogressmenuthefollowingup:
        "Click the View Case progress menu, the following window will pop up.",
      Changasswordandbindwechat: "Change password and bind wechat",
      AftersystemclicompleteasswordAshownbelow:
        "After logging in to the system, click Settings first, and then click Bind to complete the binding operation, or modify your login password. As shown below.",
      IfwantunbindclickUnbindwindow:
        "If you want to unbind, click Unbind in this window.",

      // 庭审指引
      Instructionsforusingthevideotrialsystem:
        "Instructions for using the video trial system",
      Computersidetoparticipateincourt:
        "Computer side to participate in online court",
      Completefunctionsrecommended: "(Complete functions, recommended)",
      StepOpenthevideopage: "Step 1: Open the video trial page",
      Checkthatthcameramicrophoneandothernormally:
        "Check that the camera, microphone, headset, sound playback and other equipment can be used normally.",
      UsingGoogleChrome: "Using Google Chrome（Chrome）",
      OR: "OR",
      Extremebrowser360: "360 Extreme Speed browser",
      Unfold: "Unfold",
      Clickonhetopofthehomepage: "Click on the top of the home page",
      TheWisdomTrial: "The Wisdom Trial",
      Checkinonline: "Check in online",
      AfterenteringthetrialthtrialageClickon:
        "After entering the trial code to log in to the trial page, the check-in process will be entered first. Click on",
      Signin: "Sign in",
      Buttonforidentityinformationcheckpersoncheck:
        "Button for identity information check - real person authentication, authentication successfully completed the check-in.",
      Pretrialtest: "Pretrial test",
      Beforetheofficialcourtsessionhesystlldetectetworksession:
        "Before the official court session, the system will automatically detect the network, camera, microphone and speaker to ensure the normal court session.",
      Ifyoufindthathemicrophonespeakertheisselectedyoursystem:
        "If you find that the microphone, speaker, or camera test fails, please check whether the computer is equipped with the microphone, speaker, and camera, and whether the drivers are installed correctly. If you are using external microphones, speakers, and cameras, check that the correct device is selected in your system.",
      Boardinfrontofcourt: "Board in front of court",
      Beforetheformalcourtsessiyoucancheckndcliwhenready:
        "Before the formal court session, you can check the case related information on the pre-court kanban. Please read the trial discipline, pre-court notice, etc., and click when ready",
      Gototrial: "Go to trial",
      Disciplineoftrial: "Discipline of trial",
      Afterenteringthetrialthetrialdisciplineplayed:
        "After entering the trial, the trial discipline will be played. All court members were forbidden to speak when the court discipline was played and returned to normal after it was played.",
      Step2Enterthevideotrialsystem: "Step 2: Enter the video trial system",
      EnterhetrialcodereceivedintheSMSystem:
        "Enter the trial code received in the SMS to enter the trial system.",
      Step3Completetheealpersonertificationtrial:
        "Step 3:  Complete the real person certification to begin the trial",
      Afterenteringthetrialsystemtheartthetrial:
        "After entering the trial system, the case secretary will initiate real person authentication, complete real person authentication through face recognition, and start the trial.",
      Step4Confirmsigningtranscriptscourthighlights:
        "Step 4: Confirm signing transcripts/court highlights",
      AfterthehearingthecasesecretaryFollowthesesteps:
        "After the hearing, the case secretary will initiate the transcript confirmation. Follow these steps:",
      CheckagreetotheElectronicSignatureEntrustme:
        "Check [I agree to the Electronic Signature Entrustment Agreement] and click [Confirm].",
      CheckhaveverifiedthatmyidentityclickConfirm:
        "Check [I have verified that my identity information is correct] and click [Confirm].",
      Check: "Check",
      Pagelocatioofsignature: "[Page location of signature]",
      AQRcodewilpoppthescreenAfterscanninghecodewithyourclick:
        "A QR code will pop up on the screen. After scanning the code with your mobile device, sign on the whiteboard and click",
      Acknowledgereceipt: "[Acknowledge receipt]",
      Afterconfirmationwaitrial:
        "After confirmation, wait for the end of the trial",
      Ifthetranscriptmainpointsofhetrialneedtomodifiedbysecretary:
        "If the transcript/main points of the trial need to be modified, they can still call the case secretary through the video trial system and be changed by the case secretary.",
      Casefileuploadfunction: "Case file upload function",
      Afterreceivingthecourtthevideocourtcode:
        "After receiving the court code, you can enter the video court system many times with the court code.",
      Afterenteringthecourtystemclickeft:
        "After entering the court system, click on the left",
      ElectronicMaterial: "[electronic material]",
      NewMaterials: "[New Materials]",
      Click: "Click",
      Uploadevidencaterialsopinionsparticipantsandotherdocuments:
        "Upload scanned evidence materials, written cross-examination opinions, confirmation letter of video trial, confirmation letter of video trial participants and other documents.",
      Thefileuploadfunctioncanduringhetrial:
        "The file upload function can be used before and during the trial.",
      Mobileterminalstoparticipateinonlinetrials:
        "Mobile terminals to participate in online trials",
      Setupcalltransferindvancetoallsession:
        "(Set up call transfer in advance to avoid interruption of call session)",
      Step1download: "Step 1: Download",
      Methodofdownload: "Method of Download",
      Downloadlink: "Download link",
      ScantheQRcodebelowtodownload: "Scan the QR code below to download",
      Step2EnterthetrialcodereceivedSMStoenterthetrial:
        "Step 2: Enter the trial code received in the SMS to enter the trial",
      Step3Completetherealpersoncertificationrial:
        "Step 3: Complete the real person certification to begin the trial",
      Afterenteringthetrialsystemrecognitionstartthetrial:
        "After entering the trial system, the case secretary will initiate real person authentication, complete real person authentication through face recognition, and start the trial.",
      Afterheasesecretaryinitiatesthetrmationclickright:
        "After the case secretary initiates the transcript/court highlights confirmation, click on the bottom right",
      Recordofwords: "[record of words]",
      Checkfirst: "Check first",
      IagretotheElectronicSignatureAgreement:
        "[I agree to the Electronic Signature Entrustment Agreement]",
      Andthenclickagain: "And then click again",
      Recordconfirmation: "[Record confirmation]",
      Afteronfirmingthetranscriptrialpointsclick:
        "After confirming the transcript/trial points, sign the blank page and click",
      Afterconfirmationwaitforend:
        "After confirmation, wait for the trial to end.",
      Ifthetranscripttrialpointsmendedyoucancretarywillchangeit:
        "If the transcript/trial points need to be amended, you can still call the case secretary through the video trial system and the case secretary will change it.",
      Themobileterminaldoesnotuploading:
        "The mobile terminal does not support case file uploading",
      NewslettersAttendonlinetrials: "Newsletters Attend online trials",
      Telephoneaccesssavailabdnswerthehonetoparticipateinthetrial:
        "Telephone access is available when the network signal is poor. The case secretary can call relevant personnel through the video trial system and answer the phone to participate in the trial.",

      // 申请开票--在线申请发票使用说明
      Applyforinvoiceinstructionsnline: "Apply for invoice instructions online",
      ThecasenumbeD20233915andthenvoice:
        "The case number is D20233915(F), and the invoice extraction code of the applicant is c20238hq697.",
      Thespecificstepsarefollows: "The specific steps are as follows:",
      Pleasevisitourfficialwebsite: "Please visit our official website",
      ScantheQcodCIETACeattheAPPStore:
        'Scan the QR code at the bottom to download "CIETAC APP", or search for "CIETAC APP" from Huawei App Store or Apple APPStore.',
      OpenCIETACtheAPPelow: 'Open the "CIETAC APP", as shown below.',
      Firstofllselectthepartybuttoninoiceonline:
        "First of all, select the party button in the picture above, then enter the invoice extraction code, mobile phone number and verification code, click login, you can apply for invoice online.",
      Notenvoiceleasetheloginheextractioncode:
        'Note: If there is no invoice extraction code, please click "Retrieve invoice extraction code" on the login page of the APP above, enter the basic information of the applicant, click search, the system will automatically generate an invoice extraction code.',
      Submittheinvoicepplicationbelow:
        "Submit the invoice application online, as shown below.",
      Afteruccessfulhenvoicecanseetheubmissionstatus:
        "After successful login, select the case to be invoiced, choose to apply for invoicing, fill in the information such as ticket type, fee type, application amount, etc. If you change the invoicing name, you need to upload instructions, if the invoice needs to be mailed, you need to fill in the mailing information. Finally click the Request billing button. After success, you can see the submission status.",
      NoteAccordinpartiestothecasedissue:
        'Note: ① According to the requirements of the Tax Bureau on "three streams in one" (that is, the flow of goods, the flow of funds, and the flow of invoices should be unified), for the trade, it means that the parties, the payers of arbitration-related fees, the recipients of returns, and the invoice recipients should be unified. Therefore, we only collect and refund arbitration-related expenses and issue invoices for the parties to the case.',
      Pleasecheckthebillingubmissionofficialsealbefore:
        "② Please check the billing information carefully before submission. If the invoice needs to be changed due to the party is filling in error, the party must provide the original explanation with the official seal before the invoice can be changed. For technical questions, please call: 010-82217622 17603319137, for financial questions, please call: 010-82217606",

      //申请退费----撤案仲裁费用的退费
      TheseMeasuresreformulateiccordancewithithdrawn:
        "These Measures are formulated in accordance with relevant laws, regulations and rules as well as the provisions of the Arbitration Rules of the China International Economic and Trade Arbitration Commission, the Arbitration Rules of the China International Economic and Trade Arbitration Commission for Financial Disputes and the Online Arbitration Rules of the China International Economic and Trade Arbitration Commission in order to regulate the refunding of arbitration fees when an arbitration case is withdrawn.",
      Article1Refundsomesticases: "Article 1 Refunds for domestic cases",
      Ifthcaswithdrbeforehormationfthendlingfeeshallreturned:
        "If the case is withdrawn before the formation of the arbitration tribunal, the full amount of the case acceptance fee and 50% of the case handling fee shall be returned.",
      Ifacaseswithdrawnbitrationfeechargedhallotbeless:
        "If a case is withdrawn after the arbitration tribunal is formed and before the hearing, 50% of the case acceptance fee and 50% of the case handling fee shall be returned, provided that the arbitration fee charged shall not be less than RMB 6,100.",
      Ifthearbitrationtribunallcircumstancesrefundedundheprocessingfee:
        "If the arbitration tribunal withdraws the case after the hearing, the fee will generally not be refunded. Under special circumstances, the case handling fee shall not be refunded, and the case handling fee may be refunded according to the specific circumstances, but the refund fee shall not be higher than 20% of the processing fee.",
      ArticleRefundforforeigrelatedcases:
        "Article 2 Refunds for foreign-related cases",
      Ifthecaseiswithdrawnbeforeefeethebelowreturned:
        "If the case is withdrawn before the formation of the arbitration tribunal, 60% of the arbitration fee (excluding the case filing fee, the same below) shall be returned.",
      Ifthithdrawnrationrbitrationfeeshallreturned:
        "If the case is withdrawn after the arbitration tribunal is formed and before the hearing, 50% of the arbitration fee shall be returned.",
      Ifthearbitrationtribunalearbitrationfee:
        "If the arbitration tribunal withdraws the case after the hearing, the fee will generally not be refunded. In special circumstances, the arbitration fee may be refunded according to the specific circumstances, but the refund fee shall not be higher than 20% of the arbitration fee.",
      ArticleRefundsfortheCommissionnonattendancereason:
        "Article 3 Refunds for the withdrawal of the case due to the absence of jurisdiction of the arbitration Commission or the non-attendance of the parties without reason",
      Ifanarbitrationwithdrawnapeopleisourtfthereturned:
        "If an arbitration case is withdrawn due to a decision made by a people is court or an arbitration commission without jurisdiction before the hearing of the arbitration case, 80% of the acceptance fee and handling fee shall be refunded for domestic cases; In foreign-related cases, 80% of the arbitration fee will be returned.",
      Ifthearbitrationcasedueshalligherthanarbitrationfee:
        "If the arbitration case is withdrawn due to the absence of jurisdiction of the Arbitration Commission after the hearing of the arbitration case: In domestic cases, the amount of refund shall not exceed 70% of the acceptance fee and processing fee according to the degree and workload of the handling of the case by the arbitration Commission and the hearing of the case by the arbitration tribunal and the responsibilities of the parties; For foreign-related cases, the refund amount shall not be higher than 70% of the arbitration fee.",
      Ifpauponwrittennoticeppearinnorefundshallbegranted:
        "If a party, upon written notice, fails to appear in court without a valid reason or withdraws from the court without permission of the arbitral tribunal, and the case is deemed to be withdrawn automatically or the claim or counterclaim is withdrawn automatically, no refund shall be granted.",
      ArticlRefundsshallapplyArbitrationdradeArbitrationCommission:
        "Article 4 Refunds shall apply to other cases under the Arbitration Rules of the China International Economic and Trade Arbitration Commission",
      TherefundoffeesincaseswhertheChinaeasures:
        'The refund of fees in cases where the "China International Economic and Trade Arbitration Commission Financial Dispute Arbitration Rules" and the "China International Economic and Trade Arbitration Commission Online Arbitration Rules" are applied, with reference to the provisions of Articles 2 and 3 of these Measures.',
      ThesemeasuresareapprovedytheCIETACecretarBureau:
        "These measures are approved by the 7th Joint Meeting of Directors of the 17th Committee of CIETAC and CIETAC, and shall come into force on January 1, 2012, and shall be interpreted and revised by the CIETAC Secretary Bureau.",

      //仲裁申请须知-----
      WelcometoChinaInternationalEconomicandtration:
        "Welcome to China International Economic and Trade Arbitration Commission! If you/your company plans to claim compensation or resolve existing disputes through arbitration, please pay attention to the following instructions for applying for arbitration.",
      ArbitrationtoAssociationgenerallyauseinthecontracagreement:
        "Arbitration to the Association, generally requires that you/your company and the other party have an arbitration clause in the contract, or reach a written arbitration agreement.",
      Tofileanapplicationyouanchoose: "To file an application, you can choose:",
      Onsitesubmissionnoreservation:
        "On-site submission (no reservation required)",
      Filingbymail: "Filing by mail",
      TheaddressformailDistrictBeing:
        "The address for on-site filing and mail filing is: China International Economic and Trade Arbitration Commission, 6th Floor, International Chamber of Commerce Building, No. 2 Huapichang Hutong, Xicheng District, Beijing",
      Thepartiesosemitapplicationplatformis:
        "The parties may choose to submit the application online through the MAzhong Smart platform, the website is:",
      Whenapplyingrbitrationpleaseocuments:
        "When applying for arbitration, please submit the following documents:",
      Applicationforarbitrationandtharbitrationwritten:
        "Application for arbitration and supporting evidence (including a contract with an arbitration clause or written arbitration agreement)",
      Unlessotherwisepartientiaryfivecopieseach:
        "Unless otherwise agreed by the parties, the amount of the claim shall not exceed RMB 5 million; If the parties specifically agree to apply the summary procedure, the summary procedure shall be applied, and the arbitration application and the attached evidentiary materials shall be made in triplicate. In other cases, the application for arbitration and the accompanying evidentiary materials shall be submitted in five copies each.",
      Ifthereismoreespondentlsshallbeaddedaccordingly:
        "If there is more than one respondent, then for each additional respondent, an additional application for arbitration and the accompanying evidentiary materials shall be added accordingly.",
      Iftheapplicantasbitrationdocumentesametime:
        "If the applicant has provided multiple service addresses of the respondent and wishes to send the arbitration documents to multiple service addresses at the same time,",
      Pleaseubmitaclearwrittenrequest: "Please submit a clear written request",
      Andsubmitcorrespondingnumberofmaterials:
        "And submit the corresponding number of materials",
      Theapplicationfoneedsompanysealorsignedbyepresentative:
        "The application for arbitration needs to be submitted in original, stamped with the company seal or signed by an authorized representative/agent.",
      Evidencedocumentsenerallytocopiesecrossexamination:
        "Evidence documents generally only need to be photocopies, but the original must be brought in court for cross-examination.",
      Ifappointhandlethearbitratiriginalpoweropies:
        "If an agent is appointed to handle the arbitration case, please submit the original power of attorney in copies.",
      Ifapplicantiscompanyplease: "If applicant is a company, please",
      Pershare: "Per share",
      Submitofcompantiveaturalpersonplease:
        "Submit a copy of the company is business license and the original identity certificate of the legal representative; If applicant is a natural person, please",
      Submitcopiesdentitydocumentsassport:
        "Submit copies of identity documents such as ID card/passport.",
      Ifyouneedtoapplypropertypreservatipreservationplease:
        "If you need to apply for property preservation/evidence preservation, please",
      Submitapropertypreservationapplicationevide:
        "Submit a property preservation application/evidence preservation application in duplicate;",
      Submitoneadditionalapplicationforarbitration:
        "Submit one additional application for arbitration;",
      Givewritteninstructionstothespecificourt:
        "Give written instructions to the specific court to which the referral is to be made and provide the address, postal code and/or telephone number of that court.",
      Pleasepplicationeatlydocumentedpaginabound:
        "Please note that the application for arbitration must be neatly documented, paginated and bound.",
      Pleasepayheinbebornebythelosingparty:
        "Please pay the arbitration fee in advance according to the arbitration fee schedule in the Arbitration Rules. The amount of arbitration fee will be notified to the applicant by the Arbitration Commission upon receipt of the arbitration application. The arbitration fee shall be determined by the arbitral tribunal in the award and shall in principle be borne by the losing party.",
      Ifyouhaveanyotherquesti82217766website:
        "If you have any other questions, please call +86 10 82217788, fax +86 10 82217766, or visit our website",

      // 收文信息
      ReceiptInformation: "Receipt Information",
      CorrespondenceAffiliation: "Correspondence Affiliation",
      ModeofCommunication: "Mode Of Communication",
      Source: "Source",

      //立案信息
      CasefilingInformation: "Case Filing Information",
      ContractNumber: "Contract Number",
      ContractDate: "Contract Date",
      ContentofArbitrationClause: "Content Of Arbitration Clause",
      FilingReminder: "Filing Reminder",
      ForeignCountries: "Foreign Countries",
      PlaceofAcceptanceofIntermediateClauses:
        "Place Of Acceptance Of Intermediate Clauses",
      PlaceofAcceptanceofMajorClauses: "Place Of Acceptance Of Major Clauses",
      CaseInformationes: "Case Information",
      DisputetypeOld: "Dispute Type (old)",
      TypeoffirstorderDispute: "Type of first order dispute",
      SecondarysecondarydisputeTypes: "Secondary/Secondary Dispute Types",
      Language: "Language",
      NumberofArbitrators: "Number of arbitrators",
      ApplicableRules: "Applicable rules",
      SeatofArbitration: "Seat of Arbitration",
      ProgramType: "Program type",
      FilingSecretary: "Filing Secretary",
      DateofTransfer: "Date of transfer",
      OfficeDate: "Office date",
      DateofnoticeofArbitration: "Date of notice of arbitration",
      DateofTribunalFormation: "Date of Tribunal Formation",
      TimelimitforAdjudication: "Time limit for adjudication",
      ClosingDate: "Closing date",
      settlealawsuitType: "settle a lawsuit type",
      WinningParty: "Winning party",

      //报酬查询
      Compensationnquiryding:
        "Compensation inquiry after 2016 (including 2016)",
      Compensationinquiryudingcluding:
        "Compensation inquiry before 2016 (excluding 2016)",
      Queryyear: "Query year",
      Querymonth: "Query month",
      Nameofthehandler: "Name of the handler",
      Pleaseenternamehandler: "Please enter the name of the handler",
      Nameofparty: "Name of party",
      Pleaseenterthenameparty: "Please enter the name of the party",
      FinancialNumber: "Financial Number",
      PreTaxIncome: "Pre-tax Income",
      personalIncomeTax: "personal Income Tax",
      ActualAmountReceived: "Actual Amount Received",
      CreditingAccountNumber: "Crediting Account Number",
      Issuestatus: "Issue status",
      Sent: "Sent",
          Unbilled: "Unbilled",
          DistributionTime:'Distribution time',

      // 开庭日历
      Optionmonth: "Option month",
      Jumptotecifmonth: "Jump to the specified month",

      // 消息代办事项
      MessageStatus: "Message Status",
      MessageHeader: "Message Header",
      Gotonotifynstruction: "Go to notify instruction",
      NotificationMessage: "Notification Message",
      Dearpartyconcerned: "Dear party concerned",
      Notsubmittedorganizatiyet:
        "Not submitted to CIETAC (no organization name yet)",
      Notificationinstruction: "Notification instruction",
      Posttitlequery: "Post title query",
      Titleofpost: "Title of post",
      TypeofPosting: "Type of Posting",

      // 日期模板
      Messagecenter: "Message Center",
      MessageType: "Message Type",
      All: "All",
      StartDate: "Start Date",
      EndingDate: "Ending Date",

      // 收发管理---模块
      CourierNumber: "Courier Number",
      Enterthetrackingquery: "Enter the tracking number to query",
      Pleasemovethecursoricketbarcode:
        "Please move the cursor to this place when scanning the express ticket bar code",
      Todayhasreceivedtheexpresscode:
        "Today has received the express scanning code",
      Pieces: "Pieces",
      DataEntryKeyers: "Data Entry Keyers",
      ReceivedDate: "Received Date",
      Addthesenanually: "Add the sender is unit manually",

      //收文登记
      Registerofreceipt: "Register of receipt",
      Subjectofreceipt: "Subject of receipt",
      Pleaseentertherecetitle: "Please enter the receiving title",
      Numberofdocumentsreceived: "Number of documents received",
      Pleaseenterthenumbereceived: "Please enter the number of copies received",
      Tobefiledno: "To be filed no",
      Methodofletter: "Method of letter",
      Pleasechooseheetter: "Please choose the method of letter",
      Typeofletter: "Type of letter",
      Pleaseselectthetyetter: "Please select the type of letter",
      Unitofletter: "Unit of letter",
      Typeofcorrespondence: "Type of correspondence",
      Nameofthewriter: "Name of the writer",
      PostalCode: "Postal Code",
      Province: "Province",
      Handoff: "Handoff",
      Addresofthsender: "Address of the sender",
      Pleaseenterhemployer: "Please enter the employer",
      Pleaseentethedresseeaddress: "Please enter the addressee is address",
      PostcodeoftheAddressee: "Postcode of the Addressee",
      Callerphonenumber: "Caller is phone number",
      Pleaseentetheelepsender:
        "Please enter the telephone number of the sender",
      ThesenderFaxes: "The sender Faxes",
      Nationalityofthewriter: "Nationality of the writer",
      Provincfthewrite: "Province of the writer",
      Persontoin: "Person to log in",
      Signer: "Signer",
      Whetherhecompanyislisted: "Whether the company is listed",
      Thecontentoftheletter: "The content of the letter",
      Pleaseenterthecontent: "Please enter the content",

      // 收文统计
      Dateofeceiptfrom: "Date of receipt from",
      Arrive: "Arrive",
      Copies: "Copies",
      Receiptnumber: "Receipt number",
      Dateofletter: "Date of letter",
      Detailsofreceipt: "Details of receipt",
      Issuenumber: "Issue number",
      Pleasenterthemessagenumber: "Please enter the message number",
      Awardnumber: "Award number",
      Pleaseentertheawardnumber: "Please enter the Award Number",
      Pleaseenterfilingecretary: "Please enter filing secretary",
      Pleaseenterhecaseecretary: "Please enter the case secretary",
      Caseyear: "Case year",
      Partynameunit: "Party Name/Unit",
      Pleaseenterpartynamunit: "Please enter party Name/Unit",
      Agentnameunit: "Agent Name/Unit",
      Pleaseentergenameunit: "Please enter agent Name/Unit",
      Dateoffiling: "Date of Filing",
      Handoverdate: "Handover date",
      Target: "Target",
      ArbitrationFees: "Arbitration Fees",
      Amountofmoneyinvolvedcase: "Amount of money Involved in the Case",

      //全文检索
      FilenameRetrieval: "File name Retrieval",
      ContentDetection: "Content Detection",
      Pleaseenterhecontentharacters:
        "Please enter the content (at least two characters)",
      Bycasenumber: "By case Number",
      Selectchapter: "Select chapter",
      Handovertarttime: "Handover start Time",
      HandoverendTime: "Handover end Time",
      Find: "Find",
      Viewcurrentpage: "View current page",
      ViewcurrentFile: "View current File",
      DownloadcurrentFile: "Download current file",
      HandoverTime: "Handover Time",

      // 上传材料ocr
      PleasentertheReceivingNumber: "Please enter the Receiving Number",
      IdentificationQueue: "Identification Queue",
      StartrecognitionTime: "Start Recognition Time",
      CurrentState: "Current State",
      Identifying: "Identifying",
      Submitter: "Submitter",
      Identify: "Identify",
      Reprint: "Reprint",
      Describe: "Describe",
      UploadTime: "Upload Time",
      DeletetheuploadedFile: "Delete the uploaded File",
      UploadDirectory: "Upload Directory",
      ClicktoUpload: "Click to Upload",
      ModifyDescription: "Modify Description",
      PrintQRcode: "Print QR code",
      Print: "Print",
      Dossier: "Dossier",
      ElectronicProgram: "Electronic Program",
      Factsofhecase: "Facts of the Case",
      ImageprojectionFile: "Image projection File",
      Notyet: "Not yet",
      Acceptanceof: "Acceptance of",

      TheFullState: "The Full State",
      IftheMoneyhasBeenCredited: "If the Money has Been Credited",
      Numberofcallsback: "Number of calls back",

      // 复用
      Addproofofthesubjectisqualifications:
        "Add proof of the subject is qualifications",
      AddAttachment: "Add Attachment",
      NameoftheMaterial: "Name of the Material",
      Uploadfileprogress: "Upload file progress",
      Save: "Save",
      AddthepowerofAttorney: "Add the power of Attorney",
      ViewAttachments: "View Attachments",
      UploadAttachment: "Upload Attachment",
      Fileuploaded: "File uploaded",
      FileDescription: "File Description",
      Dateofsubmission: "Date of Submission",
      FileName: "File Name",
      OperationLog: "Operation Log",
      SelectFile: "Select file",
      PleaseentertheDescriptionoftheFile:
        "Please enter the Description of the File",
      PleaseenteraFileName: "Please enter a File Name",
      StartTime: "Start Time",
      EndTime: "End Time",
      Review: "Review",
      OptionDate: "Option Date",
      CaseInformation: "Case Information",
      ApplyForInvoices: "Apply For Invoices",
      InvoiceInformation: "Invoice Information",
      Date: "Date",
      Status: "Status",
      Emailaddress: "E-mail address",
      Groundsforrejection: "Grounds for rejection",
      InformationRetrieval: "Information Retrieval",
      Currency: "Currency",
      effective: "Effective",
      invalid: "Invalid",
      IDNumber: "ID Number",
      BasicInfo: "Basic Info",

      // 消息提示
      Cancelled: "Cancelled",
      Undelete: "Undelete",
      SuccessfullyDelete: "Successfully Delete",
      FailtoDelete: "Fail to Delete",
      Whethertodelerackingnumber: "Whether to delete the tracking number",
      Thesearchtrackingnumbercannompty:
        "The search tracking number cannot be empty",
      ModifySuccessfully: "Modify Successfully",

      // 按钮
      finder_cancel: "Cancel",
      finder_confirm: "Confirm",
      header_logout: "logout",
      back: "back",
      View: "View",
      Preview: "Preview",
      Download: "Download",
      Operate: "Operate",
      Add: "Add",
      Return: "Return",
      Edit: "Edit",
      MoveUp: "Move Up",
      Delete: "Delete",
      Revamp: "Revamp",
      ViewLog: "View Log",
      Confirm: "Confirm",
      ShutDown: "Shut Down",
      ScannedVersion: "Scanned Version",

      TechnicalSupportContactPhoneNumber:
        "Technical support contact phone number",
      CIETAC: "China International Economic and Trade Arbitration Commission",

      // 特殊程序指令 页面
      SpecialProgramInstructions: "Special program instructions",

      // 2024/12/4  更新在线申请发票使用说明
      zxfpsq1:
        "Case number [Case Number], applicant's invoice extraction code: [Invoice Extraction Code].",
      zxfpsq2: "The specific operation steps are as follows:",
      zxfpsq3:
        '1. Please log in to the CIETAC official website www.cietac.org, scan the QR code at the bottom of the page to download "CIETAC Smart Platform", or search for "CIETAC Smart Platform" in Huawei App Market or Apple Store, or search for the WeChat mini-program "CIETAC Smart Platform".',
      zxfpsq4:
        '2. Open the "CIETAC Smart Platform", as shown in the following figure.',
      zxfpsq5: "No registration and login required.",
      zxfpsq6:
        "On the homepage, click on [Apply for Invoice Receipt] and select [Invoice Application], enter the invoice extraction code, then fill in the invoice information, save and submit to apply for the invoice online.",
      zxfpsq7:
        "Note: If you do not have an invoice extraction code, please click on the above [Retrieve Invoice Extraction Code], enter the basic information of the person applying for the invoice, click next, the system will automatically generate an invoice extraction code, and then proceed to invoice application.",
      zxfpsq8:
        "3. Online submission of invoice application, as shown in the following figure.",
      zxfpsq9:
        "After successful login, select the case for which you want to apply for an invoice, choose to apply for invoice issuance, fill in the type of ticket, cost category, claimed amount, etc. If there is a change in the invoice name, etc., an explanation needs to be uploaded. If the invoice needs to be mailed, then fill in the mailing information. Finally, click the Apply for Invoice button. After success, you can see the submission status.",
      zxfpsq10: `Note: ① According to the tax bureau's requirement of "three flows in one" (i.e., the unification of goods flow, capital flow, and invoice flow), for CIETAC, it means that the parties, the payers of arbitration-related fees, the refund recipients, and the invoice recipients should be unified. Therefore, CIETAC only handles the collection and refund of arbitration-related fees and the issuance of invoices for case parties.`,
      zxfpsq11:
        "② Please carefully verify the invoice information before submission. If the invoice needs to be reissued due to errors made by the party, a replacement can only be made with an original explanation document stamped with the company seal provided by the party.",
      zxfpsq12: "For technical issues, please call: 010-82217622 17603319137",
          zxfpsq13: "For financial issues, please call: 010-82217606",
      


             //   uploadScanSecDoc上传扫描件(经办人)
             UploadScannedCopyHandler:'Upload scanned copy (handler))',

                    //   2024-12-16添加报酬查询的字段
                    CaseStatus:'Case status',
                    //   FinancialNumber:'财务编号',   有
          DistributionStatus: 'Distribution status',
                      
        //   2025-3-17
          showCietac: "CIETAC(Description)",
			smBtn: 'Tips',
			// 提示
			showTips:
				"When the applicant and the respondent log in to the CIETAC Smart Platform, they must register first and then log in. It is recommended to register using a mobile phone for convenience and speed; After the applicant logs in, they can apply for arbitration. After the respondent logs in, please perform a case binding operation first to see the case.",
			showTips_one:
				"When arbitrators log in to the CIETAC Smart Platform, they do not need to register. If they do not have an account, they can obtain it from the handling secretary or CIETAC. After logging in, they can handle cases online and browse or download electronic and scanned evidence materials.",
      showTips_two: "The handling personnel can directly log in using their case management system account without the need for separate registration.",
          // 2025-3-17
            
             // 2025-3-18
          Uploader:'Uploader',
          // 2025-3-18

            // 2025-3-21
          IdTypeLang: 'Passport / Hong Kong/Macau Pass',
               SendToMobile: 'Send to mobile',
          Landline:'Landline',


          //   新的注册指引
          zczy1: 'I. Register an Account',
          zczy2: 'Participants can register an account on the smart platform through two methods:',
          zczy3: '1. Log in to the official website of China International Economic and Trade Arbitration Commission (CIETAC) at www.cietac.org, click on "Online Filing".',
          zczy4: '2. Directly access the platform via www.cietacodr.org.',
          zczy5: 'New users please register first. You can click "Register Now" in the bottom right corner of the platform homepage and register using either your phone or email.',
          zczy6: '1. Mobile Registration',
          zczy7: 'The system defaults to mobile registration. Please fill in the required information, including phone number, email, name, ID number, and set a password. Click "Send" to receive an SMS verification code and enter it.',
          zczy8: 'After filling in the above information, please read and check the "Service Agreement." After checking, click "Register Now" (as shown in the figure).',
          zczy9: '2. Email Registration',
          zczy10: 'To switch to "email registration," please click on the option at the top of the registration page, as shown in the figure below:',
          zczy11: 'Fill in the required information as above, receive a verification code via email, and complete the registration.',
          zczy12: 'Once the system confirms the successful registration, it will automatically return to the login homepage.',
          zczy13: 'II. Log in to the Platform',
          zczy14: '1. The parties can enter the registered account and password into the smart platform through the official website, smart platform and the official account of "China International Economic and Trade Arbitration Commission". In order to facilitate you to receive various notifications in the program, it is recommended to bind personal WeChat through "Settings".',
          zczy15: 'How to bind WeChat:',
          zczy16: 'After logging in to the smart platform, click on the upper right corner to access "Settings," fill in your personal information, and bind your WeChat.',
          zczy17: 'After binding WeChat, you can use WeChat QR code login next time.',
          zczy18: '2. Forgot Password',
          zczy19: 'If you have forgotten your account password, you can click "Forgot Password" on the homepage and follow the prompts. You can retrieve your password via your phone number or email.',
          zczy20: 'To retrieve via phone number: Enter the registered phone number, click "Send" to receive the verification code, enter the code received on your phone, then proceed to the next step. You will then need to reset your password. After resetting, click "Confirm," return to the login page, and log in using the account and new password.',
          zczy21: 'To retrieve via email: Enter the registered email, click "Send" to receive the verification code, enter the code received in your email, then proceed to the next step. You will then need to reset your password. After resetting, click "Confirm," return to the login page, and log in using the account and new password.',
          // 2025-3-21
          

          //   2025-3-24
          lazy1: "How to create a new case, fill in the information, and submit it for CIETAC case review",
  lazy2: "To better assist parties in understanding and using the CIETAC Smart Platform, CIETAC has introduced a series of user guides for the platform. The立案篇 (Layuan Pian) Part 1 explained how to register an account and log in to the Smart Platform, with detailed content available in \"2024 CIETAC Smart Platform User Guide · Layuan Pian (Part 1): How to Register an Account and Log in to the CIETAC Smart Platform.\" This article will guide parties on how to create a new case, fill in the information, and submit it for CIETAC case review.",
  lazy3: "1. Creating a New Arbitration Case",
  lazy4: "After registering your account and logging in, enter the \"My Cases\" interface.",
  lazy5: "Click \"Create New Case\".",
  lazy6: "2. Filling in Case Information",
  lazy7: "Please ensure that the applicant accurately enters the relevant information and that the paper text is completely consistent with the electronic text.",
  lazy8: "1. Filling in Information of Both Parties and Their Representatives",
  lazy9: "After selecting to create a new case, you will enter a new case page. Please fill in the relevant information step by step.",
  lazy10: "Please first select the type of party (individual, legal person, other organization, or sole proprietorship) and then fill in the relevant party information. Items marked with * are mandatory.",
  lazy11: "Please note:",
  lazy12: "(1) When uploading files, please rename the files to match the corresponding materials, such as ID card, business license, etc;",
  lazy13: "(2) If the applicant is an individual, please submit scanned copies of both sides of the ID card or other valid identification documents, such as Home Return Permit, Hong Kong ID card, Taiwan Compatriot Pass, passport, etc. (Please ensure the identification is valid);",
  lazy14: "(3) If the parties to the case involve individuals, please inform them in writing whether they fall under the circumstances stipulated in Article 13 of the Interpretation of Several Issues Concerning the Application of the Law Applicable to Civil Relations Involving Foreign Elements by the Supreme People’s Court (hereinafter referred to as the \"Interpretation\") so as to confirm the applicable procedures. [Article 13: If a natural person has continuously resided in a place for more than one year at the time of the establishment, change, or termination of foreign-related civil relations and such place is the center of his or her life, the people's court may determine such place as the habitual residence of the natural person under the Law Applicable to Civil Relations Involving Foreign Elements, except for medical treatment, labor dispatch, and official duties.]",
  lazy15: "(4) If the applicant is a legal person within mainland China, please upload scanned copies of a valid business license and the original certificate of legal representative (with the date of issuance);",
  lazy16: "(5) If the applicant is a legal person from Hong Kong, Macau, Taiwan, or a foreign country, please submit valid commercial registration certificates issued by the relevant authorities in the place of registration. If the submitted documents are not in English, please provide a translation in the same language as the arbitration language;",
  lazy17: "(6) All documents submitted by the parties do not need to be notarized during the立案 stage. If the opposing party raises objections or the arbitration tribunal deems it necessary, notarization of evidence formed abroad may be required during the arbitration process;",
  lazy18: "(7) The name of the party must match the seal. If they do not match, please submit an explanatory document. If the party name has changed, please submit relevant evidence of the name change.",
  lazy19: "Email:",
  lazy20: "Please note:",
  lazy21: "(1) When uploading files, please rename the files to match the corresponding materials, such as \"ID card\", \"Business License\", etc. (Please ensure they are valid);",
  lazy22: "(2) If the respondent is an individual, the applicant may submit a copy of the respondent’s ID card or a scanned copy of other valid identification documents, such as Home Return Permit, Hong Kong ID card, Taiwan Compatriot Pass, passport, etc. (Please ensure they are valid);",
  lazy23: "(3) If the respondent is a legal person within mainland China, the applicant should submit documents proving the respondent’s continued legal existence, such as printouts from platforms like Qi Xin Wang or Qi Cha Cha;",
  lazy24: "(4) If the respondent is a legal person from Hong Kong, Macau, Taiwan, or a foreign country, the applicant may submit documents proving the respondent’s continued legal existence;",
  lazy25: "(5) The name of the party must match the seal. If they do not match, please submit an explanatory document. If the respondent’s name has changed, please submit relevant evidence of the name change.",
  lazy26: "Please note:",
  lazy27: "(1) When uploading files, please rename the files to match the corresponding materials, such as \"Power of Attorney\", \"Law Firm Letter\", etc., and specify the date of issuance;",
  lazy28: "(2) If the parties appoint arbitration agents, please submit scanned copies of the original power of attorney. The scope of authority should be specified as \"arbitration\" rather than \"litigation\". Please do not specify only \"general authority\" or \"special authority\";",
  lazy29: "(3) All documents submitted by the parties do not need to be notarized during the立案 stage. If the opposing party raises objections or the arbitration tribunal deems it necessary, notarization of evidence formed abroad may be required during the arbitration process;",
  lazy30: "(4) If the principal is a legal person from Hong Kong, Macau, Taiwan, or a foreign country, please upload scanned copies of the original power of attorney signed by an authorized signatory or stamped by the company, recognized by the place of registration. If the power of attorney is signed by an individual, the name of the signatory should be printed in the document, and evidence of their authority to represent the company in signing relevant legal documents should be submitted (e.g., board resolution);",
  lazy31: "(5) If there are multiple agents, please enter the relevant information separately to ensure consistency with the power of attorney.",
  lazy32: "2. Filling in Delivery Addresses",
  lazy33: "After entering the information of both parties, please click \"Add\" to fill in the delivery addresses.",
  lazy34: "Please note that delivery information must be accurately filled in! The applicant’s/ applicant’s agent’s phone number and email will receive messages from the Smart Platform. Therefore, please ensure accurate entry of phone numbers and emails to avoid missing messages and delaying the progress of the case.",
  lazy35: "Please note that when filling in the \"Applicant’s Delivery Address\", only one delivery address can be entered per applicant.",
  lazy36: "Please note that the applicant is obligated to inquire and provide the respondent’s valid delivery address. If the applicant fails to honestly provide the respondent’s valid delivery address, resulting in incomplete or failed delivery of case-related legal documents, the applicant shall bear the legal consequences arising therefrom.",
  lazy37: "The applicant may provide multiple delivery addresses for the respondent.",
  lazy38: "Attachment: Delivery Address Confirmation Form",
  lazy39: "If multiple delivery addresses for the respondent are provided, please specify the order of delivery. CIETAC will deliver arbitration documents to the respondent in the specified order. If simultaneous delivery is required, please submit a clear written application and provide the corresponding number of paper documents.",
  lazy40: "3. Filling in Arbitration Basis",
  lazy41: "Please click \"Add\" to enter the arbitration basis.",
  lazy42: "Please accurately enter the contract name, arbitration clause, and if available, the signing date, contract number, and signing location.",
  lazy43: `(If arbitration is initiated based on multiple contracts, after entering one arbitration basis, please click "Add" again to continue entering the relevant contracts.)`,
  lazy44: "Please note:",
  lazy45: "(1) Please enter the names, numbers, signing dates, and signing locations of the case-related contracts one by one;",
  lazy46: "(2) If the applicant initiates arbitration based on similar orders (with the same contract name and arbitration clause), they may prepare a written letter listing all case-related contract numbers, signing dates, etc., stamp or sign it, and submit it in the evidence section;",
  lazy47: "(3) If the applicant initiates arbitration based on multiple contracts, please refer to Article 14 of the Arbitration Rules and submit relevant explanations if necessary.",
  lazy48: "Article 14: Arbitration Involving Multiple Contracts and Addition of Contracts During Arbitration",
  lazy49: "(1) An applicant may combine disputes arising from multiple contracts into a single arbitration case, but the following conditions must be met:",
  lazy51: "1. The multiple contracts are in a principal-agent relationship, or the multiple contracts involve the same parties and the same nature of legal relations, or the multiple contracts involve related subject matters;",
  lazy52: "2. The multiple contracts are derived from the same transaction or a series of transactions;",
  lazy53: "3. The arbitration agreements in the multiple contracts are identical or compatible.",
  lazy54: "(2) If the above conditions (1.1, 1.2, 1.3) are met, the applicant may apply to add a contract during the arbitration process. However, if the application is made too late to affect the normal progress of the arbitration, the addition of the contract may be rejected.",
  lazy55: "(3) The procedures stipulated in Article 14(1) and (2) shall be decided by the Arbitration Institute of the Arbitration Commission. Applications to add contracts made after the formation of the tribunal shall be decided by the tribunal.",
  lazy56: "4. Filling in Arbitration Claims, Facts, and Reasons",
  lazy57: "Please click \"Add\" to enter the arbitration claims, facts, and reasons.",
  lazy58: "Note: Please list all arbitration claims in sequence and clearly indicate the numbers.",
  lazy59: "5. Preservation",
  lazy60: "If the applicant requires property, evidence, or behavioral preservation, please click \"Add\" and fill in the court name, court address, briefly describe the preservation content, and upload the scanned copy of the original application for property preservation.",
  lazy61: "If the applicant requires preservation from multiple courts, please click \"Add\" and fill in the relevant information separately. If no preservation is needed, this section may be left blank.",
  lazy62: "Please note: Except for the application for property preservation, the preparation of other property preservation documents should comply with the requirements of the relevant courts.",
  lazy63: "6. Uploading Applicant’s Evidence",
  lazy64: "Documents previously uploaded by the applicant, such as proof of legal status and power of attorney, are already displayed in the evidence section. Please do not upload them again. Only upload new evidence.",
  lazy65: "It is recommended that applicants submit evidence materials that are scanned clearly and appropriately sized (individual files should be about 500MB).",
  lazy66: "Please note:",
  lazy67: "1. When submitting evidence, please submit them in PDF format as much as possible, with continuous numbering and pagination. Ensure that the copies/scan are clear and complete; do not submit single photos or single-page PDF scan files;",
  lazy68: "2. When uploading evidence files, please rename the files to match the evidence name, or indicate the evidence number (e.g., Evidence 1 to Evidence 5);",
  lazy69: "3. If the original evidence is in English, no translation is required during the立案 stage. If the case-related contracts are in other languages, translations should be provided during the立案 stage (the language should match the arbitration language of the case). If the opposing party raises objections or the arbitration tribunal deems it necessary, translations of foreign language evidence may be required during the arbitration process.",
  lazy70: "7. Submitting for Registration and Obtaining a Registration Number",
  lazy71: "After accurately filling in the above information, click \"Submit to CIETAC\". Please note that if \"Submit to CIETAC\" is not clicked, the case will only be saved as a draft in your system.",
  lazy72: "After clicking \"Submit to CIETAC\", please select whether to submit to the Headquarters or a Sub-Committee based on the arbitration clause and the provisions of the Arbitration Rules, and then click \"Submit to CIETAC\" again.",
  lazy73: "Note: The China International Economic and Trade Arbitration Commission is the Headquarters based in Beijing.",
  lazy74: "After clicking \"Submit to CIETAC\", a case registration number will be prompted. Please remember your registration number during the立案 stage.",
          //   2025-3-24
  
  
  
          //   2025--4-3
          lazy75: '3. Review process and communication points for online filing',
          lazy76: '2. Create a new case, fill in the required information, and submit it for review by CIETAC',
          lazy77: 'To better help parties understand and use the CIETAC Smart Platform, CIETAC specially launches the "Smart Platform User Guide - Case Filing Series". Part 1 and Part 2 of the Filing Series respectively introduced how to register/login to the Smart Platform and how to create a case, complete filing materials and submit to CIETAC. For details, please refer to "2024 CIETAC Smart Platform User Guide - Case Filing Part 1: How to Register an Account and Log In" and "2024 CIETAC Smart Platform User Guide - Case Filing Part 2: How to Create a Case, Complete Materials and Submit for Filing Review". This article will introduce how to communicate with CIETAC during the filing review process.',
          lazy78: '1. Preliminary Review',
          lazy79: 'After the claimant submits a new case, CIETAC will conduct preliminary review promptly. The registered mobile number and email will receive notifications - please check and make corresponding modifications in time.',
          lazy80: 'After passing preliminary review, the case will be assigned to a case secretary and officially enter the filing review stage.',
          lazy81: 'The page you will see when preliminary review is passed',
          lazy82: 'The page you will see when preliminary review is not passed - please check the reasons and make corrections promptly',
          lazy83: '2. Contacting the Case Secretary',
          lazy84: 'After the case secretary is assigned, the claimant may log in to the platform and view the secretary\'s name and phone number under "My Cases". For any inquiries, the claimant may contact the case secretary.',
          lazy85: 'The claimant may also use the "Message Board" to submit messages and communicate with the case secretary regarding case matters.',
          lazy86: '3. Case Return',
          lazy87: 'If modifications or supplementary materials are required after filing review, the case secretary will return the case to the claimant\'s system with instructions. The registered mobile and email will receive return notifications - please check and make corrections promptly.',
          lazy88: 'After making required modifications or supplements, remember to click "Submit to CIETAC" promptly to avoid delaying case progress.',
          lazy89: '4. Filing Review Approval',
          lazy90: 'Upon approval, the registered mobile/email will receive notifications - please check and take corresponding actions:',
          lazy91: '(1) Check the approval notice under "Messages·To-dos" or by clicking the specific case, and submit paper materials as required.',
          lazy92: '(2) Under "My Cases", select "Notices·Instructions" to view the PDF payment notice and pay the arbitration advance payment accordingly.',
          lazy93: 'After approval you will see the following page:',
          lazy94: '(3) Click the "PDF" button under "Generate PDF Arbitration Application" to preview/download. Print the PDF version and mail the required copies to CIETAC along with other materials. (Note: 1. This PDF version has passed CIETAC review. To improve efficiency, please submit this version with official seal or agent signature.)',
          lazy95: '2. Besides the application, evidence and qualification documents, all online submissions should also be provided in paper version with seal/signature.)',
          lazy96: 'After generating the PDF application, prepare all arbitration documents and mail to CIETAC promptly while paying the arbitration fee (no sequence requirement). The case will proceed after CIETAC receives both paper documents and payment.',
          lazy97: 'At this point, the online filing process is completed.',
          lazy98: 'CIETAC encourages parties to use the Smart Platform for evidence exchange and document service. All electronically submitted documents will be automatically included in the case file without re-uploading.',
          lazy99: 'Thank you for using CIETAC Smart Platform. We look forward to providing more professional, efficient and convenient arbitration services!'
        //   2025--4-3
    },
  },
  zh: {
    msg: {
      hello: "敬请期待",
      lang: "English",
      UnitName: "中国国际经济贸易仲裁委员会",
      login_account_type: "账号登录",
      login_wechat_type: "微信登录",
      login_username: "请输入手机号/邮箱",
      login_password: "请输入密码",
      login_forget: "忘记密码",
      login_verify_code: "请输入验证码",

      login_btn: "立即登录",
      register_btn: "立即注册",
      register_type_mobile: "手机注册",
      register_type_email: "邮箱注册",
      register_mobile_no: "手机号",
      register_mobile_no_tip: "请输入手机号",
      register_rec_code: "验证码",
      register_rec_code_tip: "请输入验证码",
      register_email: "邮箱",
      register_email_tip: "请输入邮箱地址",
      register_real_name: "姓名",
      register_real_name_tip: "请输入姓名",
      register_address: "地址",
      register_address_tip: "请输入地址",
      register_tel_no: "证件号码",
      register_tel_no_tip: "请输入证件号码",
      register_pass: "密码",
      register_pass_tip: "请输入密码",
      register_confirm: "确认",
      register_confirm_tip: "再次输入密码",
      register_now: "立即注册",
      register_to_login: "已有账号？去登录",
      register_agree: "我已阅读并同意",
      register_service_agreement: "《服务协议》",
      register_notification:
        "中国国际经济贸易仲裁委员会在线争议解决平台使用告知书",
      found_forget_pass: "忘记密码",
      found_verify: "验证",
      found_set_new: "设置新密码",
      found_new: "新密码",
      found_by_mobile: "通过手机号找回",
      found_by_email: "通过邮箱找回",
      found_next: "下一步",
      found_prev: "上一步",
      register_safe: "安全校验",
      register_send: "发送",
      register_resend: "重新发送",
      header_setting: "设置",
      home_workbench: "工作台",
      home_case: "我的案件",
      home_dispatch: "收发管理",
      home_analyze: "统计查询",
      bread_current: "当前位置",
      case: "案件编号",
      case_list: "案件列表",
      case_num: "案件编号",
      bind_num: "绑定码",
      case_search: "查询",
      case_reset: "重置",
      case_bind: "绑定",
      case_bind_dialog_title: "绑定案件",
      case_bind_cancel: "取消",
      case_bind_confirm: "确认",
      case_my: "我的案件",
      case_name: "案件名称",
      case_status: "案件状态",
      case_content: "案件内容",
      case_create_time: "提交时间",
      case_in_progress: "进行中",
      finder_upload: "上传",
      finder_create_folder: "新建文件夹",
      finder_folder_name: "名称",
      finder_search: "搜索",
      finder_table_name: "名称",
      finder_table_type: "类型",
      finder_table_size: "大小",
      finder_table_update: "更新时间",
      finder_table_power: "权限",
      finder_upload_tip: "将文件拖到此处，或点击上传",
      finder_no_data: "没有数据",
      finder_count: "数量",
      finder_size: "大小",
      finder_selected_count: "已选中",
      setting_personal: "个人信息",
      setting_pass: "修改密码",
      setting_pass_now: "当前密码",
      workbench_print: "快递单打印",
      workbench_calendar: "日程日历",
      workbench_timer: "定时器管理",
      workbench_tip: "提醒管理",
      workbench_more: "更多",
      workbench_todo: "待办事项",
      workbench_case_tip: "案件提醒",
      workbench_tool: "快捷工具",
      workbench_search_info: "案件内容",
      workbench_search_no: "案件编号",
      other_link1: "贸仲官网",
      other_link2: "网上立案",
      other_link3: "在线庭审",
      other_link4: "域名争议解决",
      other_link5: "网上仲裁",
      not_sign: "待签章",
      have_sign: "已签章",
      declaration_signature_agree_left: "本人已经阅读《",
      declaration_signature_agreement: "廉洁办案告知书",
      declaration_signature_agree_right: "》，同意签署声明书",
      declaration_signature_dialog_close: "返回",
      declaration_signature_dialog_no: "否",
      declaration_signature_dialog_yes: "是",
      declaration_signature_my_signature: "My Signature 我的签名",
      declaration_signature_accept_assign: "接受指定",
      declaration_signature_confirm: "确认签名",
      declaration_signature_success: "签名成功",
      declaration_signature_preview: "预览声明书",
      dialog_confirm: "确定",
      arbitrament_signature_preview: "查看裁决书",
      arbitrament_signature_agreement: "同意签名",
      S_N: "序号",
      Application_Number: "申请编号",
      WithdrawACase: "撤案",
      Receipt: "收据",
      // 首页
      NewCase: "新建案件",
      AddaCase: "添加案件",
      Inform: "通知",
      Directive: "指令",
      Notice: "消息",
      OperationInstructions: "操作指引",
      HistoricalCaseSearch: "历史案件查询",
      RemunerationInquiry: "报酬查询",
      CalendarOfHearings: "开庭日历",
      InstrumentTemplateDownload: "文书模版下载",

      // 主页面列表头部
      Claimant: "申请方",
      Respondent: "被申请方",
      Secretary: "秘书",
      TelephoneNumberofSecretary: "秘书电话",
      GenerateaPDFArbitrationApplication: "生成PDF版仲裁申请书",
      GeneratePDF: "生成PDF",
      ReasonForWithdraw: "撤案理由",
      PleaseEnterTheReasonForReasonForWithdraw: "请输入撤案理由",
      Reason: "理由",
      ConfirmtheDismissaloftheCase: "确认撤案",
      ProcessingTime: "受理时间",
      CaseSecretary: "办案秘书",
      Arbitrator: "仲裁员",
      //案件状态
      SubmitApplication: "提交申请",
      FormationofCourt: "组庭",

      // 新建案件
      //当事人
      Case: "案件",
      NoCaseNumberisAvailable: "暂无案件编号",
      ViewingErrors: "查看错误",
      MessageBoard: "留言板",
      DeleteTheCurrentCase: "删除当前案件",
      SubmittoCIETAC: "提交至贸仲",
      ApplicationNumber: "申请号",
      Party: "当事人",
      Applicant: "申请人",
      TheDefendant: "被申请人",
      AddTheDefendant: "添加被申请人",
      AgentofTheApplicant: "申请人代理人",
      AgentoftheRespondent: "被申请人代理人",
      AddanApplicant: "添加申请人",
      AddAgentofTheApplicant: "添加申请人代理人",
      AddAgentoftheRespondent: "添加被申请人代理人",
      AddApplicantInformation: "添加申请人信息",
      AddTheDefendantInformation: "添加被申请人信息",
      PartyType: "当事人类型",
      TypeofRespondent: "被申请人类型",
      Name: "姓名",
      NameofCountryRegion: "国家/地区名称",
      IDType: "证件类型",
      IDNo: "证件号码",
      DomicileDocumentAddress: "住所/证件地址",
      Company: "单位名称",
      LegalPersonCountryRegion: "法人国家/地区",
      LegalEntityType: "法人类型",
      Pleaseselectrdingtothebusinesslicense: "请按照营业执照选择法人分类",
      ForeignFactors: "外商因素",
      Classification: "企业分类",
      UnifiedSocialCreditCodeBusinessLicenseNo: "统一社会信用代码",
      Businesslicensenumber: "营业执照编号",
      Residence: "住所",
      Businesslicenseaddress: "营业执照地址",
      OrganizationName: "组织名称",
      TaxpayerIdentificationNumber: "纳税人识别号",
      Firm: "商号",
      Operator: "经营者",
      Certificateofidentityoflegalrepresentative: "法定代表人身份证明书",
      Addressbusinesslicenseaddress: "住所(营业执照地址)",

      // 代理人

      Addapplicantagentinformation: "添加申请人代理人信息",
      Client: "委托人",
      AgentsName: "代理人名称",
      Nationality: "国籍",
      ContactNumber: "联系电话",
      SelectAddress: "选择地址",
      WhetherOverseas: "是否境外",
      Overseas: "境外",
      ConfirmtheAddress: "确认地址",

      //送达地址
      AddressForService: "送达地址",
      PersonServingaDocument: "受送达人",
      Recipients: "收件人",
      TelephoneNumberforDelivery: "送达电话",
      Email: "电子邮箱",
      DetailedAddress: "详细地址",
      ApplicantsAddressofService: "申请人送达地址",
      ServiceAddressoftheRespondent: "被申请人送达地址",

      //仲裁依据
      ArbitrationBasis: "仲裁依据",
      AddArbitrationBasis: "添加仲裁依据",
      ContractName: "合同名称",
      SigningDate: "签订日期",
      ContractNo: "合同号",
      ArbitrationClause: "仲裁条款",
      TypeofSigningDate: "签署日期类型",
      SigningPlace: "签订地点",
      YearMonthDay: "年月日",
      YearMonth: "年月",

      //仲裁请求
      ArbitrationClaims: "仲裁请求",
      FactsandReasons: "事实与理由",
      ViewArbitrationClaim: "查看仲裁请求",
      ViewFactsandReasons: "查看事实与理由",
      AddarbitrateRequests: "添加/仲裁请求",
      AddmodifyanArbitrationRequest: "添加/修改仲裁请求",
      TypeofAmount: "金额种类",

      //保全
      ConservatoryMeasures: "保全",
      AddConservatoryMeasures: "添加保全",
      PreservationType: "保全类型",
      CourtName: "法院名称",
      CourtAddress: "法院地址",
      Application: "保全内容",
      PleaseenterApplication: "请输入保全内容",

      // 证据材料
      ApplicantsEvidentiaryMaterials: "申请人证据材料",
      Annex: "附件",
      AddApplicantMaterials: "添加申请人材料",
      EvidenceName: "证据名称",
      EvidencePurpose: "证明内容",

      // 留言板
      LeaveAMessage: "留言",
      SelectSubmittoHeadquartersChapters: "选择提交总会/分会",
      YourCaseNumberIs: "您的立案编号为",
      KeepinMind: "请牢记",
      AddaMessage: "添加留言",
      Time: "时间",
      Publisher: "发布人",
      Content: "内容",
      PleaseentertheMessageContent: "请输入留言内容",

      // 侧边栏
      CommonProblem: "常见问题",
      FeeCalculator: "费用快算",
      GuidetoRegistration: "注册指引",
      GuidelinesforFiling: "立案指引",
      GuidelinesonHandlingCases: "办案指引",
      CourtTrialGuidelines: "庭审指引",
      ApplyforBilling: "申请开票",
      ApplyforRefund: "申请退费",
      NotesonApplicationforArbitration: "仲裁申请须知",

      // ----------当事人管理
      //庭审
      OnlineCourtHearing: "网上开庭",
      AccessToTheCourtroom: "进入庭审",
      PleaseEnteraCourtroomCode: "请输入庭审码",
      //发票申请
      AmountOfMoney: "金额",
      DetailsofInvoiceApplication: "发票申请详情",
      TypeOfTicket: "票种",
      TypeOfExpense: "费用种类",
      AmountToBeInvoiced: "应开金额",
      AmountApplied: "申请金额",
      ApplicationAmountExchangeRatetoRMB: "申请金额(汇率转人民币)",
      Applicantts: "申领方",
      TaxCode: "税号",
      CompanyRegisteredAddress: "注册地址",
      InvoiceHeaderPhone: "发票抬头电话",
      AccountOpeningBank: "开户行",
      BankAccount: "银行账号",
      RefundamountRMB: "退费金额(人民币)",
      Telephonenumberofrecipient: "收件人电话",
      InvoicingInstructions: "开票说明",
      Addadescriptionofmakeoutaninvoice: "添加开票说明",
      Theuploadformatis: "上传格式为",
      BillingInstructionsPrecautions: "开票说明注意事项",
      NoteThereisnoinvoicingquota: "注:已无开票额度",
      Billinginstretheattachmentshauploathefol:
        "开票说明(注:以下两种情况须上传附件)",
      Ifthedrawhangeitshalldregistration:
        "如开票人变更名称，需上传准予变更(备案)登记通知书;",
      Anawardpagetheamounofthrbitrfeaded: "需上传带有仲裁费金额的裁决页;",

      //电子收据下载
      ElectronicReceiptdownload: "电子收据下载",
      NameofPayer: "交款人名称",
      Manager: "经办人",
      PaymentItems: "交款项目",
      amountInWords: "金额大写",
      AmountInFigures: "金额小写",
      Note: "备注",
      BillNumber: "账单编号",
      NoteBillNumber: "备注:账单编号:",

      //退费信息补录
      Refundinformationissupplemented: "退费信息补录",
      RMB: "人民币",
      foreignCurrency: "外币",
      PayeeAddress: "收款人地址",
      PleaseenterEnglish: "请输入英文",
      AddressofReceivingBank: "收款行地址",
      SubmissionofaSupplement: "提交补录",
      NameOfRecipientsAccount: "收款人账户名称",
      RecipientsAccount: "收款账号",
      Addressofthepayeeisopeningbank: "收款人开户行地址",

      // 裁决书签字
      SignatureStatus: "签名状态",
      Tobesigned: "待签名",
      HaveBeenSigned: "已签名",
      CaseManager: "经办秘书",
      Award: "裁决书",
      Declaration: "声明书",
      ArbitrationLanguage: "仲裁语言",
      ProcedureRules: "程序规则",
      TypeOfDispute: "争议类型",
      AmountinDispute: "争议金额",
      ViewTheAward: "查看裁决书",
      ViewDeclaration: "查看声明书",
      MySignature: "本人签名",
      IhaveCarefullyReadthisAwardandagreetoSignit:
        "本人已经详细阅读此裁决书，同意签署裁决",

      // 扫描文件管理
      ElectronicDossier: "电子卷宗",
      Sortbytime: "按时间排",
      sortbyType: "按类型排",
      Sort: "排序",
      InReverseOrder: "倒序",
      PositiveSequence: "正序",
      PartyMaterial: "当事人材料",
      ThereisnoScannedVersion: "暂无扫描版",
      Clicktoidentify: "点击识别",
      ElectronicVersion: "电子版",
      ThereisnoElectronicVersionYet: "暂无电子版",
      IssuanceOfCommunicationsByTheCaseManager: "经办人发文",

      ReceiptletterNumber: "收文函号",
      DateofReceipt: "收文日期",
      Nofiles: "无文件",
      Photocopy: "电子版",
      IssueletterNumber: "发文函号",
      PublicationDate: "发文日期",
      Material: "材料",
      Transfer: "转移",
      TheCurrentDirectory: "当前所在目录",
      TheDirectoryAftertheChange: "变更后所在目录",
      MaterialType: "材料类型",
      Alteration: "变更",

      // 声明书披露
      Identity: "身份",
      AppointedDate: "指定日期",
      Disclosure: "披露",
      IdeclarethatalthoughIconsiderthattherearenoArbitrationRulesoftheChinaInternationalEconomic:
        "本人声明，虽然本人认为在接受代为指定前无《中国国际经济贸易仲裁委员会仲裁规则》及《中国国际经济贸易仲裁委员会仲裁员守则》规定的必须回避的情形，并保证独立、公正、高效、勤勉地履行仲裁员的职责，但鉴于本人与当事人/当事人代理人存在如下可能引起当事人对本人公正性或独立性产生怀疑的情形，故给予披露",

      // 上传裁决书
      Nameofculture: "文化名",
      DateofUpload: "上传日期",

      //延载信息
      DelayCuttingInformation: "延载信息",
      NatureoftheCase: "案件性质",
      ApplicationDate: "申请日期",
      NumberofDeferred: "已延期次数",
      OriginalHearingDeadline: "原审理期限",
      NewTrialDeadlines: "新审理期限",

      // 开庭信息
      OpenaCourtSessionInformation: "开庭信息",
      LawfulDay: "开庭日期",
      PlaceOfHearing: "开庭地点",
      SittingRoom: "开庭室",
      HearingStatus: "开庭状态",

      // 仲裁员个人信息
      Modificationofarbitratorspersonalinformation: "仲裁员个人信息修改",
      ModifyPhotos: "修改照片",
      EnglishName: "英文姓名",
      DateOfBirth: "出生日期",
      EnglishNationality: "英文国籍",
      City: "城市",
      EnglishCity: "英文城市",
      EnglishCompany: "英文单位",
      EducationAttainment: "学历",
      EnglishEducationAttainment: "英文学历",
      OccupationalClassification: "职业分类",
      EnglishOccupationalClassification: "英文职业分类",
      EffectiveCommunicationAddress: "有效通信地址",
      EnglishEffectiveCommunicationAddress: "英文有效通信地址",
      Title: "职称",
      EnglishTitle: "英文职称",
      WorkUnitAddress: "单位地址",
      EnglishWorkUnitAddress: "英文单位地址",
      Position: "职务",
      EnglishPosition: "英文职务",
      EducationalBackground: "教育背景",
      EnglishEducationalBackground: "英文教育背景",
      WorkExperience: "工作经历",
      EnglishWorkExperience: "英文工作经历",
      ArbitrationRelatedWorkExperience: "仲裁相关工作经历",
      EnglishArbitrationRelatedWorkExperience: "英文仲裁相关工作经历",
      SocialTeamPartTimeJobSituation: "社会团队兼职情况",
      EnglishSocialTeamPartTimeJobSituation: "英文社会团队兼职情况",
      BusinessExpertise: "业务专长",
      EnglishBusinessExpertise: "英文业务专长",
      WorkingLanguage: "工作语言",
      EnglishWorkingLanguage: "英文工作语言",
      MainBusinessAchievements: "主要业务成果",
      EnglishMainBusinessAchievements: "英文主要业务成果",
      SelfDescriptionOrEvaluation: "自我描述或评价",
      EnglishSelfDescriptionOrEvaluation: "英文自我描述或评价",
      MobilePhoneNumber: "手机",
      PhoneNumber: "电话",
      Fax: "传真",
      HomePhoneNumber: "家庭电话",
      SavetheMessage: "保存信息",

      // 微信
      BindWeChat: "微信绑定",
      ClickBind: "点击绑定",
      Clickunbind: "点击解绑",
      Wechatmessage: "微信信息",
      RealNameAuthentication: "已实名认证",
      NotYetAuthenticated: "未实名认证",

      // 主页面
      GuidelinesforFilingArbitrationCases: "仲裁立案指引",
      Apartymaysubmitanapplicationforarbitrationinthreeways:
        "当事人提交仲裁申请，可通过线下立案、邮寄立案和线上立案三种方式进行。三种方式均无需预约。",
      Thepartieschooseofflinefilingandmailingfilingpleasereferto:
        "当事人选择线下立案和邮寄立案的，请参考",
      Instructionsforarbitration: "仲裁申请须知",
      Onlinefilingguide: "在线立案指引",
      PrepareyourfilingForonlinefilingguidelinespleasereferto:
        "准备您的立案文件。 关于在线立案指引，请参考",
      Submitanapplicationforarbitration: "提交仲裁申请",
      Summaryoffrequentlyaskedquestions: "常见问题汇总",
      Documentsrequiredtoapplyforarbitration: "申请仲裁所需材料",
      RefundofArbitrationFeesforWithdrawalofCase: "撤案仲裁费用的退费",
      GuidelinesforVideoTrial: "视频庭审指引",
      Issueaninvoiceforthearbitrationfee: "开具仲裁费发票",

      // 输入框名称
      PleaseEnteraName: "请输入名称!",
      PleaseSelect: "请选择",
      PleaseEnterTheIDNumber: "请输入证件号码",
      PleaseEnterResidenceDocumentAddress: "请输入住所/证件地址",
      Pleaseenterthenameoftheorganization: "请输入单位名称",
      Pleaseenterthebusinesslicensenumber: "请输入营业执照号",
      Pleaseenterresidencebusinesslicenseaddress: "请输入住所(营业执照地址)",
      PleaseenteranorganizationName: "请输入组织名称",
      PleaseEntertheOrganizationCode: "请输入组织机构代码",
      Pleaseenterthebusinessname: "请输入商号",
      Pleaseenteroperator: "请输入经营者",
      PleaseEntertheAgentName: "请输入代理人名称",
      PleaseenteryourcontactNumber: "请输入联系电话",
      PleaseenteryourEmailAddress: "请输入电子邮箱",
      PleaseentertheAddressDetails: "请输入详细地址",
      PleaseEntertheContractName: "请输入合同名称",
      PleaseentertheArbitrationClause: "请输入仲裁条款",
      SelecttheYearandMonth: "选择年月",
      SelecttheyearmonthandDay: "选择年月日",
      Optionalyear: "选择年",
      PleaseentertheContractNumber: "请输入合同号",
      PleaseenterthePlaceWhereyouSignedtheContract: "请输入签订地点",
      PleaseentertheNameofCourt: "请输入法院名称",
      PleaseentertheCourtAddress: "请输入法院地址",
      PleaseentertheRecipient: "请输入收件人",
      PleaseenteryourMobilePhoneNumber: "请输入手机号码",
      PleaseentertheDeliveryAddress: "请输入送达地址",
      Pleaseenterhecasenumber: "请输入案件编号",
      Pleaseenterthecasename: "请输入案件名称",
      Pleaseenterthehandler: "请输入经办人",
      Pleaseenterarbitrator: "请输入仲裁员",

      // 提示文本
      Article21ofthecETACArbitranformation:
        "贸仲现行《仲裁规则》（2024版）第二十一条规定“电子文本与纸质文本不一致的，以电子文本为准，除非当事人另有约定”。因此，请申请人慎重、准确录入相关信息。",

      // 当事人
      WhenuploadingafilepleasechangethenameofthefiletobeploadedtothenameofthecorrespondingmaterialuchasIDcardbusinesslicenseetc:
        "在上传文件时，请将待上传文件名称改为相应材料名，如身份证、营业执照等",
      Iftheapplicantisanaturalersonhesheshouldsubmitscannedcopiesuc:
        "申请人为自然人时，应提交身份证正反面扫描件或其他有效身份证件扫描件，如港澳回乡证、香港居民证、台胞证、护照",
      IftheapplicantisalegalpersoninChinesemainlandascannedcopyofavalidbusinesslicenseandthesubmitted:
        "申请人为中国内地法人时，应提交有效的营业执照扫描件及法定代表人证明书原件",
      IftheapplicantisalegalpersonfromHongKongMacaoTaiwanoraforeigncountry:
        "申请人为港澳台或国外法人的，应提交有效的、由当事人注册地相应机构出具的商业登记证等文件；如提交非英文版本的相关文件，请同时提供与本案仲裁语言一致的翻译件",
      Alldocumentssubmittedbythepartiesdonotneedtobenotarizedathetribunal:
        "当事人提交的所有文件，在立案阶段无需公证，如对方当事人提出异议或仲裁庭认为确有必要的，在仲裁程序中可能需要对境外形成的证据进行公证",
      Wherethereischangeinthenameofthepartiesrelevantevidenceofthenamechangeshallbesubmitted:
        "当事人名称有变更的，应提交名称变更的相关证据。",
      ReferenceTemplate: "参考模板",
      TemplateofPowerofAttorney: "授权委托书模板",
      Ifthereisanaturalpersoninvolcontractlenancbusiness:
        "如案涉合同当事人存在自然人，请书面告知该自然人是否存在《最高人民法院关于适用<中华人民共和国涉外民事关系法律适用法>若干问题的解释（一）》第十三条（见下文引述）之情形，以便确认本案适用程序。【第十三条 自然人在涉外民事关系产生或者变更、终止时已经连续居住一年以上且作为其生活中心的地方，人民法院可以认定为涉外民事关系法律适用法规定的自然人的经常居所地，但就医、劳务派遣、公务等情形除外。】",

      // 被申请人
      Whenuploadingafilepleaseheofthefileslicenseetc:
        "在上传文件时，请将待上传文件名称改为相应材料名，如身份证、营业执照等；",
      Iftheapplicantisnapersoncopyofhisandpassport:
        "被申请人为自然人的，申请人可提交其身份证复印件或其他有效身份证件扫描件，如港澳回乡证、香港居民身份证、台胞证、护照；",
      IftherespondentisCionslatformssuchasQichacha:
        "被申请人为中国内地法人的，申请人应提交其主体资格仍存续的相关文件，如企信网、企查查等平台打印的相关工商登记信息；",
      IftheaplegaHoqualificationstillexists:
        "被申请人为港澳台或国外法人的，申请人可提交其主体资格仍存续的相关文件；",
      Ifthnameoftherespoeenrelevantubmitted:
        "被申请人名称有变更的，应提交名称变更的相关证据。",

      //代理申请人
      Whenuploadingilhangenheattorneyletteretc:
        "在上传文件时，请将待上传文件名称改为相应材料名，如授权委托书、所函等；",
      Ifthepartibitratiothorizationpecialauthorization:
        "当事人委托仲裁代理人的，应提交授权委托书原件，相关权限应列明“仲裁”而非“诉讼”，请勿仅列明“一般授权”或“特别授权”；",
      Alldocumentssubmittedbythepaneeformebiroceedings:
        "当事人提交的所有文件，在立案阶段无需公证，如对方当事人提出异议或仲裁庭认为确有必要的，在仲裁程序中可能需要对境外形成的证据进行公证；",
      IftheclieongMacaoaiwanptorsdbesubmtted:
        "委托人是港澳台或国外法人的，需上传由当事人注册地认可的、有授权的签字人签署或公司盖章的授权委托书原件。如授权委托书为个人签署，应在授权委托书中用打印字体列明签字人姓名，并提交该签字人有权代表公司对外签署相关法律文书的证据（如董事会决议等）。",
      Thesizeoftheuploadedfiledoenotexceed: "上传文件大小不超过",

      //仲裁依据
      Thapplicantisreqenamepllvedonebyone:
        " 请申请人逐份录入案涉合同名称、编号、签订日期及签订地。",
      Iftheapplicantinitiatesarbitrantracsnotnecesheconbmission:
        "如申请人依据类似订单（合同名称、仲裁条款均一致）提起仲裁，可不必重复录入合同名称及仲裁条款内容，在合同编号处注意添加全部所涉合同号即可；也可自行制作书面函件，列明全部案涉合同编号、签订日期等信息，盖章或签字后提交。",
      IftheapplicantinitiatesroviationRules:
        "申请人依据多份合同提起仲裁的，请注意《仲裁规则》第十四条的规定。",
      Article14Multicontratrationandaddiitration:
        " 第十四条 多合同仲裁及仲裁中追加合同",
      Anapplicantmayapparbitrinbitraolloremet:
        "（一）申请人就多个合同项下的争议可在单个仲裁案件中合并提出仲裁申请，但应同时符合下列条件：",
      Multiplecontractsaremastertiomeactsinvolvethesublicated:
        "多个合同系主从合同关系，或多个合同所涉当事人相同且法律关系性质相同，或多个合同所涉标的具有牵连关系；",
      Disputesarisingtransationortransaciplecontracts:
        "多个合同所涉争议源于同一交易或同一系列交易；",
      Thearbitratmentsinultiplecontticalorcompatible:
        "多个合同中的仲裁协议内容相同或相容。",
      Wheretheircumstancepeciftheapplicationaffectdinonalcontract:
        "（二）同时符合上述第（一）款1、2、3项规定情形的，申请人可在仲裁程序中申请追加合同，但上述申请过迟影响仲裁程序正常进行的，可决定不予追加合同。",
      Theproceduralmattersapedthearbitrationtribunalshalldecide:
        "（三）上述第（一）（二）款程序事项，由仲裁委员会仲裁院决定。在仲裁庭组成后提出追加合同申请的，由仲裁庭决定。",

      //证据材料文本
      Whensubmittingevidensnotsubmphoto:
        "提交证据时，请连续编号、编页，注意应复印/扫描清晰、完整，请勿提交单张照片；",
      Whenuploadingevidencefromevidencetoevidence:
        "在上传证据文件时，请将待上传文件名称改为该证据名称，或列明证据几至证据几；",
      Iftheoriginalequiredinthearbitrationproceedings:
        "原始证据为英文的，在立案阶段不需要提供翻译；如案涉合同为其他语言的，在立案阶段应提供翻译（语言应与本案仲裁语言保持一致）。如对方当事人提出异议或仲裁庭认为确有必要的，在仲裁程序中可能需要对外文证据进行翻译。",

      // 注册指引
      Smartplatformregistrationaccountusageguide: "智慧平台注册账号使用指引",
      WelcomoMAozhongSmartPlatformNewuserspleaseregisterfirst:
        "欢迎使用贸仲智慧平台！新用户请先注册",
      Accountregistration: "账号注册",
      Partiescanaccessthesmartplatformintwoways:
        "当事人可通过两种途径进入智慧平台",
      LogontotheofficialwebsiteofChinaInternationCommission:
        "登录中国国际经济贸易仲裁委员会（“贸仲”）官网",
      OnthehomepageclickOnlineFilingtoplatform:
        "首页，点击“在线立案”，即可进入智慧平台。",
      Directinput: "直接输入",
      Enterthesmartplatform: "进入智慧平台。",
      Newuserneetoregisterbeforeusingthesystem:
        "新用户需注册后才可使用本系统。请在立案系统首页右下角点击立即注册，可选择使用手机注册或邮箱注册。",
      gentlereminder: "温馨提示",
      ForcasessubmittedbeforeJanuarypleasealsologintothehomepageoftheofficial:
        "2024年1月1日前已提交贸仲立案系统的案件，也请登录官网首页，点击“在线立案”进入智慧平台后，点击上图右上角“网上立案（旧版）”继续在线立案操作。",
      BydefaultmobilephoneregistrationpreferredPleasefillinthemobilephonenumberemail:
        "系统默认优先使用手机注册。请按照要求填写手机号、邮箱、姓名、身份证号后，设置密码后，点击获取验证码并输入手机收到的短信验证码。",
      PleasereadandchecktheServiceAgreementandclick:
        "请注意阅读并勾选“《服务协议》”，勾选后，请点击“立即注册”。",
      PleaseswitchtoEmailregistrationatthetopofheregistrationinformationpageasshownbelow:
        "请在注册信息的页面上部，切换为“邮箱注册”，如下图：",
      PleasefillintheemailnameIDnumberasrequiredsetthepassword:
        "请按照要求填写邮箱、姓名、证件号码后，设置密码后，点击获取验证码并输入邮箱收到的验证码。",
      PleasereandchecktheServiceAgreementandclickRegisternow:
        "请注意阅读并勾选“《服务协议》”，勾选后，请点击“立即注册”。",
      Aftertheregistrationissuccessfulthesystemautomaticallyreturnstothelogin:
        "提示注册成功后，自动返回登录首页。",
      Loginplatform: "登录平台",
      ThepartiescanlogintothesmartPlatformbyfillingnthewechat:
        "当事人可通过填写注册的账号、输入密码登录智慧平台，亦可通过微信登录。",
      Howtobindwechat: "如何绑定微信",
      Afterloggingintothesmartplatformyoucanfillinyourpersonalinformation:
        "登陆智慧平台后，可在右上角“设置”处，填写个人信息后绑定微信。",
      Afterbindingtowechatyoucanusewechattime:
        "绑定微信后，下次登陆时即可使用微信扫码登陆。",
      IfyouforgetthepasswordoftheregiyoucanclickForgotPasswordtooperate:
        "如果忘记注册账号密码，可点击“忘记密码”进行操作。可分别通过手机号和邮箱找回密码。",
      ToretrievethroughthemobilephonenumberhmobilephonenumberuseddurickSend:
        "通过手机号找回，需要输入注册时使用的手机号码，点击“发送”发送验证码，输入手机收到的验证码后点击下一步。之后需要重新设置密码，设置完成后，点击确认，回到登录页，输入账号和新设密码即可登录。",
      Toretrievebyemailouneedtoentertheemailaddressuse:
        "通过邮箱找回，需要输入注册时使用的邮箱，点击“发送”发送验证码，输入邮箱收到的验证码后点击下一步。之后需要重新设置密码，设置完成后，点击确认，回到登录页，输入账号和新设密码即可登录。",
      Top: "置顶",

      // 常见问题
      Pleasenotethatthefollowinganswersareforthereferenceofthepartie:
        "请当事人注意，下列回答仅供当事人参考，最终解释权归贸仲所有。",
      CanwegototheChinaInternationalEconomicandTradeArbitrationCommission:
        "我们能到中国国际经济贸易仲裁委员会来解决争议吗?",
      settleadisputealEconomicndbitratnCoarbitration:
        "1.通过向中国国际经济贸易仲裁委员会提请仲裁的方式解决争议，需要您与对方的合同中包含有效的仲裁条款（或者有单独的仲裁协议），明确规定一旦双方发生争议，应提交中国国际经济贸易仲裁委员会，通过仲裁的方式解决。",
      clausearbitrationagreementoisputetheparties:
        "2.如果没有仲裁条款（仲裁协议），可以请求本仲裁委员会对双方的纠纷进行调解。",
      ModelArbitrationisputearisinoutbotarties:
        "示范仲裁条款（一）：凡因本合同引起的或与本合同有关的任何争议，均应提交中国国际经济贸易仲裁委员会，按照申请仲裁时该会现行有效的仲裁规则进行仲裁。仲裁裁决是终局的，对双方均有约束力。",
      ModelArbititratioThearbitralbindingonbothparties:
        "示范仲裁条款（二）：凡因本合同引起的或与本合同有关的任何争议，均应提交中国国际经济贸易仲裁委员会__________分会（仲裁中心），按照仲裁申请时中国国际经济贸易仲裁委员会现行有效的仲裁规则进行仲裁。仲裁裁决是终局的，对双方均有约束力。",

      // 费用快算
      DomesticArbitrationCase: "国内案件",
      ForeignRelatedArbitrationCase: "涉外案件",
      Result: "费用计算结果",
      Pleaseenterthecalculatedamount: "请输入计算金额",
      Calculate: "计算",
      ThepecificdeliveryamountofthefeeshallCommission:
        "该费用的具体交付金额以贸仲委书面复函为准",
      RegistrationFee: "案件受理费",
      HandlingFee: "案件处理费",
      TotalAmount: "受处理合计",

      //立案指引
      Createanarbitrationcase: "新建仲裁案件",
      AfterregisteringandlogginginentertheCasescreen:
        "注册账户并登陆后，进入“我的案件”界面。",
      ClickNewCase: "点击“新建案件”。",
      Fillincaseinformation: "填写案件信息",
      Article21ofthecurrentCIETACArbitrationRulessionandthepaperversionagreed:
        "请申请人准确录入相关信息。",
      Fillintheinformationofbothpartiesandagents: "填写双方当事人及代理人信息",
      AfterselectinganewcasetheNewcasepagewillbedisplayedPlease:
        "选择新建案件后，会进入新案件页面。请逐一填写相关信息。",
      Pleasefirstpleasefillintherelevantpartyinformationnumberisrequired:
        "请首先按照当事人类型，勾选自然人、法人、其他组织或个体工商户，之后请填写相关当事人信息，*号为必填项。",
      Whenfillingintheinformationofthepartiesaupload:
        "填写当事人信息和上传材料时，请注意填写提示（以代理人填写提示为例）",
      Theapplicantanuploadthesubjectqualificationdocument:
        "申请人可在此部分上传主体资格文件、授权委托书等文件。",
      Fillintheaddressforservice: "填写送达地址",
      Afterenteringheinformationofbothparties:
        "在录入双方当事人信息后，请填写送达地址。首先，点击“添加”。",
      Pleasenotethatthedeliverybefilledinaccurately:
        "请注意，送达信息请务必准确填写！",
      Theapplicantwillreceiveamessagenotificationfromthesmart:
        "申请人的手机号码、邮箱中，会收到智慧平台发送的消息通知，因此，请确保准确填写手机号码和邮箱，以免遗漏消息、耽误案件进展。",
      AfterselectingApplicantServiceAddresspleasenotethatonly:
        "选择“申请人送达地址”后，请注意，一位申请人仅可录入一个送达地址。",
      Afterselectingproviderespondentingoodfaithandtherelevant:
        "选择“被申请人送达地址”后，请注意，申请人有义务查询并提供被申请人有效的送达地址。如申请人未能诚信提供被申请人的有效送达地址，导致案件相关法律文书未能及时送达或无法送达的，申请人应自行承担由此产生的法律后果。",
      Theapplicantmayprovidemultipleserviceaddressesrespondent:
        "申请人可提供被申请人的多个送达地址。",
      Ifthereisanyorderofservicepleasesendthearbitration:
        "如有送达顺序，请书面明确，贸仲将按照顺序逐一向被申请人寄送仲裁文件。",
      Ifyouneedtosenditatthesametimepleasesubmitadocuments:
        "如需同时寄送，亦请提交明确的书面申请，并增加提交相应份数的纸质文件。",
      Fillinthebasisofarbitration: "填写仲裁依据",
      PleaseclickAddtoenterthearbitrationbasis: "请点击“添加”，录入仲裁依据。",
      Pleaseaccuratelyinputnamearbitrationclauseifanysigningdate:
        "请准确录入合同名称、仲裁条款，如有签订日、合同编号和签订地点的，亦请如实录入。",
      Ifarbitrationisfiledbasedonmorethanonecontractclick:
        "如依据多份合同提起仲裁的，录入一份仲裁依据后，请再次点击“添加”，继续录入相关合同。",
      Fillinthearbitrationclaimfactsandeasons: "填写仲裁请求、事实和理由",
      PleaseclickAddtoenterthearbitration:
        "请点击“添加”，录入仲裁请求、事实和理由。",
      Iftheapplicantneedspropertpreservationnameuploadthescannedopyofheapplica:
        "如申请人需进行财产、证据或行为保全，请点击“添加”后，填写法院名称、法院地址，简要输入保全内容，并上传财产保全申请书扫描件。",
      Iftheapplicantdoesnotneedpreservation: "如申请人无需保全，可不填写。",
      Uploadtheapplicantevidence: "上传申请人证据材料",
      Thesubjectqualificationevidencmaterialsustuploadnewev:
        "申请人此前上传过的主体资格文件、授权委托书等文件，均已显示在证据材料中，请勿重复上传。仅上传新的证据材料即可。",
      Itisrecommendedthattheapplicantmaterialsabout500Mforaingledocument:
        "建议申请人提交扫描清晰、大小适中（单个文件约500M）的证据材料。",
      Submitthecaseandobtainthecasenumber: "提交立案、取得立案编号",
      AfterfillingintheaboveinformationaccuratelyclickSubmitforfilingnot:
        "准确填写完毕上述信息后，点击“提交立案”。请注意，如未点击“提交立案”，则案件将仅作为草稿保存在您的系统中。",
      AfterclickingSubmittoCIETACpleasenotethataccordingto:
        "点击“提交至贸仲”后，请注意根据仲裁条款的约定和《仲裁规则》的规定，先选择应提交总会/分会后，再次点击“提交至贸仲”。",
      AfterlickingSubmittoCIETACyouwilbepromptedwithyourcasenumber:
        "点击“提交至贸仲”后，会提示立案编号，在立案阶段，请牢记您的立案编号。",
      CasefilingInvestigation: "立案审查",
      initialExamination: "初步审查",
      AftertheapplicantsubmitsanewcaseCIETACwillonduc:
        "申请人提交新建案件后，贸仲将尽快对案件进行初步审查。申请人录入的手机号码和邮箱将会收到立案审查的提醒，请注意及时查看并进行相应修改。",
      Contactthefilingsecretary: "联系立案秘书",
      AfterthecasesecretaryisdeterminedthenameaceofMyCase:
        "在案件确定立案秘书后，申请人登录平台后，在“我的案件”界面中，会显示立案秘书的姓名、电话。如有疑问，申请人可与立案秘书联系沟通。",
      Thepplicanalsocheckthemessageboardtofillsecretary:
        "申请人也可通过查看“留言板”填写留言，与立案秘书沟通。",
      Returnofcase: "案件退回",
      Whenthefilingreviewfindtthecaseneedstobemodified:
        "当立案审查发现案件需要修改或补充提交材料时，立案秘书会将案件退回到申请人的系统中，以便申请人修改相关信息或补充上传立案所需文件。申请人录入的手机号码和邮箱将会收到案件退回的提醒，请注意及时查看并进行相应修改。",
      Aftertheapplicantsupplementstheuploadedcarbitration:
        "在申请人按照相关要求修改或补充上传材料后，请注意及时点击“提交至仲裁”，以免耽误案件进度。",
      Thecasewasfiledndexamined: "立案审查通过",
      Afterpassingtheexaminationthemobilephonebytheapplicantnglyintime:
        "立案审查通过后，申请人录入的手机号码和邮箱将会收到审查通过的提醒，请注意及时查看并进行相应操作：",
      Pleaseotethatafterpassingotice:
        "请注意通过“消息·待办”或点击具体案件后，查看审查通过的通知，并按照通知要求提交纸质材料。",
      Pleaseselectarbitrationprepaymentaccordingtothenotice:
        "请在“我的案件”板块，选择“通知·指令”，及时查看PDF版缴费通知并按照通知缴纳仲裁预付金。",
      ClickthebuttonofthecorrespondiversionficeofCIETACasrequired:
        "点击案件列表中“生成PDF版仲裁申请书”下对应案件的“PDF”按钮，之后可预览或下载生成的PDF版仲裁申请书。请打印PDF版仲裁申请书并按要求向贸仲立案处寄送相应份数的仲裁申请书。",
      attentionshouldbepaidto: "请留意",
      ApplicationforarbiotesandndnoteThiscanbeusedwheofthedocument:
        "《仲裁申请书》脚注和尾注的问题：当需要引用某些大段文字（如有关法律条文、有关国内外获本项目合同文本、或有关专著的论述等），而又不宜原封不动的写入正文时，可采用这种方式。脚注和尾注可放在本页页脚或该文件的最后一页。",
      Aboutthenumberingofthepagesvidence: "关于证据页码的编排的事宜：",
      Eachpieceofevidencebepaginatedseparatelthebottomrightofachpage:
        "每一份证据在需要时可以单独编页码，该页码放在每一页的右下方。",
      Allevidenceshallbepaginatedtotalpageeachpage:
        "全部证据应当编总页码。总页码在每一页的右上方。",
      WhentherearemorethetotalpagenumberconsecutiveWhenevidenceyubmissions:
        "当有多册证据时，每册的证据总页码应当连续；多次提交证据时，后来提交的证据应紧随先前提交的证据连续编页码。",
      Thecontentstobeprovedevidenceshallbeaffected:
        "每一份证据要证明的内容应当用有色笔在文中作出标记、划出重点，但不得对证据进行任何改动或者影响对证据原状的识别。",
      Whenthevidencesubmittedagainisrepeateeindicated:
        "当再次提交的证据与以前提交的证据有重复时，说明前次提交的证据编号及总页码即可，一般不必重复提交。在十分必要时，也可重复提交，但应注明与以前提交的某个证据相同，且注明原先证据的序号。",
      Asforthebindingofdocumentsifthedocumentsarethickg:
        "关于文件的装订，如果文件较厚，最好能用容易拆卸的方式进行装订，比如塑料圆环装订等。",
      Annotation: "注释",
      ThepurposeofwritingTheCalculattheaward:
        "撰写“索赔额的计算及各项计算数值的说明和依据”的目的，是使仲裁员对该仲裁案件索赔的各项要求的依据和计算有一个全面、深入、细致而具体的了解，便于仲裁员查找相关的证据，研究索赔项目及款额的合理性，核算索赔的款额以及最终确定和计算裁决的款额。",
      Thissectionthearbitrationcaseprojectincludingthenamenproblemsetc:
        "这部分以1500字以下为宜。这一部分的内容是对仲裁案件项目的基本情况进行简介，包括项目的名称、内容，合同的签订时间，项目的进展情况，项目目前存在的主要问题等。",
      Thespecificclaimlaimpaymentevidencearenotinvolvedhere:
        "此处不涉及具体的索赔要求、索赔款项、索赔论证、答辩说明以及索赔和反索赔证据等。",
      Boththeapplicantandthesubmittingthedocumentlater:
        "申请人与被申请人在第一次提交文件时，均应编写该部分内容，以后提交文件时，如有需要，还可以补充该部分内容。",
      Thepurposeofbriefntroductionivethearbitratorbitrationcase:
        "这部分简介的目的是使仲裁员对该仲裁案件涉及的项目有一个全面的了解。",
      Pleasetionllproceedtothelicantandarbitrationfeepaidinadvance:
        "请申请人备妥需提交的仲裁文件，尽快寄送至贸仲立案处；同时，请尽快缴纳仲裁费用（缴费及寄送材料无先后顺序）。案件将于贸仲立案处收到申请人提交的书面仲裁文件及预缴的仲裁费用后进入下一步仲裁程序。",
      Atthisointtheonlinefilingprocess: "至此，在线立案流程结束。",

      //办案指引
      Thepartiesbindthecase: "当事人绑定案件",
      Aftersuccessfullyloggingindisplayed: "登录成功后，会到我的案件页面",
      ClickAddCasetobindtheaccount: "点击添加案件，进行账号绑定",
      Enterthecasenumbertobeboundherebinding:
        "在这里输入需要绑定的案件号码，后点击确定就可以完成绑定了",
      Afterthebindingthetheboundcaseinformation:
        "绑定成功后，点击在办案件按钮就可以看到绑定的案件信息了",
      Uploadelectronicdocuments: "上传电子文件",
      Clickotheasename: "点击案件名称",
      Afterlickitherewillbeinstructionndapplicant:
        "点击后会有三个文件夹分别是申请方、通知指令、被申请方。",
      Youcanclickafolderdirectortouploadneedstouploadilesrectory:
        "可点击某一个文件夹目录下进行上传文件，比如申请方需要把文件上传到申请方目录。",
      AfterselectingtheidentityfolderclickUpload: "选中身份文件夹后，点击上传",
      Clickuploadimagemiddleselectthefiledraganddropupload:
        "点击中间的上传图片，选择需要上传的文件，支持选中或拖拽上传",
      Iftheuploadissuccessfulpleaseuploadeduploadissuccessful:
        "如果成功后会提醒上传成功，请确认上传成功后看文件目录是否有上传的文件",
      Inthiswindowpartiesarbitratorsndhandlers:
        "当事人可以在此窗口，管理自己的电子档案，包括下载，拷贝等，可以查看双方当事人、仲裁员及经办人上传的电子文件。",
      Operatingguideforarbitratorend: "仲裁员端操作指南",
      Registerofarbitrators: "仲裁员登录",
      Notethathearbitratordoeseratedcharactercode:
        "注意，仲裁员不用注册，账户和密码会由经办秘书通过邮件或短信的方式告知，也不用绑定案件。若和微信绑定，也可通过微信扫码登录。仲裁员登录账户默认是手机号或邮箱，口令默认是随机生成一串字符码。",
      Afterenteringtheaccountpasswordanderificationlogging:
        "输入完账户和口令及验证码，登录后，弹出如下窗口，",
      ClickheArbitratorAdministrationagain: "点仲裁员管理菜单。再点击案件名称",
      Viewelectronicfiles: "查看电子档案",
      AfterlogginginclickonElectronicManagement: "登录后，点击电子文件管理。",
      Thearbitratorcanviewllelectronicuploadedbythesecretary:
        "仲裁员可以查看当事人、仲裁员及经办秘书三方上传的所有电子文件。",
      Viewcaseprogress: "查看案件进程",
      ClicktheViewCaseprogressmenuthefollowingup:
        "点击查看案件进程菜单，弹出如下窗口。",
      Changasswordandbindwechat: "修改口令及绑定微信",
      AftersystemclicompleteasswordAshownbelow:
        "登录系统后，首先点击设置，然后点击绑定，即可完成绑定操作，也可修改自己的登录口令。如下图。",
      IfwantunbindclickUnbindwindow:
        "若想解除绑定，也是在该窗口中，点击解除绑定即可。",

      // 庭审指引
      Instructionsforusingthevideotrialsystem: "视频庭审系统使用说明",
      Computersidetoparticipateincourt: "电脑端参加网上庭审",
      Completefunctionsrecommended: "（功能完整，推荐使用）",
      StepOpenthevideopage: "第一步打开视频庭审页面",
      Checkthatthcameramicrophoneandothernormally:
        "检查确认摄像头、话筒、耳麦、声音外放等设备可正常使用。",
      UsingGoogleChrome: "使用谷歌浏览器（Chrome）",
      OR: "或",
      Extremebrowser360: "360极速浏览器",
      Unfold: "展开",
      Clickonhetopofthehomepage: "点击首页上方的",
      TheWisdomTrial: "智慧庭审",
      Checkinonline: "在线签到",
      AfterenteringthetrialthtrialageClickon:
        "输入庭审码登录进庭审页面后，先进入签到流程。点击",
      Signin: "签到",
      Buttonforidentityinformationcheckpersoncheck:
        "按钮进行身份信息核对—实人认证，认证成功完成签到。",
      Pretrialtest: "庭前测试",
      Beforetheofficialcourtsessionhesystlldetectetworksession:
        "在正式开庭前系统会对网络、摄像头、麦克风及扬声器进行自动检测，以保证可以正常开庭。",
      Ifyoufindthathemicrophonespeakertheisselectedyoursystem:
        "如果发现麦克风、扬声器或者摄像头测试失败，请先查看电脑是否配备有麦克风、扬声器和摄像头，驱动是否安装正确。如果使用的是外接麦克风、扬声器和摄像头，请检查系统中是否选择了正确的设备。",
      Boardinfrontofcourt: "庭前看板",
      Beforetheformalcourtsessiyoucancheckndcliwhenready:
        "正式开庭前，可在庭前看板查阅案件相关信息。请阅读庭审纪律、庭前告知等，准备完毕后点击",
      Gototrial: "进入庭审",
      Disciplineoftrial: "庭审纪律",
      Afterenteringthetrialthetrialdisciplineplayed:
        "进入庭审后，将播放庭审纪律。庭审纪律播放时全员禁言，播放后恢复正常。",
      Step2Enterthevideotrialsystem: "第二步进入视频庭审系统",
      EnterhetrialcodereceivedintheSMSystem:
        "输入手机短信中收到的庭审码进入庭审系统。",
      Step3Completetheealpersonertificationtrial: "第三步完成实人认证开始庭审",
      Afterenteringthetrialsystemtheartthetrial:
        "进入庭审系统后，待案件秘书发起实人认证，通过人脸识别完成实人认证，开始庭审。",
      Step4Confirmsigningtranscriptscourthighlights:
        "第四步确认签署笔录/庭审要点",
      AfterthehearingthecasesecretaryFollowthesesteps:
        "庭审发言结束后，案件秘书将发起笔录确认。按以下步骤操作：",
      CheckagreetotheElectronicSignatureEntrustme:
        "勾选【本人同意《电子签章委托协议》】，点击【确认】。",
      CheckhaveverifiedthatmyidentityclickConfirm:
        "勾选【我已核实，身份信息正确无误】，点击【确认】。",
      Check: "勾选",
      Pagelocatioofsignature: "【签名所在页面位置】",
      AQRcodewilpoppthescreenAfterscanninghecodewithyourclick:
        "屏幕会弹出二维码，用移动设备扫描二维码后在白板上签字，点击",
      Acknowledgereceipt: "【确认提交】",
      Afterconfirmationwaitrial: "确认之后，等待结束庭审",
      Ifthetranscriptmainpointsofhetrialneedtomodifiedbysecretary:
        "笔录/庭审要点如需修改，仍可通过视频庭审系统与案件秘书通话，由案件秘书改入。",
      Casefileuploadfunction: "案件文件上传功能",
      Afterreceivingthecourtthevideocourtcode:
        "确定案件开庭时间收到庭审码后，可凭庭审码可多次进入视频庭审系统。",
      Afterenteringthecourtystemclickeft: "进入庭审系统后，左侧点击",
      ElectronicMaterial: "【电子材料】",
      NewMaterials: "【新增材料】",
      Click: "点击",
      Uploadevidencaterialsopinionsparticipantsandotherdocuments:
        "将扫描好的证据材料、书面质证意见、视频庭审确认书、视频庭审出庭人员确认书等文件上传。",
      Thefileuploadfunctioncanduringhetrial:
        "庭审开始前和庭审之中均可使用文件上传功能。",
      Mobileterminalstoparticipateinonlinetrials: "移动端参加网上庭审",
      Setupcalltransferindvancetoallsession:
        "（事先设置呼叫转移，以免来电中断开庭）",
      Step1download: "第一步下载",
      Methodofdownload: "下载方式",
      Downloadlink: "下载链接",
      ScantheQRcodebelowtodownload: "扫描下方二维码下载",
      Step2EnterthetrialcodereceivedSMStoenterthetrial:
        "第二步输入手机短信中收到的庭审码进入庭审",
      Step3Completetherealpersoncertificationrial: "第三步完成实人认证开始庭审",
      Afterenteringthetrialsystemrecognitionstartthetrial:
        "进入庭审系统后，待案件秘书发起实人认证，通过人脸识别完成实人认证，开始庭审。",
      Afterheasesecretaryinitiatesthetrmationclickright:
        "案件秘书发起笔录/庭审要点确认之后，点击右下方",
      Recordofwords: "【笔录】",
      Checkfirst: "先勾选",
      IagretotheElectronicSignatureAgreement:
        "【本人同意《电子签章委托协议》】",
      Andthenclickagain: "然后再点",
      Recordconfirmation: "【笔录确认】",
      Afteronfirmingthetranscriptrialpointsclick:
        "确认笔录/庭审要点后，在空白页面上签字，并点击",
      Afterconfirmationwaitforend: "确认之后，等待结束庭审。",
      Ifthetranscripttrialpointsmendedyoucancretarywillchangeit:
        "笔录/庭审要点如需修改，仍可通过视频庭审系统与案件秘书通话，由案件秘书改入。",
      Themobileterminaldoesnotuploading: "手机端不支持案件文件上传",
      NewslettersAttendonlinetrials: "通讯参加网上庭审",
      Telephoneaccesssavailabdnswerthehonetoparticipateinthetrial:
        "网络信号不佳时可采用电话接入。案件秘书可通过视频庭审系统拨打相关人员电话，接听即可参与庭审。",

      // 申请开票--在线申请发票使用说明
      Applyforinvoiceinstructionsnline: "在线申请发票使用说明",
      ThecasenumbeD20233915andthenvoice:
        "案号为D20233915(F)，申请方发票提取码为c20238hq697。",
      Thespecificstepsarefollows: "具体操作步骤如下：",
      Pleasevisitourfficialwebsite: "请登录贸仲官网",
      ScantheQcodCIETACeattheAPPStore:
        "扫描底部二维码下载“贸仲APP”，或从华为应用市场或苹果APPStore中搜索“贸仲APP”下载。",
      OpenCIETACtheAPPelow: "打开“贸仲APP”，如下图。",
      Firstofllselectthepartybuttoninoiceonline:
        "首先选择上图中当事人按钮，接着输入发票提取码，手机号及验证码，点击登录,即可在线申请发票。",
      Notenvoiceleasetheloginheextractioncode:
        "注意：若没有发票提取码，请点击上图贸仲APP登录页“找回发票提取码”，把申请开票人的基本信息录入完毕后，点搜索，系统会自动生成一个发票提取码。",
      Submittheinvoicepplicationbelow: "在线提交发票申请，如下图。",
      Afteruccessfulhenvoicecanseetheubmissionstatus:
        "登录成功后，选择要开票的案件，选择申请开票，把票种、费用类型、申领金额等信息填写完毕，若变更开票名称等，需要上传说明，若发票需要邮寄，则要填写邮寄信息。最后点击申请开票按钮。成功后，可看出提交状态。",
      NoteAccordinpartiestothecasedissue:
        "注：①按照税务局关于“三流合一”（即货物流、资金流、发票流应统一）的要求，对于贸仲而言是指当事人、仲裁相关费用的缴纳方和退费接收方、发票受票方，三者应统一。所以，贸仲只针对案件当事人进行仲裁相关费用的收退和发票的开具工作。",
      Pleasecheckthebillingubmissionofficialsealbefore:
        "②提交前请务必认真核对开票信息，如因当事人填写错误需要换开发票的，需由当事人提供盖有公章的说明原件方可换票。遇技术问题请拨打电话：010-82217622  17603319137,遇财务问题请拨打电话：010-82217606",

      //申请退费----撤案仲裁费用的退费
      TheseMeasuresreformulateiccordancewithithdrawn:
        "为了规范仲裁案件撤案时仲裁费用的退费工作，根据相关法律法规和规章以及《中国国际经济贸易仲裁委员会仲裁规则》、《中国国际经济贸易仲裁委员会金融争议仲裁规则》和《中国国际经济贸易仲裁委员会网上仲裁规则》的规定，特制定本办法。",
      Article1Refundsomesticases: "第一条 国内案件的退费",
      Ifthcaswithdrbeforehormationfthendlingfeeshallreturned:
        "仲裁庭组成前撤案的，案件受理费的全部和案件处理费的50％退回。",
      Ifacaseswithdrawnbitrationfeechargedhallotbeless:
        "仲裁庭组成后至开庭前撤案的，案件受理费的50％和案件处理费的50％退回，但收取的仲裁费用最低不少于6100元人民币。",
      Ifthearbitrationtribunallcircumstancesrefundedundheprocessingfee:
        "仲裁庭开庭后撤案的，一般不予退费。情况特殊的，案件受理费不予退回，案件处理费可视具体情况酌退，但所退费用不高于处理费的20％。",
      ArticleRefundforforeigrelatedcases: "第二条 涉外案件的退费",
      Ifthecaseiswithdrawnbeforeefeethebelowreturned:
        "仲裁庭组成前撤案的，仲裁费（不含立案费，下同）的60％退回。",
      Ifthithdrawnrationrbitrationfeeshallreturned:
        "仲裁庭组成后至开庭前撤案的，仲裁费的50％退回。",
      Ifthearbitrationtribunalearbitrationfee:
        "仲裁庭开庭后撤案的，一般不予退费。情况特殊的，仲裁费可视具体情况酌退，但所退费用不高于仲裁费的20％。",
      ArticleRefundsfortheCommissionnonattendancereason:
        "第三条 因仲裁委员会无管辖权而撤案或当事人无故不参加开庭的退费",
      Ifanarbitrationwithdrawnapeopleisourtfthereturned:
        "仲裁案件开庭审理前，因人民法院或者仲裁委员会作出无管辖权决定而撤案的：国内案件，受理费和处理费的80％退回；涉外案件，仲裁费的80％退回。",
      Ifthearbitrationcasedueshalligherthanarbitrationfee:
        "仲裁案件开庭审理后，因仲裁委员会无管辖权而撤案的：根据仲裁委员会办理案件和仲裁庭审理案件所进行的程度和工作量及当事人本身的责任，国内案件，退费金额不高于受理费和处理费的70％；涉外案件，退费金额不高于仲裁费的70％。",
      Ifpauponwrittennoticeppearinnorefundshallbegranted:
        "当事人经书面通知，无正当理由不到庭或者未经仲裁庭许可中途退庭，被视为自动撤案或自动撤回仲裁请求或反请求的，不予退费。",
      ArticlRefundsshallapplyArbitrationdradeArbitrationCommission:
        "第四条  适用中国国际经济贸易仲裁委员会其它《仲裁规则》的案件的退费",
      TherefundoffeesincaseswhertheChinaeasures:
        "适用《中国国际经济贸易仲裁委员会金融争议仲裁规则》和《中国国际经济贸易仲裁委员会网上仲裁规则》等案件的退费，参照本办法第二条和第三条的规定执行。",
      ThesemeasuresareapprovedytheCIETACecretarBureau:
        "本办法经贸仲委和海仲委第十七届委员会第七次主任联席会议批准，自2012年1月1日起施行，由贸仲委秘书局负责解释和修订。",

      // 仲裁申请须知------
      WelcometoChinaInternationalEconomicandtration:
        "欢迎垂询中国国际经济贸易仲裁委员会!如果您/您公司准备通过本会的仲裁向对方索赔或者解决已经出现的纠纷,请关注下列申请仲裁须知。",
      ArbitrationtoAssociationgenerallyauseinthecontracagreement:
        "到本会仲裁，一般需要您/您公司与对方的合同中有仲裁条款，或者达成书面仲裁协议。",
      Tofileanapplicationyouanchoose: "申请立案，您可选择：",
      Onsitesubmissionnoreservation: "现场提交（无需预约）",
      Filingbymail: "邮寄立案",
      TheaddressformailDistrictBeing:
        "现场立案和邮寄立案的地址为：北京市西城区桦皮厂胡同2号国际商会大厦6层 中国国际经济贸易仲裁委员会",
      Thepartiesosemitapplicationplatformis:
        "当事人可以选择通过贸仲智慧平台，在线提交立案申请，网址为:",
      Whenapplyingrbitrationpleaseocuments: "申请仲裁时，请提交下列文件：",
      Applicationforarbitrationandtharbitrationwritten:
        "仲裁申请书及所附证据材料（包括有仲裁条款的合同或书面仲裁协议）",
      Unlessotherwisepartientiaryfivecopieseach:
        "除非当事人另有约定，凡索赔金额不超过500万元人民币；或双方当事人特别约定适用简易程序的，应适用简易程序，需要仲裁申请书及所附证据材料各一式三份；其他情况则应提交仲裁申请书及所附证据材料各一式五份。",
      Ifthereismoreespondentlsshallbeaddedaccordingly:
        "如果被申请人不止一个，那么，每增加一个被申请人，需相应增加一份仲裁申请书及所附证据材料。",
      Iftheapplicantasbitrationdocumentesametime:
        "如申请人提供了被申请人的多个送达地址、并希望向多个送达地址同时寄送仲裁文件，",
      Pleaseubmitaclearwrittenrequest: "请提交明确的书面申请",
      Andsubmitcorrespondingnumberofmaterials: "并补交相应份数的材料。",
      Theapplicationfoneedsompanysealorsignedbyepresentative:
        "仲裁申请书需要提交原件，加盖公司公章或由授权代表/代理人签字。",
      Evidencedocumentsenerallytocopiesecrossexamination:
        "证据文件一般只需要复印件，但开庭时须带原件以备当庭质证。",
      Ifappointhandlethearbitratiriginalpoweropies:
        "如果委托了代理人代为办理仲裁案件，请按份数提交授权委托书原件。",
      Ifapplicantiscompanyplease: "如申请人为公司，则请",
      Pershare: "按份数",
      Submitofcompantiveaturalpersonplease:
        "提交公司营业执照复印件、法定代表人身份证明原件；如申请人为自然人，则请",
      Submitcopiesdentitydocumentsassport:
        "提交身份证/护照等身份证明文件的复印件。",
      Ifyouneedtoapplypropertypreservatipreservationplease:
        "如果需要申请财产保全/证据保全，请：",
      Submitapropertypreservationapplicationevide:
        "提交财产保全申请书/证据保全申请书原件一式两份；",
      Submitoneadditionalapplicationforarbitration: "增加提交一份仲裁申请材料",
      Givewritteninstructionstothespecificourt:
        "书面指示本会向哪一具体法院转送，并提供该法院的地址、邮政编码和/电话等。",
      Pleasepplicationeatlydocumentedpaginabound:
        "请注意，仲裁申请材料须文件整洁，编写页码，装订成册。",
      Pleasepayheinbebornebythelosingparty:
        "请按照仲裁规则中仲裁费用表的规定交纳仲裁费预付金，仲裁费的数额将由仲裁委员会在收到仲裁申请后通知申请人。仲裁费的承担由仲裁庭在裁决中最后确定，原则上由败诉方承担。",
      Ifyouhaveanyotherquesti82217766website:
        "如有其他问题，欢迎来电话+86 10 82217788、传真+86 10 82217766、来人咨询或查访本会网站",

      // 收文信息
      ReceiptInformation: "收文信息",
      CorrespondenceAffiliation: "来函人单位",
      ModeofCommunication: "来文方式",
      Source: "来源",

      //立案信息
      CasefilingInformation: "立案信息",
      ContractNumber: "合同编号",
      ContractDate: "合同日期",
      ContentofArbitrationClause: "仲裁条款内容",
      FilingReminder: "立案提示",
      ForeignCountries: "涉外国家",
      PlaceofAcceptanceofIntermediateClauses: "中条款受理地",
      PlaceofAcceptanceofMajorClauses: "大条款受理地",
      CaseInformationes: "办案信息",
      DisputetypeOld: "争议类型(旧)",
      TypeoffirstorderDispute: "一级争议类型",
      SecondarysecondarydisputeTypes: "二级/次级争议类型",
      Language: "语言",
      NumberofArbitrators: "仲裁员人数",
      ApplicableRules: "适用规则",
      SeatofArbitration: "仲裁地",
      ProgramType: "程序类型",
      FilingSecretary: "立案秘书",
      DateofTransfer: "移送日期",
      OfficeDate: "分办日期",
      DateofnoticeofArbitration: "仲裁通知日期",
      DateofTribunalFormation: "组庭日期",
      TimelimitforAdjudication: "裁决期限",
      ClosingDate: "结案日期",
      settlealawsuitType: "结案类型",
      WinningParty: "胜诉方",

      //报酬查询
      Compensationnquiryding: "2016年以后报酬查询(含2016年)",
      Compensationinquiryudingcluding: "2016年以前报酬查询(不含2016年)",
      Queryyear: "查询年份",
      Querymonth: "查询月份",
      Nameofthehandler: "经办人姓名",
      Pleaseenternamehandler: "请输入经办人姓名",
      Nameofparty: "当事人名称",
      Pleaseenterthenameparty: "请输入当事人名称",
      FinancialNumber: "财务编号",
      PreTaxIncome: "税前所得",
      personalIncomeTax: "个人所得税",
      ActualAmountReceived: "实领金额",
      CreditingAccountNumber: "入账账号",
      Issuestatus: "发放状态",
      Sent: "已发",
          Unbilled: "未发",
          DistributionTime:'发放时间',

      // 开庭日历
      Optionmonth: "选择月",
      Jumptotecifmonth: "跳转到指定月份",

      // 消息代办事项
      MessageStatus: "消息状态",
      MessageHeader: "消息标题",
      Gotonotifynstruction: "去通知指令",
      NotificationMessage: "通知消息",
      Dearpartyconcerned: "尊敬的当事人",
      Notsubmittedorganizatiyet: "未提交至贸仲(暂无机构名称)",
      Notificationinstruction: "通知指令",
      Posttitlequery: "发文标题查询",
      Titleofpost: "发文标题",
      TypeofPosting: "发文类型",

      // 日期模板
      Messagecenter: "消息中心",
      MessageType: "消息类型",
      All: "全部",
      StartDate: "开始日期",
      EndingDate: "结束日期",

      // 收发管理---模块
      CourierNumber: "快递单号",
      Enterthetrackingquery: "输入快递单号进行查询",
      Pleasemovethecursoricketbarcode: "扫描快递单条码时请将光标移至此处",
      Todayhasreceivedtheexpresscode: "今日已收快递扫码",
      Pieces: "件",
      DataEntryKeyers: "录入人",
      ReceivedDate: "收件日期",
      Addthesenanually: "手动添加来函人单位",

      //收文登记
      Registerofreceipt: "收文登记",
      Subjectofreceipt: "收文标题",
      Pleaseentertherecetitle: "请输入收文标题",
      Numberofdocumentsreceived: "收文份数",
      Pleaseenterthenumbereceived: "请输入收文份数",
      Tobefiledno: "待立案编号",
      Methodofletter: "来函方式",
      Pleasechooseheetter: "请选择来函方式",
      Typeofletter: "函件种类",
      Pleaseselectthetyetter: "请选择函件种类",
      Unitofletter: "来函单位",
      Typeofcorrespondence: "函件类型",
      Nameofthewriter: "来函人姓名",
      PostalCode: "邮编",
      Province: "省份",
      Handoff: "切换",
      Addresofthsender: "来函人地址",
      Pleaseenterhemployer: "请输入来函人单位",
      Pleaseentethedresseeaddress: "请输入来函人地址",
      PostcodeoftheAddressee: "来函人邮编",
      Callerphonenumber: "来函人电话",
      Pleaseentetheelepsender: "请输入来函人电话",
      ThesenderFaxes: "来函人传真",
      Nationalityofthewriter: "来函人国籍",
      Provincfthewrite: "来函人省份",
      Persontoin: "登录人",
      Signer: "签收人",
      Whetherhecompanyislisted: "是否上市公司",
      Thecontentoftheletter: "来函人内容",
      Pleaseenterthecontent: "请输入内容",

      // 收文统计
      Dateofeceiptfrom: "收文日期从",
      Arrive: "到",
      Copies: "份数",
      Receiptnumber: "收文号",
      Dateofletter: "来函日期",
      Detailsofreceipt: "收文详情",
      Issuenumber: "发文号",
      Pleasenterthemessagenumber: "请输入发文号",
      Awardnumber: "裁决书编号",
      Pleaseentertheawardnumber: "请输入裁决书编号",
      Pleaseenterfilingecretary: "请输入立案秘书",
      Pleaseenterhecaseecretary: "请输入办案秘书",
      Caseyear: "案件年份",
      Partynameunit: "当事人名称/单位",
      Pleaseenterpartynamunit: "请输入当事人名称 / 单位",
      Agentnameunit: "代理人名称/单位",
      Pleaseentergenameunit: "请输入代理人名称 / 单位",
      Dateoffiling: "立案日期",
      Handoverdate: "移交日期",
      Target: "标的",
      ArbitrationFees: "仲裁费",
      Amountofmoneyinvolvedcase: "涉案金额",

      //全文检索
      FilenameRetrieval: "文件名检索",
      ContentDetection: "内容检测",
      Pleaseenterhecontentharacters: "请输入内容（最少不低于两个字符）",
      Bycasenumber: "根据案件编号",
      Selectchapter: "选择分会",
      Handovertarttime: "移交开始时间",
      HandoverendTime: "移交结束时间",
      Find: "查找",
      Viewcurrentpage: "查看当前页",
      ViewcurrentFile: "查看当前文件",
      DownloadcurrentFile: "下载当前文件",
      HandoverTime: "移交时间",

      // 上传材料ocr
      PleasentertheReceivingNumber: "请输入收文函号",
      IdentificationQueue: "识别队列",
      StartrecognitionTime: "开始识别时间",
      CurrentState: "当前状态",
      Identifying: "识别中",
      Submitter: "提交人",
      Identify: "识别",
      Reprint: "补打",
      Describe: "描述",
      UploadTime: "上传时间",
      DeletetheuploadedFile: "删除已上传文件",
      UploadDirectory: "上传目录",
      ClicktoUpload: "点击上传",
      ModifyDescription: "修改描述",
      PrintQRcode: "打印二维码",
      Print: "打印",
      Dossier: "卷宗",
      ElectronicProgram: "电子程序",
      Factsofhecase: "案情",
      ImageprojectionFile: "影映文件",
      Notyet: "暂无",
      Acceptanceof: "受理",

      TheFullState: "全部状态",
      IftheMoneyhasBeenCredited: "是否到账",
      Numberofcallsback: "打回次数",

      // 复用
      Addproofofthesubjectisqualifications: "添加主体资格证明材料",
      AddAttachment: "添加附件",
      NameoftheMaterial: "材料名称",
      Uploadfileprogress: "上传文件进度",
      Save: "保存",
      AddthepowerofAttorney: "添加授权委托书",
      UploadAttachment: "上传附件",
      ViewAttachments: "查看附件",
      Fileuploaded: "已上传文件",
      FileDescription: "文件描述",
      Dateofsubmission: "提交日期",
      FileName: "文件名称",
      OperationLog: "操作日志",
      SelectFile: "选择文件",
      PleaseentertheDescriptionoftheFile: "请输入文件描述内容",
      PleaseenteraFileName: "请输入文件名称",
      StartTime: "开始时间",
      EndTime: "结束时间",
      Review: "浏览",
      OptionDate: "选择日期",
      CaseInformation: "案件信息",
      ApplyForInvoices: "申请发票",
      InvoiceInformation: "发票信息",
      Date: "日期",
      Status: "状态",
      Emailaddress: "邮箱地址",
      Groundsforrejection: "驳回理由",
      InformationRetrieval: "信息补录",
      Currency: "币种",
      effective: "有效",
      invalid: "无效",
      IDNumber: "身份证号",
      BasicInfo: "基本信息",

      // 消息提示
      Cancelled: "已取消",
      Undelete: "已取消删除",
      SuccessfullyDelete: "删除成功!",
      FailtoDelete: "删除失败",
      Whethertodelerackingnumber: "是否删除快递单号?",
      Thesearchtrackingnumbercannompty: "搜索快递单号不能为空！",
      ModifySuccessfully: "修改成功",

      // 按钮
      finder_cancel: "取消",
      finder_confirm: "确认",
      header_logout: "退出",
      back: "返回",
      View: "查看",
      Preview: "预览",
      Download: "下载",
      Operate: "操作",
      Add: "添加",
      Return: "返回",
      Edit: "编辑",
      MoveUp: "上移",
      Delete: "删除",
      Revamp: "修改",
      ViewLog: "查看日志",
      Confirm: "确定",
      ShutDown: "关闭",
      ScannedVersion: "扫描版",

      // 没数据提示
      ApplicantInformationHasnotBeenAdded: "还未添加申请人信息",
      ApplicantAgentInformationHasNotBeenAdded: "还未添加申请人代理人信息",
      TheInformationofTheRespondentIsAgentHasNotBeenAdded:
        "还未添加被申请人代理人信息",
      TheCurrentDataisEmpty: "当前数据为空",
      ThereIsNoRequestForArbitration: "暂无仲裁请求",
      ThereArenoFactsorReasons: "暂无事实与理由",
      NoMaterialatPresent: "暂无材料",

      // 首页新加
      TechnicalSupportContactPhoneNumber: "技术支持联系电话",
      CIETAC: "中国国际经济贸易仲裁委员会",

      // 特殊程序指令 页面
      SpecialProgramInstructions: "特殊程序指令",

      // 2024/12/4  更新在线申请发票使用说明
      zxfpsq1: "案号为[案件编号]，申请方发票提取码为[申发票提取码]。",
      zxfpsq2: "具体操作步骤如下：",
      zxfpsq3:
        "1、请登录贸仲官网www.cietac.org，扫描底部二维码下载“贸仲智慧平台”，或从华为应用市场或苹果商店中搜索“贸仲智慧平台”下载，亦可搜索微信小程序“贸仲智慧平台”。",
      zxfpsq4: "2、打开“贸仲智慧平台”，如下图。",
      zxfpsq5: "无需注册和登录，",
      zxfpsq6:
        "在首页点击【申请发票收据】选择【发票申请】，输入发票提取码，然后填写发票信息，保存提交即可在线申请发票。",
      zxfpsq7:
        "注意：若没有发票提取码，请点击上图【找回发票提取码】，把申请开票人的基本信息录入完毕后，点击下一步，系统会自动生成一个发票提取码，然后进入发票申请。",
      zxfpsq8: "3、在线提交发票申请，如下图。",
      zxfpsq9:
        "登录成功后，选择要开票的案件，选择申请开票，把票种、费用类型、申领金额等信息填写完毕，若变更开票名称等，需要上传说明，若发票需要邮寄，则要填写邮寄信息。最后点击申请开票按钮。成功后，可看出提交状态。",
      zxfpsq10:
        "注：①按照税务局关于“三流合一”（即货物流、资金流、发票流应统一）的要求，对于贸仲而言是指当事人、仲裁相关费用的缴纳方和退费接收方、发票受票方，三者应统一。所以，贸仲只针对案件当事人进行仲裁相关费用的收退和发票的开具工作。",
      zxfpsq11:
        "②提交前请务必认真核对开票信息，如因当事人填写错误需要换开发票的，需由当事人提供盖有公章的说明原件方可换票。",
      zxfpsq12: "遇技术问题请拨打电话：010-82217622 17603319137",
          zxfpsq13: "遇财务问题请拨打电话：010-82217606",
      



          //   uploadScanSecDoc上传扫描件(经办人)
          UploadScannedCopyHandler:'上传扫描件(经办人)',
          



          //   2024-12-16添加报酬查询的字段
          CaseStatus:'案件状态',
        //   FinancialNumber:'财务编号',   有
          DistributionStatus: '发放状态',
          
          showCietac: "贸仲智慧平台使用指引",
			smBtn: "提示",
			// 提示
			showTips:
				"申请人和被申请人登录贸仲智慧平台时，须先注册，然后再登录，建议用手机注册，方便快捷；申请人登录后可以申请仲裁，被申请人登录后，请先进行案件绑定操作，才能看到案件。",
			showTips_one:
              "仲裁员登录贸仲智慧平台时，不用注册，若没有账户，请问经办秘书获取或从贸仲获取，登录后可在线处理案件，可浏览或下载电子版和扫描版等证据材料。",
      showTips_two: "经办人可直接使用办案系统账户登录，无需另行注册。",
          // 2025-3-18
          Uploader:'上传人',
          // 2025-3-18
          

            // 2025-3-21
          IdTypeLang: '护照/港澳通行证',
          SendToMobile: '送达手机',
          Landline: '座机',
          

          //   更新注册指引内容
          zczy1:'一、注册账号',
          zczy2:'当事人可通过两种途径在智慧平台注册账号：',
          zczy3:'1. 登录中国国际经济贸易仲裁委员会（“贸仲”）官网www.cietac.org首页，点击“在线立案”。',
          zczy4:'2.直接输入www.cietacodr.org，进入智慧平台。',
          zczy5:'新用户请先行注册。您可在平台首页右下角点击“立即注册”，使用手机、邮箱任一方式均可进行注册。',
          zczy6:'1. 手机注册',
          zczy7:'系统默认优先使用手机注册。请按照要求填写手机号、邮箱、姓名、证件号码，同时设置密码，点击“发送”通过手机短信获取验证码并输入。',
          zczy8:'上述信息填写完毕后，请注意阅读并勾选“《服务协议》”，勾选后，点击“立即注册”（如图）。',
          zczy9:'2. 邮箱注册',
          zczy10:'请在注册信息的页面上部，切换为“邮箱注册”，如下图：',
          zczy11:'同上按要求填写各项信息，通过电子邮箱获取验证码，进行注册。',
          zczy12:'系统提示注册成功后，即自动返回登录首页。',
          zczy13:'二、登录平台',
          zczy14:'1.当事人可通过官网、智慧平台、“中国国际经济贸易仲裁委员会”公众号输入已注册的账号、密码登录智慧平台，为便于您在程序中接收各项通知，建议通过“设置”绑定个人微信。',
          zczy15:'如何绑定微信：',
          zczy16:'登陆智慧平台后，可在右上角点击“设置”，填写个人信息后绑定微信。',
          zczy17:'绑定微信后，下次登陆时即可使用微信扫码登陆。',
          zczy18:'2.忘记密码',
          zczy19:'如果忘记注册账号密码，可在平台首页点击“忘记密码”根据提示进行操作。您可通过手机号或邮箱找回密码。',
          zczy20:'通过手机号找回，需要输入注册时使用的手机号码，点击“发送”发送验证码，输入手机收到的验证码后点击下一步。之后需要重新设置密码，设置完成后，点击确认，回到登录页，输入账号和新设密码即可登录。',
          zczy21:'通过邮箱找回，需要输入注册时使用的邮箱，点击“发送”发送验证码，输入邮箱收到的验证码后点击下一步。之后需要重新设置密码，设置完成后，点击确认，回到登录页，输入账号和新设密码即可登录。',
          // 2025-3-21
          

          //   2025-3-24
          lazy1: '如何新建案件、填写资料并提交贸仲立案审查',
          lazy2: '为了更好地帮助当事人了解、使用贸仲智慧平台，贸仲特推出智慧平台使用指引系列文章。立案篇（一）介绍了如何注册账号及登录智慧平台，详细内容可参见“2024贸仲智慧平台使用指引·立案篇（一）如何注册账号登录贸仲智慧平台”。本篇将向当事人介绍如何新建案件填写资料提交贸仲立案审查。',
          lazy3: '一、新建仲裁案件',
          lazy4: '注册账户并登陆后，进入“我的案件”界面。',
          lazy5: '点击“新建案件”。',
          lazy6: '二、填写案件信息',
          lazy7: '请申请人准确录入相关信息并确保纸质文本与电子文本完全一致。',
          lazy8: '1. 填写双方当事人及代理人信息',
          lazy9: '选择新建案件后，会进入新案件页面。请逐一填写相关信息。',
          lazy10: '请首先按照当事人类型，勾选自然人、法人、其他组织或个体工商户，之后请填写相关当事人信息，*号为必填项。',
          lazy11: '请注意：',
          lazy12: '（1）在上传文件时，请将待上传文件名称改为相应材料名，如身份证、营业执照等；',
          lazy13: '（2）申请人为自然人时，应提交身份证正反面扫描件或其他有效身份证件扫描件，如港澳回乡证、香港居民证、台胞证、护照等（请确保身份证件在有效期内）；',
          lazy14: '（3）如案涉合同当事人存在自然人，请书面告知该自然人是否存在《最高人民法院关于适用<中华人民共和国涉外民事关系法律适用法>若干问题的解释（一）》第十三条（见下文引述）之情形，以便确认本案适用程序。【第十三条 自然人在涉外民事关系产生或者变更、终止时已经连续居住一年以上且作为其生活中心的地方，人民法院可以认定为涉外民事关系法律适用法规定的自然人的经常居所地，但就医、劳务派遣、公务等情形除外。】',
          lazy15: '（4）申请人为中国内地法人时，应上传有效的营业执照扫描件及法定代表人证明书原件（应写明落款日期）扫描件；',
          lazy16: '（5）申请人为港澳台或国外法人的，应提交有效的、由当事人注册地相应机构出具的商业登记证等文件；如提交非英文版本的相关文件，请同时提供与本案仲裁语言一致的翻译件；',
          lazy17: '（6）当事人提交的所有文件，在立案阶段无需公证，如对方当事人提出异议或仲裁庭认为确有必要的，在仲裁程序中可能需要对境外形成的证据进行公证；',
          lazy18: '（7）当事人主体名称需与印章保持一致，如不一致，需提交相应说明文件。当事人名称有变更的，应提交名称变更的相关证据。',
          lazy19: '电子邮箱：',
          lazy20: '请注意：',
          lazy21: '（1）在上传文件时，请将待上传文件名称改为相应材料名，如“身份证”、“营业执照”等（请确保在有效期内）；',
          lazy22: '（2）被申请人为自然人的，申请人可提交其身份证复印件或其他有效身份证件扫描件，如港澳回乡证、香港居民身份证、台胞证、护照等（请确保在有效期内）；',
          lazy23: '（3）被申请人为中国内地法人的，申请人应提交其主体资格仍存续的相关文件，如企信网、企查查等平台打印的相关工商登记信息；',
          lazy24: '（4）被申请人为港澳台或国外法人的，申请人可提交其主体资格仍存续的相关文件；',
          lazy25: '（5）当事人主体名称需与印章保持一致，如不一致，需提交相应说明文件。被申请人名称有变更的，应提交名称变更的相关证据。',
          lazy26: '请注意：',
          lazy27: '（1）在上传文件时，请将待上传文件名称改为相应材料名，如“授权委托书”、“所函”等，并应写明落款日期；',
          lazy28: '（2）当事人委托仲裁代理人的，应提交授权委托书原件扫描件，相关权限应列明“仲裁”而非“诉讼”，请勿仅列明“一般授权”或“特别授权”； ',
          lazy29: '（3）当事人提交的所有文件，在立案阶段无需公证，如对方当事人提出异议或仲裁庭认为确有必要的，在仲裁程序中可能需要对境外形成的证据进行公证；',
          lazy30: '（4）委托人是港澳台或国外法人的，需上传由当事人注册地认可的、有授权的签字人签署或公司盖章的授权委托书原件扫描件。如授权委托书为个人签署，应在授权委托书中用打印字体列明签字人姓名，并提交该签字人有权代表公司对外签署相关法律文书的证据（如董事会决议等）。',
          lazy31: '（5）如存在多个代理人，请分别填写相关信息，确保与授权委托书保持一致。',
          lazy32: '2. 填写送达地址',
          lazy33: '在录入双方当事人信息后，请点击“添加”填写送达地址。',
          lazy34: '请注意，送达信息请务必准确填写！申请人/申请人代理人的手机号码/邮箱中，会收到智慧平台发送的消息通知，因此，请确保准确填写手机号码和邮箱，以免遗漏消息、耽误案件进展。',
          lazy35: '请注意，填写“申请人送达地址”时，一位申请人仅可录入一个送达地址。',
          lazy36: '请注意，申请人有义务查询并提供被申请人有效的送达地址。如申请人未能诚信提供被申请人的有效送达地址，导致案件相关法律文书未能及时送达或无法送达的，申请人应自行承担由此产生的法律后果。',
          lazy37: '申请人可提供被申请人的多个送达地址。',
          lazy38: '附件：送达地址确认书',
          lazy39: '如提供被申请人多个送达地址，请明确送达顺序，贸仲将按照顺序逐一向被申请人寄送仲裁文件。如需同时寄送，亦请提交明确的书面申请，并增加提交相应份数的纸质文件。',
          lazy40: '3. 填写仲裁依据',
          lazy41: '请点击“添加”，录入仲裁依据。',
          lazy42: '请准确录入合同名称、仲裁条款，如有签订日、合同编号和签订地点的，亦请如实录入。',
          lazy43: '（如依据多份合同提起仲裁的，录入一份仲裁依据后，请再次点击“添加”，继续录入相关合同。）',
          lazy44: '请注意：',
          lazy45: '（1）请申请人逐份录入案涉合同名称、编号、签订日期及签订地（如有）。',
          lazy46: '（2）如申请人依据类似订单（合同名称、仲裁条款均一致）提起仲裁，可自行制作书面函件，列明全部案涉合同编号、签订日期等信息，盖章或签字后在证据部分提交。',
          lazy47: '（3）申请人依据多份合同提起仲裁的，请注意《仲裁规则》第十四条的规定，必要时请提交相关说明。',
          lazy48: '第十四条 多合同仲裁及仲裁中追加合同',
          lazy49: '（一）申请人就多个合同项下的争议可在单个仲裁案件中合并提出仲裁申请，但应同时符合下列条件：',
          lazy51: '  1.多个合同系主从合同关系，或多个合同所涉当事人相同且法律关系性质相同，或多个合同所涉标的具有牵连关系；',
          lazy52: '  2.多个合同所涉争议源于同一交易或同一系列交易；',
          lazy53: '  3.多个合同中的仲裁协议内容相同或相容。',
          lazy54: '（二）同时符合上述第（一）款1、2、3项规定情形的，申请人可在仲裁程序中申请追加合同，但上述申请过迟影响仲裁程序正常进行的，可决定不予追加合同。',
          lazy55: '（三）上述第（一）（二）款程序事项，由仲裁委员会仲裁院决定。在仲裁庭组成后提出追加合同申请的，由仲裁庭决定。',
          lazy56: '4. 填写仲裁请求、事实和理由',
          lazy57: '请点击“添加”，录入仲裁请求、事实和理由',
          lazy58: '注意：请依序列明全部仲裁请求，并准确标明序号。',
          lazy59: '5. 保全',
          lazy60: '如申请人需进行财产、证据或行为保全，请点击“添加”后，填写法院名称、法院地址，简要输入保全内容，并上传财产保全申请书原件扫描件。',
          lazy61: '申请人如需向多个法院提交保全，请点击“添加”后，逐一填写上述相关信息。如无需保全，可不填写。',
          lazy62: '请注意：除财产保全申请书外，其它关于财产保全相关文件的准备请以相应法院要求为准。',
          lazy63: '6. 上传申请人证据材料',
          lazy64: '申请人此前上传过的主体资格文件、授权委托书等文件，均已显示在证据材料中，请勿重复上传。仅上传新的证据材料即可。',
          lazy65: '建议申请人提交扫描清晰、大小适中（单个文件约500M）的证据材料。',
          lazy66: '请注意：',
          lazy67: '(1). 提交证据时请尽量以pdf形式提交，请连续编号、编页，注意应复印/扫描清晰、完整，请勿提交单张照片或单张pdf扫描件；',
          lazy68: '(2). 在上传证据文件时，请将待上传文件名称改为该证据名称，或列明证据几至证据几；',
          lazy69: '(3). 原始证据为英文的，在立案阶段不需要提供翻译；如案涉合同为其他语言的，在立案阶段应提供翻译（语言应与本案仲裁语言保持一致）。如对方当事人提出异议或仲裁庭认为确有必要的，在仲裁程序中可能需要对外文证据进行翻译。',
          lazy70: '7. 提交立案、取得立案编号',
          lazy71: '准确填写完毕上述信息后，点击“提交至贸仲”。请注意，如未点击“提交至贸仲”，则案件将仅作为草稿保存在您的系统中。',
          lazy72: '点击“提交至贸仲”后，请注意根据仲裁条款的约定和《仲裁规则》的规定，先选择应提交总会/分会后，再次点击“提交至贸仲”。',
          lazy73: '提示：中国国际经济贸易仲裁委员会即北京总会',
          lazy74: '点击“提交至贸仲”后，会提示立案编号，在立案阶段，请牢记您的立案编号。',
          
        //   2025-3-24
            
          
          //   2025--4-3
          lazy75: '三、在线立案的审查流程及沟通要点',
          lazy76: '二、新建案件、填写资料并提交贸仲立案审查',
          lazy77: '为了更好地帮助当事人了解、使用贸仲智慧平台，贸仲特推出智慧平台使用指引·立案篇系列文章。立案篇（一）（二）分别介绍了如何注册及登录智慧平台和如何新建案件、填写立案资料并提交至贸仲，详细内容可分别参见“2024贸仲智慧平台使用指引·立案篇（一）如何注册账号登录贸仲智慧平台”“2024贸仲智慧平台使用指引·立案篇（二）如何新建案件、填写资料并提交贸仲立案审查”。本篇将向当事人介绍如何在立案审查环节与贸仲沟通。',
          lazy78: '1.初步审查',
          lazy79: '申请人提交新建案件后，贸仲将尽快对案件进行初步审查。申请人录入的手机号码和邮箱将会收到提醒，请注意及时查看并进行相应修改。',
          lazy80: '初审通过后，案件将确认立案秘书，正式进入立案审查阶段。',
          lazy81: '您可看到的初审通过页面',
          lazy82: '您可看到的初审未通过页面，请注意查看相关原因并及时修改提交',
          lazy83: '2. 联系立案秘书',
          lazy84: '在案件确定立案秘书后，申请人可登录平台，在“我的案件”界面中查看立案秘书的姓名及电话。如有疑问，申请人可与立案秘书联系沟通。',
          lazy85: '申请人也可通过“留言板”填写留言并提交，与立案秘书沟通案件事宜。',
          lazy86: '3.案件退回',
          lazy87: '立案审查后如需修改或补充提交材料，立案秘书会将案件退回至申请人系统中，并提示需修改或补充事项，以便申请人修改相关信息或补充上传立案所需文件。申请人录入的手机号码和邮箱将会收到案件退回的提醒，请注意及时查看并进行相应修改。',
          lazy88: '在申请人按照相关要求修改或补充上传材料后，请注意及时点击“提交至贸仲”，以免耽误案件进度。',
          lazy89: '4.立案审查通过',
          lazy90: '立案审查通过后，申请人录入的手机号码或邮箱将会收到审查通过的提醒，请注意及时查看并进行相应操作：',
          lazy91: '（1）请注意通过“消息·待办”或点击具体案件，可查看审查通过的通知，并按照通知要求提交纸质材料。',
          lazy92: '（2）请在“我的案件”板块，选择“通知·指令”，及时查看PDF版缴费通知并按照通知缴纳仲裁预付金。',
          lazy93: '审批通过后您可看到如下页面：',
          lazy94: '（3）点击案件列表中“生成PDF版仲裁申请书”下对应案件的“PDF”按钮，可预览或下载生成PDF版仲裁申请书。请打印PDF版仲裁申请书并按（1）项通知要求向贸仲立案处寄送相应份数的仲裁申请书及其他材料。（1.该PDF版仲裁申请书系经贸仲立案审查后版本，为提高立案效率，请向贸仲提交加盖公章或代理人签字后的该版纸质仲裁申请书。',
          lazy95: '2.除仲裁申请书、证据及主体资格文件外，在线立案阶段提交的相应说明请盖章或代理人签字后一并提交纸质版。）',
          lazy96: '依（3）项生成PDF版仲裁申请书后，请备妥全部仲裁文件，尽快寄送至贸仲立案处；同时，请尽快缴纳仲裁费（缴费及寄送材料无先后顺序）。案件将于贸仲立案处收到申请人提交的书面仲裁文件及预缴的仲裁费后进入下一步仲裁程序。',
          lazy97: '至此，在线立案流程结束。',
          lazy98: '贸仲支持并鼓励当事人使用智慧平台进行仲裁案件中的证据交换和文件送达。当事人通过智慧平台提交立案时上传的所有电子文件，将被同步至案件电子卷宗中，无需再次上传即可使用。',
          lazy99: '感谢您使用贸仲智慧平台，我们期待给您带来更加专业、高效和便捷的仲裁服务！',
         
        //   2025--4-3
            
            
    },
  },
};

export default messages;