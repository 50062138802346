<template>
  <div>
    <!-- <h1>历史案件查询</h1> -->
    <el-card>
      <el-row>
        <el-col :span="8">
          <el-row>
            <el-col :span="6">
              <!-- <span>16年前后</span> -->
            </el-col>
            <el-col :span="18">
              <el-select
                style="width: 100%"
                v-model="remFlag"
                :placeholder="$t('msg.PleaseSelect')"
                @change="changeRemFlag"
              >
                <el-option :label="$t('msg.Compensationnquiryding')" :value="2">
                </el-option>
                <el-option
                  :label="$t('msg.Compensationinquiryudingcluding')"
                  :value="1"
                >
                </el-option> </el-select
            ></el-col>
          </el-row>
        </el-col>
        <el-col :span="8">
          <el-row>
            <el-col :span="6"
              ><span>{{ $t("msg.Queryyear") }}</span></el-col
            >
            <el-col :span="18">
              <!-- 2016以前  -->
              <el-select
                v-if="remFlag !== 2"
                v-model="infoForm.year"
                :placeholder="$t('msg.PleaseSelect')"
                style="width: 100%"
              >
                <el-option label="全部" value=""> </el-option>
                <el-option
                  v-for="item in oldFrontYear"
                  :key="item"
                  :label="item + '年'"
                  :value="item"
                >
                </el-option>
              </el-select>

              <el-select
                v-else
                v-model="infoForm.year"
                :placeholder="$t('msg.PleaseSelect')"
                style="width: 100%"
              >
                <el-option label="全部" value=""> </el-option>
                <el-option
                  v-for="item in newFrontYear"
                  :key="item"
                  :label="item + '年'"
                  :value="item"
                >
                </el-option>
              </el-select>

              <!-- 2016以后  -->

              <!-- <el-date-picker
                            style="width: 100%;"
      v-model="pickerValue"
       @change="changeDate"
      type="monthrange"
      range-separator="至"
      start-placeholder="开始月份"
      end-placeholder="结束月份">
    </el-date-picker> -->
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="8">
          <el-row v-if="remFlag === 2">
            <el-col :span="6"
              ><span>{{ $t("msg.Querymonth") }}</span></el-col
            >
            <el-col :span="18">
              <el-select
                v-model="infoForm.month"
                :placeholder="$t('msg.PleaseSelect')"
                style="width: 100%"
              >
                <el-option label="全部" value=""> </el-option>
                <el-option
                  v-for="item in 12"
                  :key="item"
                  :label="item + '月'"
                  :value="item"
                >
                </el-option>
              </el-select>
            </el-col>
          </el-row>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="8">
          <el-row>
            <el-col :span="6"
              ><span>{{ $t("msg.case") }}</span></el-col
            >
            <el-col :span="18"
              ><el-input
                v-model="infoForm.caseNo"
                :placeholder="$t('msg.Pleaseenterhecasenumber')"
              ></el-input
            ></el-col>
          </el-row>
        </el-col>
        <!-- <el-col :span="8"> 
                    <el-row>
                        <el-col :span="6"><span>{{$t('msg.Nameofparty')}}</span></el-col>
                        <el-col :span="18"
                            ><el-input
                                v-model="infoForm.partyName"
                                :placeholder="$t('msg.Pleaseenterthenameparty')"
                            ></el-input
                        ></el-col>
                    </el-row>
                </el-col> -->

        <el-col :span="8">
          <!-- <el-row>
            <el-col :span="6"
              ><span>{{ $t("msg.Nameofthehandler") }}</span></el-col
            >
            <el-col :span="18"
              >
              <el-input
                v-model="infoForm.handleName"
                :placeholder="$t('msg.Pleaseenternamehandler')"
              ></el-input
            ></el-col>
          </el-row> -->
        </el-col>

        <el-col :span="8">
          <!-- <el-row v-if="remFlag !== 2">
            <el-col :span="6"
              ><span>{{ $t("msg.FinancialNumber") }}</span></el-col
            >
            <el-col :span="18"
              ><el-input
                v-model="infoForm.fncNo"
                :placeholder="$t('msg.FinancialNumber')"
              ></el-input
            ></el-col>
          </el-row> -->
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="8">
          <!-- <el-row>
                        <el-col :span="6"><span>{{$t('msg.FinancialNumber')}}</span></el-col>
                        <el-col :span="18"
                            ><el-input
                                v-model="infoForm.fncNo"
                                :placeholder="$t('msg.FinancialNumber')"
                            ></el-input
                        ></el-col>
                    </el-row> -->
          <!-- <el-row>
                        <el-col :span="6"><span>{{$t('msg.CaseStatus')}}</span></el-col>
                        <el-col :span="18"
                            >
                            <el-select
                                :placeholder="$t('msg.PleaseSelect')"
                                style="width: 100%"
                                   v-model="infoForm.caseState"
                            >
                            <el-option
                                    label="全部"
                                    :value="0"
                                >
                                </el-option>
                                <el-option
                                    label="未结"
                                    :value="1"
                                >
                                </el-option>
                                <el-option
                                    label="已结"
                                    :value="2"
                                >
                                </el-option>
                             
                            </el-select>
                        </el-col>
                    </el-row> -->
        </el-col>
        <el-col :span="8">
          <!-- <el-row>
                        <el-col :span="6"><span>{{$t('msg.FinancialNumber')}}</span></el-col>
                        <el-col :span="18"
                            ><el-input
                                v-model="infoForm.fncNo"
                                :placeholder="$t('msg.FinancialNumber')"
                            ></el-input
                        ></el-col>
                    </el-row> -->
        </el-col>
        <el-col :span="8">
          <!-- <el-row>
                        <el-col :span="6"><span>{{$t('msg.DistributionStatus')}}</span></el-col>
                        <el-col :span="18"
                            ><el-input
                                v-model="infoForm.caseNo"
                                :placeholder="$t('msg.DistributionStatus')"
                            ></el-input
                        ></el-col>
                    </el-row> -->
        </el-col>
      </el-row>

   
      <el-row>
        <div class="blackBtnBox">
          <el-button type="primary" @click="clickQueryBtn">
            {{ $t("msg.case_search") }}
          </el-button>
          <el-button @click="clickResetting">
            {{ $t("msg.case_reset") }}
          </el-button>
          <el-button type="info" @click="clickGomyCaseList">{{
            $t("msg.back")
          }}</el-button>
        </div>
      </el-row>
    </el-card>

    <el-table
    v-if="remFlag === 2"
      v-loading="loading"
      :data="tableData"
      style="width: 100%"
      :summary-method="getSummaries"
      show-summary
      :row-class-name="tableRowClassName"
      :span-method="spanMethod"
    >
      <el-table-column prop="year" label="年" width="80"></el-table-column>
      <el-table-column prop="month" label="月" width="100"></el-table-column>
      <!-- <el-table-column prop="caseNo" :label="$t('msg.case')"> </el-table-column> -->
      <el-table-column prop="arbSumAmt" :label="$t('msg.PreTaxIncome')">
        <template #default="scope">
          <span >{{ formatAmount(scope.row.arbSumAmt) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="arbSumTax" :label="$t('msg.personalIncomeTax')">
        <template #default="scope">
          <span >{{ formatAmount(scope.row.arbSumTax) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="arbSumGet" :label="$t('msg.ActualAmountReceived')">
        <template #default="scope">
          <span >{{ formatAmount(scope.row.arbSumGet) }}</span>
        </template>
      </el-table-column>
      <el-table-column :label="$t('msg.Issuestatus')">
        <div>
          <span>{{ $t("msg.Sent") }}</span>
        </div>
      </el-table-column>

      <!-- 银行账号 -->
      <el-table-column :label="$t('msg.BankAccount')">
        <template #default="scope">
          <span >{{ formatBankAccount(scope.row.bankNo) }}</span>
        </template>
      </el-table-column>

      <el-table-column label="详情">
        <template #default="scope">
          <el-button type="text" @click="openDrawerType(scope.row)"
            >详情</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    
    <el-table v-else v-loading="loading" :data="tableData" style="width: 100%" :summary-method="getBeforeSummaries" show-summary
            :row-class-name="tableRowClassName" :span-method="spanMethod">
         
            <el-table-column :label="$t('msg.S_N')" type="index" width="70px">
            </el-table-column>
            <el-table-column prop="caseNo" :label="$t('msg.case')"> </el-table-column>
            <el-table-column prop="arbSumAmt" :label="$t('msg.PreTaxIncome')">
                <template #default="scope">
          <span >{{ formatAmount(scope.row.arbSumAmt) }}</span>
        </template>
            </el-table-column>
            <el-table-column prop="arbSumTax" :label="$t('msg.personalIncomeTax')">
                <template #default="scope">
          <span >{{ formatAmount(scope.row.arbSumTax) }}</span>
        </template>
            </el-table-column>
            <el-table-column prop="arbSumGet" :label="$t('msg.ActualAmountReceived')">
                <template #default="scope">
          <span >{{ formatAmount(scope.row.arbSumGet) }}</span>
        </template>
            </el-table-column>
            <el-table-column :label="$t('msg.Issuestatus')">
                <div>
                    <span>{{ $t('msg.Sent') }}</span>
                </div>
            </el-table-column>
            <el-table-column prop="fncNo" :label="$t('msg.FinancialNumber')"> </el-table-column>


        </el-table>

    <div class="paginationBox">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="current"
        :page-sizes="[100, 200, 300, 400]"
        :page-size="size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>

    <el-drawer v-model="drawerType" direction="rtl" size="50%">
      <div>
        <h1 style="margin-top: 0">
          {{ this.drawerMoneyObj.year }}年{{
            this.drawerMoneyObj.month
          }}月份薪酬
        </h1>
        <el-table
          :data="drawerObj"
          style="width: 100%"
          :summary-method="getInfoSummaries"
          show-summary
          :row-class-name="tableRowClassName"
          :span-method="spanMethod"
        >
          <!-- <el-table-column prop="year" label="年" width="80"></el-table-column> -->
          <!-- <el-table-column prop="month" label="月" width="80"></el-table-column> -->
          <el-table-column prop="caseNo" :label="$t('msg.case')">
          </el-table-column>
          <el-table-column prop="arbSumAmt" :label="$t('msg.PreTaxIncome')">
            <template #default="scope">
          <span >{{ formatAmount(scope.row.arbSumAmt) }}</span>
        </template>
          </el-table-column>
          <el-table-column prop="" :label="$t('msg.personalIncomeTax')">
            <template >
          <!-- <span >{{ formatAmount(scope.row.arbSumTax) }}</span> -->
        </template>
          </el-table-column>
          <el-table-column prop="" :label="$t('msg.ActualAmountReceived')">
            <template >
          <!-- <span >{{ formatAmount(scope.row.arbSumGet) }}</span> -->
        </template>
          </el-table-column>
          <el-table-column :label="$t('msg.Issuestatus')">
            <div>
              <span>{{ $t("msg.Sent") }}</span>
            </div>
          </el-table-column>
        </el-table>
      </div>
    </el-drawer>
  </div>
</template>

<script>
export default {
  name: "RemunerationQuery",
  components: {},
  data() {
    return {
      tableData: [],
      // 默认展示
      remFlag: 2,
      infoForm: {
        // 案件编号
        caseNo: "",
        // 当事人名称
        partyName: "",
        // 经办人姓名
        handleName: "",

        // 财务编号
        fncNo: "",
      },
      // 2004-2015年渲染数组
      oldFrontYear: [
        2004, 2005, 2006, 2007, 2008, 2009, 2010, 2011, 2012, 2013, 2014, 2015,
      ],
      // 新数组
      newFrontYear: [],
      size: 100,
      current: 1,
      total: 0,
      loading: false,
      pickerValue: "",

      // 抽屉状态
      drawerType: false,
      // 抽屉展示列表数据
      drawerObj: {},
      // 携带进去的展示金额
      drawerMoneyObj: {},
    };
  },

  methods: {
    load() {
      this.clickResetting();
    },

    // 请求列表
    async ArbRemuneration() {
      this.loading = true;
      let param = {
        req: {
          q: {
            remFlag: this.remFlag,
            ...this.infoForm,
          },
          current: this.current,
          size: this.size,
        },
      };
      let { data } = await this.$API.sendRequest("ArbRemuneration", param);
      this.total = data.total;
      this.tableData = data.records;
      this.loading = false;
    },

    clickQueryBtn() {
      this.ArbRemuneration();
    },
    // 点击了返回
    clickGomyCaseList() {
      this.$emit("gomyCaseList");
    },

    // 本页的合计
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 1) {
          sums[index] = "本页合计";
          return;
        }
        let values = data.map((item) => {
          return Number(item[column.property]);
        });
        if (!values.every((value) => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
          if (index === 2 || index === 3 || index === 4 ) {
            sums[index] = "¥" + this.formatAmount(sums[index]) + " 元";
          } else {
            sums[index] = "";
          }
        } else {
          sums[index] = " ";
        }
      });
      return sums;
    },
 // 2016年以前的本页的合计
 getBeforeSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 1) {
          sums[index] = "本页合计";
          return;
        }
        let values = data.map((item) => {
          return Number(item[column.property]);
        });
        if (!values.every((value) => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
          if ( index === 2 ||index === 3 || index === 4  ) {
            sums[index] = "¥" + this.formatAmount(sums[index]) + " 元";
          } else {
            sums[index] = "";
          }
        } else {
          sums[index] = " ";
        }
      });
      return sums;
    },

    // 本页的合计
    getInfoSummaries(param) {
      const { columns } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "本页合计";
          return;
        }
        if (index === 1) {
          sums[index] = "¥" + this.formatAmount(this.drawerMoneyObj.arbSumAmt) 
          return;
        }
        if (index === 2) {
          sums[index] =  "¥" + this.formatAmount(this.drawerMoneyObj.arbSumTax) 
          return;
        }
        if (index === 3) {
          sums[index] = "¥" + this.formatAmount(this.drawerMoneyObj.arbSumGet);
          return;
        }
        if (index === 4) {
          sums[index] = "已发";
          return;
        }
      });
      return sums;
    },

    // 金额使用，号划分
    formatAmount(amount) {
      // 将金额四舍五入到两位小数，并转换为字符串
        amount = Number(amount) ? Number(amount).toFixed(2) : 0;
      let strAmount = String(amount).trim();
      // 使用正则表达式匹配最后一个小数点后的数字（如果有）
      const match = strAmount.match(/(\.\d+)$/);

      // 分割整数部分，每三位添加逗号
      const integerPart = strAmount
        .replace(/\.\d+$/, "")
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");

      // 如果存在小数部分，则将整数部分和小数部分拼接起来
      if (match) {
        return integerPart + match[1];
      } else {
        return integerPart;
      }
    },

    // 点击了重置数据
    clickResetting() {
      this.remFlag = 2;
      this.current = 1;
      this.size = 100;
      this.infoForm = {
        // 开始年份
        startYear: "",
        // 开始月份
        startMonth: "",
        // 结束年份
        endYear: "",
        // 结束月份
        endMonth: "",
        // 案件编号
        caseNo: "",
        // 当事人名称
        partyName: "",
        // 经办人姓名
        handleName: "",
        // 案件状态
        caseState: 0,
      };
      this.getNewFrontYear();
      this.ArbRemuneration();
    },

    // 获取2016年到当前年的方法为数组
    getNewFrontYear() {
      // 定义起始年份和结束年份
      const startYear = 2016;
      const endYear = new Date().getFullYear();
      // 创建一个空数组来存储所有年份
      let years = [];
      // 循环从起始年份到结束年份，将每个年份添加到数组中
      for (let year = startYear; year <= endYear; year++) {
        years.unshift(year);
      }
      // 输出所有年份
      this.newFrontYear = years;
    },

    // 如果选择年份的下拉框改变了就重新请求
    changeRemFlag() {
      this.current = 1;
      this.size = 100;
      this.infoForm = {
        // 开始年份
        startYear: "",
        // 开始月份
        startMonth: "",
        // 结束年份
        endYear: "",
        // 结束月份
        endMonth: "",
        // 案件编号
        caseNo: "",
        // 当事人名称
        partyName: "",
        // 经办人姓名
        handleName: "",
        // 案件状态
        caseState: 0,
      };
      this.ArbRemuneration();
    },

    // 每页条数改变
    handleSizeChange(e) {
      this.current = 1;
      this.size = e;
      this.ArbRemuneration();
    },
    handleCurrentChange(e) {
      this.current = e;
      this.ArbRemuneration();
    },

    tableRowClassName({ rowIndex }) {
      if (rowIndex % 2 === 0) {
        return "warning-row";
      } else {
        return "";
      }
    },

    changeDate() {
      this.infoForm.startMonth = this.pickerValue[0].getMonth() + 1;
      this.infoForm.startYear = this.pickerValue[0].getFullYear();
      this.infoForm.endMonth = this.pickerValue[1].getMonth() + 1;
      this.infoForm.endYear = this.pickerValue[1].getFullYear();
    },

    // objectSpanMethod({ row, column, rowIndex, columnIndex }) {
    spanMethod({ rowIndex, column }) {
      if (column.property === "year" || column.property === "month") {
        let spanCount = 1;
        // 如果当前行的 year 和 month 与上一行相同，则合并单元格
        if (
          rowIndex > 0 &&
          this.tableData[rowIndex].year === this.tableData[rowIndex - 1].year &&
          this.tableData[rowIndex].month === this.tableData[rowIndex - 1].month
        ) {
          spanCount = 0; // 如果当前行的年和月与上一行相同，则不进行合并
        }
        return { rowspan: spanCount, colspan: 1 };
      }
      return { rowspan: 1, colspan: 1 }; // 对其他列不做合并
    },
    async arbRemunerationInfoFn(year, month) {
      this.loading = true;
      let param = {
        req: {
          year,
              month,
          caseNo: this.infoForm.caseNo
        },
      };
      let res = await this.$API.sendRequest("arbRemunerationInfo", param);
      if (!res.success) {
        this.loading = false;
        return this.$message.error(res.message);
      }
      this.drawerObj = res.data;
      this.loading = false;
    },

    // 打开抽屉
    async openDrawerType(row) {
      this.drawerMoneyObj = row;
      // return
      await this.arbRemunerationInfoFn(row.year, row.month);
      this.drawerType = true;
      },

      // 银行账号格式化...
  formatBankAccount(account) {
    if (!account || account.length <= 8) {
      return account; // 如果账号长度小于等于8，则不进行格式化
    }
    return account.slice(0, 4) + '*'.repeat(account.length - 8) + account.slice(-4);
  },
  },
};
</script>

<style scoped lang="scss">
.el-row {
  margin: 10px 0;

  span {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.blackBtnBox {
  width: 100%;
  display: flex;
  justify-content: center;
}

.paginationBox {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

:deep(.el-table) .warning-row {
  background: #f5f7fa !important;
}

:deep(.el-drawer__header) {
  margin-bottom: 0 !important;
}

:deep(.el-table__footer-wrapper) tbody td.el-table__cell {
  font-weight: 600;
  font-size: 16px;
  background: rgb(246, 238, 217);
}
</style>
